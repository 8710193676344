import React from 'react';
import PropTypes from 'prop-types';

export const CheckmarkIcon = ({ color }) => {
   return (
      <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
         <g id='22.-Checkout' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Artboard' transform='translate(0.000000, -4.000000)' fill={ color }>
               <g id='Row-/-Plans-'>
                  <g id='Icon/Checkbox' transform='translate(0.000000, 4.000000)'>
                     <path d='M10.4334677,16.8415726 C10.24375,17.0297581 9.93737903,17.0285484 9.74919355,16.8388306 L6.28637097,13.3480242 C6.09818548,13.1583065 6.09939516,12.8519355 6.2891129,12.66375 L6.63266129,12.3229839 C6.82237903,12.1347984 7.12875,12.1360081 7.31693548,12.3257258 L10.0982258,15.1294758 L16.6885887,8.59197581 C16.8783065,8.40379032 17.1846774,8.405 17.3728629,8.59471774 L17.713629,8.93826613 C17.9018548,9.12798387 17.9006048,9.43435484 17.7108871,9.62254032 L10.4334677,16.8415726 Z' id='Shape' />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const UncheckedCircleIcon = ({ color }) => {
   return (
      <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
         <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Icon-/-Radio-Button-/-Unchecked'>
               <polygon id='Path' points='0 0 24 0 24 24 0 24' />
               <polygon id='Path' points='0 0 24 0 24 24 0 24' />
               <path d='M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z' id='Shape' fill={ color || '#3F4F65' } fillRule='nonzero' />
            </g>
         </g>
      </svg>
   );
};
export const CheckedCircleIcon = ({ color }) => {
   return (
      <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
         <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Icon-/-Radio-Button-/-Checked'>
               <polygon id='Path' points='0 0 24 0 24 24 0 24' />
               <polygon id='Path' points='0 0 24 0 24 24 0 24' />
               <path d='M12,7 C9.24,7 7,9.24 7,12 C7,14.76 9.24,17 12,17 C14.76,17 17,14.76 17,12 C17,9.24 14.76,7 12,7 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z' id='Shape' fill={ color || '#3F4F65' } fillRule='nonzero' />
            </g>
         </g>
      </svg>
   );
};

export const NotSelectedIcon = ({ style, color = '#3F4F65' }) => {
   return (
      <svg width='24px' height='24px' style={ style } viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
         <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Icon-/-Checkbox-/-Not-Selected'>
               <polygon id='Path' points='0 0 24 0 24 24 0 24' />
               <path d='M19,5 L19,19 L5,19 L5,5 L19,5 L19,5 Z M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z' id='Shape' fill={ color } fillRule='nonzero' />
            </g>
         </g>
      </svg>
   );
};

export const SelectedIcon = ({ style, color = '#3F4F65' }) => {
   return (
      <svg width='24px' height='24px' style={ style } viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
         <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Icon-/-Checkbox-/-Selected' className='icon_checkbox_selected'>
               <polygon id='Path' points='0 0 24 0 24 24 0 24' />
               <path d='M19,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.11,21 21,20.1 21,19 L21,5 C21,3.9 20.11,3 19,3 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z' id='Shape' fill={ color } fillRule='nonzero' />
            </g>
         </g>
      </svg>
   );
};

export const MSCheckBoxOffIcon = ({ color, className }) => {
   return (
      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <g fill={ color }>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M1 1v12h12V1H1zm0-1h12c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1z' transform='translate(-658 -873) translate(634 777) translate(0 88) translate(24 8) translate(5 5)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const TextIcon = () => {
   return (
      <div>
         <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
            <g fill='none' fillRule='evenodd'>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M0 0L36 0 36 36 0 36z' transform='translate(-267 -218) translate(222 188) translate(31 30) translate(14)' />
                           <path fill='#C2CEDB' fillRule='nonzero' d='M30 33H6c-.828 0-1.5-.672-1.5-1.5v-27C4.5 3.672 5.172 3 6 3h24c.828 0 1.5.672 1.5 1.5v27c0 .828-.672 1.5-1.5 1.5zm-1.5-3V6h-21v24h21zM12 10.5h12v3H12v-3zm0 6h12v3H12v-3zm0 6h12v3H12v-3z' transform='translate(-267 -218) translate(222 188) translate(31 30) translate(14)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </svg>
      </div>
   );
};

export const BulletIcon = () => {
   return (
      <div>
         <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
            <g fill='none' fillRule='evenodd'>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M0 0H36V36H0z' transform='translate(-401 -350) translate(356 320) translate(31 30) translate(14)' />
                           <g fill='#C2CEDB' fillRule='nonzero'>
                              <path d='M2.25 9C1.005 9 0 10.005 0 11.25s1.005 2.25 2.25 2.25 2.25-1.005 2.25-2.25S3.495 9 2.25 9zm0-9C1.005 0 0 1.005 0 2.25S1.005 4.5 2.25 4.5 4.5 3.495 4.5 2.25 3.495 0 2.25 0zm0 18C1.005 18 0 19.02 0 20.25s1.02 2.25 2.25 2.25 2.25-1.02 2.25-2.25S3.495 18 2.25 18zm4.5 3.75h21v-3h-21v3zm0-9h21v-3h-21v3zm0-12v3h21v-3h-21z' transform='translate(-401 -350) translate(356 320) translate(31 30) translate(14) translate(4.5 7.5)' />
                           </g>
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </svg>
      </div>
   );
};

export const VideoIcon = () => {
   return (
      <div>
         <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
            <g fill='none' fillRule='evenodd'>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M0 0L36 0 36 36 0 36z' transform='translate(-267 -350) translate(222 320) translate(31 30) translate(14)' />
                           <path fill='#C2CEDB' d='M4.5 5.99c0-.822.668-1.49 1.49-1.49h24.02c.822 0 1.49.668 1.49 1.49v24.02c0 .823-.667 1.49-1.49 1.49H5.99c-.823 0-1.49-.667-1.49-1.49V5.99zm3 1.51v21h21v-21h-21zm8.433 5.123l7.319 4.877c.167.112.267.3.267.5s-.1.388-.267.5l-7.32 4.878c-.184.121-.42.133-.615.029-.194-.104-.316-.307-.317-.527v-9.76c0-.22.122-.424.317-.528.196-.104.432-.092.616.03z' transform='translate(-267 -350) translate(222 320) translate(31 30) translate(14)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </svg>
      </div>
   );
};

export const ButtonIcon = () => {
   return (
      <div>
         <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
            <g fill='none' fillRule='evenodd'>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M0 0H36V36H0z' transform='translate(-133 -350) translate(88 320) translate(31 30) translate(14)' />
                           <path fill='#C2CEDB' fillRule='nonzero' d='M33 13.5v9c0 1.65-1.35 3-3 3h-1.5v-3H30v-9H6v9h9v3H6c-1.65 0-3-1.35-3-3v-9c0-1.65 1.35-3 3-3h24c1.65 0 3 1.35 3 3zm-11.25 15l1.635-3.615L27 23.25l-3.615-1.635L21.75 18l-1.635 3.615L16.5 23.25l3.615 1.635L21.75 28.5zM25.5 21l.93-2.07L28.5 18l-2.07-.93L25.5 15l-.93 2.07-2.07.93 2.07.93.93 2.07zm-3.75 7.5l1.635-3.615L27 23.25l-3.615-1.635L21.75 18l-1.635 3.615L16.5 23.25l3.615 1.635L21.75 28.5zM25.5 21l.93-2.07L28.5 18l-2.07-.93L25.5 15l-.93 2.07-2.07.93 2.07.93.93 2.07z' transform='translate(-133 -350) translate(88 320) translate(31 30) translate(14)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </svg>
      </div>
   );
};

export const ImageIcon = () => {
   return (
      <div>
         <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
            <g fill='none' fillRule='evenodd'>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M0 0L36 0 36 36 0 36z' transform='translate(-401 -218) translate(356 188) translate(31 30) translate(14)' />
                           <path fill='#C2CEDB' fillRule='nonzero' d='M28.5 7.5v21h-21v-21h21m0-3h-21c-1.65 0-3 1.35-3 3v21c0 1.65 1.35 3 3 3h21c1.65 0 3-1.35 3-3v-21c0-1.65-1.35-3-3-3zm-7.29 13.29l-4.5 5.805-3.21-3.885L9 25.5h18l-5.79-7.71z' transform='translate(-401 -218) translate(356 188) translate(31 30) translate(14)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </svg>
      </div>
   );
};


export const HeadlineIcon = () => {
   return (
      <div>
         <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36'>
            <g fill='none' fillRule='evenodd'>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M0 0L36 0 36 36 0 36z' transform='translate(-133 -218) translate(88 188) translate(31 30) translate(14)' />
                           <path fill='#C2CEDB' fillRule='nonzero' d='M6 22.5h24v-3H6v3zm0 6h24v-3H6v3zm0-12h24v-3H6v3zM6 7.5L6 10.5 30 10.5 30 7.5z' transform='translate(-133 -218) translate(88 188) translate(31 30) translate(14)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </svg>
      </div>
   );
};

export const TimerIcon = () => {
   return (
      <div>
         <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='alarm_24px'>
               <path id='icon/action/alarm_24px' fillRule='evenodd' clipRule='evenodd' d='M7.17007 3.88071L4.1 6.44071C3.68008 6.79071 3.05007 6.7407 2.69998 6.3107C2.33999 5.89072 2.40005 5.26072 2.81997 4.9007L5.90005 2.3407C6.31997 1.9907 6.94998 2.04071 7.30007 2.47071C7.66006 2.89072 7.6 3.5207 7.17007 3.88071ZM21.1801 4.9007L18.1001 2.3407C17.6801 1.9907 17.0501 2.04071 16.6901 2.47071C16.3401 2.89072 16.4 3.5207 16.8201 3.88071L19.89 6.44071C20.3101 6.79071 20.9401 6.7407 21.3001 6.3107C21.6601 5.89072 21.6001 5.26072 21.1801 4.9007ZM15.87 15.1407L12.5 13.1407V8.61072C12.5 8.21073 12.1801 7.89072 11.7801 7.89072H11.72C11.32 7.89072 11 8.21073 11 8.61072V13.3307C11 13.6807 11.1801 14.0107 11.49 14.1907L15.14 16.3807C15.48 16.5807 15.9201 16.4807 16.12 16.1407C16.33 15.7907 16.22 15.3407 15.87 15.1407ZM12 3.89072C7.03005 3.89072 3.00003 7.92072 3.00003 12.8907C3.00003 17.8607 7.03005 21.8907 12 21.8907C16.97 21.8907 21 17.8607 21 12.8907C21 7.92072 16.97 3.89072 12 3.89072ZM5.00003 12.8907C5.00003 16.7507 8.14004 19.8907 12 19.8907C15.86 19.8907 19 16.7507 19 12.8907C19 9.03074 15.86 5.89072 12 5.89072C8.14004 5.89072 5.00003 9.03074 5.00003 12.8907Z' fill='#C2CEDB' fillOpacity='1' />
            </g>
         </svg>
      </div>
   );
};

export const CodeIcon = () => {
   return (
      <div>
         <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='code_24px'>
               <path id='icon/action/code_24px' fillRule='evenodd' clipRule='evenodd' d='M19.2 12L15.3 15.9C15.1128 16.0849 15.0075 16.3369 15.0075 16.6C15.0075 16.8631 15.1128 17.1151 15.3 17.3C15.69 17.69 16.31 17.69 16.7 17.3L21.2899 12.7C21.68 12.31 21.68 11.68 21.2899 11.29L16.7 6.7C16.31 6.31 15.69 6.31 15.3 6.7C15.1128 6.88486 15.0075 7.13695 15.0075 7.4C15.0075 7.66306 15.1128 7.91515 15.3 8.10001L19.2 12ZM4.79996 12L8.69998 15.9C9.09 16.29 9.09 16.91 8.69998 17.3C8.30997 17.69 7.68997 17.69 7.29996 17.3L2.70999 12.7C2.31998 12.31 2.31998 11.68 2.70999 11.29L7.29996 6.7C7.48489 6.51285 7.73697 6.40752 8.00003 6.40752C8.26309 6.40752 8.51517 6.51285 8.69998 6.7C9.09 7.09 9.09 7.71 8.69998 8.09999L4.79996 12Z' fill='#C2CEDB' fillOpacity='1' />
            </g>
         </svg>
      </div>
   );
};

export const FAQIcon = () => {
   return (
      <div>
         <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='question_answer_24px'>
               <path id='icon/action/question_answer_24px' fillRule='evenodd' clipRule='evenodd' d='M17 4V11C17 12.1 16.1 13 15 13H6L2 17V4C2 2.89999 2.90002 2 4 2H15C16.1 2 17 2.89999 17 4ZM19 6H20C21.1 6 22 6.89999 22 8V22L18 18H8C6.90002 18 6 17.1 6 16V15H18C18.55 15 19 14.55 19 14V6Z' fill='#C2CEDB' fillOpacity='1' />
            </g>
         </svg>

      </div>
   );
};

export const BannerIcon = () => {
   return (
      <div>
         <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='chrome_reader_mode_24px'>
               <path id='icon/action/chrome_reader_mode_24px' fillRule='evenodd' clipRule='evenodd' d='M21 3.5H3C1.90002 3.5 1 4.39999 1 5.5V18.5C1 19.6 1.90002 20.5 3 20.5H21C22.1 20.5 23 19.6 23 18.5V5.5C23 4.39999 22.1 3.5 21 3.5ZM21 17.5C21 18.05 20.55 18.5 20 18.5H12V5.5H20C20.55 5.5 21 5.95 21 6.5V17.5ZM13.75 9H19.25C19.66 9 20 9.34 20 9.75C20 10.16 19.66 10.5 19.25 10.5H13.75C13.34 10.5 13 10.16 13 9.75C13 9.34 13.34 9 13.75 9ZM19.25 11.5H13.75C13.34 11.5 13 11.84 13 12.25C13 12.66 13.34 13 13.75 13H19.25C19.66 13 20 12.66 20 12.25C20 11.84 19.66 11.5 19.25 11.5ZM13.75 14H19.25C19.66 14 20 14.34 20 14.75C20 15.16 19.66 15.5 19.25 15.5H13.75C13.34 15.5 13 15.16 13 14.75C13 14.34 13.34 14 13.75 14Z' fill='#C2CEDB' fillOpacity='1' />
            </g>
         </svg>
      </div>
   );
};


export const CallToActionIcon = () => {
   return (
      <div>
         <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM8 16H16C16.55 16 17 15.1 17 14C17 12.9 16.55 12 16 12H8C7.45 12 7 12.9 7 14C7 15.1 7.45 16 8 16Z' fill='#C2CEDB' fillOpacity='1' />
         </svg>
      </div>
   );
};

MSCheckBoxOffIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,

};

MSCheckBoxOffIcon.defaultProps = {
   color: '#DCDFE3',
};


export const MSCheckBoxOnIcon = ({ color, className }) => {
   return (
      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <g>
                        <g fill={ color }>
                           <path d='M1.143 1.143v13.714h13.714V1.143H1.143zm0-1.143h13.714C15.488 0 16 .512 16 1.143v13.714c0 .631-.512 1.143-1.143 1.143H1.143C.512 16 0 15.488 0 14.857V1.143C0 .512.512 0 1.143 0z' transform='translate(-658 -833) translate(634 777) translate(0 48) translate(24 8) translate(4 4)' />
                        </g>
                        <path fill='#7CB740' d='M19 4c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h14zm-2.17 4.705c-.347-.347-.91-.347-1.257 0l-4.705 4.704-1.795-1.795-.084-.074c-.348-.271-.853-.247-1.173.074-.347.347-.347.91 0 1.257l2.424 2.424.084.074c.349.271.853.247 1.173-.074l5.334-5.333.074-.084c.27-.349.246-.853-.074-1.173z' transform='translate(-658 -833) translate(634 777) translate(0 48) translate(24 8)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const AdvancedsettingsIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <g>
                        <path d='M0 0L24 0 24 24 0 24z' transform='translate(-459 -160) translate(280 144) translate(155) translate(24 16)' />
                        <g>
                           <path d='M0 0L24 0 24 24 0 24z' transform='translate(-459 -160) translate(280 144) translate(155) translate(24 16)' />
                           <path fill='#C2CEDB' fillRule='nonzero' d='M12.414 5H21c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H3c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1h7.414l2 2zm-3.823 8.809l-.991.572 1 1.731.991-.572c.393.371.872.653 1.405.811v1.145h1.999V16.35c.525-.157 1.006-.434 1.404-.811l.991.572 1-1.73-.991-.573c.127-.533.127-1.089 0-1.622l.99-.573-.999-1.73-.992.572c-.398-.377-.879-.655-1.404-.812V8.5h-1.999v1.144c-.525.157-1.006.435-1.404.812L8.6 9.883l-1 1.732.991.572c-.127.533-.127 1.089 0 1.622zm3.404.688c-.549.02-1.064-.261-1.345-.733-.28-.472-.28-1.06 0-1.532s.796-.753 1.345-.733c.807.03 1.445.692 1.445 1.499 0 .807-.638 1.47-1.445 1.499z' transform='translate(-459 -160) translate(280 144) translate(155) translate(24 16)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const TemplateIcon = () => {
   return (
      <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M0.992 0C0.44556 0.0043584 0.00380782 0.446555 0 0.993V17.007C0 17.555 0.443972 17.9994 0.992 18H19.008C19.5544 17.9956 19.9962 17.5534 20 17.007V0.993C20 0.445 19.556 0 19.008 0H0.992ZM4 14V12H16V14H4ZM4 4V10H10V4H4ZM12 6V4H16V6H12ZM12 8V10H16V8H12ZM8 6H6V8H8V6Z' fill='#C2CEDB' />
      </svg>
   );
};

export const TrackingIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <g>
                        <path d='M0 0L24 0 24 24 0 24z' transform='translate(-700 -160) translate(280 144) translate(396) translate(24 16)' />
                        <g>
                           <path d='M0 0L24 0 24 24 0 24z' transform='translate(-700 -160) translate(280 144) translate(396) translate(24 16)' />
                           <path fill='#C2CEDB' fillRule='nonzero' d='M17.618 5.968l1.453-1.453 1.414 1.414-1.453 1.453c2.981 3.731 2.528 9.142-1.032 12.326-3.56 3.184-8.986 3.033-12.364-.344C2.26 15.986 2.108 10.559 5.292 7c3.184-3.56 8.595-4.013 12.326-1.032zM11 8v6h2V8h-2zM8 1h8v2H8V1z' transform='translate(-700 -160) translate(280 144) translate(396) translate(24 16)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const TestimonialLeftIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <path d='M0 0H40V40H0z' transform='translate(-100 -1183) translate(100 1103) matrix(-1 0 0 1 40 80)' />
                     <path fill='#DCDFE3' d='M25 20L18.464 26.667 16.667 24.917 21.487 20 16.667 15.083 18.464 13.333z' transform='translate(-100 -1183) translate(100 1103) matrix(-1 0 0 1 40 80)' />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const TestimonialRightIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <path d='M0 0H40V40H0z' transform='translate(-628 -1183) translate(100 1103) translate(528 80)' />
                     <path fill='#DCDFE3' d='M25 20L18.464 26.667 16.667 24.917 21.487 20 16.667 15.083 18.464 13.333z' transform='translate(-628 -1183) translate(100 1103) translate(528 80)' />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const BulletCheckIcon = ({ color }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <g fill={ color || '#BDBDD2' }>
               <g>
                  <g>
                     <g>
                        <g>
                           <path d='M10.433 16.842c-.19.188-.496.187-.684-.003l-3.463-3.491c-.188-.19-.187-.496.003-.684l.344-.341c.19-.188.496-.187.684.003l2.781 2.803 6.59-6.537c.19-.188.497-.187.685.003l.34.343c.189.19.188.496-.002.685l-7.278 7.219z' transform='translate(-156 -701) translate(156 461) translate(0 156) translate(0 80) translate(0 4)' />
                        </g>
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const BulletCheckSecondIcon = ({ color }) => {
   return (
      <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 0h24v24H0z' />
            <path d='M12 11V8l4 4-4 4v-3H8v-2h4zm0-9c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8z' fill={ color } />
         </g>
      </svg>
   );
};

BulletCheckIcon.propTypes = {
   color: PropTypes.string,
};

BulletCheckSecondIcon.propTypes = {
   color: PropTypes.string,
};

MSCheckBoxOnIcon.propTypes = {
   color: PropTypes.string,
   className: PropTypes.string,
};

MSCheckBoxOnIcon.defaultProps = {
   color: '#DCDFE3',
};

// Prop Types

CheckmarkIcon.propTypes = {
   color: PropTypes.string,
};

SelectedIcon.propTypes = {
   style: PropTypes.object,
   color: PropTypes.string,
};

NotSelectedIcon.propTypes = {
   style: PropTypes.object,
   color: PropTypes.string,
};

// Default Props

CheckmarkIcon.defaultProps = {
   color: '#006DFF',
};
UncheckedCircleIcon.defaultProps = {
   color: '#3F4F65',
};
UncheckedCircleIcon.propTypes = {
   color: PropTypes.string,
};

CheckedCircleIcon.propTypes = {
   color: PropTypes.string,
};

CheckedCircleIcon.defaultProps = {
   color: '#3F4F65',
};
