import React, { Component, createRef, Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import NaturalDragAnimation from "./NaturalDragAnimation";
import { Icon, Dropdown, Input,Button,Modal } from "semantic-ui-react";
import AppContext from "../../../contexts/AppContext";
import slug from "../../../utils/slugGenerator";
import PropTypes from "prop-types";
import "./index.css";
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'
import Switch from "components/switchNew";
import IconNew from "components/elements/iconsSize";
import DeleteModal from "components/DeleteModal";
import DropTriggle from "components/newDropTriggle";
import IconButton, {THEMES as themes} from "components/IconButton";
import SliceAndConnectText from "utils/slice";
const grid = 8;

const images = {}

images['Header'] = require(`../../../images/sidebar/Header.png`)
images['Hero'] = require(`../../../images/sidebar/Hero.png`)
images['Textwithimage'] = require(`../../../images/sidebar/Textwithimage.png`)
images['Pricing'] = require(`../../../images/sidebar/Pricing.png`)
images['Footer'] = require(`../../../images/sidebar/Footer.png`)

const getItemStyle = (isDragging, draggableStyle) => ({
   // some basic styles to make the items look a bit nicer
   userSelect: "none",
   padding: "12px 16px",
   display: "flex",
   justifyContent: "space-between",
   // change background colour if dragging
   background: isDragging ? "#d7e5f5" : "#fff",

   // styles we need to apply on draggables
   ...draggableStyle,
});

class Item extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sectionName: null,
         sectionRename: false,
         editableSectionOrder: null,
         openModal:false,
         inputRef: createRef(),
      };
   }

   componentDidMount() {
      const sections = document.querySelector('.sidebar_top_section_text');
      if(sections) sections.style = 'color: #fff;'
   }
   renameSection = name => {
      this.setState({
         sectionName: name,
      });
   };
   toggleRenameSection = sectionOrder => {
      this.setState({
         sectionRename: false,
         sectionName: null,
         editableSectionOrder: null,
      });
   };
   handleChangeVisibility = (functions,visibility) => {
      this.setState({
         sectionRename: false,
      });
      functions.handleSectionOptions(
         this.props.index,
         'hide',
         !visibility
      );
   }
   sectionDropdown = (e, data, sectionOrder) => {
      this.setState({
         sectionRename: this.state.sectionRename,
         sectionName: null,
         editableSectionOrder: null,
      });
      switch (data.value) {
         case "rename": 
            this.setState({
               sectionRename: !this.state.sectionRename,
               sectionName: this.props.landing[sectionOrder].section.name,
               editableSectionOrder: sectionOrder,
            });
            return true;
            break;
         case "hide":
            this.setState({
               sectionRename: false,
               editableSectionOrder: sectionOrder,
            });
            return "hide";
            break;
         case "duplicate":
            return "duplicate";
            break;
         case "delete":
            return "delete";
            break;
         default:
            return false;
            break;
      }
   };
   render() {
      const { item, index, isHidenDrag, ...props } = this.props;
      const { sectionRename, editableSectionOrder, sectionName } = this.state;
      let sectionOptions = [
         { key: slug(), value: "none" },
         {
            key: slug(),
            text: "Rename",
            value: "rename",
         },
         {
            key: slug(),
            text: "Duplicate",
            value: "duplicate",
         },
         {
            key: slug(),
            text: "Delete",
            value: "delete",
         },
      ]

      let generateOptions = (functions) =>{
         if(item.props.duplicated === 'header' ||  item.props.duplicated === 'pricing' || item.props.duplicated ==='footer') {
            return([
               {
                  trash: false, iconName: 'EditMediaM', name: 'Rename', onClick: () => {
                     this.setState({
                        sectionRename: !this.state.sectionRename,
                        sectionName: this.props.landing[index].section.name,
                        editableSectionOrder: index,
                     });
                     setTimeout(() => {
                        this.state.inputRef.current.focus()
                     }, 100);
                  }, 
               },
            ])
         }
         return (
            [
               {
                  trash: false, iconName: 'EditMediaM', name: 'Rename', onClick: () => {
                     this.setState({
                        sectionRename: !this.state.sectionRename,
                        sectionName: this.props.landing[index].section.name,
                        editableSectionOrder: index,
                     });
                     setTimeout(() => {
                        this.state.inputRef.current.focus()
                     }, 100);
                  }, 
               },
               {
                  trash: false, iconName: 'DuplicateMediaM', name: 'Duplicate', onClick: () => {
                     functions.handleSectionOptions(
                        index,
                        'duplicate',
                        !item.props.visibility
                     );
                  },
               },
               {
                  trash: true, iconName: 'DeleteMediaM', name: 'Delete', onClick: () =>{this.setState({openModal:true})},
               },
            ]
         )
      }

      return (
         <Draggable
            key={"dragKey-" + item.props.order}
            draggableId={"item-" + item.props.order}
            index={index}
         >
            {(provided, snapshot) => (
               <NaturalDragAnimation
                  style={getItemStyle(
                     snapshot.isDragging,
                     provided.draggableProps.style
                  )}
                  snapshot={snapshot}
                  {...props}
               >
                  {style => (
                     <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={style}
                        className='menu-item-draggable'
                     >
                        {!sectionRename ? (
                           <Fragment>
                              <div
                                 className='menu-item-left'
                                 style={{
                                    maxWidth: "250px",
                                    overflow: "hidden",
                                 }}
                              >
                                 {!isHidenDrag && (
                                    <div className='menu-item-left-drag'>
                                       <IconNew name='DragAndDropMLanding'/>
                                    </div>
                                 )}
                                 <Text
                                    inner={SliceAndConnectText(item.name,12)}
                                    type={types.regular148}
                                    size={sizes.medium}
                                    onClick={()=>this.props.onClick()}
                                 />
                              </div>
                              <div className='menu-item-right'>
                                 {this.state.openModal && (
                                    <AppContext.Consumer>
                                       {({ state, functions }) => (
                                          <DeleteModal
                                             title='Are you sure you want to delete this section?'
                                             deleteText='Delete'
                                             maxWidth={414}
                                             onCancel={()=>{
                                                this.setState({openModal:false});
                                             }}
                                             onDelete={()=>{
                                                functions.handleSectionOptions(
                                                   index,
                                                   'delete',
                                                   !item.props.visibility
                                                );
                                             }}
                                          />
                                       )}
                                    </AppContext.Consumer>

                                 )}
                                 <AppContext.Consumer>
                                    {({ state, functions }) => (
                                       <div className='menu-item-right-actions'>
                                          <Switch
                                             value={item.props.visibility }
                                             size='medium'
                                             onChange={()=>this.handleChangeVisibility(functions,item.props.visibility)}
                                          />
                                          <DropTriggle
                                             type='one'
                                             options={generateOptions(functions)}
                                          />
                                       </div>
                                    )}
                                 </AppContext.Consumer>

                              </div>
                           </Fragment>
                        ) : editableSectionOrder === index ? (
                           <Fragment>
                              <div className='menu-item-left'>
                                 <input
                                    value={sectionName}
                                    ref={this.state.inputRef}
                                    onChange={(e)=> this.renameSection(
                                       e.target.value
                                    )}
                                    placeholder='Section Name'
                                 />
                              </div>
                              <div className='menu-item-right'>
                                 <div className='menu-item-right-buttons'>
                                    <IconButton
                                       name='CloseLandingM'
                                       onClick={()=>  this.toggleRenameSection(
                                          index
                                       )}
                                          
                                    />
                                    <AppContext.Consumer>
                                       {({ state, functions }) => (
                                          <IconButton
                                             name='AcceptLandingM'
                                             onClick={()=> {
                                                functions.handleSectionOptions(
                                                   index,
                                                   "rename",
                                                   this
                                                      .state
                                                      .sectionName
                                                )
                                                this.toggleRenameSection(
                                                   index
                                                )
                                             }}
                                             theme={themes.primary}
                                          />
                                       )}
                                    </AppContext.Consumer>
                                 </div>
                              </div>
                           </Fragment>
                        ) : (
                           <Fragment>
                              <div
                                 className='menu-item-left'
                                 style={{
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                 }}
                              >
                                 <img
                                    alt='default'
                                    src={require("../../../images/sidebar/Background.png")}
                                 />

                                 <span>{item.name}</span>
                              </div>
                              <div className='menu-item-right'>
                                 <AppContext.Consumer>
                                    {({ state, functions }) => (
                                       <Dropdown
                                          options={sectionOptions}
                                          icon='ellipsis horizontal'
                                          pointing='top right'
                                          defaultValue='none'
                                          onClick = {(e) => {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             e.nativeEvent.stopPropagation();
                                             let section_slug = e.target.closest(".miestro_dropdown");
                                             let miestro_dropdown = document.querySelectorAll(".miestro_dropdown");
                                             let childofELement = section_slug.querySelector('.menu.transition');

                                             miestro_dropdown.forEach(element => {
                                                element.querySelector('.menu.transition').classList.remove("visible");
                                                element.classList.remove("visible");
                                                element.classList.remove("active");

                                             });
                                             section_slug.classList.add("visible");
                                             section_slug.classList.add("active");
                                             childofELement.classList.add("visible");
                                          }}

                                          onChange={(e, data) => {
                                             return new Promise(
                                                resolve => {
                                                   resolve(
                                                      this.sectionDropdown(
                                                         e,
                                                         data,
                                                         index
                                                      )
                                                   );
                                                }
                                             ).then(result => {
                                                result &&
                                                                        functions.handleSectionOptions(
                                                                           index,
                                                                           result,
                                                                           sectionName
                                                                        );
                                             });
                                          }}
                                       />
                                    )}
                                 </AppContext.Consumer>
                                 {!item.props.visibility && (
                                    <Icon name='eye slash outline' />
                                 )}
                                 <img
                                    alt='default'
                                    src={require("../../../images/sidebar/burger.png")}
                                 />
                              </div>
                           </Fragment>
                        )}
                     </div>
                  )}
               </NaturalDragAnimation>
            )}

         </Draggable>
      );
   }
}

export default Item;


export const FooterItem = ({
   item,
   index,
   sectionOptions,
   sectionRename,
   editableSectionOrder,
   sectionName,
   sectionDropdown,
   renameSection,
   toggleRenameSection,
   sectionVisibility,
}) => {
   return (
      <div className='menu-footer-item menu-item-draggable-disabled'>
         {!sectionRename ? (
            <Fragment>
               <div
                  className='menu-item-left'
                  style={{
                     maxWidth: "200px",
                     overflow: "hidden",
                  }}
               >
                  {!sectionVisibility && (
                     <Icon name='eye slash outline' />
                  )}
                  <img
                     alt='default'
                     src={images[item.name.replace(/\s/g,'')] || images['Pricing']}
                  />
                  <span>{item.name}</span>
               </div>
               <div className='menu-item-right'>
                  <AppContext.Consumer>
                     {({ state, functions }) => (
                        <Dropdown
                           className='miestro_dropdown'
                           options={sectionOptions}
                           icon='ellipsis horizontal'
                           pointing='top right'
                           defaultValue='none'
                           onClick = {(e)=>{
                              e.preventDefault();
                              e.stopPropagation();
                              e.nativeEvent.stopPropagation();
                              let section_slug = e.target.closest(".miestro_dropdown");
                              let miestro_dropdown = document.querySelectorAll(".miestro_dropdown");

                              let childofELement = section_slug.querySelector('.menu.transition');

                              miestro_dropdown.forEach(element => {
                                 element.querySelector('.menu.transition').classList.remove("visible");
                                 element.classList.remove("visible");
                                 element.classList.remove("active");

                              });
                              section_slug.classList.add("visible");
                              section_slug.classList.add("active");
                              childofELement.classList.add("visible");
                           }}
                           onChange={(e, data) => {
                              return new Promise(
                                 resolve => {
                                    resolve(
                                       sectionDropdown(
                                          e,
                                          data,
                                          index
                                       )
                                    );
                                 }
                              ).then(result => {
                                 result && functions.handleSectionOptions(
                                    index,
                                    result,
                                    !sectionVisibility
                                 );
                              });
                           }}
                        />
                     )}
                  </AppContext.Consumer>

               </div>
            </Fragment>
         ) : editableSectionOrder === index ? (
            <Fragment>
               <div className='menu-item-left'>
                  <img
                     alt='default'
                     src={require("../../../images/sidebar/Background.png")}
                  />
                  <Input
                     value={sectionName}
                     size='mini'
                     type='text'
                     placeholder='Section Name'
                     style={{
                        marginLeft: "20px",
                     }}
                     onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                     }}
                     onChange={e => {
                        renameSection(
                           e.target.value
                        );
                     }}
                  />
               </div>
               <div className='menu-item-right'>
                  <AppContext.Consumer>
                     {({ state, functions }) => (
                        <Icon
                           name='check'
                           color='grey'
                           onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              e.nativeEvent.stopImmediatePropagation();
                              return new Promise(
                                 resolve => {
                                    resolve(
                                       functions.handleSectionOptions(
                                          index,
                                          "rename",
                                          sectionName
                                       )
                                    );
                                 }
                              ).then(() => {
                                 toggleRenameSection(
                                    index
                                 );
                              });
                           }}
                        />
                     )}
                  </AppContext.Consumer>
                  <Icon
                     name='times'
                     color='grey'
                     onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        toggleRenameSection(
                           index
                        );
                     }}
                  />
               </div>
            </Fragment>
         ) : (
            <Fragment>
               <div
                  className='menu-item-left'
                  style={{
                     maxWidth: "200px",
                     overflow: "hidden",
                  }}
               >
                  <img
                     alt='default'
                     src={require("../../../images/sidebar/Background.png")}
                  />

                  <span>{item.name}</span>
               </div>
               <div className='menu-item-right'>
                  <AppContext.Consumer>
                     {({ state, functions }) => (
                        <Dropdown
                           options={sectionOptions}
                           icon='ellipsis horizontal'
                           pointing='top right'
                           defaultValue='none'
                           onClick = {(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              e.nativeEvent.stopPropagation();
                              let section_slug = e.target.closest(".miestro_dropdown");
                              let miestro_dropdown = document.querySelectorAll(".miestro_dropdown");
                              let childofELement = section_slug.querySelector('.menu.transition');

                              miestro_dropdown.forEach(element => {
                                 element.querySelector('.menu.transition').classList.remove("visible");
                                 element.classList.remove("visible");
                                 element.classList.remove("active");

                              });
                              section_slug.classList.add("visible");
                              section_slug.classList.add("active");
                              childofELement.classList.add("visible");
                           }}

                           onChange={(e, data) => {
                              return new Promise(
                                 resolve => {
                                    resolve(
                                       sectionDropdown(
                                          e,
                                          data,
                                          index
                                       )
                                    );
                                 }
                              ).then(result => {
                                 result &&
                                                functions.handleSectionOptions(
                                                   index,
                                                   result,
                                                   sectionName
                                                );
                              });
                           }}
                        />
                     )}
                  </AppContext.Consumer>
                  {!sectionVisibility && (
                     <Icon name='eye slash outline' />
                  )}
                  <img
                     alt='default'
                     src={require("../../../images/sidebar/burger.png")}
                  />
               </div>
            </Fragment>
         )}
      </div>
   )
}

FooterItem.propTypes = {
   item: PropTypes.object,
   index: PropTypes.number,
   sectionOptions: PropTypes.object,
   sectionRename: PropTypes.func,
   editableSectionOrder: PropTypes.number,
   sectionName: PropTypes.string,
   sectionDropdown: PropTypes.func,
   renameSection: PropTypes.func,
   toggleRenameSection: PropTypes.func,
   sectionVisibility: PropTypes.bool,
};
