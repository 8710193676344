import React, { Component, Fragment } from "react";
import classNames from 'classnames';
import AppContext from "contexts/AppContext";
import EditorContext from "contexts/EditorContext";
import Wrapper from './Wrapper';
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { HeadlineIcon, SubheadlineIcon, TextIcon, ButtonIcon, BulletIcon, ImageIcon, VideoIcon, Divider, FAQIcon, CallToActionIcon, CustomCodeIcon, BannerIcon, CountdownTimerIcon } from './ElementIcon.jsx';
import {
   Segment,
   Tab,
   Icon,
   Sidebar,
   Checkbox,
} from "semantic-ui-react";
import IconOld from 'components/elements/Icon'
import Button,{SIZES, THEMES as themes} from 'components/BaseButtonNew'
import SectionComponentsMenu from "./SectionComponentsMenuDrag";
import SettingsMenu from "./SettingsMenu";
import AddSectionMenu from "./AddSectionMenu_drag_new";
import AddColumnCanvas from "./AddColumnCanvas";
import ViewMode from './ViewMode';
import List from "./List";
//Utils
import { getAllLandings, getAllEditableComponents, isNewApp, removeQueryParam } from "utils";
import EditorTop from "./EditorTop";
import Info from "components/messages/info";
import Text,{TYPES as types,SIZES as sizes} from "components/TextNew";
import Favicon from "components/SettingsComponents/Favicon";
import SideBarSettings from "components/SettingsComponents/SideBarSettings";
import Popover from '@material-ui/core/Popover';
import IconNew from "components/elements/iconsSize";

class EditorMi extends Component {
   constructor(props) {
      super(props);
      this.state = {
         courseId:null,
         menuVisible: true,
         activeIndex: 0,
         sectionsSubMenu: false,
         editableComponent: null,
         editableSection: null,
         editableSectionOrder: null,
         settingsMenu: false,
         settingItem: "",
         currentSidebarComponent: "",
         addSectionMenu:false,
         addColumnCanvas:false,
         previewModeSwitch:false,
         viewMode : "",
         currentIndex: -1,
         isOpenImage: localStorage.getItem('is_open_helper'),
         isOpenTriangle: false,
         anchorEl: null,
         screenWidth: window.innerWidth
      };
      this.handleDOMChange = this.handleDOMChange.bind(this);
   }


   componentDidMount() {
      let url = window.location.href;
      let url_array = url.split("/");
      let courseId = url_array[url_array.length-4];
      let previewUrl = new URL(url);
      let previewMode = previewUrl.searchParams.get("preview");
      let searchParams = new URLSearchParams(window.location.search);
      this.courseId = searchParams.get('courseId')
      this.landing_id = this.props.page.split('_')[1];
      this.setState({
         courseId,
      });
      if (this.props.previewMode) {
         this.setState({
            menuVisible: false,
         });
      }
      if(previewMode==='true'){
         this.setState({
            previewModeSwitch: !this.state.previewModeSwitch,
            menuVisible: !this.state.menuVisible,
         })
      }
      this.observer = new MutationObserver(this.handleDOMChange);
      this.observer.observe(document.body, { childList: true, subtree: true });

      window.addEventListener('resize', this.handleResize);
   }

   componentWillUnmount(){
      this.observer.disconnect();
      window.removeEventListener('resize', this.handleResize);
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevState.viewMode !== this.state.viewMode) {
         this.handleResize();
      }
   }

   handleResize = () => {
      let screenWidth;

      if (this.state.viewMode) {
         const iframe = document.querySelector('#right_container iframe');
         screenWidth = iframe.clientWidth;
      } else {
         screenWidth = window.innerWidth;
      }

      this.setState({ screenWidth: screenWidth });
   };

   handleDOMChange(mutationsList) {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.tagName === 'IFRAME' && node.style.zIndex === '2147483647') {
              node.parentNode.removeChild(node);
            }
          });
        }
      });
   };

   static getDerivedStateFromProps(nextProps, prevState){
      const currentSection = nextProps.landing[prevState.editableSectionOrder];
      const editableSection = prevState.editableSection;
      if (currentSection){
         const currentSectionJson = JSON.stringify(currentSection);
         const editableSectionJson = JSON.stringify(editableSection);
         if (currentSectionJson !== editableSectionJson) {
            return {
               editableSection: currentSection,
            };
         }
      }
      return {
         editableSection : prevState.editableSection,
      };
   }

   togglePreview = () => {
      this.props.handleUpdateTempLanding();
   }

   toggleSidebar = () => {
      this.setState({
         menuVisible: !this.state.menuVisible,
      });
   };

   toggleMenuSidebar = () => {
      this.setState({
         menuVisible: true,
      });
   };

   backToCurrentMenu = () => {
      this.setState({
         menuVisible: true,
         sectionsSubMenu: false,
         settingsMenu: false,
         addSectionMenu:false,
         addColumnCanvas:false,
         currentIndex: -1,
      });
   };

   openAddSectionMenu = (currentIndex) => {
      this.setState({
         sectionsSubMenu: false,
         settingsMenu: false,
         addColumnCanvas:false,
         addSectionMenu:true,
         currentIndex: currentIndex,
      });
   };

   openAddColumnCanvas = (currentIndex) => {
      this.setState({
         sectionsSubMenu: false,
         settingsMenu: false,
         addColumnCanvas:true,
         addSectionMenu:false,
         currentIndex: currentIndex,
      });
   };

   showSection = (order , section_slug = null) => {
      this.props.landing[order].section.props.visibility &&
            this.setState({
               sectionsSubMenu: !this.state.sectionsSubMenu,
               editableSection: this.props.landing[order],
               editableSectionOrder: order,
            },() => {
               this.props.handleEditableSectionOrder(order);
               var selected_section = document.querySelector("#right_container #" + section_slug);
               if(selected_section){
                  selected_section.scrollIntoView({ behavior: "smooth"});
               } else {
                  console.log('Ooops something went wrong!')
               }
            });
   };

   editableSection = event => {
      event.preventDefault();
      const { target } = event;

      let clickedSection = target.closest(".Section");
      if (!clickedSection) {
         const section = target.closest(".section_edit")?.previousElementSibling;

         if (section?.classList.contains('Section')) {
            clickedSection = section;
         }
      }

      if (!clickedSection) return;

      const section_slug = clickedSection.dataset.slug;
      let currentSidebarComponent = section_slug;

      const mark = target.closest(".mark");
      if (mark) currentSidebarComponent = mark.dataset.slug;

      this.props.landing.forEach((section, order) => {
         if (section.section.slug !== section_slug) return;

         this.props.handleEditableSectionOrder(order);
         this.setState({
            sectionsSubMenu: true,
            editableSection: section,
            editableSectionOrder: order,
            currentSidebarComponent,
         }, () => {
            //temprorary solution
            setTimeout(() => {
               this.setState({ currentSidebarComponent: "" });
            }, 1200);
         });
      });
   };

   showEditableComponent = (component, key, duplicated, isSubComponent, maincomponentSlug) => {
      let editableComponents = getAllEditableComponents();
      let Component;

      if (key === -1) {
         Component = editableComponents["SectionEditable"];
      } else {
         Component = editableComponents[component.type + "Editable"];
      }

      return (
         <div>
            <Component
               {...component.props}
               section={this.state.editableSectionOrder}
               slug={component.slug}
               key={key}
               scroll={
                  component.slug === this.state.currentSidebarComponent
                     ? true
                     : false
               }
               scroll_to_options = {this.props.scroll_to_options}
               course_id={this.props.course_id}
               user_id={this.props.user_id}
               siteUUid={this.props.siteUUid}
               checkout_courses={this.props.checkout_courses}
               duplicated={ duplicated }
               defaultLanding={this.props.defaultLanding}
               toggleSidebar={() => this.toggleMenuSidebar()}
               menuVisible={this.state.menuVisible}
               isSubComponent={isSubComponent}
               maincomponentSlug={maincomponentSlug}
            />
         </div>
      );
   };

   handleTabChange = (e, { activeIndex }) => {
      this.setState({ activeIndex });
      e.stopPropagation();
   };

   settingsMenuShow = component => {
      this.setState({
         settingsMenu: true,
         settingItem: component,
      });
   };

   handleViewMode = (type) => {
      this.props.handleChangeViewMode(type !== 'phone' && type !== 'tablet' ? 'hidden' : 'auto');

      this.setState({
         viewMode: type,
      });
   }

   handleButtonClick = (e, order = -1) => {
      if (e) {
         e.stopPropagation();

         this.setState({
            isOpenTriangle: true,
            anchorEl: e.currentTarget,
            currentIndex: order,
         });
      }
   }

   moveToSmartBlocks = () => {
      this.setState({
         sectionsSubMenu: false,
         addSectionMenu: true,
         addColumnCanvas: false,
         // currentIndex: -1,
         isOpenTriangle: false,
      });
      // this.backToCurrentMenu()
   }
   
   moveToColumnCanvas = () => {
      this.setState({
         sectionsSubMenu: false,
         addColumnCanvas: true,
         addSectionMenu: false,
         // currentIndex: -1,
         isOpenTriangle: false,
      })
   }
  
   handleClosePopover = () => {
      this.setState({ isOpenTriangle: false });
   }

   render() {
      const url = window.location.href;
      const Landing = getAllLandings()[this.props.page];
      const settingPanes = ["Favicon", "Typography", "Seo", "Analytics"];

      const elements = [
         { id: "Headline", name: "Headline", icon: <HeadlineIcon/>},
         { id: "Subheadline", name: "Sub-headline", icon: <SubheadlineIcon/>},
         { id: "Text", name: "Text", icon:  <TextIcon/>},
         { id: "Bullet", name: "Bullet", icon:  <BulletIcon/>},
         { id: "Button", name: "Button", icon:  <ButtonIcon/>},
         { id: "Image", name: "Image", icon:  <ImageIcon/>},
         { id: "Video", name: "Video", icon:  <VideoIcon/>},
         { id: "Divider", name: "Divider", icon:  <Divider/>},
         { id: "FAQ", name: "FAQ", icon:  <FAQIcon />},
         { id: "CallAction", name: "Call to Action", icon:  <CallToActionIcon />},
      ];

      const dragableSections = [
         { id: "CustomCode", name: "CustomCode", icon:  <CustomCodeIcon />},
         { id: "Banner", name: "Banner", icon:  <BannerIcon />},
         { id: "CountdownTimer", name: "CountdownTimer", icon:  <CountdownTimerIcon />},
      ];

      let styles;
      if (this.state.menuVisible) {
         styles = {
            width: "calc(100vw - 308px)",
            transition: "0.65s",
            transform: "translate3d(308px,0,0)",
         };
      } else if (!this.state.menuVisible) {
         styles = {
            width: "100vw",
            transition: "0.65s",
         };
      }
      const newApp = isNewApp(url)
      let stylePageHeader={
         minHeight: '100%',
         overflowY: 'hidden',
      }
      if(!this.props.previewMode && !this.state.previewModeSwitch){
         stylePageHeader={
            height: this.state.menuVisible
               ?  isNewApp(url) ? "calc(100vh - 80px)" : "calc(100vh - 80px)"
               : this.state.previewModeSwitch ? "calc(100vh - 50px)" : "calc(100vh - 80px)",
         }
      }
      // const popupStyles = {
      //    display: 'flex',
      //    width: '268px',
      //    padding: 'var(--spacing-xs, 8px)',
      //    flexDirection: 'column',
      //    alignItems: 'flex-start',
      //    gap: 'var(--spacing-xs, 8px)',
      //    borderRadius: '12px',
      //    background: 'var(--grey-scale-0, #FFF)',
      //    boxShadow: '1px 2px 2px 0px rgba(19, 31, 30, 0.04), 0px 4px 10px 0px rgba(19, 31, 30, 0.10)',
      // };
      const panes = [
         {
            menuItem: {
               key : "Sections",
               content: "Sections",
               className: "sidebar_top_section_text",
            },
            render: () => (
               <Tab.Pane style={{ padding: 0, background: 'none', border: 'none' }}>
                  <List
                     animationRotationFade={0.5}
                     rotationMultiplier={5}
                     landing={this.props.landing}
                  />
                  <Button
                     iconName='PlusLandingM'
                     text='Add Section'
                     theme={themes.secondary}
                     style={{ width: '100%', marginTop: '12px' }}
                     isIconRight={true}
                     onClick={
                        this.handleButtonClick
                     }
                  />
               </Tab.Pane>
            ),
         },
         {
            menuItem: {
               key: "Elements",
               content: "Elements",
               className: "sidebar_top_section_text",
            },
            render: () => {
               return(
                  <Tab.Pane style={{ padding: 0, height: "100%" }}>
                     <Info
                        title='Drag the item you want to add to where you want it'
                        isHaveCancel={false}
                     />
                     {/* {!localStorage.getItem('is_open_helper') && (
                        <div className='elements__helper' >
                           <ImageHelperIcon />
                           <div className='elements__helper__icon' role='presentation' onClick={(e)=>{
                              // document.querySelector('.elements__helper').remove()
                              localStorage.setItem('is_open_helper',true)
                              this.setState({isOpenImage: true})
                           }}>
                              <HelperCloserIcon />
                           </div>
                        </div>
                     )} */}
                     <div
                        className='elements_content'
                     >
                        {elements.map((element, index) => (
                           <Droppable
                              droppableId='newElements'
                              direction='vertical'
                              type='element'
                           >
                              {
                                 (provided) => {
                                    return <div
                                       {...provided.droppableProps}
                                       ref={provided.innerRef}
                                    >
                                       <Draggable
                                          key={element.id}
                                          draggableId={element.id}
                                          index={index}
                                          disableInteractiveElementBlocking
                                       >
                                          {(dragProvided, snapshot) => (
                                             <div>
                                                <div
                                                   key={element.id}
                                                   ref={dragProvided.innerRef}
                                                   {...dragProvided.draggableProps}
                                                   {...dragProvided.dragHandleProps}
                                                   style={dragProvided.draggableProps.style.position==='fixed'? {...dragProvided.draggableProps.style} : {...dragProvided.draggableProps.style, transform: 'none', top: 'auto', left: 'auto'} }
                                                   className='element_content'
                                                >
                                                   <div className='elementIcon'>{element.icon}</div>
                                                   <Text
                                                      inner={element.name}
                                                      type={types.regularDefault}
                                                      size={sizes.small}
                                                   />
                                                </div>
                                                {snapshot.isDragging &&
                                                   <div className='element_content'>
                                                      <div className='elementIcon'>{element.icon}</div>
                                                      <div className='elementName'>{element.name}</div>
                                                   </div>
                                                }
                                             </div>
                                          )}
                                       </Draggable>
                                    </div>
                                 }
                              }
                           </Droppable>
                        ))}
                        {dragableSections.map((element, index) => (
                           <Droppable
                              droppableId='newSection'
                              direction='vertical'
                              type='section'
                           >
                              {
                                 (provided) => {
                                    return <div
                                       {...provided.droppableProps}
                                       ref={provided.innerRef}
                                    >
                                       <Draggable
                                          key={element.id}
                                          draggableId={element.id}
                                          index={index}
                                          disableInteractiveElementBlocking
                                       >
                                          {(dragProvided, snapshot) => (
                                             <div>
                                                <div
                                                   key={element.id}
                                                   ref={dragProvided.innerRef}
                                                   {...dragProvided.draggableProps}
                                                   {...dragProvided.dragHandleProps}
                                                   style={dragProvided.draggableProps.style.position==='fixed'? {...dragProvided.draggableProps.style} : {...dragProvided.draggableProps.style, transform: 'none', top: 'auto', left: 'auto'} }
                                                   className='element_content'
                                                >
                                                   <div className='elementIcon'>{element.icon}</div>
                                                   <Text
                                                      inner={element.name}
                                                      type={types.regularDefault}
                                                      size={sizes.small}
                                                   />
                                                </div>
                                                {snapshot.isDragging &&
                                                   <div className='element_content'>
                                                      <div className='elementIcon'>{element.icon}</div>
                                                      <div className='elementName'>{element.name}</div>
                                                   </div>
                                                }
                                             </div>
                                          )}
                                       </Draggable>
                                    </div>
                                 }
                              }
                           </Droppable>
                        ))}
                     </div>
                  </Tab.Pane>
               )
            },
         },
         {
            menuItem: {
               key: "Settings",
               content: "Settings",
               className: "sidebar_top_section_text",
            },
            render: () => (
               <Tab.Pane style={{ padding: 0, height: "100%" }}>
                  <SideBarSettings
                     landingTitle={this.props.landingTitle}
                     domains={this.props.domains}
                     landingUrl={this.props.landingNewUrl}
                     customDomain={this.props.customDomain}
                     landingUrlType={this.props.landingUrlType}
                     metas={this.props.landing_metas}
                  />
                  {/* {settingPanes.map((setting, i) => {
                     return (
                        <div
                           className='menu-item menu-item-settings'
                           onClick={e =>
                              this.settingsMenuShow(setting)
                           }
                           key={i}
                        >
                           <div className='menu-items'>
                              <div className='menu-item-left'>
                                 <img
                                    alt='default'
                                    src={require("../../images/sidebar/Hero.png")}
                                 />
                                 <span>Change {setting}</span>
                              </div>
                              <img
                                 alt='default'
                                 src={require("../../images/sidebar/burger.png")}
                              />
                           </div>
                        </div>
                     );
                  })} */}
               </Tab.Pane>
            ),
         },
      ];
      return (
         <Fragment>
            <AppContext.Consumer>
               {({ state, functions }) => (
                  <DragDropContext onDragEnd={functions.handleElementOnDragEnd} onDragStart={this.onDragStart} >
                     <div className='EditorDrag'>
                        {(!this.props.previewMode && !this.state.previewModeSwitch) && (
                           <EditorTop
                              viewMode={this.state.viewMode}
                              setViewMode={this.handleViewMode}
                              togglePreview={this.togglePreview}
                           />)}
                        <Sidebar.Pushable
                           style={{
                              overflowY: "hidden",
                              border: "none",
                              margin: "0",
                              // minHeight: isNewApp(url) ? "calc(100vh - 74px)" : "calc(100vh - 140px)",
                              // maxHeight:   !this.props.previewMode && !this.state.previewModeSwitch ?  "calc(100vh - 74px)": "unset",
                           }}
                           as={Segment}
                           attached='bottom'
                        >
                           {(!this.props.previewMode && !this.state.previewModeSwitch) && (
                              <Sidebar
                                 animation='uncover'
                                 visible={this.state.menuVisible}
                                 vertical='true'
                                 inline='true'
                                 width='wide'
                                 className='sidebarContainer'
                              >
                                 <EditorContext.Provider
                                    value={{
                                       state: this.state,
                                       functions: {
                                          backToCurrentMenu: this
                                             .backToCurrentMenu,
                                          showEditableComponent: this
                                             .showEditableComponent,
                                          showSection:this.showSection,
                                       },
                                       metas:this.props.landing_metas,
                                    }}
                                 >
                                    {this.state.sectionsSubMenu ? (
                                       <>
                                          {/* {
                                             this.state.addSectionMenu
                                             ? <AddSectionMenu newApp={ newApp } currentIndex={this.state.currentIndex} />
                                             : this.state.addColumnCanvas
                                                ? <AddColumnCanvas newApp={ newApp } currentIndex={this.state.currentIndex} />
                                                : <SectionComponentsMenu
                                                      section={this.state.editableSection.section}
                                                      linkComponentCount={this.props.linkComponentCount} page={this.props.page}
                                                   />
                                          } */}
                                          <SectionComponentsMenu
                                             section={this.state.editableSection.section}
                                             linkComponentCount={this.props.linkComponentCount} page={this.props.page}
                                          />
                                       </>
                                    ) : this.state.settingsMenu ? (
                                       <SettingsMenu/>
                                    ) : this.state.addSectionMenu ? (
                                       <AddSectionMenu newApp={ newApp } currentIndex={this.state.currentIndex}/>
                                    ) : this.state.addColumnCanvas ? (
                                       <AddColumnCanvas newApp={ newApp } currentIndex={this.state.currentIndex}/>
                                    ) : (
                                       <Tab
                                          panes={panes}
                                          activeIndex={this.state.activeIndex}
                                          onTabChange={this.handleTabChange}
                                          className='Tabs'
                                       />
                                    )}
                                    {/* <AddColumnCanvas newApp={ newApp } currentIndex={this.state.currentIndex}/> */}
                                 </EditorContext.Provider>
                              </Sidebar>
                           )}
                           <Sidebar.Pusher style={styles}>
                              {(!this.props.previewMode ) && (
                                 <div
                                    icon
                                    onClick={(!this.props.previewMode && !this.state.previewModeSwitch) ? () => this.toggleSidebar() : () => this.togglePreview()}
                                    className='toggleSidebar'
                                    role='presentation'
                                 >
                                    <IconOld name='TriangleSvg' color='rgba(0,0,0,.6)' style={ this.state.menuVisible ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' } } />
                                 </div>
                              )}


                              <div
                                 className={classNames({
                                    'right_container_wrapper' : this.state.viewMode !== '',
                                    'right_container_style' :  !this.props.previewMode,
                                 })}
                              >


                                 <Segment
                                    style={stylePageHeader}
                                    className={
                                       classNames({
                                          'phoneMode' : this.state.viewMode === 'phone',
                                          'tabletMode': this.state.viewMode === 'tablet',
                                       })
                                    }
                                    id='right_container'
                                    basic
                                    onClick={(e) => {this.editableSection(e);}}
                                 >
                                    <EditorContext.Provider
                                       value={{
                                          state: this.state,
                                          functions: {
                                             editableSection: this.editableSection,
                                          },
                                       }}
                                    >
                                       <Landing
                                          customLinks={this.props.customLinks}
                                          landing_metas={this.props.landing_metas}
                                          landing_courses={this.props.landing_courses}
                                          course_id={this.props.course_id}
                                          landingUrl={this.props.landingUrl}
                                          landing_prices={this.props.landing_prices}
                                          authUserRole={this.props.authUserRole}
                                          landing={this.props.landing}
                                          viewMode={this.state.viewMode}
                                          previewModeSwitch={this.state.previewModeSwitch}
                                          privacy= {this.props.privacy}
                                          terms={this.props.terms}
                                          isModeAdmin={ this.props.isModeAdmin }
                                          course={ this.props.course }
                                          openAddSectionMenu={ this.openAddSectionMenu }
                                          openAddColumnCanvas={ this.openAddColumnCanvas }
                                          linkComponentCount={this.props.linkComponentCount}
                                          user_id={this.props.user_id}
                                          siteUUid={this.props.siteUUid}
                                          checkout_courses={this.props.checkout_courses}
                                          menuVisible={this.state.menuVisible}
                                          handleTabChange={this.handleTabChange}
                                          backToCurrentMenu={this.backToCurrentMenu}
                                          handleButtonClick={this.handleButtonClick}
                                       />
                                       <Popover
                                          open={this.state.isOpenTriangle}
                                          anchorEl={this.state.anchorEl}
                                          onClose={this.handleClosePopover}
                                          className='custom-popover'
                                          elevation={24}
                                          anchorOrigin={{
                                             vertical: 'top', 
                                             horizontal: 'right', 
                                          }}
                                          transformOrigin={{
                                             vertical: 'bottom', 
                                             horizontal: 'right', 
                                          }}
                                          id={1}
                                       >
                                          <div className="addSectionPopup">
                                             <div className="smartBlocks" onClick={this.moveToSmartBlocks}>
                                                <div className="popupSection">
                                                   <strong>Smart Blocks</strong>
                                                   <span>Hero, Content, Features, and Video, Testimonials etc.</span>
                                                </div>
                                                <IconNew name='ArrowRight' />
                                             </div>
                                             <div className="columnCanvas" onClick={this.moveToColumnCanvas}>
                                                <div className="popupSection">
                                                   <strong>Column Canvas</strong>
                                                   <span>Make your own layout with empty columns.</span>
                                                </div>
                                                <IconNew name='ArrowRight' />
                                             </div>
                                          </div>
                                       </Popover>
                                    </EditorContext.Provider>
                                 </Segment>
                              </div>
                           </Sidebar.Pusher>
                        </Sidebar.Pushable>
                     </div>
                  </DragDropContext>
               )}
            </AppContext.Consumer>
         </Fragment>
      );
   }
}

EditorMi.defaultProps = {
   page: "landing_1",
   landing: [],
   landing_metas: "",
   landing_prices:"",
   authUserRole:"",
   previewMode:"",
   scroll_to_options:[],
};

export default EditorMi;
