import React from 'react'
import OldItem from './Item.old'
import NewItem from './Item.new'
import { isNewApp } from 'utils'
import getUrl from 'utils/getUrl'
class Editor  extends React.Component {
   constructor(props) {
      super(props)
      let url = getUrl();    
      this.isNewApp = isNewApp(url);
   }
   
   render() {
      return (
         <React.Fragment>
            { this.isNewApp ?  <NewItem { ...this.props } /> : <OldItem { ...this.props } /> }
         </React.Fragment>
    
      )
   }
}

export default Editor