import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_14",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#333333",
        regular_text_color: "#333333",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#e5f0ff",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:"<span style='font-size:16px;line-height:initial'>Alex Parkinson</span>",
                        color: "#3f4f65",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Home",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#006dff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        scroll_to_section:false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "About",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#006dff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        scroll_to_section:false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Instructor",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#006dff",
                        size: "small",
                        linked: true,
                        scroll_to_section:false,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Course",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#006dff",
                        size: "small",
                        linked: true,
                        scroll_to_section:false,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "FAQ",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#006dff",
                        size: "small",
                        linked: true,
                        scroll_to_section:false,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Log in",
                        bgColor: "#006dff",
                        textColor: "#006dff",
                        size: "extsmall",
                        blanked: false,
                        href: "",
                        bordered: true,
                        border: "solid",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Hero",
                //section_props
                props: {
                    // props:value
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_13/section_1_background.png"),
                    bgColor: "#8C70B1",
                    background_type: "image",
                    alignmentAvalaible: true,
                    spacing: "xl",
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='font-size:60px;line-height:initial'>Lorem ipsum dolor</span>",
                        color: "#333333",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text: "<p><span style='font-size:20px'>Lorem ipsum dolor sit amet, sit id delectus intellegebat. Et vis ignota </br> offendit probatus, an nibh soleat aeterno nam, id per ludus omittam </br> constituto. Agam praesent maluisset ad his. Primis meliore </br> repudiare sit in, iusto euismod dissentiet ea sea.</span></p>",
                        color: "#333333",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Sign up",
                        bgColor: "#27ad5f",
                        textColor: "#ffffff",
                        href: "",
                        size: "small",
                        widthChangeable: true,
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Text with image",
                props: {
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_13/section_2_background.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    spacing: "md",
                    reverseAvailaible: true,
                    reversed: true,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/comp.png"),
                        width: "560",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='font-weight:bold;font-size:40px'>What will you learn</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='color: #333333; line-height: 1.5; font-weight: 500; font-size:18px;'>I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page. ​This is a great space to write long text about your courses. You can use this space to go into a little more detail about your course. </span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Sign up",
                        bgColor: "#006dff",
                        textColor: "#006dff",
                        size: "extsmall",
                        blanked: false,
                        href: "",
                        bordered: true,
                        border: "solid",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Text with image",
                props: {
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_13/section_3_background.png"),
                    bgColor: "#e1ece4",
                    background_type: "image",
                    reverseAvailaible: true,
                    reversed: false,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_13/instructor.jpg"),
                        width: "200",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h1>Your Instructor</h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p style='line-height: 1.5; font-weight: 500; font-size: 18px'>I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. Feel free to drag and drop me anywhere you like on your page. ​This is a great space to write long text about you. You can use this space to go into a little more detail about your teaching life.</p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h3>Alex Parkinson</h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p style='line-height: 1.5; font-weight: 500; font-size: 18px'>Personal Life Couch </p>",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:4,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_13/section_pricing_background.png"),
                    bgColor: "white",
                    background_type: "image",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='font-size:60px;line-height:initial'>Lorem ipsum dolor</span>",
                        color: "#333333",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text: "<p><span style='font-size:20px'>Lorem ipsum dolor sit amet, sit id delectus intellegebat. Et vis ignota </br> offendit probatus, an nibh soleat aeterno nam, id per ludus omittam </br> constituto. Agam praesent maluisset ad his. Primis meliore </br> repudiare sit in, iusto euismod dissentiet ea sea.</span></p>",
                        color: "#333333",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#000000",
                        textColor_selected: "#000000",
                        bgColor: "#ffffff",
                        bgColor_selected: "#d8e9ff",
                        border_selected: 'solid 2px #006dff',
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Sign up",
                        bgColor: "#27ad5f",
                        textColor: "#ffffff",
                        href: "",
                        size: "small",
                        widthChangeable: true,
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Footer",
                //section_props
                props: {
                    //props:value
                    order:5,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#2b44ff",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "About",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "extsmall",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Privacy Policy",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "extsmall",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Terms",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "extsmall",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Copyright © 2023 by Personal Life Coach.",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "extsmall",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
    ],
};

export default state;
