import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'
import './index.css'
import UploadImg from 'components/UploadImg';
import Button,{THEMES as themes,SIZES as btnSizes} from 'components/BaseButtonNew'
import AppContext from 'contexts/AppContext';
import { Image, Divider, Icon } from "semantic-ui-react";
import Select from 'components/SelectNew';
import Input from 'components/inputNew';
import IconNew from 'components/elements/iconsSize';
import { toast } from "react-toastify";
import {
   getQueryParams,
} from "utils";
import getUrl from "utils/getUrl";

const landingTypeOptions = [{ value: 'custom', label: 'Landing Url' }];

const SideBarSettings = ({metas,landingTitle,landingUrl,landingUrlType,domains,customDomain}) => {
   const inputRef = useRef()
   let fonts = [];
   const createData = (key = 0, text, value) => {
      let id = key++;
      return {
         id,
         label: text,
         value,
      };
   };
   const json = require("./fonts.json");
   Object.values(json)[0].map((font, i) => {
      fonts.push(createData(i, font, font));
      return font;
   });
   let url = getUrl();
   let apiUrl = getQueryParams('apiUrl', url);

   useEffect(() => {
      console.log(metas.landing_favicon)
   }, [metas.landing_favicon]);

   if (domains.length) {
      landingTypeOptions.push({ value: 'pointed', label: 'Custom Domain' });
   }

   const isPrint = (message) => {
      const errors = document.getElementsByClassName('Toastify__toast-body');

      for (let i = 0; i < errors.length; i++) {
         if (errors[i].innerHTML === message) {
            return false
         }
      }

      return true
   }

   const changeLandingTitle = (name, value, functions) => {
      const noSymbolsRegexp = /^[a-zA-Z0-9\s]+$/;

      if (value && !noSymbolsRegexp.test(value)) {
         if (!isPrint('Please enter only letters, numbers, and spaces')) return;

         toast.error('Please enter only letters, numbers, and spaces');
         return;
      }

      functions.handleChangeLandingInputs(name, value);
   }

   return (
      <div className='sideBar__settings'>
         <AppContext.Consumer>
            {({ state, functions }) => (
               <div className='sideBar__settings__top'>
                  <Text
                     inner='Favicon'
                     type={types.regularDefault}
                     size={sizes.small}
                  />
                  <Image
                     size='mini'
                     centered
                     id='favicon'
                     src={ metas.landing_favicon && metas.landing_favicon.indexOf('static/media') !== -1 ? "https://miestro-production.s3-us-west-2.amazonaws.com/landing/favico.ico" : metas.landing_favicon}
                  />
                  <input
                     type='file'
                     accept='image/gif, image/ico, image/png'
                     onChange={e => functions.handleFaviconChange(e)}
                     ref={inputRef}
                  />
                  <Button
                     text={!metas.landing_favicon.includes('landing') ? 'Change Image' : 'Upload Now'}
                     onClick={()=>inputRef.current.click()}
                  />
                  <Button
                     text='Revert'
                     onClick={ ()=> functions.handleFaviconChangetoDefaul()}
                     theme={themes.secondary}
                  />
               </div>
            )}
         </AppContext.Consumer>
         <AppContext.Consumer>
            {({ state, functions }) => (
               <div className='sideBar__settings__typograpy'>
                  <Select
                     options={fonts}
                     label='Heading Font Family'
                     placeholder='Heading Font Family'
                     type='select-medium'
                     value={metas.heading_font}
                     onChange={(name,value)=>functions.handleFontChange(
                        "heading_font"
                     )(null, {value})}
                  />
                  <Select
                     options={fonts}
                     label='Regular Font Family'
                     placeholder='Regular Font Family'
                     type='select-medium'
                     value={metas.regular_font}
                     onChange={(name,value)=>functions.handleFontChange(
                        "regular_font"
                     )(null, {value})}
                  />
               </div>
            )}
         </AppContext.Consumer>
         <AppContext.Consumer>
            {({ state, functions }) => (
               <div className='sideBar__settings__seo'>
                  <Input
                     label='Landing Title'
                     name='landingTitle'
                     onChange={(name, value) => changeLandingTitle(name, value, functions)}
                     value = {landingTitle}
                     placeholder='Landing Title'
                  />
                  <Select
                     options={landingTypeOptions}
                     label='Landing type'
                     placeholder='Select landing type'
                     type='select-medium'
                     value={landingUrlType}
                     onChange={(name,value)=>functions.handleChangeLandingInputs(
                        "landingUrlType",value
                     )}
                  />
                  {landingUrlType === 'custom' && (
                     <div>
                        <Input
                           label='Landing URL'
                           name='url'
                           onChange={(name,value)=>functions.handleChangeLandingInputs(name,value)}
                           value = {landingUrl}
                           placeholder='Landing URL'
                           leftText={`${apiUrl}/p/`}
                        />
                     </div>
                  )}
                  {landingUrlType === 'pointed' && (
                     <Select
                        options={domains.map((e)=>({ label: e.domain, value: e.id }))}
                        label='Select domain'
                        placeholder='Select landing domain'
                        type='select-medium'
                        value={customDomain}
                        onChange={(name,value)=>functions.handleChangeLandingInputs(
                           "customDomain",value
                        )}
                     />
                  )}
               </div>
            )}
         </AppContext.Consumer>
         <AppContext.Consumer>
            {({ state, functions }) => (
               <div className='sideBar__settings__seo'>
                  <Input
                     label='SEO Title'
                     name='seo_title'
                     onChange={(name,value)=>functions.handleUpdateSettings(
                        value,name
                     )}
                     value = {metas.seo_title || landingTitle}
                     placeholder='SEO Title'
                  />
                  <Input
                     label='SEO Description'
                     type='textarea'
                     name='seo_description'
                     onChange={(name,value)=>functions.handleUpdateSettings(
                        value,name
                     )}
                     value = {metas.seo_description}
                     placeholder='SEO Description'
                  />
                  <Input
                     label='SEO Keywords'
                     name='seo_keywords'
                     helpText='Comma Separeted'
                     onChange={(name,value)=>functions.handleUpdateSettings(
                        value,name
                     )}
                     value = {metas.seo_keywords}
                     placeholder='SEO Keywords'
                  />
               </div>
            )}
         </AppContext.Consumer>
         <div className='sideBar__settings__info'>
            <div>  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
               <path fillRule='evenodd' clipRule='evenodd' d='M10.0003 2.1665C5.6741 2.1665 2.16699 5.67361 2.16699 9.99984C2.16699 14.3261 5.6741 17.8332 10.0003 17.8332C14.3266 17.8332 17.8337 14.3261 17.8337 9.99984C17.8337 5.67361 14.3266 2.1665 10.0003 2.1665ZM1.16699 9.99984C1.16699 5.12132 5.12181 1.1665 10.0003 1.1665C14.8788 1.1665 18.8337 5.12132 18.8337 9.99984C18.8337 14.8784 14.8788 18.8332 10.0003 18.8332C5.12181 18.8332 1.16699 14.8784 1.16699 9.99984ZM10.0003 8.6665C10.2765 8.6665 10.5003 8.89036 10.5003 9.1665V13.3332C10.5003 13.6093 10.2765 13.8332 10.0003 13.8332C9.72418 13.8332 9.50033 13.6093 9.50033 13.3332V9.1665C9.50033 8.89036 9.72418 8.6665 10.0003 8.6665ZM10.0003 7.49984C10.4606 7.49984 10.8337 7.12674 10.8337 6.6665C10.8337 6.20627 10.4606 5.83317 10.0003 5.83317C9.54009 5.83317 9.16699 6.20627 9.16699 6.6665C9.16699 7.12674 9.54009 7.49984 10.0003 7.49984Z' fill='#131F1E' />
            </svg></div>
            <div className='sideBar__settings__info__right'>
               <Text
                  inner='Analytics'
                  type={types.medium150}
                  size={sizes.medium}
               />
               <Text
                  inner='Please note that we do not provide support for this section. Any non-tracking code placed in the above areas may cause major problems with your page.'
                  type={types.regularDefault}
                  size={sizes.small}
               />
               <Text
                  inner='For troubleshooting, please use the support and documentation provided by your analytics service provider.'
                  type={types.regularDefault}
                  size={sizes.small}
               />
            </div>
         </div>
         <AppContext.Consumer>
            {({ state, functions }) => (
               <div className='sideBar__settings__seo'>
                  <Input
                     label='Facebook Pixel ID'
                     name='fb_pixel_code'
                     onChange={(name,value)=>functions.handleUpdateSettings(
                        value,name
                     )}
                     value = {metas.fb_pixel_code}
                     placeholder='XXXXXXXXXXXXXXX'
                  />
                  <Input
                     label='Google Analytics Resource ID'
                     name='google_anal_resource_id'
                     onChange={(name,value)=>functions.handleUpdateSettings(
                        value,name
                     )}
                     value = {metas.google_anal_resource_id}
                     placeholder='Ex: UA-XXXXXX-X'
                  />
                  <Input
                     label='Head Section Tracking Codes ID'
                     name='head_section_tracking_codes'
                     type='textarea'
                     onChange={(name,value)=>functions.handleUpdateSettings(
                        value,name
                     )}
                     value = {metas.head_section_tracking_codes}
                     placeholder='Head Section Tracking Codes'
                  />
                  <Input
                     label='Immediately before the closing <body> tag'
                     name='before_closing_body_tag'
                     type='textarea'
                     onChange={(name,value)=>functions.handleUpdateSettings(
                        value,name
                     )}
                     value = {metas.before_closing_body_tag}
                     placeholder='Immediately before the closing <body> tag'
                  />
               </div>
            )}
         </AppContext.Consumer>
      </div>
   );
};

SideBarSettings.propTypes = {
   landingTitle: PropTypes.string,
   landingUrl: PropTypes.string,
   landingUrlType: PropTypes.string,
};

export default SideBarSettings;