import React, { Fragment, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Crop from 'react-image-crop';
import cx from 'classnames';
import './ReactCrop.css';
import './style.css';
import { getInputAllowedExtensionsAndMimeTypes, getInitialCropSize } from './helpers';
import { toast } from 'react-toastify';
import getCroppedImage from './getCroppedImage';
import Button, {THEMES as themes, SIZES as btnSizes} from 'components/BaseButtonNew'
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'
import ModalNew from 'components/ModalNew';

function gcd(a, b) {
   return (b === 0) ? a : gcd(b, a % b);
}

function getRatio(ratioStr) {
   const acceptRatios = ['1:1', '5:4', '4:3', '3:2', '5:3', '16:9', '3:1'];
   const result = {};
   if (typeof ratioStr === 'string') {
      const [width, height] = ratioStr.split('x').map(numStr => +numStr);

      if (typeof width === 'number' && typeof height === 'number') {
         const r = gcd(width, height);
         result.aspect = width / height;
         result.ratio = acceptRatios.includes(`${ width / r }:${ height / r }`) ? `${ width / r }:${ height / r }` : '';
      }
   }

   return result;
}

const UploadView = ({
   children, open, onClose, cropRatio, fileLessonFormat, acceptFilesExtentions,
   onFileSelect,isUploadedImage,  handleChangeUploadedImage, resetImage, staticImage
}) => {
   const inputRef = useRef(null);
   const cropImageRef = useRef(null);
   const fileRef = useRef(null);
   const [isOpenModal,setIsOpenModal] = useState(false)
   const imageRef = useRef(null);
   const originalImageRef = useRef(null);
   const [isDragging, setIsDragging] = useState(false);
   const [crop, setCrop] = useState(null);
   const [cropSrc, setCropSrc] = useState(null);
   const [imageStyle, setImageStyle] = useState({})
   const cropReady = useRef(false);
   const [extensions, mimetypes] = getInputAllowedExtensionsAndMimeTypes({
      allowedExtensions: acceptFilesExtentions,
      format: fileLessonFormat,
   });
   const { ratio, aspect } = useMemo(() => getRatio(cropRatio), [cropRatio]);

   useEffect(() => {
      if (!open) {
         setCrop(null);
         setCropSrc(null);
      }
   }, [open]);

   function onDragLeave(evt) {
      evt.preventDefault();
      setIsDragging(false);
   }

   function allowDrop(evt) {
      evt.preventDefault();
      if (!isDragging) {
         setIsDragging(true);
      }
   }

   function fileToDataUrl(file) {
      return new Promise((resolve) => {
         if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
               resolve(reader.result);
            }, false);
            reader.readAsDataURL(file);
         }
      });
   }

   async function onCropStart(file) {
      const blob = await fileToDataUrl(file);
      setCropSrc(blob);
      cropReady.current = false;
      const height = typeof aspect === 'number' ? null : 100;
      setCrop({
         aspect, unit: '%', width: 100, height,
      });
   }


   function handleFileSelect(files) {
      fileRef.current = files[0];
      if (!mimetypes.includes(files[0].type)) {
         return toast.error('Invalid File');
      }
      setImageStyle({})
      if (!cropRatio) {
         onFileSelect({ file: files[0] }, () => {
            inputRef.current.value = ''
         });
      } else {
         setIsOpenModal(true)
         onFileSelect({
            file: files[0],
            onCropStart: () => {
               onCropStart(files[0]);
            },
            
         }, () => {
            inputRef.current.value = '';
         });
      }
   }

   async function handleCropSave() {
      const { name, type, lastModified } = fileRef.current;
      const image = imageRef.current.imageRef;
      setIsOpenModal(false)
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelCrop = {
         x: crop.x * scaleX,
         y: crop.y * scaleY,
         width: crop.width * scaleX,
         height: crop.height * scaleY,
      };
      if (crop.height === 0 || crop.width === 0) {
         toast.error('Please Crop The Image First');
         return onImageLoaded(cropImageRef.current);
      }

      let file 
      let fileBlob
      try {
         fileBlob = await getCroppedImage(originalImageRef.current, pixelCrop, type); 
      } catch (err) {
         file = fileRef.current
      }
      const fileOptions = {
         type,
         lastModified,
         name,
      };
      return onFileSelect({ file, fileOptions, fileBlob }, () => {
         inputRef.current.value = ''
      });
   }

   function onDrop(evt) {
      evt.preventDefault();
      setIsDragging(false);
      if (mimetypes.includes(evt.dataTransfer.files[0].type)) {
         handleFileSelect(evt.dataTransfer.files);
      } else {
         toast.error('Invalid File');
      }
   }

   function onCropCancel() {
      setCrop(null);
      setCropSrc(null);
   }

   function handleClose() {
      onCropCancel();
      onClose();
      fileRef.current = null;
   }

   function onImageLoaded(img) {
      cropImageRef.current = img
      const { width, height } = originalImageRef.current || {};
      const imageHeight = img.height > 360 ? 360 : img.height;
      const imageWidth = img.height > 360 ? 360 * (width / height) : img.width;
      if (img.height > 360) {
         setImageStyle({ width: `${ imageWidth }px`, height: imageHeight });
      }


      const [cw, ch] = typeof cropRatio === 'string' ? cropRatio.split('x').map(n => +n) : [];
      const { width: cropWidth, height: cropHeight } = getInitialCropSize(ch / cw, imageWidth, imageHeight, typeof cropRatio === 'boolean');

      let x = 0;
      let y = 0;
      if (imageHeight === cropHeight && imageWidth === cropWidth) {
         x = 0;
         y = 0;
      } else if (cropWidth === imageWidth) {
         x = 0;
         y = (imageHeight / 2) - (cropHeight / 2);
      } else if (imageHeight === cropHeight) {
         y = 0;
         x = (imageWidth / 2) - (cropWidth / 2);
      }

      const newCrop = {
         aspect,
         height: cropHeight,
         unit: 'px',
         width: cropWidth,
         x,
         y,
      };
      setTimeout(() => {
         cropReady.current = true;
      }, 200);
      setCrop(newCrop);
   }

   function onCropChange(newCrop) {
      if (cropReady.current === true) {
         setCrop(newCrop);
      }
   }


   return (
      <div>
         {(crop && cropSrc && isOpenModal) && (
            <ModalNew onCloseModal={onCropCancel}>
               <div className='cropArea'>
                  <div className='cropAreaImage'>
                     <Crop
                        src={ cropSrc }
                        crop={ crop }
                        onChange={ onCropChange }
                        ref={ imageRef }
                        imageStyle={ imageStyle }
                        onImageLoaded={onImageLoaded}
                     />
                  </div>
                  <img className='originalImage' src={ cropSrc } ref={ originalImageRef } alt='' />
               </div>
               <div className='cropAreaFooter'>
                  <div>
                     <Button 
                        theme={themes.secondary}
                        text='Cancel'
                        onClick={onCropCancel} />
                  </div>
                  <div className='cropRatioText'>
                     <Text
                        inner={typeof cropRatio === 'string' ? ratio : 'Free'}
                        type={types.regularDefault}
                        size={sizes.small}
                     />
                  </div>
                  <div>
                     <Button 
                        text='Save'                     
                        onClick={handleCropSave}/>
                  </div>
               </div>
            </ModalNew>
         )}
         {
            isUploadedImage ? <div className='uploaded__image__wrapper'>
               <input accept={ extensions } ref={ inputRef } type='file' onChange={ ({ target: { files } }) => handleFileSelect(files) } />
               <img src={isUploadedImage ? isUploadedImage : default_image} alt="" />
               {
                  !staticImage && <div className='button__wrapper'>
                     <Button
                        text='Change Image'
                        isIconRight={true}
                        onClick={()=> inputRef.current.click() }
                        theme={themes.change}
                        style={{ maxWidth:'max-content' }}
                     />
                  </div>
               }
               {
                  !staticImage && <div className='close__button__wrapper'>
                     <Button
                        theme={ themes.change }
                        size={ btnSizes.small }
                        text=''
                        iconName={ 'ClearImage' }
                        className='image__view__clear'
                        onClick={ () => {
                           handleChangeUploadedImage();
                           resetImage(null);
                        } }
                     />
                  </div>
               }
            </div> : <div
            className={cx('uploadModalWrapper empty', {'centered empty': !crop || !cropSrc})}
            onDragLeave={ onDragLeave }
            onDrop={ onDrop }
            onDragOver={ allowDrop }
         > 
            <div className={ `uploadArea ${ cx({ dragging: isDragging }) }` }>
               <input accept={ extensions } ref={ inputRef } type='file' onChange={ ({ target: { files } }) => handleFileSelect(files) } />
               <Button
                  text='Upload Image'
                  iconName='LandingUploadM'
                  isIconRight={true}
                  onClick={()=> inputRef.current.click() }
                  theme={themes.secondary}
                  style={{ maxWidth:'max-content' }}
               />
               <Text
                  inner='or drop a file'
                  type={types.regularDefault}
                  size={sizes.small}
                  style={{ color: '#444C4B' }}
               />
            </div>

         </div>
         }
         
      </div>
   );
};

UploadView.propTypes = {
   children: PropTypes.node,
   open: PropTypes.bool,
   cropRatio: PropTypes.string,
   onClose: PropTypes.func,
   acceptFilesExtentions: PropTypes.string,
   onFileSelect: PropTypes.func,
   resetImage: PropTypes.func,
   handleChangeUploadedImage: PropTypes.func,
   fileLessonFormat: PropTypes.string,
   imgUrl: PropTypes.string,
};

export default UploadView;
