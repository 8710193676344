import AppContext from "contexts/AppContext";
import isNewApp from './isNewApp';
import getQueryParams from './getQueryParams'

const toggleHighlighted = (event, active) => {
    event.preventDefault();
    event.stopPropagation();
    if(event.nativeEvent) {
        event.nativeEvent.stopImmediatePropagation();
    }
    let url = window.location.href;
    let previewUrl = new URL(url);
    let previewMode = previewUrl.searchParams.get("preview");
    if((!url.includes("localhost") && !url.includes("admin/courses")) && !(isNewApp(url) && getQueryParams('mode', url) === 'admin' )|| previewMode) {
        return false;
    }
    return !active;
};

export default toggleHighlighted;
