import React, { Component } from "react";
import { Checkbox } from "semantic-ui-react";
import AppContext from "contexts/AppContext";
import PropTypes from "prop-types";
import "./index.css";
import Switch from "components/switchNew";

class ToggleMi extends Component {
   render() {
      return (
         <AppContext.Consumer>
            {({ state, functions }) => (
               // <Checkbox
               //     id={ `toggle-${ this.props.slug }-${ this.props.value}` }
               //     toggle
               //     label={this.props.label}
               //     data-content={this.props.checked? "ON" : "OFF"}
               //     name='toggleRadioGroup'
               //     value={this.props.value}
               //     checked={this.props.checked}
               //     onKeyDown={ (e) => e.key === 'Enter' && e.preventDefault()}
               //     onChange={(e, input) => {
               //         let value = this.props.props_name === "background_type" ? this.props.value : input.checked;
               //         functions.handleUpdateProps(
               //             this.props.section,
               //             this.props.slug,
               //             value,
               //             this.props.props_name
               //         );
               //     }}
               // />
               <Switch
                  positionText='right'
                  value={this.props.value}
                  size='medium'
                  label={this.props.label || this.props.imageLabel}
                  onChange={(checked) => {
                     functions.handleUpdateProps(
                        this.props.section,
                        this.props.slug,
                        checked,
                        this.props.props_name,
                        null,
                        this.props.propsName
                     );
                  }}
               />
            )}
            {/* <label class={this.props.checked? "up" : "dn"} for={ `toggle-${ this.props.slug }-${ this.props.value }-${ this.props.props_name }` } data-content={this.props.checked? "ON" : "OFF"}></label> */}
            {/* {(this.props.label || this.props.imageLabel) && <label for={ `toggle-${ this.props.slug }-${ this.props.value }-${ this.props.props_name }`} className='checkbox-toggle-label'>{this.props.imageLabel ? this.props.imageLabel : this.props.label}</label>} */}
            {/* {this.props.placeholder && this.props.checked && (
                <p style={{ color: '#8c959e', fontSize: '12px' }}> {this.props.placeholder}</p>
            )} */}
         </AppContext.Consumer>
      );
   }
}

ToggleMi.propTypes = {
   // EXPECTED STRING
   label: PropTypes.string,
   slug: PropTypes.string,
   props_name: PropTypes.string,
   // EXPECTED NUMBER
   section: PropTypes.number,
   // EXPECTED BOOLEAN
   checked: PropTypes.bool,
   // EXPECTED BOOLEAN OR STRING IF EMPTY
   value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
   ]),
}
export default ToggleMi;
