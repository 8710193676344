import React, { Component, Fragment } from "react";
import EditorContext from "contexts/EditorContext";
import AppContext from "contexts/AppContext";
import { Header, Menu, Icon , Button } from "semantic-ui-react";
import Landing_added from "./../../../defaults/sections/added_sections";
import "./index.css";
import hero from '../../../images/sidebar/Hero.png';
class AddSectionMenu extends Component {

   constructor(props){
      super(props);
      this.state = {
         addVisibleSectionName:'',
      }
   }
   showButton = (sectionName) => {
      this.setState({
         addVisibleSectionName:sectionName,
      })
   }

   slug = () => {
      function s4() {
         return new Date().getTime() + Math.floor(Math.random() * 10000);
      }
      return "miestro-" + s4();
   };

   render() {
      const { newApp, currentIndex }  = this.props
      return (
         <Fragment>
            <Menu vertical={true} style={{ width: "100%" }}>
               <EditorContext.Consumer>
                  {({ state,functions, SettingComponent , Sections}) => (

                     <Menu.Item
                        name='header'
                        onClick={() => functions.backToCurrentMenu()}
                     >
                        <Header as='h4' color='black'>
                           <Icon name='angle left' disabled />
                           <Header.Content>Go Back</Header.Content>
                        </Header>
                     </Menu.Item>

                  )}
               </EditorContext.Consumer>
               <AppContext.Consumer>
                  {({ state, functions }) => (

                     Landing_added.map((section_default_json, i)=>{
                        return (
                           <div className='add-section-items'  key= {"add_section_"  + i}
                              onClick={()=> {
                                 this.showButton(section_default_json.section.name)
                              }}
                              onMouseOver={()=> {
                                 let newSection = {...section_default_json};
                                 newSection.section.slug = this.slug();
                                 if(newSection.components) {
                                    newSection.components.map(comp => comp.slug = this.slug());
                                 }
                                 functions.handlePreviewSection(newSection, newSection.section.slug, currentIndex )
                              }}
                              onMouseOut={()=> functions.handleRemovePreviewSection(currentIndex)}
                           >

                              <div className='add-section-left-side'>
                                 <img
                                    alt='Default'
                                    src={hero}
                                 />
                                 <div className='add-section-name-and-description'>
                                    <span className='add-section-name'>
                                       {section_default_json.section.name}
                                    </span>
                                    <p className='add-section-description'>
                                                Add a new {section_default_json.section.name} section
                                    </p>
                                 </div>
                              </div>
                              {(this.state.addVisibleSectionName == section_default_json.section.name) && (
                                 <div className='add-section-right-side'>
                                    <Button

                                       className='tiny' primary
                                       onClick = {(e) =>
                                       {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          e.nativeEvent.stopImmediatePropagation();
                                          let newSection = {...section_default_json};
                                          newSection.section.slug = this.slug();
                                          if(newSection.components) {
                                             newSection.components.map(comp => comp.slug = this.slug());
                                          }
                                          functions.handleAddSection(newSection, newSection.section.slug, currentIndex );
                                       }}
                                    >Add</Button>
                                 </div>
                              )}
                           </div>
                        )
                     })
                  )}
               </AppContext.Consumer>
            </Menu>
         </Fragment>
      );
   }
}

AddSectionMenu.defaultProps = {};

export default AddSectionMenu;


