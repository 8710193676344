import React from 'react'
import slug from "../../utils/slugGenerator";

export const Landings_groups = [
   {
      name: '1 Column',
      data: [
         {
            section: {
               slug: slug(),
               name: "1 Column",
               icon: (
                  <svg width='76' height='60' viewBox='0 0 76 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' fill='#F8FAFA'/>
                     <rect x='20.8999' y='15' width='35.15' height='4' rx='2' fill='#D0D2D2'/>
                     <rect x='22.8' y='23' width='30.4' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='26.6001' y='31' width='23.75' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='29.45' y='41' width='18.05' height='6' rx='3' fill='#A1A5A5'/>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' stroke='#E7E9E9'/>
                  </svg>
               )
               ,
               props: {
                  order:'',
                  duplicated:'1 Column',
                  visibility: true,
                  bgVideoSrc: "",
                  // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                  bgColor: "#000000",
                  background_type: "color",
                  imageOverlay: true,
                  spacing: "lg",
                  alignmentAvalaible: true,
                  alignment: "center",
                  bgColorOverlay: '#000000',
                  paddingBottom: 80,
                  paddingTop: 80,
               },
            },
            components: [
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props', background: 'blue', },
                  subcomponents: [],
               },
            ],
         },
      ],
   },
   {
      name: '2 Column',
      data: [
         {
            section: {
               slug: slug(),
               name: "2 Column",
               icon: (
                  <svg width='76' height='60' viewBox='0 0 76 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' fill='#F8FAFA'/>
                     <rect x='20.8999' y='15' width='35.15' height='4' rx='2' fill='#D0D2D2'/>
                     <rect x='22.8' y='23' width='30.4' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='26.6001' y='31' width='23.75' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='29.45' y='41' width='18.05' height='6' rx='3' fill='#A1A5A5'/>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' stroke='#E7E9E9'/>
                  </svg>
               )
               ,
               props: {
                  order:'',
                  duplicated:'2 Column',
                  visibility: true,
                  bgVideoSrc: "",
                  // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                  bgColor: "#000000",
                  background_type: "color",
                  imageOverlay: true,
                  spacing: "lg",
                  alignmentAvalaible: true,
                  alignment: "center",
                  bgColorOverlay: '#000000',
                  paddingBottom: 80,
                  paddingTop: 80,
               },
            },
            components: [
               {
                  slug: slug(),
                  type: 'component',
                  name: 'Two Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'Two Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
            ],
         },
      ],
   },
   {
      name: '3 Column',
      data: [
         {
            section: {
               slug: slug(),
               name: "3 Column",
               icon: (
                  <svg width='76' height='60' viewBox='0 0 76 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' fill='#F8FAFA'/>
                     <rect x='20.8999' y='15' width='35.15' height='4' rx='2' fill='#D0D2D2'/>
                     <rect x='22.8' y='23' width='30.4' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='26.6001' y='31' width='23.75' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='29.45' y='41' width='18.05' height='6' rx='3' fill='#A1A5A5'/>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' stroke='#E7E9E9'/>
                  </svg>
               )
               ,
               props: {
                  order:'',
                  duplicated:'3 Column',
                  visibility: true,
                  bgVideoSrc: "",
                  // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                  bgColor: "#000000",
                  background_type: "color",
                  imageOverlay: true,
                  spacing: "lg",
                  alignmentAvalaible: true,
                  alignment: "center",
                  bgColorOverlay: '#000000',
                  paddingBottom: 80,
                  paddingTop: 80,
               },
            },
            components: [
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
            ],
         },
      ],
   },
   {
      name: '4 Column',
      data: [
         {
            section: {
               slug: slug(),
               name: "4 Column",
               icon: (
                  <svg width='76' height='60' viewBox='0 0 76 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' fill='#F8FAFA'/>
                     <rect x='20.8999' y='15' width='35.15' height='4' rx='2' fill='#D0D2D2'/>
                     <rect x='22.8' y='23' width='30.4' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='26.6001' y='31' width='23.75' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='29.45' y='41' width='18.05' height='6' rx='3' fill='#A1A5A5'/>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' stroke='#E7E9E9'/>
                  </svg>
               )
               ,
               props: {
                  order:'',
                  duplicated:'4 Column',
                  visibility: true,
                  bgVideoSrc: "",
                  // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                  bgColor: "#000000",
                  background_type: "color",
                  imageOverlay: true,
                  spacing: "lg",
                  alignmentAvalaible: true,
                  alignment: "center",
                  bgColorOverlay: '#000000',
                  paddingBottom: 80,
                  paddingTop: 80,
               },
            },
            components: [
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
            ],
         },
      ],
   },
   {
      name: '5 Column',
      data: [
         {
            section: {
               slug: slug(),
               name: "5 Column",
               icon: (
                  <svg width='76' height='60' viewBox='0 0 76 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' fill='#F8FAFA'/>
                     <rect x='20.8999' y='15' width='35.15' height='4' rx='2' fill='#D0D2D2'/>
                     <rect x='22.8' y='23' width='30.4' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='26.6001' y='31' width='23.75' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='29.45' y='41' width='18.05' height='6' rx='3' fill='#A1A5A5'/>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' stroke='#E7E9E9'/>
                  </svg>
               )
               ,
               props: {
                  order:'',
                  duplicated:'5 Column',
                  visibility: true,
                  bgVideoSrc: "",
                  // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                  bgColor: "#000000",
                  background_type: "color",
                  imageOverlay: true,
                  spacing: "lg",
                  alignmentAvalaible: true,
                  alignment: "center",
                  bgColorOverlay: '#000000',
                  paddingBottom: 80,
                  paddingTop: 80,
               },
            },
            components: [
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
            ],
         },
      ],
   },
   {
      name: '6 Column',
      data: [
         {
            section: {
               slug: slug(),
               name: "6 Column",
               icon: (
                  <svg width='76' height='60' viewBox='0 0 76 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' fill='#F8FAFA'/>
                     <rect x='20.8999' y='15' width='35.15' height='4' rx='2' fill='#D0D2D2'/>
                     <rect x='22.8' y='23' width='30.4' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='26.6001' y='31' width='23.75' height='4' rx='2' fill='#E7E9E9'/>
                     <rect x='29.45' y='41' width='18.05' height='6' rx='3' fill='#A1A5A5'/>
                     <rect x='0.5' y='0.5' width='75' height='59' rx='7.5' stroke='#E7E9E9'/>
                  </svg>
               )
               ,
               props: {
                  order:'',
                  duplicated:'6 Column',
                  visibility: true,
                  bgVideoSrc: "",
                  // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                  bgColor: "#000000",
                  background_type: "color",
                  imageOverlay: true,
                  spacing: "lg",
                  alignmentAvalaible: true,
                  alignment: "center",
                  bgColorOverlay: '#000000',
                  paddingBottom: 80,
                  paddingTop: 80,
               },
            },
            components: [
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
               {
                  slug: slug(),
                  type: 'component',
                  name: 'One Column',
                  props: { text: 'Component Props' },
                  subcomponents: [],
               },
            ],
         },
      ],
   },
]






































const Landings_added = [

   //  HERO  SECTIONS DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "1 Column",
         props: {
            order:'',
            duplicated:'1 Column',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#000000",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#000000',
            paddingBottom: 80,
            paddingTop: 80,
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'One Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text: "<span>DEFAULT TITLE</span>",
                     color: "#ffffff",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 40,
                     paddingBottom: 10,
                     paddingTop: 10,
                     maxWidth: 'none',
                     centerText: true,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "center",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#ffffff",
                     lineHeight: 10,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingBottom: 10,
                     paddingTop: 10,
                     maxWidth: 'none',
                     centerText: true,
                  },
               },
               {
                  slug: slug(),
                  type: "ButtonMi",
                  name: "Call to Action",
                  props: {
                     text: "Default Button",
                     bgColor: '#7cb740',
                     textColor: '#ffffff',
                     blanked: false,
                     size: "medium",
                     href: "",
                     border: "none",
                     linked: true,
                     alignment: 'center',
                     marginBottom: 10,
                     marginTop: 10,
                     marginLeft: 0,
                     marginRight: 0,
                  },
               },
            ],
         },
      ],
   },

   //  TEXT WITH IMAGE  SECTIONS DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Text with Image",
         props: {
            order:'',
            duplicated:'Text with image',
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "md",
            reverseAvailaible: true,
            reversed: false,
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_2/comp.png"),
                     width: "350",
                     radius: "0",
                     visibility: true,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text:
                            "<span>DEFAULT IMAGE AND TEXT</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-Bold',
                     fontSize: 40,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
      ],
   },

   //  TESTIMONIALS  SECTIONS DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Testimonials",
         props: {
            order:'',
            duplicated:"Testimonials",
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "sm",
            reverseAvailaible: true,
            reversed: false,
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text:
                            "<span>DEFAULT TESTIMONIALS</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 5,
                     paddingBottom: 5,
                     paddingLeft: 5,
                     paddingRight: 5,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 5,
                     paddingBottom: 5,
                     paddingLeft: 5,
                     paddingRight: 5,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/add_section_default/author.jpg"),
                     width: "150",
                     radius: "0",
                     form: "circle",
                     visibility: true,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text:
                            "<span>Class Author</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 14,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
      ],
   },

   //  TEXT WITH VIDEO  SECTIONS DEFAULT JSON
   {
      section: {
         //slug unique identifier
         slug: slug(),
         //component name (used as show name for sidebar)
         name: "Text with Video",
         //section_props
         props: {
            //props:value
            order:'',
            duplicated:"Text with video",
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "lg",
            bgColorOverlay: '#ffffff',
            paddingTop: 80,
            paddingBottom: 80,
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text:
                            "<span>DEFAULT TEXT AND VIDEO</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 48,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqu consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Video",
                  name: "Video",
                  props: {
                     src: "https://www.youtube.com/embed/RVjxFLTCngw",
                     width: "800",
                     height: "500",
                     spacing: "xs",
                     visibility: true,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                  },
               },
            ],
         },
      ],
   },

   //  FEATURES SECTIONS DEFAULT JSON
   {
      section: {
         //slug unique identifier
         slug: slug(),
         //component name (used as show name for sidebar)
         name: "Features",
         //section_props
         props: {
            //props:value
            order:'',
            duplicated:"Features",
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "lg",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/add_section_default/default_circle_1.png"),
                     width: "150",
                     radius: "0",
                     visibility: true,
                     feautures: "1",
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text:
                            "<span>DEFAULT 1</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 26,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "center",
                     text:
                            "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 20,
                     paddingBottom: 20,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/add_section_default/default_circle_2.png"),
                     width: "150",
                     radius: "0",
                     visibility: true,
                     feautures: "2",
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text:
                            "<span>DEFAULT 2</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 26,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "center",
                     text:
                            "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 20,
                     paddingBottom: 20,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/add_section_default/default_circle_3.png"),
                     width: "150",
                     radius: "0",
                     visibility: true,
                     feautures: "3",
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text:
                            "<span>DEFAULT 3</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 26,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "center",
                     text:
                            "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 20,
                     paddingBottom: 20,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
      ],
   },

   //  Text One Column DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Text One Column",
         props: {
            order:'',
            duplicated:'Text one column',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'One Column',
            props: { text: 'One Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 48,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "center",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
      ],
   },

   //  Text Two Column DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Text Two Column",
         props: {
            order:'',
            duplicated:'Text two column',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Two Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Two Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
      ],
   },

   //  Text Three Column DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Text Three Column",
         props: {
            order:'',
            duplicated:'Text three column',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Three Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Three Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Three Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
      ],
   },


   //  Call to action DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Call to Action",
         props: {
            order:'',
            duplicated:'Call to action',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'One Column',
            props: { text: 'One Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 48,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "center",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: 'ButtonMi',
                  name: 'Call to Action',
                  props: {
                     text: 'Default Button',
                     bgColor: '#7cb740',
                     textColor: '#ffffff',
                     widthChangeable: true,
                     href: '',
                     size: 'medium',
                     border: 'none',
                     blanked: true,
                     linked: true,
                     alignment: 'center',
                     marginTop: 10,
                     marginBottom: 10,
                     marginLeft: 0,
                     marginRight: 0,
                     fontFamily: 'Inter-Bold',
                     width: "283",
                  },
               },
            ],
         },
      ],
   },

   // Two feature column DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Two Feature Column",
         props: {
            order:'',
            duplicated:'Two feature column',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Two Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "200",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: 'ButtonMi',
                  name: 'Call to Action',
                  props: {
                     text: 'Default Button',
                     bgColor: '#7cb740',
                     textColor: '#ffffff',
                     widthChangeable: true,
                     href: '',
                     size: 'medium',
                     border: 'none',
                     blanked: true,
                     linked: true,
                     alignment: 'left',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Two Column',
            props: { text: 'Two Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "200",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: 'ButtonMi',
                  name: 'Call to Action',
                  props: {
                     text: 'Default Button',
                     bgColor: '#7cb740',
                     textColor: '#ffffff',
                     widthChangeable: true,
                     href: '',
                     size: 'medium',
                     border: 'none',
                     blanked: true,
                     linked: true,
                     alignment: 'left',
                  },
               },
            ],
         },
      ],
   },

   //  Three feature column DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Three Feature Column",
         props: {
            order:'',
            duplicated:'Three feature column',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Three Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "200",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: 'ButtonMi',
                  name: 'Call to Action',
                  props: {
                     text: 'Default Button',
                     bgColor: '#7cb740',
                     textColor: '#ffffff',
                     widthChangeable: true,
                     href: '',
                     size: 'medium',
                     border: 'none',
                     blanked: true,
                     linked: true,
                     alignment: 'left',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Three Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "200",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: 'ButtonMi',
                  name: 'Call to Action',
                  props: {
                     text: 'Default Button',
                     bgColor: '#7cb740',
                     textColor: '#ffffff',
                     widthChangeable: true,
                     href: '',
                     size: 'medium',
                     border: 'none',
                     blanked: true,
                     linked: true,
                     alignment: 'left',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Three Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "200",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Default title</span>",
                     color: "#34495e",
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 30,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                            "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                     color: "#34495e",
                     lineHeight: 18,
                     fontFamily: 'Inter-Regular',
                     fontSize: 18,
                     paddingTop: 10,
                     paddingBottom: 10,
                     paddingLeft: 0,
                     paddingRight: 0,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
               {
                  slug: slug(),
                  type: 'ButtonMi',
                  name: 'Call to Action',
                  props: {
                     text: 'Default Button',
                     bgColor: '#7cb740',
                     textColor: '#ffffff',
                     widthChangeable: true,
                     href: '',
                     size: 'medium',
                     border: 'none',
                     blanked: true,
                     linked: true,
                     alignment: 'left',
                  },
               },
            ],
         },
      ],
   },


   //  Image column DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Image Column",
         props: {
            order:'',
            duplicated:'Image column',
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "md",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Image Column',
            props: { text: 'Image Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/image-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                  },
               },
            ],
         },
      ],
   },
   //  Video column DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Video Column",
         props: {
            order:'',
            duplicated:'Video column',
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "md",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Video Column',
            props: { text: 'Video Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Video",
                  name: "Video",
                  props: {
                     src: "https://www.youtube.com/embed/RVjxFLTCngw",
                     width: "800",
                     height: "500",
                     spacing: "xs",
                     visibility: true,
                  },
               },
            ],
         },
      ],
   },
   //  Image gallery DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Image Gallery",
         props: {
            order:'',
            duplicated:'Image gallery',
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "md",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Image Column',
            props: { text: 'Image Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (400x250)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Image Column',
            props: { text: 'Image Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (400x250)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Image Column',
            props: { text: 'Image Column' },
            subcomponents: [

               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (400x250)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Image Column',
            props: { text: 'Image Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (400x250)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Image Column',
            props: { text: 'Image Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (400x250)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Image Column',
            props: { text: 'Image Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (400x250)',
                  },
               },
            ],
         },
      ],
   },
   // Logo list DEFAULT JSON
   {
      section: {
         slug: slug(),
         name: "Logo List",
         props: {
            order:'',
            duplicated:'Logo list',
            visibility: true,
            bgVideoSrc: "",
            bgImgSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            spacing: "md",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Logo Column',
            props: { text: 'Logo Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Logo Column',
            props: { text: 'Logo Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Logo Column',
            props: { text: 'Logo Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Logo Column',
            props: { text: 'Logo Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Logo Column',
            props: { text: 'Logo Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Logo Column',
            props: { text: 'Logo Column' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Image",
                  name: "Image",
                  props: {
                     imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                     width: "auto",
                     radius: "0",
                     visibility: true,
                     imageLabel: 'Show Image (200x100)',
                  },
               },
            ],
         },
      ],
   },

   //  FAQ JSON
   {
      section: {
         slug: slug(),
         name: "FAQ",
         props: {
            order:'',
            duplicated:'FAQ',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#edf5ff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#edf5ff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'FAQ title',
            props: { text: 'FAQ title' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text: "<span>FAQ</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqQuestion: true,
                     label: 'Show title',
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 48,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'QuestionAnswer',
            props: { text: 'QuestionAnswer' , isFaq: true , isNotDraggable: true},
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Where do I host my class?</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqQuestion: true,
                     label: 'Show question',
                     lineHeight: 10,
                     fontFamily: 'Inter-Medium',
                     fontSize: 20,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                        "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqAnswer: true,
                     lineHeight: 18,
                     fontFamily: 'Inter-Medium',
                     fontSize: 18,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'QuestionAnswer',
            props: { text: 'QuestionAnswer' , isFaq: true , isNotDraggable: true},
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Are there any transaction fees?</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqQuestion: true,
                     label: 'Show question',
                     lineHeight: 10,
                     fontFamily: 'Inter-Medium',
                     fontSize: 20,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                        "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqAnswer: true,
                     lineHeight: 18,
                     fontFamily: 'Inter-Medium',
                     fontSize: 18,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'QuestionAnswer',
            props: { text: 'QuestionAnswer' , isFaq: true , isNotDraggable: true},
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<p><span style='font-size:20px;'>Can I use my own domain?</span></p>",
                     color: "#34495e",
                     visibility: true,
                     isFaqQuestion: true,
                     label: 'Show question',
                     lineHeight: 10,
                     fontFamily: 'Inter-Medium',
                     fontSize: 20,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                        "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqAnswer: true,
                     lineHeight: 18,
                     fontFamily: 'Inter-Medium',
                     fontSize: 18,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'QuestionAnswer',
            props: { text: 'QuestionAnswer' , isFaq: true , isNotDraggable: true},
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "left",
                     text: "<span>Do you offer any money back guarantees?</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqQuestion: true,
                     label: 'Show question',
                     lineHeight: 10,
                     fontFamily: 'Inter-Medium',
                     fontSize: 20,
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "left",
                     text:
                        "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                     color: "#34495e",
                     visibility: true,
                     isFaqAnswer: true,
                     lineHeight: 18,
                     fontFamily: 'Inter-Medium',
                     fontSize: 18,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Empty Column',
            props: { text: 'Empty Column'},
            subcomponents: [],
         },
      ],
   },
   //  Countdown JSON
   {
      section: {
         slug: slug(),
         name: "Countdown",
         props: {
            order:'',
            duplicated:'Countdown',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Countdown',
            props: { text: 'Countdown'},
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Title",
                  props: {
                     align: "center",
                     text: "<span>Countdown</span>",
                     color: "#34495e",
                     visibility: true,
                     label: 'Show title',
                     lineHeight: 10,
                     fontFamily: 'Inter-ExtraBold',
                     fontSize: 48,
                     maxWidth: 'none',
                     centerText: false,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Time',
            props: { text: 'Time', isNotDraggable: true, isType: 'Timer' },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Days",
                  props: {
                     align: "center",
                     text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Days</span></p>",
                     color: "#34495e",
                     visibility: true,
                     label: 'Days',
                     number: 0,
                     isCountdown: true,
                     date: Math.round(Date.now() / 1000),
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Hours",
                  props: {
                     align: "center",
                     text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Hours</span></p>",
                     color: "#34495e",
                     visibility: true,
                     label: 'Hours',
                     number: 0,
                     isCountdown: true,
                     date: Math.round(Date.now() / 1000),
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Minutes",
                  props: {
                     align: "center",
                     text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Minutes</span></p>",
                     color: "#34495e",
                     visibility: true,
                     label: 'Minutes',
                     number: 0,
                     isCountdown: true,
                     date: Math.round(Date.now() / 1000),
                  },
               },
               {
                  slug: slug(),
                  type: "Text",
                  name: "Seconds",
                  props: {
                     align: "center",
                     text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Seconds</span></p>",
                     color: "#34495e",
                     visibility: true,
                     label: 'Seconds',
                     number: 0,
                     isCountdown: true,
                     date: Math.round(Date.now() / 1000),
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Empty Column',
            props: { text: 'Empty Column'},
            subcomponents: [],
         },

      ],
   },

   //  Custom code
   {
      section: {
         slug: slug(),
         name: "Custom Code",
         props: {
            order:'',
            duplicated:'Custom code',
            visibility: true,
            bgVideoSrc: "",
            bgColor: "#ffffff",
            background_type: "color",
            imageOverlay: false,
            spacing: "lg",
            alignmentAvalaible: false,
            alignment: "center",
            isCustomCode: true,
            bgColorOverlay: '#ffffff',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Custom code',
            props: { text: 'Custom code', isNotDraggable: true },
            subcomponents: [
               {
                  slug: slug(),
                  type: "Text",
                  name: "Text",
                  props: {
                     align: "center",
                     text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Custom code goes here...</span></p>",
                     color: "#34495e",
                     isCustomCode: true,
                     isNotDraggable: true,
                  },
               },
            ],
         },
      ],
   },

   // Lessons Area Default JSON

   {
      section: {
         slug: slug(),
         name: 'Lessons Area',
         props: {
            order: '',
            duplicated: 'course',
            visibility: true,
            bgVideoSrc: '',
            bgImgSrc: '',
            bgColor: '#272727',
            background_type: 'color',
            spacing: 'md',
            videoAvailaible: false,
            bgColorOverlay: '#272727',
         },
      },
      components: [
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Component Props' },
            subcomponents: [
               {
                  slug: slug(),
                  type: 'Text',
                  name: 'Title',
                  props: {
                     align: 'center',
                     text: '<span>Class Curriculum</span>',
                     color: '#ffffff',
                     lineHeight: 10,
                     fontFamily: 'Roboto-Black',
                     fontSize: 48,
                     centerText: true,
                     maxWidth: 'none',
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Component Props', isNotDraggable: true, type: 'classes_conteiner' },
            subcomponents: [
               {
                  slug: slug(),
                  type: 'Text',
                  name: "Curriculum's Colors",
                  props: {
                     text: "<span>Curriculum's Colors</span>",
                     visibility: true,
                     label: "Curriculum's Colors",
                     lessonsBgColor: '#333333',
                     lessonsColor: '#ffffff',
                     isLessonsBgColor: true,
                     lineHeight: 18,
                     fontFamily: 'Roboto-Medium',
                     fontSize: 18,
                  },
               },
            ],
         },
         {
            slug: slug(),
            type: 'component',
            name: 'Three Column',
            props: { text: 'Component Props' },
            subcomponents: [],
         },
      ],
   },
]

export default Landings_added;





// export {
//     hero,
//     text_and_image,
//     testimonials,
//     text_and_video,
//     features,
// };
