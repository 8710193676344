import React, { Component, Fragment } from "react";
import { Image, Divider, Button, Icon } from "semantic-ui-react";
import "./index.css";
import AppContext from "contexts/AppContext";

class Favicon extends Component {
   constructor(props) {
      super(props);
   }
   handleButtonClick = () => {
      this.inputElement.click();
   };
   render() {
      return (
         <Fragment>
            <h4>Favicon</h4>
            <Divider />
            <div className='ImageEditable'>
               <p>{this.props.label}</p>
               <Image
                  size='mini'
                  centered
                  id='favicon'
                  src={ this.props.landing_favicon && this.props.landing_favicon.indexOf('static/media') !== -1 ? "https://miestro-production.s3-us-west-2.amazonaws.com/landing/favico.ico" : this.props.landing_favicon}
               />
               <br />
               <AppContext.Consumer>
                  {({ state, functions }) => (
                     <input
                        type='file'
                        accept='image/gif, image/ico, image/png'
                        onChange={e => functions.handleFaviconChange(e)}
                        ref={input => (this.inputElement = input)}
                     />
                  )}
               </AppContext.Consumer>
               <Button
                  onClick={this.handleButtonClick}
                  className='upload_favicon_button'
               >
                        Upload Now
               </Button>
               <AppContext.Consumer>
                  {({ state, functions }) => (
                     <Button
                        onClick={ ()=> functions.handleFaviconChangetoDefaul()}
                        className='upload_favicon_button upload_favicon_button-revert'
                     >
                        Revert
                     </Button>
                  )}
               </AppContext.Consumer>
            </div>
         </Fragment>
      );
   }
}

export default Favicon;
