import React, { Component, Fragment } from "react";
import { Header, Form } from "semantic-ui-react";
import AppContext from "contexts/AppContext";
import PropTypes from 'prop-types';
import Input from 'components/inputNew'
import "./index.css";

class InputText extends Component {
   render() {
       return (
           <AppContext.Consumer>
            {({ state, functions }) => (
                <Fragment>
                        <Form>
                            <Form.Field>
                                <div className='text_column_name'>{this.props.label}</div>
                                <div className='text_column_content'>
                                {this.props.disabled && 
                                <div className="disabled_text" dangerouslySetInnerHTML={ { __html: this.props.inputValue!==undefined ? this.props.inputValue: this.props.default } }/>}
                                 {!this.props.disabled &&    
                                  <input
                                        style={{width: '100%'}}
                                        type='text'
                                        maxLength={this.props.maxLength}
                                        value={this.props.inputValue!==undefined ? this.props.inputValue: this.props.default}
                                        disabled={((this.props.disabled && !this.props.isCustomLink) && this.props.scroll_to!=='courses') ? true: false}
                                        placeholder={this.props.placeholder}
                                        onChange={e => {
                                            {
                                                functions.handleUpdateProps(
                                                    this.props.section,
                                                    this.props.slug,
                                                    e.target.value,
                                                    this.props.props_name,
                                                    null,
                                                    this.props.propsName
                                                )
                                            }
                                        }}
                                        />}
                                </div>
                            </Form.Field>
                        </Form>
                        {!this.props.removeLine ? <Header as='h3' dividing /> : <div style={{marginTop: '5px'}}></div>}
                </Fragment>
            )}
         </AppContext.Consumer>
      );
   }
}




InputText.propTypes = {

   // EXPECTED STRING
   label: PropTypes.string,
   props_name: PropTypes.string,
   slug: PropTypes.string,
   props_name: PropTypes.string,
   placeholder: PropTypes.string,
   default: PropTypes.string,
   // EXPECTED BOOLEAN
   checked: PropTypes.bool,
   value: PropTypes.bool,

   // EXPECTED NUMBER OR STRING IF EMPTY
   section: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
   ]),
}

InputText.defaultProps = {
   default: "",
};

export default InputText;
