import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_5",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#0db2c5",
                    background_type: "color",
                    spacing: "xs",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "30",
                        imgUrl: require("../images/landing_2/logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "SUPPORT",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "SIGN UP",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "|",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "medium",
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Hero",
                props: {
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_5/t1-bg.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    spacing: "xl",
                    alignmentAvalaible: true,
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-size:44px;font-weight:bold;'>JOIN OUR ONLINE MARKETING </span></h1><h1><span style='font-size:44px;font-weight:bold;'> COMMUNITY TODAY!</span></h1>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#0db2c5",
                        textColor: "#ffffff",
                        size: "medium",
                        href: "",
                        widthChangeable: true,
                        width: "full",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Text with video",
                props: {
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    imageOverlay: false,
                    videoAvailaible: true,
                    imageAvailaible: true,
                    alignmentAvalaible: false,
                    height: "",
                    spacing: "sm",
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color: #003366;'>Day 1-2: comprehensive online training</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<p><span style='color: #333333;line-height: 1.5; font-weight: 500;'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Video",
                    name: "Video",
                    props: {
                        src: "https://www.youtube.com/embed/zIEtTDXUa3E",
                        visibility: true,
                        width: "600",
                        height: "400",
                        spacing: "xs",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Hero",
                props: {
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_5/t1-bg.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    spacing: "md",
                    alignmentAvalaible: true,
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span>Subscribe to the course now</span></h1>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE FOR $35",
                        bgColor: "#0db2c5",
                        textColor: "#ffffff",
                        size: "medium",
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Text with video",
                props: {
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color: #003366;'>Day 3-4: comprehensive online training</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<p><span style='color: #333333;line-height: 1.5; font-weight: 500;'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Video",
                    name: "Video",
                    props: {
                        src: "https://www.youtube.com/embed/zIEtTDXUa3E",
                        visibility: true,
                        width: "600",
                        height: "400",
                        spacing: "xs",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Testimonials",
                props: {
                    order:5,
                    duplicated:5,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "sm",
                    reverseAvailaible: true,
                    reversed: false,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/comp.png"),
                        width: "200",
                        visibility: true,
                        spacing: "sm",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text:
                            '<p><span style="color: #003366;">John Smith</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text:
                            '<p><span style="color: #808080;">Course developer</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<h2><span style="color: #003366;">Meet your course creator</span></h2>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #333333;line-height: 1.5; font-weight: 500;">John Smith Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span></p>',
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:6,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#f9f9f9",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:26px;font-weight:800'>Purchase options</span></p>",
                        color: "#30242c",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#666666",
                        textColor_selected: "#2b8074",
                        bgColor: "#f1f3f3",
                        bgColor_selected: "#e0eeec",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#0db2c5",
                        textColor: "#ffffff",
                        size: "large",
                        href: "",
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Footer",
                props: {
                    order:7,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#264555",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Miestro community",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "medium",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        radius: true,
                        floated: "right",
                        text: "Powered by Miestro ©",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        radius: true,
                        floated: "right",
                        text: "Privacy Policy",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        radius: true,
                        floated: "right",
                        text: "Terms and Conditions",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        radius: true,
                        floated: "right",
                        text: "Contact us",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
    ],
};

export default state;
