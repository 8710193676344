import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import classNames from 'classnames';
import './index.css';
import IconNew from 'components/elements/iconsSize';

export const THEMES = {
   light: 'light',
   delete: 'delete',
   primary: 'primary',
   inherit: 'inherit',
};

const IconButton = ({
   name, theme, wBorder, onClick, className, disabled, title,
}) => {
   return (
      <button
         type='button'
         title={ title }
         onClick={ disabled ? () => {} : onClick }
         className={
            classNames(
               'iconButton',
               className,
               {
                  [`iconButton__${ theme }`]: theme && !disabled,
                  [`iconButton__${ theme }__border`]: wBorder && theme && !disabled,
                  'iconButton__disabled': disabled,
               }
            )
         }
      >
         {/* <Icon name={ name } /> */}
         <IconNew name={ name } />
      </button>
   );
};

IconButton.propTypes = {
   name: PropTypes.string,
   onClick: PropTypes.func,
   theme: PropTypes.string,
   wBorder: PropTypes.bool,
   className: PropTypes.string,
   disabled: PropTypes.bool,
   title: PropTypes.string,
};

export default IconButton;
