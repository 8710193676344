import React from 'react';
import PropTypes from 'prop-types';
import Text, { TYPES as txtTypes, SIZES as txtSizes } from 'components/TextNew';
import BaseButton, { THEMES as btnTheme, SIZES as btnSize } from 'components/BaseButtonNew';
import './index.css';
import ClickOutside from './OutsideClick';


const DeleteModal = ({
   title, deleteText, onDelete, onCancel, maxWidth, cancelBtnSize = 'large', approveBtnSize = 'large120',
}) => {
   return (
      <div className='delete__modal'>
         <div className='delete__modal__background' />
         <ClickOutside onClick={ () => onCancel() }>
            <div className='delete__modal__content' style={ { maxWidth: `${ maxWidth }px` || '334px' } }>
               <Text
                  inner={ title }
                  type={ txtTypes.medium }
                  className='delete__modal__content__span'
                  size={ txtSizes.xxlarge }
               />
               <div className='delete__modal__content__footer'>
                  <BaseButton
                     text='Cancel'
                     theme={ btnTheme.secondary }
                     size={ btnSize[cancelBtnSize] }
                     onClick={ () => onCancel() }
                  />
                  {onDelete && (
                     <BaseButton
                        text={ deleteText }
                        size={ btnSize[approveBtnSize] }
                        theme={ btnTheme.red }
                        onClick={ (e) => onDelete(e) }
                     />
                  )}
               </div>
            </div>
         </ClickOutside>
      </div>
   );
};

DeleteModal.propTypes = {
   title: PropTypes.string,
   onDelete: PropTypes.func,
   onCancel: PropTypes.func,
   deleteText: PropTypes.string,
   maxWidth: PropTypes.number,
   cancelBtnSize: PropTypes.string,
   approveBtnSize: PropTypes.string,
};

export default DeleteModal;
