import Evaporate from 'evaporate';
import sha256 from 'js-sha256';
import sparkMD5 from 'spark-md5';
import { getQueryParams } from 'utils'

const {
   REACT_APP_AWS_KEY, REACT_APP_AWS_BUCKET, REACT_APP_API_LOCAL_ENDPOINT, REACT_APP_AWS_S3_REGION,
} = process.env;
const apiUrl = window.location.origin;
const url = window.location.href;
const token = getQueryParams('token', url);

const config = {
   signerUrl: `${ apiUrl }/api/s3-sign`,
   timeUrl: `${ apiUrl }/api/get-time`,
   aws_key: REACT_APP_AWS_KEY,
   bucket: REACT_APP_AWS_BUCKET,
   cloudfront: true,
   awsRegion: REACT_APP_AWS_S3_REGION,
   computeContentMd5: true,
   cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
   cryptoHexEncodedHash256: sha256,
   signHeaders: {
      Authorization: () => {
         return `Bearer ${ token }`;
      },
   },
};

let evaporate = null;

export default async function init() {
   try {
      const instance = await Evaporate.create(config);
      evaporate = instance;
      return evaporate;
   } catch (reason) {
      throw reason;
   }
}
