import land_1_defaults from "defaults/landing_1";
import land_2_defaults from "defaults/landing_2";
import land_3_defaults from "defaults/landing_3";
import land_4_defaults from "defaults/landing_4";
import land_5_defaults from "defaults/landing_5";
import land_6_defaults from "defaults/landing_6";
import land_7_defaults from "defaults/landing_7";
import land_8_defaults from "defaults/landing_8";
import land_9_defaults from "defaults/landing_9";
import land_10_defaults from "defaults/landing_10";
import land_11_defaults from "defaults/landing_11";
import land_12_defaults from "defaults/landing_12";
import land_13_defaults from "defaults/landing_13";
import land_14_defaults from "defaults/landing_14";
import land_15_defaults from "defaults/landing_15";
import land_16_defaults from "defaults/landing_16";
import land_17_defaults from "defaults/landing_17";
import land_18_defaults from "defaults/landing_18";
import land_19_defaults from "defaults/landing_19";
import land_20_defaults from "defaults/landing_20";

const getAllLandingDefaults = () => {
    return {
        land_1: land_1_defaults,
        land_2: land_2_defaults,
        land_3: land_3_defaults,
        land_4: land_4_defaults,
        land_5: land_5_defaults,
        land_6: land_6_defaults,
        land_7: land_7_defaults,
        land_8: land_8_defaults,
        land_9: land_9_defaults,
        land_10: land_10_defaults,
        land_11: land_11_defaults,
        land_12: land_12_defaults,
        land_13: land_13_defaults,
        land_14: land_14_defaults,
        land_15: land_15_defaults,
        land_16: land_16_defaults,
        land_17: land_17_defaults,
        land_18: land_18_defaults,
        land_19: land_19_defaults,
        land_20: land_20_defaults,
    };
};

export default getAllLandingDefaults;
