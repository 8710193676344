import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_9",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#15639e",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "60",
                        imgUrl: require("../images/landing_9/logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#dd5c13",
                        textColor: "#ffffff",
                        size: "large",
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Support",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "large",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Hero",
                //section_props
                props: {
                    //props:value
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#146fb5",
                    background_type: "color",
                    alignmentAvalaible: true,
                    spacing: "sm",
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='line-height:1.7;font-size:50px'>Everyone Can Learn PPC </br> Earn Money with Online Advertising</span>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#dd5c13",
                        textColor: "#ffffff",
                        size: "large",
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Text with image",
                //section_props
                props: {
                    //props:value
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(255,255,255)",
                    background_type: "color",
                    spacing: "sm",
                    reverseAvailaible: true,
                    reversed: false,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color:#333333;font-size:36px'>Start selling any product in 3 simple steps...</span></h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/arrow.png"),
                        width: "40",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Exitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/img1.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h1><span style='color:#146fb5'>Choose your course</h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#dd5c13",
                        textColor: "#dd5c13",
                        size: "medium",
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "solid",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Text with image",
                //section_props
                props: {
                    //props:value
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(255,255,255)",
                    background_type: "color",
                    spacing: "sm",
                    reverseAvailaible: true,
                    reversed: true,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color:#333333'>Start selling any product in 3 simple steps...</span></h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/arrow.png"),
                        width: "40",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Exitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/img2.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h1><span style='color:#146fb5'>Choose your course</h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#dd5c13",
                        textColor: "#dd5c13",
                        size: "medium",
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "solid",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Text with image",
                //section_props
                props: {
                    //props:value
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(255,255,255)",
                    background_type: "color",
                    spacing: "sm",
                    reverseAvailaible: true,
                    reversed: false,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color:#333333'>Start selling any product in 3 simple steps...</span></h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/arrow.png"),
                        width: "40",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:16px;line-height: 1.5; font-weight: 500;"><span>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Exitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/img3.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h1><span style='color:#146fb5'>Choose your course</h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#dd5c13",
                        textColor: "#dd5c13",
                        size: "medium",
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "solid",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Features",
                //section_props
                props: {
                    //props:value
                    order:5,
                    duplicated:5,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#f4f4f4",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color:#333333;font-size:36px'>Start selling any product in 3 simple steps...</span></h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/code.png"),
                        width: "80",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h4><span style='color:#333333'>Learn to code</span></h4>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/design.png"),
                        width: "80",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h4><span style='color:#333333'>Learn to code</span></h4>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/analytics.png"),
                        width: "80",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h4><span style='color:#333333'>Learn to design</span></h4>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#dd5c13",
                        textColor: "#dd5c13",
                        size: "large",
                        linked: false,
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "solid",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Details",
                //section_props
                props: {
                    //props:value
                    order:6,
                    duplicated:6,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#ffffff",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color:#333333;font-size:36px'>Course details</span></h1>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h4><span>Course module #1</span></h4>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/youtube.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h4><span style='color:#333333;font-family:Montserrat-Bold'>Introduction video</span></h4>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;color:#999999"><span>00:23:56</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h4><span>Course module #2</span></h4>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/youtube.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h4><span style='color:#333333;font-family:Montserrat-Bold'>Introduction video</span></h4>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;color:#999999"><span>00:23:56</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h4><span>Course module #3</span></h4>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/pdf.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h4><span style='color:#333333;font-family:Montserrat-Bold'>Introduction file</span></h4>",
                        color: "rgb(255,255,255)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;color:#999999"><span>00:23:56</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="font-size:14px;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis</span></p>',
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Images",
                //section_props
                props: {
                    //props:value
                    order:7,
                    duplicated:7,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#ffffff",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/mailchimp.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/activecampaign.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_9/getresponse.png"),
                        width: "",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Hero",
                //section_props
                props: {
                    //props:value
                    order:8,
                    duplicated:8,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#146fb5",
                    background_type: "color",
                    alignmentAvalaible: true,
                    alignment: "center",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='line-height:1.7;font-size:40px'>Sign up for the easiest way to sell </br> tons of stuff online today</span>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#dd5c13",
                        textColor: "#ffffff",
                        size: "large",
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:9,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#ffffff",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:30px;font-weight:800'>Get course package</span></p>",
                        color: "#333333",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#666666",
                        textColor_selected: "#dd5c13",
                        bgColor: "#d6d6d6",
                        bgColor_selected: "#cae8ff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROL NOW!",
                        bgColor: "#dd5c13",
                        textColor: "#ffffff",
                        size: "large",
                        href: "",
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Footer",
                //section_props
                props: {
                    //props:value
                    order:10,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#eef4fe",
                    spacing: "xs",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "2017 Sitename All rights reserved",
                        bgColor: "transparent",
                        textColor: "#999999",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Powered By Miestro",
                        bgColor: "transparent",
                        textColor: "#999999",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
    ],
};

export default state;
