import { slug } from "utils";

export const Text = () => {
    return (
        {
            slug: slug(),
            type: 'Text',
            name: 'Text',
            props: {
               align: 'center',
               text: '<span>Text Example</span>',
               color: 'rgb(168 166 166)',
               lineHeight: 10,
               fontFamily: 'Roboto-Regular',
               fontSize: 14,
               paddingTop: 12,
               paddingBottom: 12,
               paddingRight: 12,
               paddingLeft: 12,
               availableDelete: true,
               centerText: true,
               maxWidth: 'none',
            },
        }
    )
}

export const Headline = () => {
    return (
        {
            slug: slug(),
            type: 'Text',
            name: 'Title',
            props: {
               align: 'center',
               text: '<span>Headline Example</span>',
               color: 'rgb(168 166 166)',
               lineHeight: 11,
               fontFamily: 'Roboto-Black',
               fontSize: 56,
               availableDelete: true,
               centerText: true,
               maxWidth: 'none',
            },
        }
    )
}

export const Subheadline = () => {
    return (
        {
            slug: slug(),
            type: 'Text',
            name: 'Subheadline',
            props: {
               align: 'center',
               text: '<span>Sub-headline text</span>',
               color: 'rgb(168 166 166)',
               lineHeight: 8,
               fontFamily: 'Roboto-Regular',
               fontSize: 24,
               availableDelete: true,
               centerText: true,
               maxWidth: 'none',
            },
        }
    )
}

export const Image = () => {
    return (
        {
            slug: slug(),
            type: 'Image',
            name: 'Image',
            props: {
               align: 'center',
               imgUrl: 'https://miestro-production.s3-us-west-2.amazonaws.com/landing/empty_image.svg',
               width: '545',
               radius: '0',
               visibility: true,
               imageLabel: 'Show Image',
               isBrandNewImg: true,
               availableDelete: true,
            },
         }
    )
}

export const Button = () => {
    return (
        {
            slug: slug(),
            type: 'ButtonMi',
            name: 'Call to Action',
            props: {
               text: 'Get Started',
               bgColor: '#7cb740',
               textColor: '#ffffff',
               href: '',
               size: 'medium',
               widthChangeable: true,
               border: 'none',
               alignment: 'center',
               fontFamily: 'Roboto-Bold',
               fontStyle: 'bold',
               textSize: 19,
               widthValue: 288,
               allCaps: true,
               marginTop: 10,
               marginBottom: 10,
               marginLeft: 0,
               marginRight: 0,
               availableDelete: true,
            },
         }
    )
}

export const Video = () => {
    return (
        {
            slug: slug(),
            type: "Video",
            name: "Video",
            props: {
                src: "https://www.youtube.com/embed/",
                width: "800",
                height: "500",
                spacingValue: 5,
                visibility: true,
                availableDelete: true,
                centerText: true,
                maxWidth: 'none',
            },
        }
    )
}

export const Bullet = () => {
    return (
        {
            slug: slug(),
            type: 'Bullet',
            name: 'Bullet',
            props: {
               align: 'center',
               isChecklist: true,
               text1: 'Guidelines For Inkjet Cartridge Refill',
               text2: 'Internet Advertising What Went Wrong',
               text3: 'Advertising Relationships Vs Business Decisions',
               text4: 'Stu Unger Rise And Fall Of A Poker Genius',
               text5: 'Download Free Song For Ipod',
               visibility1: true,
               visibility2: true,
               visibility3: true,
               visibility4: true,
               visibility5: true,
               color: '#ffffff',
               iconColor: '#ffffff',
               lineHeight: 15,
               fontFamily: 'Roboto-Medium',
               fontSize: 16,
               visibility: true,
               availableDelete: true,
               centerText: true,
               maxWidth: 400,
               iconType: 'circle',
            },
         }
    )
}

export const Divider = () => {
    return (
        {
            slug: slug(),
            type: 'Divider',
            name: 'Divider',
            props: {
                contentWidth: '100%',
                align: 'center',
                lineThickness: 1,
                marginTop: 0,
                marginBottom: 0,
                availableDelete: true,
                centerText: true,
                width: '100',
                maxWidth: 'none',
                textBgColor: '#A1A5A5',
                borderTop: "solid",
            },
        }
    )
}

export const FaqElementData = () => {
    return (
        {
            slug: slug(),
            type: 'FaqElement',
            name: 'Faq Element',
            props: {
                maxWidth: 700,
                marginTop: 0,
                marginLeft: 0,
                marginBottom: 0,
                marginRight: 0,
                alignment: 'left',
    
                paddingTop: 24,
                paddingLeft: 24,
                paddingBottom: 24,
                paddingRight: 24,
                backColor: '#F7F8FA',
                openedBackColor: '#ffffff',
                iconColor: '#22272F',
                borderColor: '#E7E9E9',
                borderRadius: 20,
    
                title: '<span>How can I access the ballet video program?</span>',
                titleColor: '#131F1E',
                titleFontFamily: 'Roboto-Medium',
                titleFontSize: 18,
                titleLineHeight: 20,
                text: "<span>Upon enrollment, you'll receive instant access to our video program. Simply log in to your account, and you can start streaming the lessons at any time that suits you.</span>",
                textColor: '#727978',
                textFontFamily: 'Roboto-Regular',
                textFontSize: 14,
                textLineHeight: 18,
            }
        }
    )
}

export const CallActionElementData = () => {
    return (
        {
            slug: slug(),
            type: 'CallActionElement',
            name: 'Call To Action Element',
            avatarWithText: true,
            props: {
                bgColor: '#00000000',

                titleShow: true,
                titleAlign: 'center',
                title: '<span>Default title</span>',
                titleColor: '#131F1E',
                titleFontFamily: 'Roboto-Medium',
                titleFontSize: 48,
                titleLineHeight: 10,
                titlePaddingTop: 10,
                titlePaddingLeft: 0,
                titlePaddingBottom: 10,
                titlePaddingRight: 0,

                textShow: true,
                textAlign: 'center',
                text: "<span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt amet, soluta suscipit excepturi laboriosam velit.</span>",
                textColor: '#727978',
                textFontFamily: 'Roboto-Regular',
                textFontSize: 18,
                textLineHeight: 10,
                textPaddingTop: 10,
                textPaddingLeft: 0,
                textPaddingBottom: 10,
                textPaddingRight: 0,

                buttonProps: {
                    slug: slug(),
                    text: 'Default Button',
                    bgColor: '#7cb740',
                    textColor: '#ffffff',
                    blanked: false,
                    href: '',
                    size: 'medium',
                    fontFamily: 'Roboto-Bold',
                    widthChangeable: true,
                    widthValue: 288,
                    fontWeight: 700,
                    textSize: 16,
                    border: 'none',
                    borderRadius: '12',
                    linked: true,
                    alignment: 'center',
                    marginBottom: 10,
                    marginTop: 20,
                    marginLeft: 0,
                    marginRight: 0,
                    lineHeight: '1.5'
                },
            }
        }
    )
}