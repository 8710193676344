const getYoutubeId = (url, bool, setIsValid) => {
   var regExp = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
   var match = url.match(regExp);
   if (match) {
      if(setIsValid) {
         setIsValid(true);
      }

      let videoId = match[1];
      let isHaveEmbed = /(embed\/?|embe|emb|em|e)/.test(videoId);

      if (!isHaveEmbed) {
         videoId = 'embed/' + videoId;
      }

      return `https://www.youtube.com/${!isHaveEmbed ? '' : ''}` + videoId;
   } else {
      if(setIsValid) {
         setIsValid(false);
      }

      return url;
   }
};


const getVimeoEmbed = (url, bool, setIsValid) => {
   var regExp = /vimeo.*(?:\/|clip_id=)([0-9a-z]*)/;
   var match = url.match(regExp);
   if (match) {
      return "https://player.vimeo.com/video/"+match[1];
   } else {
      if(setIsValid) {
         setIsValid(false);
      }

      return url;
   }
};

const getWistiaEmbed = (url, bool, setIsValid) => {
   const regExp = /https?:\/\/[^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*/;
   const match = url.match(regExp);
   if (match) {
      return `${ match[0] }`;
   }
   if(setIsValid) {
      setIsValid(false);
   }

   return url;
};

const getIframeEmbed = (code, bool, setIsValid) => {
   const regExp = /<iframe>*([0-9a-z]*)/;
   const match = code.match(regExp);
   if (match) {
      return `${ code }`;
   }
   if(setIsValid) {
      setIsValid(false);
   }

   return code;
};

const isValidVideoUrl = (url, autoplay) => {
   const youtubeRegex =/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
   const vimeoRegex = /vimeo.*(?:\/|clip_id=)([0-9a-z]*)/;
   const wistiaRegex = /https?:\/\/[^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\/.*/;
 
   if(url === "https://www.youtube.com/embed/"){
      return false;
   }
   if(youtubeRegex.test(url) || vimeoRegex.test(url) || wistiaRegex.test(url)) {
      if(!window.location.href.includes('admin')) {
         if(youtubeRegex.test(url)) {
            return `${url}?autoplay=${autoplay? 1: 0}`;
         }  else if(vimeoRegex.test(url)) {
            return `${url}?autoplay=${autoplay? true: false}`;
         }  else if(wistiaRegex.test(url)) {
            return `${url}?autoPlay=${autoplay? true: false}`;
         }
      }
      return url;
   }else{
      return false;
   } 
   //return 'https://www.youtube.com/embed/RVjxFLTCngw';
 }

 const isIframeEmbed = (code) => {
   const regExp = /<iframe>*([0-9a-z]*)/;
   const match = code.match(regExp);
   if (match) {
      return `${ code }`;
   }
   return false;
};

export {
   getYoutubeId, getVimeoEmbed, getIframeEmbed, getWistiaEmbed, isValidVideoUrl, isIframeEmbed
};
