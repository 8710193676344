import React, { Component } from "react";
import { toggleHighlighted } from "utils";
import PropTypes from 'prop-types';
import { ToastContainer, toast } from "react-toastify";
import { Icon } from 'semantic-ui-react'
import "./index.css";
import classnames from 'classnames';

class IconMi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            isTempLanding: false,
        };
    }

    componentDidMount() {
        const url = window.location.pathname.split('/');
        if((url && url[1] && url[1] ===  'temp-landing') || (this.props.course && this.props.course.is_published!==1 && this.props.pricing_button)) {
            this.setState({ isTempLanding: true });
        } else {
            this.setState({ isTempLanding: false });
        }
    }

    toggle = (e) => {
        if(this.state.active) {
            setTimeout(() => {
               this.setState({ active: toggleHighlighted(e, this.state.active) });         
            }, 300)
         } else {
            this.setState({ active: toggleHighlighted(e, this.state.active) });         
         }
    };

    openApi = href => {
        fetch(href,{
            headers:{ Authorization:  `Bearer ${ localStorage.getItem('authToken') }`},
        }).
            then(resp => resp.json())
            .then(data => {
                // window.open(data.redirect_url, '_blank')
                window.location.replace(data.redirect_url);
            })
            .catch(errors => {
                console.log(errors && errors.response);
            })
    }

    render() {
        let {href} = this.props;
        const {
            slug,
            bordered,
            border,
            size,
            bgColor,
            widthChangeable,
            width,
            text,
            textColor,
            blanked,
            customStyles,
            className,
            click,
            isClickable,
            textSize,
            borderRadius,
            fontFamily,
            fontStyle,
            alignment,
            allCaps,
            widthValue,
            marginTop,
            marginBottom,
            marginLeft,
            marginRight,
            boxShadow,
        } = this.props;
        let {isTempLanding} = this.state;
        let button_size;
        let button_size_padding;
        let button_width;
        let button_border;
        // CHECK BUTTON SIZE
        switch (size) {
            case "large":
                button_size = {
                    padding: "20px 40px",
                    fontSize: "24px",
                };
                button_size_padding = {
                    padding: "22px 40px",
                };
                break;
            case "medium-large":
                button_size = {
                    padding: "10px 18px",
                    fontSize: "20px",
                };
                button_size_padding = {
                    padding: "17px",
                };
                break;    
            case "medium":
                button_size = {
                    padding: "10px 18px",
                    fontSize: "20px",
                };
                button_size_padding = {
                    padding: "14px 18px",
                };
                break;
            case "medium-small": 
            button_size = {
                padding: "10px 18px",
                fontSize: "20px",
            };
            button_size_padding = {
                padding: "11.5px 18px",
            };
            break;
            case "small":
                button_size = {
                    padding: "8px 18px",
                    fontSize: "16px",
                };
                button_size_padding = {
                    padding: "8px 18px",
                };
                break;
            case "extsmall":
                button_size = {
                    padding: "6px 18px",
                    fontSize: "14px",
                };
                button_size_padding = {
                    padding: "6px 18px",
                };
                break;
            case "no_padding":
                button_size = {
                    padding: "0px",
                    fontSize: "14px",
                };
                button_size_padding = {
                    padding: "0px 0px",
                };
                break;    
            default:
                button_size = {
                    padding: "8px 18px",
                    fontSize: "14px",
                };
                button_size_padding = {
                    padding: "8px 18px",
                };
                break;
        }

        // CHECK IF BUTTON CAN CHANGE WIDTH
        if (widthChangeable) {
            switch (width) {
                case "auto":
                    button_width = {
                        width: "auto",
                    };
                    break;
                case "full":
                    button_width = {
                        width: "100%",
                    };
                    break;
                default:
                    button_width = {
                        width: "auto",
                    };
                    break;
            }
        }

        // CHECK IF BUTTON BORDERED AND SET BORDER TYPES
        if (bordered) {
            switch (border) {
                case "none":
                    button_border = {
                        color: textColor,
                        backgroundColor: bgColor,
                    };
                    break;
                case "solid":
                    button_border = {
                        border: "2px solid " + bgColor,
                        backgroundColor: "transparent",
                        color: textColor,
                    };
                    break;
                case "dotted":
                    button_border = {
                        border: "2px dotted " + bgColor,
                        backgroundColor: "transparent",
                        color: textColor,
                    };
                    break;
                default:
                    button_border = {
                        color: textColor,
                        backgroundColor: bgColor,
                    };
                    break;
            }
        }
        let buttonWidth;
        if(widthValue) {
            buttonWidth = {
                width: widthValue+'px',
            }
        }
        let buttonFontFamily;
        if(fontFamily) {
            buttonFontFamily = {
                fontFamily: fontFamily,
            }
        }
        let marginProperty;
        if(marginTop || marginBottom || marginLeft || marginRight) {
            marginProperty = {
                marginTop: marginTop? marginTop +'px': 0,
                marginBottom: marginBottom? marginBottom +'px': 0,
                marginLeft: marginLeft? marginLeft +'px': 0,
                marginRight:marginRight? marginRight +'px': 0,
            }
        }
        var styles = {
            display: "inline-block",
            textAlign: "center",
            cursor: "pointer",
            color: textColor,
            backgroundColor: bgColor,
            fontWeight:"bold",
            ...marginProperty,
            ...customStyles,
             ...button_size_padding,
            ...button_border,
            ...button_width,
            ...buttonWidth,
            ...buttonFontFamily,
            fontSize: textSize? textSize+'px': button_size.fontSize,
            borderRadius: borderRadius!=undefined? borderRadius+'px': '5px',
            fontWeight: fontStyle? fontStyle: 'normal',
            textTransform: allCaps? 'uppercase' : 'none',
            maxWidth: '100%',
            boxShadow: boxShadow? boxShadow: '',
        };
        return this.props.visibility ?  (
            <div
                className={this.state.active ? "mark" : null}
                data-slug={slug}
                style={alignment? {textAlign: alignment, width: '100%'} : {}}
                onMouseOver={this.toggle}
                onMouseOut={this.toggle}
            >
                <a
                    onClick={ isClickable || isTempLanding ? ()=>{} : (e) => {
                        if(this.props.onClick){
                            this.props.onClick(e)
                        }
                        if (!this.props.linked || this.props.scroll_to_section) {
                            if (this.props.buttonType !== 'pricing') {
                                if (this.props.buttonType === 'login') {
                                    e.preventDefault();
                                    const href = window.location.origin + '/login';
                                    window.location.replace(href);
                                } else {
                                    var selected_section = !this.props.linked ? document.querySelector(".section_pricing") : document.querySelector("#" + this.props.scroll_to);
                                    if(selected_section){
                                        selected_section.scrollIntoView({ behavior: "smooth"});
                                    } else {
                                        toast.error("There are no selected section for scroll button!");
                                    }
                                }
                            } else  {
                                if(href && href.indexOf('/api/') == -1 ) {
                                    if (href.indexOf('/register?') != -1) {
                                        // window.open(href, blanked ? "_blank" : "_self")
                                        window.location.replace(href);
                                    }
                                } else {
                                    if(href){
                                        this.openApi(href);
                                    } else toast.error("Please select a plan.");
                                }

                            }
                        }
                        if (!this.state.active && this.props.href && !this.props.scroll_to_section) {
                            if (!href.match(/^https?:\/\//i) && this.props.buttonType !== 'pricing') {
                                href = 'http://' + href;
                            }
                            if(href.indexOf('/api/') == -1) {
                                // window.open(href, blanked ? "_blank" : "_self")
                                if (!href.match(/^https?:\/\//i)) {
                                    href = 'http://' + href;
                                }
                                window.location.replace(href);
                            } else {
                                this.openApi(href);
                            }
                        }
                    }}
                    style={styles}
                    className={classnames({ [`${ className }`]: !!className })}
                >
                   <Icon name={text} />
                </a>
            </div>
    ) : (
    <div />
        );
    }
}



IconMi.defaultProps = {
    slug: "",
    text: "SIGNUP",
    bgColor: "#b5cc18",
    textColor: "#000000",
    linked: false,
    href: "",
    blanked: false,
    size: "medium",
    size_options: [
        { text: "No Padding", value: "no_padding" },
        { text: "Extra small", value: "extsmall" },
        { text: "Small", value: "small" },
        { text: "Medium", value: "medium" },
        { text: "Large", value: "large" },
    ],
    widthChangeable: false,
    width: "auto",
    width_options: [
        { text: "Auto", value: "auto" },
        { text: "Full width", value: "full" },
    ],
    bordered: true,
    border: "none",
    border_options: [
        { text: "Normal", value: "none" },
        { text: "Bordered", value: "solid" },
        { text: "Dotted", value: "dotted" },
    ],
    header: "Button Text",
    customStyles: {},
    visibility: true,
    props_name: "visibility",
};

IconMi.propTypes = {
    // EXPECTED STRING
    slug: PropTypes.string,
    text: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    size: PropTypes.string,
    href: PropTypes.string,
    width: PropTypes.string,
    border: PropTypes.string,
    header: PropTypes.string,
    // EXPECRED BOOLEAN
    linked: PropTypes.bool,
    blanked: PropTypes.bool,
    widthChangeable: PropTypes.bool,
    bordered: PropTypes.bool,
    // EXPECTED ARRAY
    size_options: PropTypes.array,
    width_options: PropTypes.array,
    border_options: PropTypes.array,
    // EXPECTED OBJECT
    customStyles: PropTypes.object,
    props_name: PropTypes.string,
    visibility: PropTypes.bool,
}


export default IconMi;
