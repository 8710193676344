import React, { useRef } from 'react';
import './index.css';
import PropTypes from 'prop-types';
//gif from './loader.svg';
import loader from './loader.svg';

const LoaderSpinner = ({
   width = 120, heigth = 120, LoaderSpinnerStyle,
}) => {
   const el = useRef();
   const isAdminPage = !!window.location.href.includes('admin');
   return (
      <div ref={ el } className='loading__SpinnerContainer' style={ LoaderSpinnerStyle }>
         <img src={ loader} width={width } height={ heigth } alt='loader' />
      </div>
   );
};

LoaderSpinner.propTypes = {
   width: PropTypes.number,
   heigth: PropTypes.number,
   LoaderSpinnerStyle: PropTypes.object,
};

export default LoaderSpinner;
