import React, { Component, Fragment } from "react";
import { Header, Form } from "semantic-ui-react";
import AppContext from "contexts/AppContext";
import PropTypes from 'prop-types';
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'

import "./index.css";

class InputRange extends Component {
   render() {
      return (
         <AppContext.Consumer>
            {({ state, functions }) => (
               <Fragment>
                  <Form>
                     <Form.Field>
                        <div className='text_column_top'>
                           <Text
                              inner={this.props.label}
                              type={types.regularDefault}
                              size={sizes.small}
                           />
                           <input 
                              className="text_column_width_new"
                              style={ { borderRadius: '8px !important' } }
                              type="text" 
                              value={(this.props.inputValue!==undefined && this.props.inputValue!=='none') ? this.props.inputValue: this.props.default}
                              onKeyDown={(e) => {
                                 // Allow only numeric keys (0-9), Backspace, and arrow keys
                                 if (
                                   !/^[0-9]$/.test(e.key) &&
                                   e.key !== 'Backspace' &&
                                   e.key !== 'ArrowLeft' &&
                                   e.key !== 'ArrowRight' &&
                                   e.key !== 'ArrowUp' &&
                                   e.key !== 'ArrowDown' &&
                                   e.key !== 'Delete'
                                 ) {
                                   e.preventDefault();
                                 }
                               }}
                               onChange={e =>{
                                 let value = Number(e.target.value)
                                 if(isNaN(value)) return;
                                 if(value >this.props.max || value < this.props.min) return;
                                 functions.handleUpdateProps(
                                    this.props.section,
                                    this.props.slug,
                                    value,
                                    this.props.props_name,
                                    null,
                                    this.props.propsName
                                 )
                              }}
                           />
                        </div>
                        <div className='text_column_content'>
                           <input
                              style={{width: '100%'}}
                              type='range'
                              min={this.props.min}
                              max={this.props.max}
                              value={(this.props.inputValue!==undefined && this.props.inputValue!=='none') ? this.props.inputValue: this.props.default}
                              onChange={e =>
                              {functions.handleUpdateProps(
                                 this.props.section,
                                 this.props.slug,
                                 e.target.value,
                                 this.props.props_name,
                                 null,
                                 this.props.propsName
                              )
                              }
                              }
                           />
                        </div>
                     </Form.Field>
                  </Form>
                  {!this.props.hideDivider && <Header dividing />}
               </Fragment>
            )}
         </AppContext.Consumer>
      );
   }
}


InputRange.propTypes = {

   // EXPECTED STRING
   label: PropTypes.string,
   props_name: PropTypes.string,
   slug: PropTypes.string,
   props_name: PropTypes.string,
   placeholder: PropTypes.string,
   default: PropTypes.string,
   // EXPECTED BOOLEAN
   checked: PropTypes.bool,
   value: PropTypes.bool,

   // EXPECTED NUMBER OR STRING IF EMPTY
   section: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
   ]),
}

InputRange.defaultProps = {
   default: "",
};

export default InputRange;
