import React from 'react';

export const editLIcon = () => {
   return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M17.3341 3.48441C17.7538 3.27643 18.2465 3.27644 18.6662 3.48442C18.8682 3.58454 19.0728 3.77978 19.6465 4.35356C20.2203 4.92733 20.4155 5.13191 20.5156 5.33394C20.7236 5.75361 20.7236 6.24634 20.5156 6.66601C20.4155 6.86804 20.2203 7.07261 19.6465 7.64637L18.7986 8.49429C17.026 8.39372 15.6063 6.97407 15.5057 5.20149L16.3537 4.35353C16.9275 3.77976 17.132 3.58452 17.3341 3.48441ZM14.6298 6.07739L6.72379 13.9833C6.20231 14.5048 5.98528 14.7244 5.81404 14.9699C5.65689 15.1952 5.52681 15.4383 5.4265 15.694C5.31719 15.9727 5.2549 16.275 5.11027 16.9982L4.63739 19.3626L7.00182 18.8897C7.72496 18.7451 8.02733 18.6828 8.30599 18.5735C8.56174 18.4732 8.80479 18.3431 9.03012 18.186C9.27564 18.0147 9.49519 17.7977 10.0167 17.2762L17.9227 9.37021C16.3029 8.97224 15.0278 7.69721 14.6298 6.07739ZM19.1102 2.58842C18.4108 2.24178 17.5895 2.24177 16.8901 2.58839C16.5211 2.77124 16.1936 3.09895 15.7144 3.57853L15.7144 3.57854L15.6466 3.64642L6.01668 13.2762L5.98129 13.3116C5.50557 13.7873 5.22168 14.0711 4.99382 14.3978C4.79177 14.6875 4.62453 15 4.49555 15.3289C4.3501 15.6997 4.27139 16.0933 4.1395 16.753L4.12969 16.8021L3.50972 19.9019C3.47693 20.0659 3.52824 20.2353 3.64645 20.3535C3.76467 20.4718 3.93414 20.5231 4.09807 20.4903L7.19793 19.8703L7.24702 19.8605C7.90667 19.7286 8.30033 19.6499 8.67114 19.5044C8.99996 19.3755 9.31245 19.2082 9.60216 19.0062C9.92887 18.7783 10.2127 18.4944 10.6884 18.0187L10.7238 17.9833L20.3536 8.35348L20.4215 8.28565C20.9011 7.80645 21.2288 7.47902 21.4116 7.11005C21.7583 6.41059 21.7583 5.58938 21.4116 4.88992C21.2288 4.52094 20.9011 4.1935 20.4215 3.7143L20.3537 3.64647L20.2858 3.57858C19.8066 3.09899 19.4792 2.77127 19.1102 2.58842Z' fill='#F8FAFA' />
      </svg>
   );
};

export const arrowLeftLIcon = () => {
   return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M10.5303 4.46967C10.8232 4.76257 10.8232 5.23744 10.5303 5.53033L4.81067 11.2499H21C21.4142 11.2499 21.75 11.5857 21.75 11.9999C21.75 12.4141 21.4142 12.7499 21 12.7499H4.81066L10.5303 18.4696C10.8232 18.7625 10.8232 19.2374 10.5303 19.5303C10.2374 19.8232 9.76256 19.8232 9.46967 19.5303L2.46967 12.5303C2.32902 12.3896 2.25 12.1988 2.25 11.9999C2.25 11.801 2.32902 11.6102 2.46967 11.4696L9.46967 4.46967C9.76257 4.17678 10.2374 4.17678 10.5303 4.46967Z' fill='#131F1E' />
      </svg>
   );
};

export const plusIcon = () => {
   return (
      <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M10.6693 3.66675C10.9454 3.66675 11.1693 3.89061 11.1693 4.16675V9.50008H16.5026C16.7787 9.50008 17.0026 9.72394 17.0026 10.0001C17.0026 10.2762 16.7787 10.5001 16.5026 10.5001H11.1693V15.8334C11.1693 16.1096 10.9454 16.3334 10.6693 16.3334C10.3931 16.3334 10.1693 16.1096 10.1693 15.8334V10.5001H4.83594C4.5598 10.5001 4.33594 10.2762 4.33594 10.0001C4.33594 9.72394 4.5598 9.50008 4.83594 9.50008H10.1693V4.16675C10.1693 3.89061 10.3931 3.66675 10.6693 3.66675Z' fill='#24554E' />
      </svg>
   );
};

export const BoldIcon = () => {
   return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M5.44444 2.5C4.37056 2.5 3.5 3.37056 3.5 4.44445L3.5 12L3.5 19.2222C3.5 20.4802 4.5198 21.5 5.77778 21.5L15.5 21.5C18.2614 21.5 20.5 19.2614 20.5 16.5C20.5 13.7387 18.2617 11.5003 15.5005 11.5C16.7147 10.5878 17.5 9.1356 17.5 7.5C17.5 4.73858 15.2614 2.5 12.5 2.5L5.44444 2.5ZM12.5021 12.5C12.5014 12.5 12.5007 12.5 12.5 12.5L4.5 12.5L4.5 19.2222C4.5 19.9279 5.07208 20.5 5.77778 20.5L15.5 20.5C17.7091 20.5 19.5 18.7091 19.5 16.5C19.5 14.2909 17.7091 12.5 15.5 12.5L12.5021 12.5ZM12.5019 11.5C14.7102 11.499 16.5 9.7085 16.5 7.5C16.5 5.29086 14.7091 3.5 12.5 3.5L5.44444 3.5C4.92284 3.5 4.5 3.92284 4.5 4.44445L4.5 11.5L12.5 11.5C12.5006 11.5 12.5013 11.5 12.5019 11.5Z' fill='#131F1E' />
      </svg>
   );
};
