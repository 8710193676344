import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_2",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_2/background.png"),
                    bgColor: "rgb(255,255,255)",
                    background_type: "image",
                    spacing: "sm",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "30",
                        imgUrl: require("../images/landing_2/logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "PRICING",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: true,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ABOUT THE AUTHOR",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: true,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "FEATURES",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "white",
                        size: "small",
                        linked: true,
                        blanked: true,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL NOW",
                        bgColor: "#e16e19",
                        textColor: "white",
                        size: "small",
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Hero",
                props: {
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_2/background.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    spacing: "sm",
                    alignmentAvalaible: true,
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-size:70px'>BECOME THE </br> BEST MARKETER </span></h1> ",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='font-size:22px;color:#b6cfe4; line-height: 1.5; font-weight: 500;'>Online Training To Help You Get More Cusomters!</span>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text:
                            "<span style='font-size:20px; line-height: 1.5; font-weight: 500;'>laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non.</span>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#e16e1b",
                        textColor: "#ffffff",
                        size: "large",
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Text with image",
                props: {
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "md",
                    reverseAvailaible: true,
                    reversed: false,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/comp.png"),
                        width: "350",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='font-weight:bold;font-size:30px'>Here's an intro title</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='color: #333333; line-height: 1.5; font-weight: 500;'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span></p>",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Features",
                props: {
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='font-weight:bold;font-size:30px'>What's included</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/Layer_1.png"),
                        width: "150",
                        radius: "0",
                        visibility: true,
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='color: #234f70;font-size:20px;font-weight: bold'>Title 1 here</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/Layer_2.png"),
                        width: "150",
                        radius: "0",
                        visibility: true,
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='color: #234f70;font-size:20px;font-weight: bold'>Title 2 here</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/Layer_1.png"),
                        width: "150",
                        radius: "0",
                        visibility: true,
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='color: #234f70;font-size:20px;font-weight: bold'>Title 2 here</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</span></p>',
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Details",
                props: {
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color: #000000;'>Course details</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/video-icon.png"),
                        width: "24",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h4><span style='color: #000000;'>How to get more twitter followers this week</span></h4>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #808080;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/video-icon.png"),
                        width: "24",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h4><span style='color: #000000;'>How to get more twitter followers this week</span></h4>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #808080;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/pdf-icon.png"),
                        width: "24",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h4><span style='color: #000000;'>How to get more twitter followers this week</span></h4>",
                        color: "rgb(250,250,250)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #808080;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/pdf-icon.png"),
                        width: "24",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h4><span style='color: #000000;'>How to get more twitter followers this week</span></h4>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #808080;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/pdf-icon.png"),
                        width: "24",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h4><span style='color: #000000;'>How to get more twitter followers this week</span></h4>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #808080;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/video-icon.png"),
                        width: "24",
                        radius: "0",
                        visibility: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='color: #000000;font-weight:bold;font-size:16px'>How to get more twitter followers this week</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="color: #808080;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>',
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Testimonials",
                props: {
                    order:5,
                    duplicated:5,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_2/Rectangle3.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/Ellipse 1.jpg"),
                        width: "200",
                        radius: "0",
                        visibility: true,
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='font-weight:bold;font-size:20px'>Course Author</span>",
                        color:"#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </span></p><p><span>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></p>',
                        color:'#ffffff',
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:6,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_2/background.png"),
                    bgColor: "#003250",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:20px'>SELECT YOUR OPTION</span></p>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#666666",
                        textColor_selected: "#08508e",
                        bgColor: "#f1f3f3",
                        bgColor_selected: "#e2f5fd",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL NOW",
                        bgColor: "#e16e19",
                        textColor: "white",
                        size: "large",
                        href: "",
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Footer",
                props: {
                    order:7,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "white",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "© Miestro. All Rights Reserved.",
                        bgColor: "transparent",
                        textColor: "#aeaeae",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Terms of Use",
                        bgColor: "transparent",
                        textColor: "#aeaeae",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Privacy Policy",
                        bgColor: "transparent",
                        textColor: "#aeaeae",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Powered by Miestro",
                        bgColor: "transparent",
                        textColor: "#aeaeae",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
    ],
};

export default state;
