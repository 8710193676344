import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Text, { TYPES as txtTypes, SIZES as txtSizes } from 'components/TextNew';
import './index.css';

export const Options = ({ OptionsMap = [], onSelect }) => {
   if (OptionsMap.length) {
      return (
         <div className='SelectItem__options'>
            {OptionsMap.map((e) => {
               const slicedText = e.label.slice(-11);

               return (
                  <div className='SelectItem__item' onClick={ () => onSelect(e.value) } key={ uuidv4() } role='presentation'>
                     <Text
                        type={ txtTypes.regular }
                        size={ txtSizes.xsmall }
                        inner={ isNaN(Number(slicedText)) ? e.label : e.label.slice(0, -10) }
                     />
                  </div>
               );
            })}
            {OptionsMap.length === 0 && (
               <div className='SelectItem__item' style={ { textAlign: 'center' } }>
                  <Text
                     type={ txtTypes.regularDefault }
                     size={ txtSizes.small }
                     style={ { color: 'rgb(114, 121, 120)' } }
                     inner='No more results'
                  />
               </div>
            ) }
         </div>
      );
   }
   return null;
};

Options.propTypes = {
   OptionsMap: PropTypes.array,
   onSelect: PropTypes.func,
};
