import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const ModalNew = ({ onCloseModal, children }) => {
   return (
      <div className='modal__new'>
         <div className='modal__new__background' role='presentation' onClick={ () => onCloseModal() } />
         {/* <ClickOutside onClick={ onCloseModal }> */}
         <div className='modal__new__content'>
            {children}
         </div>
         {/* </ClickOutside> */}
      </div>
   );
};

ModalNew.propTypes = {
   onCloseModal: PropTypes.func,
   children: PropTypes.any,
};

export default ModalNew;
