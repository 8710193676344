import React, { useContext, useState } from 'react'
import './index.css';
import Text, { TYPES as txtTypes, SIZES as txtSizes } from 'components/TextNew';
import AppContext from 'contexts/AppContext';
import FontOptions from './FontOptions';
import Icon from '../elements/Icon';

const FontSelector = ({ fonts, heading, currentValue, slug, props_name, propsName }) => {
    const { functions } = useContext(AppContext);

    const [isOpen, setIsOpen] = useState(false);

    const handleContentChange = (newValue) => {
        const section = functions.handleEditableSectionOrderValue();

        functions.handleUpdateProps(
            section,
            slug,
            newValue,
            props_name,
            null,
            propsName
        )
    };

    const { text: currentLabel = 'Select Font' } = fonts.find(font => font.value === currentValue) || {};

    return (
        <div className='fontSelector' onClick={() => setIsOpen(prev => !prev)}>
            <div className='fontSelector__label'>
                <Text
                    inner={ heading }
                    type={ txtTypes.regularDefault }
                    size={ txtSizes.small }
                />
            </div>
            <div className={ `fontSelector__button${ isOpen ? ' active' : '' }` }>
                <Text
                    inner={ currentLabel }
                    type={ txtTypes.select_large }
                    size={ txtSizes.small }
                />
                <Icon className='fontSelector__button__icon' name='DownNew' color='#727978' />
            </div>
            {isOpen && (
                <FontOptions options={ fonts } onSelect={ handleContentChange } />
            )}
        </div>
    )
}

export default FontSelector