import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_12",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#333333",
        regular_text_color: "#333333",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#131417",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "30",
                        imgUrl: require("../images/landing_12/logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Hero",
                //section_props
                props: {
                    // props:value
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_12/section_1_background.png"),
                    bgColor: "#8C70B1",
                    background_type: "image",
                    alignmentAvalaible: true,
                    spacing: "md",
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='font-size:60px;line-height:initial'>MARKETING ESSENTIALS</span>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text: "<p><span style='font-size:20px'>LEARN INTERNET MARKETING</span></p>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text: "<p><span style='font-size:20px'>$90</span></p>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#be3f3f",
                        textColor: "#ffffff",
                        href: "",
                        size: "large",
                        widthChangeable: true,
                        width: "full",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Text with image",
                //section_props
                props: {
                    //props:value
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#131417",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_12/thumb.png"),
                        width: "50",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3><span>Satisfaction guaranteed</span></h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<p><span style='color:#666666; line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_12/video.png"),
                        width: "50",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3><span>20 lessons</span></h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<p><span style='color:#666666;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</span></p>",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Title",
                //section_props
                props: {
                    //props:value
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#ffffff",
                    background_type: "color",
                    spacing: "md",
                    alignmentAvalaible: true,
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='color:#4d5768;font-size:40px'>Title about your course</span></h1>",
                        color: "rgb(250,250,250)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<p><span style='color:#4d5768;font-size:18px;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo Excepteur sint</span></p>",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Details",
                //section_props
                props: {
                    //props:value
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#060606",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p style='color:#4d5768;font-family:Opensans-Regular;font-size:46px'>01</p>",
                        color: "rgb(250,250,250)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h3><span>Enter title here</span></h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='color:#4d5768;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</span></p>",
                    },
                },

                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p style='color:#4d5768;font-family:Opensans-Regular;font-size:46px'>02</p>",
                        color: "rgb(250,250,250)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h3><span>Enter title here</span></h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='color:#4d5768;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p style='color:#4d5768;font-family:Opensans-Regular;font-size:46px'>03</p>",
                        color: "rgb(250,250,250)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h3><span>Enter title here</span></h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='color:#4d5768;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p style='color:#4d5768;font-family:Opensans-Regular;font-size:46px'>04</p>",
                        color: "rgb(250,250,250)",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h3><span>Enter title here</span></h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='color:#4d5768;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo</span></p>",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Testimonials",
                //section_props
                props: {
                    //props:value
                    order:5,
                    duplicated:5,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_12/section_5_background.png"),
                    bgColor: "#ffffff",
                    background_type: "image",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='color:#4d5768;font-size:20px;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_12/auther.jpeg"),
                        width: "130",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<p><span style='color:#4d5768'>J.Smith</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<p><span style='color:#666666;font-size:8px'>Chief Executive Officer</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "www.yourpage.com",
                        bgColor: "transparent",
                        textColor: "blue",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Features",
                //section_props
                props: {
                    //props:value
                    order:6,
                    duplicated:6,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#090909",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_12/guarantee.png"),
                        width: "80",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3>Guarantee</h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_12/responsive.png"),
                        width: "80",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3>Responsive</h3>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_12/expires.png"),
                        width: "80",
                        radius: "",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3>Never expires</h3>",
                        color: "#ffffff",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:7,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#ffffff",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p style='font-size:26px;font-weight:800'><span>Enroll now</span></p>",
                        color: "#30242c",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#ffffff",
                        textColor_selected: "#be3f3f",
                        bgColor: "#000000",
                        bgColor_selected: "#ffcbcb",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "JOIN THE PROGRAMM",
                        bgColor: "#000000",
                        textColor: "#000000",
                        size: "large",
                        href: "",
                        border: "solid",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Footer",
                //section_props
                props: {
                    //props:value
                    order:8,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#d8d8d8",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "© Miestro. All Rights Reserved",
                        bgColor: "transparent",
                        textColor: "#797979",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Terms of Use",
                        bgColor: "transparent",
                        textColor: "#797979",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Privacy Policy",
                        bgColor: "transparent",
                        textColor: "#797979",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Powered By Miestro",
                        bgColor: "transparent",
                        textColor: "#797979",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
    ],
};

export default state;
