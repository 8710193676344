import React from 'react';
import PropTypes from 'prop-types';

export const waitIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16'>
         <defs>
            <filter id='t95z633rqa' width='120.8%' height='177.5%' x='-10.4%' y='-38.8%' filterUnits='objectBoundingBox'>
               <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
               <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='7' />
               <feColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0.247058824 0 0 0 0 0.309803922 0 0 0 0 0.396078431 0 0 0 0.14 0' />
               <feMerge>
                  <feMergeNode in='shadowMatrixOuter1' />
                  <feMergeNode in='SourceGraphic' />
               </feMerge>
            </filter>
         </defs>
         <g fill='none' fillRule='evenodd' filter='url(#t95z633rqa)' transform='translate(-14 -19)'>
            <g>
               <path d='M0 0L16 0 16 16 0 16z' transform='translate(14.923 19)' />
               <path className='waitIcon' fill={ color } fillRule='nonzero' d='M7.993 1.333c3.687 0 6.674 2.987 6.674 6.667 0 3.68-2.987 6.667-6.674 6.667-3.68 0-6.66-2.987-6.66-6.667 0-3.68 2.98-6.667 6.66-6.667zM8 2.667C5.053 2.667 2.667 5.053 2.667 8S5.053 13.333 8 13.333 13.333 10.947 13.333 8 10.947 2.667 8 2.667zm.333 2v3.5l3 1.78-.5.82-3.5-2.1v-4h1z' transform='translate(14.923 19)' />
            </g>
         </g>
      </svg>
   );
};

export const AddAutomationIcon = ({ style }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd'>
            <path d='M0 0L24 0 24 24 0 24z' transform='translate(.5)' />
            <path fill='#C2CEDB' fillRule='nonzero' d='M17.161 11.355v1.29c0 .266-.217.484-.484.484H13.13v3.548c0 .267-.218.484-.484.484h-1.29c-.266 0-.484-.217-.484-.484V13.13H7.323c-.267 0-.484-.218-.484-.484v-1.29c0-.266.217-.484.484-.484h3.548V7.323c0-.267.218-.484.484-.484h1.29c.266 0 .484.217.484.484v3.548h3.548c.267 0 .484.218.484.484zM22 12c0 5.524-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2s10 4.476 10 10zm-1.935 0c0-4.456-3.61-8.065-8.065-8.065-4.456 0-8.065 3.61-8.065 8.065 0 4.456 3.61 8.065 8.065 8.065 4.456 0 8.065-3.61 8.065-8.065z' transform='translate(.5)' />
         </g>
      </svg>
   );
};

export const emailIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16'>
         <defs>
            <filter id='x1ipgps8ra' width='120.8%' height='177.5%' x='-10.4%' y='-38.8%' filterUnits='objectBoundingBox'>
               <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
               <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='7' />
               <feColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0.247058824 0 0 0 0 0.309803922 0 0 0 0 0.396078431 0 0 0 0.14 0' />
               <feMerge>
                  <feMergeNode in='shadowMatrixOuter1' />
                  <feMergeNode in='SourceGraphic' />
               </feMerge>
            </filter>
         </defs>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g filter='url(#x1ipgps8ra)' transform='translate(-585 -415) translate(571 276) translate(0 120)'>
                     <g>
                        <path d='M0 0L16 0 16 16 0 16z' transform='translate(14.923 19)' />
                        <path className='emailIcon' fill={ color } d='M14.667 13.338c-.003.364-.297.66-.662.662H1.995c-.366 0-.662-.297-.662-.662v-.671h12v-7.8L8 9.667l-6.667-6v-1C1.333 2.298 1.632 2 2 2h12c.368 0 .667.298.667.667v10.671zM2.956 3.333L8 7.873l5.044-4.54H2.956zM0 10h5.333v1.333H0V10zm0-3.333h3.333V8H0V6.667z' transform='translate(14.923 19)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const tagIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <path d='M0 0L16 0 16 16 0 16z' transform='translate(-1120 -475) translate(1104 456) translate(16 19)' />
                     <path className='addTagIcon' fill={ color } d='M7.267 1.4l6.599.943.943 6.6-6.128 6.128c-.26.26-.683.26-.943 0l-6.6-6.6c-.26-.26-.26-.682 0-.942L7.267 1.4zm.471 1.415L2.552 8l5.657 5.657 5.186-5.186-.707-4.95-4.95-.706zm1.413 4.242c-.337-.337-.468-.828-.345-1.288.124-.46.483-.82.944-.943.46-.123.951.008 1.288.345.337.337.468.829.345 1.289-.123.46-.483.82-.943.943-.46.123-.952-.009-1.289-.346z' transform='translate(-1120 -475) translate(1104 456) translate(16 19)' />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const RemoveTagIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <g>
                        <path d='M0 0L16 0 16 16 0 16z' transform='translate(-1120 -591) translate(1080 276) translate(24 296) translate(16 19)' />
                        <path className='removeTagIcon' fill={ color } d='M2.943 1.333L14.727 13.12l-.942.942-2.047-2.047-.12.12-.78.78-.494.494-.37.37-.611.611-.682.682c-.26.26-.683.26-.943 0l-6.6-6.6c-.26-.26-.26-.682 0-.942l.233-.233.442-.442.97-.97.334-.334.155-.156.288-.287.257-.257.226-.226.163-.163c-.474-.464-.709-.694-.709-.686L2 2.277l.454-.46.489-.484zm2.204 4.073L2.552 8l5.657 5.657 2.587-2.586C9.354 9.622 8.126 8.389 7.11 7.372l-.577-.578-.182-.182-.51-.51-.159-.16-.536-.536zM7.267 1.4l6.599.943.943 6.6-.791.791-.33.33-.285.285-.242.242-.94-.937 1.174-1.183-.707-4.95-4.95-.706-1.19 1.19-.945-.94.32-.321.269-.27.672-.671.403-.403zM9.15 7.057c-.202-.202-.33-.46-.374-.733l-.012-.11-.002-.028c-.008-.138.006-.279.043-.417.124-.46.483-.82.944-.943.46-.123.951.008 1.288.345.337.337.468.829.345 1.289-.123.46-.483.82-.943.943-.138.037-.28.05-.418.043L10 7.443l-.116-.012c-.273-.043-.53-.171-.733-.374z' transform='translate(-1120 -591) translate(1080 276) translate(24 296) translate(16 19)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const purchaseIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <path d='M0 0L16 0 16 16 0 16z' transform='translate(-1120 -591) translate(1104 572) translate(16 19)' />
                     <path className='purchasedIcon' fill={ color } d='M7.333 1.333l4.866 1.52c.278.087.468.345.468.637v1.177H14c.368 0 .667.298.667.666v5.334c0 .368-.299.666-.667.666l-2.147.001c-.258.34-.571.64-.933.887l-3.587 2.446-3.586-2.446C2.654 11.476 2 10.24 2 8.916V3.49c0-.292.19-.55.468-.636l4.865-1.52zm0 1.396l-4 1.251v4.936c0 .827.384 1.608 1.039 2.113l.126.09 2.835 1.934 2.522-1.72H6.667c-.369 0-.667-.298-.667-.666V5.333c0-.368.298-.666.667-.666h4.666V3.98l-4-1.25zm0 5.271v2h6V8h-6zm0-1.333h6V6h-6v.667z' transform='translate(-1120 -591) translate(1104 572) translate(16 19)' />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const enrollIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <path d='M0 0L16 0 16 16 0 16z' transform='translate(-1120 -359) translate(1104 340) translate(16 19)' />
                     <path className='enrolledIcon' fill={ color } d='M4.303 12.667L1.333 15V2.667C1.333 2.298 1.632 2 2 2h12c.368 0 .667.298.667.667V12c0 .368-.299.667-.667.667H4.303zm-.461-1.334h9.491v-8H2.667v8.924l1.175-.924zm3.687-3.252l2.828-2.828.943.942-3.771 3.772-2.594-2.594.944-.942 1.65 1.65z' transform='translate(-1120 -359) translate(1104 340) translate(16 19)' />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const conditionIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <g>
                        <path d='M0 0L16 0 16 16 0 16z' transform='translate(-1120 -1051) translate(1080 276) translate(24 756) translate(16 19)' />
                        <path className='conditionalLogicIcon' fill={ color } d='M8.667 5.333v5.334c0 1.104-.896 2-2 2H5.22c-.325.918-1.264 1.47-2.225 1.305-.96-.165-1.662-.998-1.662-1.972s.702-1.807 1.662-1.972c.96-.165 1.9.387 2.225 1.305h1.447c.368 0 .666-.298.666-.666V5.333c0-1.104.896-2 2-2h2v-2L14.667 4l-3.334 2.667v-2h-2c-.368 0-.666.298-.666.666zm-5.334 7.334c.369 0 .667-.299.667-.667 0-.368-.298-.667-.667-.667-.368 0-.666.299-.666.667 0 .368.298.667.666.667z' transform='translate(-1120 -1051) translate(1080 276) translate(24 756) translate(16 19)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const ConditionalSplitIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16'>
         <defs>
            <filter id='1lips6z2ea' width='120.8%' height='177.5%' x='-10.4%' y='-38.8%' filterUnits='objectBoundingBox'>
               <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
               <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='7' />
               <feColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0.247058824 0 0 0 0 0.309803922 0 0 0 0 0.396078431 0 0 0 0.14 0' />
               <feMerge>
                  <feMergeNode in='shadowMatrixOuter1' />
                  <feMergeNode in='SourceGraphic' />
               </feMerge>
            </filter>
         </defs>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g filter='url(#1lips6z2ea)' transform='translate(-585 -676) translate(571 657)'>
                  <g>
                     <path d='M0 0L16 0 16 16 0 16z' transform='translate(14.923 19)' />
                     <path className='conditionalSplitIcon' fill={ color } d='M4.737 5.86c.237.87 1.028 1.473 1.93 1.473h2.666c1.622 0 3.008 1.167 3.285 2.764.935.305 1.51 1.244 1.356 2.215C13.82 13.283 12.984 14 12 14c-.96 0-1.786-.682-1.966-1.625-.18-.944.336-1.882 1.23-2.235-.238-.87-1.029-1.473-1.93-1.473H6.666c-.722 0-1.424-.233-2-.667v2.113c.918.325 1.47 1.264 1.305 2.225C5.807 13.298 4.974 14 4 14s-1.807-.702-1.972-1.662c-.165-.96.387-1.9 1.305-2.225V5.887C2.421 5.565 1.87 4.636 2.024 3.68c.155-.955.97-1.663 1.937-1.681.968-.019 1.81.658 2 1.606.19.949-.325 1.898-1.224 2.254zM4 4.667c.368 0 .667-.299.667-.667 0-.368-.299-.667-.667-.667-.368 0-.667.299-.667.667 0 .368.299.667.667.667zm0 8c.368 0 .667-.299.667-.667 0-.368-.299-.667-.667-.667-.368 0-.667.299-.667.667 0 .368.299.667.667.667zm8 0c.368 0 .667-.299.667-.667 0-.368-.299-.667-.667-.667-.368 0-.667.299-.667.667 0 .368.299.667.667.667z' transform='translate(14.923 19)' />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const webhookIcon = ({ style, color }) => {
   return (
      <svg style={ style } xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g>
                     <g>
                        <path d='M0 0H16V16H0z' transform='translate(-1120 -707) translate(1080 276) translate(24 412) translate(16 19)' />
                        <path className='sendWebhookIcon' fill={ color } d='M4.667 7.773v1.333c-1.103 0-2 .898-2 2 0 1.103.897 2 2 2 1.102 0 2-.897 2-2v-.666h3.518c.231-.397.656-.667 1.148-.667.737 0 1.334.597 1.334 1.333 0 .737-.597 1.334-1.334 1.334-.492 0-.918-.27-1.148-.667H7.933c-.31 1.52-1.656 2.667-3.266 2.667-1.838 0-3.334-1.496-3.334-3.334S2.83 7.773 4.667 7.773zm2.667-3.594c.638-.369 1.453-.15 1.821.488.246.427.225.93-.003 1.328l1.126 1.95c1.47-.492 3.138.101 3.943 1.495.919 1.592.371 3.634-1.22 4.554-1.592.919-3.635.371-4.554-1.22l1.155-.667c.551.955 1.777 1.283 2.732.732.955-.551 1.283-1.777.732-2.732-.551-.955-1.777-1.283-2.732-.732l-.577.333-1.76-3.046C7.54 6.66 7.092 6.426 6.846 6c-.368-.638-.15-1.453.488-1.821zm-2.22-.513c.919-1.592 2.962-2.139 4.553-1.22 1.592.918 2.139 2.962 1.22 4.553l-1.154-.667c.551-.955.223-2.18-.732-2.732-.956-.551-2.181-.223-2.732.732-.552.956-.223 2.181.732 2.733l.577.333-1.76 3.047c.23.399.25.902.004 1.328-.368.637-1.183.856-1.821.488-.638-.369-.856-1.184-.488-1.821.246-.427.692-.66 1.152-.662l1.126-1.95C4.629 6.8 4.309 5.06 5.114 3.666z' transform='translate(-1120 -707) translate(1080 276) translate(24 412) translate(16 19)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const createAutomationIcon = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <path d='M0 0L64 0 64 64 0 64z' />
               <path fill='#7CB740' d='M37.333 13.333h21.334v5.334H37.333v-5.334zm0 14.667h21.334v5.333H37.333V28zm0 14.667h21.334V48H37.333v-5.333zm-32-12C5.333 40.213 13.12 48 22.667 48H24v5.333l8-8-8-8v5.334h-1.333c-6.614 0-12-5.387-12-12 0-6.614 5.386-12 12-12H32v-5.334h-9.333c-9.547 0-17.334 7.787-17.334 17.334z' />
            </g>
         </g>
      </svg>
   );
};

// Prop Types

waitIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
AddAutomationIcon.propTypes = {
   style: PropTypes.object,
};
emailIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
tagIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
RemoveTagIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
purchaseIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
enrollIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
conditionIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
ConditionalSplitIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};
webhookIcon.propTypes = {
   color: PropTypes.string,
   style: PropTypes.object,
};

// Default props

waitIcon.defaultProps = {
   color: '#3F4F65',
};
emailIcon.defaultProps = {
   color: '#3F4F65',
};
tagIcon.defaultProps = {
   color: '#3F4F65',
};
RemoveTagIcon.defaultProps = {
   color: '#3F4F65',
};
purchaseIcon.defaultProps = {
   color: '#3F4F65',
};
enrollIcon.defaultProps = {
   color: '#3F4F65',
};
conditionIcon.defaultProps = {
   color: '#3F4F65',
};
ConditionalSplitIcon.defaultProps = {
   color: '#3F4F65',
};
webhookIcon.defaultProps = {
   color: '#3F4F65',
};
