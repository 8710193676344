import React from 'react'
import { isNewApp } from 'utils'
import getUrl from '../../utils/getUrl';
import NewEditor from './editor.new';
import NewDragEditor from './editor.new_drag';
import OldEditor from './editor.old';
class Editor  extends React.Component {
   constructor(props) {
      super(props)
      let url = getUrl();
      this.isNewApp = isNewApp(url);
      if(this.isNewApp) {
         if(!this.props.dragAndDropLanding) {
            require('./index_new.css')
         } else {
            require('./index_drag.css')
         }
      } else {
         require('./index.old.css')
      }
   }
   render() {
      return (
         <React.Fragment>
            { this.isNewApp ?  (this.props.dragAndDropLanding?  <NewDragEditor { ...this.props } /> : <NewEditor { ...this.props } /> ) : <OldEditor { ...this.props } /> }
         </React.Fragment>
      )
   }
}

export default Editor
