import React from 'react';
import PropTypes from 'prop-types';

export const RocketIcon = ({ className }) => {
   return (
      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g transform='translate(-384 -284) translate(280 252) translate(104 32)'>
                     <rect width='56' height='56' fill='#7CB740' fillOpacity='.1' rx='3.5' />
                     <g>
                        <path d='M0 0L28 0 28 28 0 28z' transform='translate(14 14)' />
                        <path fill='#7CB740' d='M5.833 15.167C5.833 9.23 9.22 4.158 14 2.12c4.78 2.037 8.167 7.11 8.167 13.046 0 .96-.089 1.897-.257 2.803l2.263 2.138c.193.182.238.47.111.703l-2.91 5.337c-.089.162-.248.273-.43.299-.182.026-.366-.035-.496-.165l-2.606-2.607c-.219-.219-.516-.342-.825-.342h-6.034c-.31 0-.606.123-.825.342l-2.606 2.607c-.13.13-.314.191-.496.165-.182-.026-.341-.137-.43-.299l-2.91-5.337c-.127-.232-.082-.521.11-.703L6.09 17.97c-.167-.906-.257-1.843-.257-2.803zm1.722 7.812l.954-.953C9.165 21.369 10.054 21 10.982 21h6.034c.928 0 1.818.37 2.474 1.026l.954.953 1.145-2.1-1.283-1.214c-.576-.544-.837-1.344-.692-2.123.145-.775.218-1.57.218-2.375 0-4.515-2.327-8.517-5.833-10.454-3.506 1.937-5.833 5.939-5.833 10.454 0 .806.073 1.6.218 2.376.145.78-.116 1.58-.692 2.123L6.41 20.88l1.145 2.1zM14 15.167c-1.289 0-2.333-1.045-2.333-2.334 0-1.288 1.044-2.333 2.333-2.333 1.289 0 2.333 1.045 2.333 2.333 0 1.289-1.044 2.334-2.333 2.334z' transform='translate(14 14)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const FolderIcon = ({ className }) => {
   return (

      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g transform='translate(-672 -284) translate(568 252) translate(104 32)'>
                     <rect width='56' height='56' fill='#7CB740' fillOpacity='.1' rx='3.5' />
                     <g>
                        <path d='M0 0L28 0 28 28 0 28z' transform='translate(14 14)' />
                        <path fill='#7CB740' d='M14.483 5.833H24.5c.644 0 1.167.523 1.167 1.167v16.333c0 .645-.523 1.167-1.167 1.167h-21c-.644 0-1.167-.522-1.167-1.167V4.667c0-.645.523-1.167 1.167-1.167h8.65l2.333 2.333zm8.85 7H4.667v9.334h18.666v-9.334zm0-2.333V8.167h-9.816l-2.333-2.334H4.667V10.5h18.666z' transform='translate(14 14)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>

   );
};

export const StudentIcon = ({ className }) => {
   return (

      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g transform='translate(-960 -284) translate(856 252) translate(104 32)'>
                     <rect width='56' height='56' fill='#7CB740' fillOpacity='.1' rx='3.5' />
                     <g>
                        <path d='M0 0L28 0 28 28 0 28z' transform='translate(14 14)' />
                        <path fill='#7CB740' d='M2.333 25.667c0-5.155 4.179-9.334 9.334-9.334 5.154 0 9.333 4.179 9.333 9.334h-2.333c0-3.866-3.134-7-7-7s-7 3.134-7 7H2.333zm9.334-10.5c-3.868 0-7-3.133-7-7 0-3.868 3.132-7 7-7 3.867 0 7 3.132 7 7 0 3.867-3.133 7-7 7zm0-2.334c2.578 0 4.666-2.088 4.666-4.666 0-2.579-2.088-4.667-4.666-4.667C9.088 3.5 7 5.588 7 8.167c0 2.578 2.088 4.666 4.667 4.666zm9.664 4.32c3.35 1.509 5.503 4.84 5.502 8.514H24.5c0-2.755-1.615-5.254-4.127-6.386l.957-2.127h.001zM20.53 3.983c2.404.99 3.972 3.334 3.971 5.935.001 3.318-2.529 6.09-5.833 6.39V13.96c1.774-.254 3.175-1.636 3.454-3.407.28-1.77-.63-3.516-2.24-4.303l.648-2.267z' transform='translate(14 14)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>

   );
};

export const TutorialIcon = ({ className }) => {
   return (

      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56'>
         <g fill='none' fillRule='evenodd'>
            <g>
               <g>
                  <g transform='translate(-1248 -284) translate(1144 252) translate(104 32)'>
                     <rect width='56' height='56' fill='#7CB740' fillOpacity='.1' rx='3.5' />
                     <g>
                        <path d='M0 0L28 0 28 28 0 28z' transform='translate(14 14)' />
                        <path fill='#7CB740' d='M3.5 21.583V5.833c0-1.933 1.567-3.5 3.5-3.5h16.333c.645 0 1.167.523 1.167 1.167v21c0 .644-.522 1.167-1.167 1.167H7.583c-2.255 0-4.083-1.829-4.083-4.084zm18.667 1.75v-3.5H7.583c-.966 0-1.75.784-1.75 1.75 0 .967.784 1.75 1.75 1.75h14.584zm-10.5-18.666H7c-.644 0-1.167.522-1.167 1.166v12.06c.547-.26 1.145-.394 1.75-.393h14.584V4.667h-2.334V14l-4.083-2.333L11.667 14V4.667z' transform='translate(14 14)' />
                     </g>
                  </g>
               </g>
            </g>
         </g>
      </svg>

   );
};

export const StepIcon = ({ className }) => {
   return (
   // <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
   //    <g fill='#006DFF' fillRule='evenodd'>
   //       <circle cx='12' cy='12' r='12' fillOpacity='.12' />
   //       <path fillRule='nonzero' d='M17.801 7.702a.591.591 0 0 0-.847 0L9.78 15.005l-2.758-2.807a.591.591 0 0 0-.847 0 .617.617 0 0 0 0 .862l3.182 3.238a.592.592 0 0 0 .846 0l7.598-7.734a.617.617 0 0 0 0-.862z' />
   //    </g>
   // </svg>

      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <g fill='none' fillRule='evenodd' transform='translate(1.929 2)'>
            <ellipse cx='9.632' cy='10' fill='#7CB740' rx='9.632' ry='10' />
            <path fill='#FFF' fillRule='nonzero' d='M8.03 11.6L6.365 9.784c-.354-.386-.926-.386-1.28 0-.357.39-.357 1.022 0 1.411L7.39 13.71c.355.387.927.387 1.281 0l5.51-6.009c.356-.39.356-1.022 0-1.411-.354-.387-.927-.387-1.281 0L8.03 11.6z' />
         </g>
      </svg>
   );
};

export const WelcomeIcon = ({ className }) => {
   return (
      <svg className={ className } xmlns='http://www.w3.org/2000/svg' width='81' height='64' viewBox='0 0 81 64'>
         <g fill='none' fillRule='evenodd'>
            <g fill='#FFF' fillRule='nonzero'>
               <path d='M589.876 98.13c.4-.173.849-.173 1.247 0l38.88 16.842c.602.261.996.874.996 1.555 0 .68-.394 1.293-.996 1.554l-5.484 2.375v25.989l6.31 13.116c.25.522.225 1.142-.072 1.639-.296.497-.816.8-1.378.8h-12.96c-.562 0-1.082-.301-1.379-.798-.296-.497-.322-1.117-.07-1.639l6.309-13.118V121.86l-30.156 13.063c-.2.088-.411.13-.624.13-.212 0-.424-.042-.623-.13l-38.88-16.842c-.602-.26-.996-.874-.996-1.554s.394-1.294.996-1.555zm-34.17 35.71c.342-.856 1.288-1.265 2.114-.905l31.707 13.748 22.008-9.542c.823-.359 1.77.047 2.114.905.345.858-.045 1.843-.87 2.2l-22.63 9.812c-.199.087-.41.13-.622.13-.212 0-.424-.043-.623-.13l-32.33-14.017c-.823-.357-1.211-1.343-.869-2.2z' transform='translate(-550 -98)' />
            </g>
         </g>
      </svg>
   );
};

// Prop Types

RocketIcon.propTypes = {
   className: PropTypes.string,
};

FolderIcon.propTypes = {
   className: PropTypes.string,
};

StudentIcon.propTypes = {
   className: PropTypes.string,
};

TutorialIcon.propTypes = {
   className: PropTypes.string,
};

StepIcon.propTypes = {
   className: PropTypes.string,
};
WelcomeIcon.propTypes = {
   className: PropTypes.string,
};
