import React, { Component, Fragment } from "react";
import './index.css'
import {  Header } from "semantic-ui-react";
import ColorInput from "components/ColorInput";
import AppContext from "contexts/AppContext";
import Select from "components/Select";
import InputText from "components/InputText";
import InputRange from "components/InputRange";
import Button, {THEMES as themes} from 'components/BaseButtonNew'
import FontSelector from "components/FontSelector";
import { highlightSidebar } from "utils";
import PropTypes from 'prop-types';

class LinkMiEditable extends Component {
   constructor(props) {
      super(props);
      this.state = {
         fonts: null,
         scroll_to_options: { text: 'Custom URL', value: 'another_page' },
      }
   }
   componentDidMount() {
      if (this.props.scroll) {
         highlightSidebar(
            this.props.slug,
            this.props.toggleSidebar,
            this.props.menuVisible,
            this.props.isSubComponent,
            this.props.maincomponentSlug
         );
      }

      let fonts = [];
      const json = require("components/SettingsComponents/Typography/fonts.json");
      Object.values(json)[0].map((font, i) => {
         fonts.push(this.createData(i, font, font));
         return font;
      });

      this.setState({fonts: fonts });
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.scroll) {
         highlightSidebar(
            this.props.slug,
            this.props.toggleSidebar,
            this.props.menuVisible,
            this.props.isSubComponent,
            this.props.maincomponentSlug
         );
      }
   }

   createData = (key = 0, text, value) => {
      let id = key++;
      return {
         className: 'a'+id,
         text,
         value,
      };
   };


   render() {
      const fontStyleOptions =  [
         { text: "Normal", value: "normal" },
         { text: "Bold", value: "bold" },
      ];
      const windowOptions =  [
         { text: "Same Window", value: "_self" },
         { text: "New Window", value: "_blank" },
      ];
      return (
         <div>
            {
               !this.props.isCustomLink && (
                  <div style={{ marginBottom: '12px' }} className="link__wrapper">
                     <InputText
                        {...this.props}
                        label='Text'
                        props_name='text'
                        maxLength={15}
                        default=''
                        inputValue={this.props.text}
                     />
                  </div>
               )
            }
            {!this.props.isCustomLink && !this.props.disabled && !this.props.isFooterLink && <Select
               {...this.props}
               heading='Link'
               placeholder='Select a destination'
               props_name='scroll_to'
               options={[...this.props.scroll_to_options, this.state.scroll_to_options]}
               default={this.props.scroll_to}
            />}
            {this.props.scroll_to === 'another_page' &&
               <Fragment>
                  <div className="link__wrapper">
                     <InputText
                        {...this.props}
                        label='Custom URL'
                        placeholder='https://miestro.com'
                        props_name='href'
                        // maxLength={50}
                        default=''
                        inputValue={this.props.href}
                        removeLine={this.props.isCustomLink}
                     />
                  </div>
                 {!this.props.isCustomLink &&   
                     <Select
                        {...this.props}
                        heading='Open Link as'
                        placeholder=''
                        props_name='target'
                        options={windowOptions}
                        default={this.props.target}
                     />
                  }
               </Fragment>
            }
            {!this.props.isCustomLink && 
               <Fragment>
                  <div className='bottom-gap'>
                     <FontSelector
                        fonts={this.state.fonts || []}
                        heading='Font Family'
                        currentValue={this.props.fontFamily || 'Inter-Bold'}
                        props_name='fontFamily'
                        slug={this.props.slug}
                     />
                  </div>
                  <Select
                     {...this.props}
                     heading='Font Style'
                     props_name='fontStyle'
                     options={fontStyleOptions}
                     default={this.props.fontStyle? this.props.fontStyle : 'normal'}
                  />
                  <ColorInput
                     label='Text Color'
                     color={this.props.textColor}
                     section={this.props.section}
                     slug={this.props.slug}
                     props_name='textColor'
                     isChecked={true}
                  />
                  <Header as='h3' dividing />
                  <InputRange
                     {...this.props}
                     label='Text Size'
                     props_name='textSize'
                     min={14}
                     max={24}
                     default={18}
                     inputValue={this.props.textSize}
                  />
               </Fragment>
            }
            {!this.props.disabled &&
                <Fragment>
                   <AppContext.Consumer>
                      {({ state, functions }) => (
                         <Header as='h4' id='section_remove_button' color='black'>
                            <Button
                               style={{ margin: '0px auto' }}
                               text='Delete Link'
                               theme={themes.red}
                               onClick={() => {
                                  this.props.availableDelete?
                                     functions.handleDeleteComponent(
                                        this.props.section,
                                        this.props.slug,
                                        true,
                                        'deleted'
                                     ) :
                                     functions.handleUpdateProps(
                                        this.props.section,
                                        this.props.slug,
                                        true,
                                        'deleted'
                                     )
                               }}
                            />
                         </Header>
                      )}
                   </AppContext.Consumer>
                   <Header as='h3' dividing />
                </Fragment>
            }
         </div>
      );
   }
}

LinkMiEditable.propTypes = {
   slug: PropTypes.string,
   text: PropTypes.string,
   textColor: PropTypes.string,
   href: PropTypes.string,
}


export default LinkMiEditable;
