const seoKeywords = (seo_keywords = null) => {
    if(seo_keywords){
        // CREATE META TAG USING KEYWORDS
        let meta = document.createElement('meta');
        meta.name = "keywords"
        meta.content = seo_keywords;
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
};

export default seoKeywords;
