import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_8",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "white",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "60",
                        imgUrl: require("../images/landing_8/t4-logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Support",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#666666",
                        size: "medium",
                        linked: true,
                        href: "",
                        blanked: true,
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#f2f2f2",
                        textColor: "#666666",
                        size: "medium",
                        linked: true,
                        blanked: true,
                        href: "",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "|",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "medium",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Section 1",
                props: {
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_8/t4-bg.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    height: "",
                    spacing: "lg",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<span style='font-size:70px'>Everyone Can Learn PPC</span>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<span style='font-size:30px'>Earn Money with Online Advertising</span>",
                        color: "#ffffff",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Section 2",
                props: {
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#eef4fe",
                    height: "",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-size:36px'>Instert your captivating headline here</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<h3 style='line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/comp.png"),
                        width: "200",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text: "<span style='font-size:18px'>Popular</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_8/t4-regular-icon.png"),
                        width: "80",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<h3><span style='font-size:24px'>Regular membership</span></h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<span style='color:#cccccc;font-size:24px;'>$39</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<span style='line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. learn more</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Join Now",
                        bgColor: "#a9c731",
                        textColor: "#a9c731",
                        size: "medium",
                        bordered: true,
                        border: "solid",
                        linked: false,
                        href: "",
                        blanked: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text: "<span style='font-size:18px'>Most popular</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_8/t4-premium-icon.png"),
                        width: "100",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<h3><span style='font-size:24px'>Premium membership</span></h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<span style='color:#cccccc;font-size:24px;'>$139</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            "<span style='line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. learn more</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Join Now",
                        bgColor: "#dd099c",
                        textColor: "#dd099c",
                        size: "medium",
                        bordered: true,
                        border: "solid",
                        linked: false,
                        href: "",
                        blanked: true,
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_8/t4-chart.png"),
                        width: "800",
                        spacing: "sm",
                        visibility: true,
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Section 3",
                props: {
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#e1f0ff",
                    background_type: "color",
                    spacing: "sm",
                    reverseAvailaible: true,
                    reversed: true,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_8/t4-woman.png"),
                        width: "400",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text: "<h1>Insert a sub-headline idea</h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa</span></p>",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Section 4",
                props: {
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#e1f0ff",
                    background_type: "color",
                    spacing: "sm",
                    reverseAvailaible: true,
                    reversed: false,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_8/t4-colors.png"),
                        width: "400",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h1>Insert a sub-headline idea</h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa</span></p>",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Section 5",
                props: {
                    order:5,
                    duplicated:5,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "sm",
                    reverseAvailaible: true,
                    reversed: false,
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_2/comp.png"),
                        width: "200",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text: "John Smith",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text: "Course Developer",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text: "<h2>Meet your course creator</h2>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='line-height: 1.5; font-weight: 500;'>John Smith Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span></p>",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:6,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#cbe8ff",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:36px;font-weight:800'>Enrol now!</span></p>",
                        color: "#333333",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#666666",
                        textColor_selected: "#be3f3f",
                        bgColor: "#f1f3f3",
                        bgColor_selected: "#ffe0e0",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#26cdc3",
                        textColor: "#ffffff",
                        size: "medium",
                        href: "",
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Footer",
                props: {
                    order:7,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#264555",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Miestro community",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "small",
                        linked: false,
                        blanked: true,
                        href: "",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Powered by Miestro ©",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "small",
                        linked: false,
                        blanked: true,
                        href: "",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Privacy Policy",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "small",
                        linked: false,
                        blanked: true,
                        href: "",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Terms&Conditions",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "small",
                        linked: false,
                        blanked: true,
                        href: "",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Contact Us",
                        bgColor: "rgba(0,0,0,0)",
                        textColor: "#fff",
                        size: "small",
                        linked: false,
                        blanked: true,
                        href: "",
                    },
                },
            ],
        },
    ],
};

export default state;
