import React, { Component, Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import NaturalDragAnimation from "./NaturalDragAnimation";
import { Icon, Dropdown, Input } from "semantic-ui-react";
import AppContext from "../../../contexts/AppContext";
import slug from "../../../utils/slugGenerator";
import "./index.css";
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
   // some basic styles to make the items look a bit nicer
   userSelect: "none",
   padding: "6% 10%",
   display: "flex",
   justifyContent: "space-between",
   // change background colour if dragging
   background: isDragging ? "#d7e5f5" : "transparent",

   // styles we need to apply on draggables
   ...draggableStyle,
});

class Item extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sectionName: null,
         sectionRename: false,
         editableSectionOrder: null,
         sectionVisibility: true,
      };
   }
   renameSection = name => {
      this.setState({
         sectionName: name,
      });
   };
   toggleRenameSection = sectionOrder => {
      this.setState({
         sectionRename: false,
         sectionName: null,
         editableSectionOrder: null,
      });
   };
   sectionDropdown = (e, data, sectionOrder) => {
      this.setState({
         sectionRename: this.state.sectionRename,
         sectionName: null,
         editableSectionOrder: null,
         sectionVisibility: true,
      });
      switch (data.value) {
         case "rename":
            this.setState({
               sectionRename: !this.state.sectionRename,
               sectionName: this.props.landing[sectionOrder].section.name,
               editableSectionOrder: sectionOrder,
            });
            return true;
            break;
         case "hide":
            this.setState({
               sectionRename: false,
               sectionVisibility: !this.props.landing[sectionOrder].section
                  .props.visibility,
               editableSectionOrder: sectionOrder,
            });
            return "hide";
            break;
         case "duplicate":
            return "duplicate";
            break;
         case "delete":
            return "delete";
            break;
         default:
            return false;
            break;
      }
   };
   render() {
      const { item, index, ...props } = this.props;
      let sectionOptions = [
         { key: slug(), value: "none" },
         {
            key: slug(),
            text: "Rename",
            value: "rename",
         },
         {
            key: slug(),
            text: "Duplicate",
            value: "duplicate",
         },
         {
            key: slug(),
            text: item.props.visibility ? "Hide" : "Show",
            value: "hide",
         },
         {
            key: slug(),
            text: "Delete",
            value: "delete",
         },
      ]

      if(item.props.duplicated === 'header' ||  item.props.duplicated === 'pricing' || item.props.duplicated ==='footer') {
         sectionOptions = [
            { key: slug(), value: "none" },
            {
               key: slug(),
               text: "Rename",
               value: "rename",
            },
            {
               key: slug(),
               text: item.props.visibility ? "Hide" : "Show",
               value: "hide",
            },
         ]
      }
      return (
      //<div/>
         <Draggable
            key={"dragKey-" + item.props.order}
            draggableId={"item-" + item.props.order}
            index={index}
         >
            {(provided, snapshot) => (
               <NaturalDragAnimation
                  style={getItemStyle(
                     snapshot.isDragging,
                     provided.draggableProps.style
                  )}
                  snapshot={snapshot}
                  {...props}
               >
                  {style => (
                     <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={style}
                     >
                        {!this.state.sectionRename ? (
                           <Fragment>
                              <div
                                 className='menu-item-left'
                                 style={{
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                 }}
                              >
                                 <img
                                    alt='default'
                                    src={require("../../../images/sidebar/Background.png")}
                                 />

                                 <span>{item.name}</span>
                              </div>
                              <div className='menu-item-right'>
                                 <AppContext.Consumer>
                                    {({ state, functions }) => (
                                       <Dropdown
                                          className='miestro_dropdown'
                                          options={sectionOptions}
                                          icon='ellipsis horizontal'
                                          pointing='top right'
                                          defaultValue='none'
                                          onClick = {(e)=>{
                                             e.preventDefault();
                                             e.stopPropagation();
                                             e.nativeEvent.stopPropagation();
                                             let section_slug = e.target.closest(".miestro_dropdown");
                                             let miestro_dropdown = document.querySelectorAll(".miestro_dropdown");

                                             let childofELement = section_slug.querySelector('.menu.transition');

                                             miestro_dropdown.forEach(element => {
                                                element.querySelector('.menu.transition').classList.remove("visible");
                                                element.classList.remove("visible");
                                                element.classList.remove("active");

                                             });
                                             section_slug.classList.add("visible");
                                             section_slug.classList.add("active");
                                             childofELement.classList.add("visible");
                                          }}
                                          onChange={(e, data) => {
                                             return new Promise(
                                                resolve => {
                                                   resolve(
                                                      this.sectionDropdown(
                                                         e,
                                                         data,
                                                         index
                                                      )
                                                   );
                                                }
                                             ).then(result => {
                                                result &&
                                                                    functions.handleSectionOptions(
                                                                       index,
                                                                       result,
                                                                       this
                                                                          .state
                                                                          .sectionVisibility
                                                                    );
                                             });
                                          }}
                                       />
                                    )}
                                 </AppContext.Consumer>
                                 {!item.props.visibility && (
                                    <Icon name='eye slash outline' />
                                 )}
                                 <img
                                    alt='default'
                                    src={require("../../../images/sidebar/burger.png")}
                                 />
                              </div>
                           </Fragment>
                        ) : this.state.editableSectionOrder ===
                                  index ? (
                              <Fragment>
                                 <div className='menu-item-left'>
                                    <img
                                       alt='default'
                                       src={require("../../../images/sidebar/Background.png")}
                                    />
                                    <Input
                                       value={this.state.sectionName}
                                       size='mini'
                                       type='text'
                                       placeholder='Section Name'
                                       style={{
                                          marginLeft: "20px",
                                       }}
                                       onClick={e => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          e.nativeEvent.stopImmediatePropagation();
                                       }}
                                       onChange={e => {
                                          this.renameSection(
                                             e.target.value
                                          );
                                       }}
                                    />
                                 </div>
                                 <div className='menu-item-right'>
                                    <AppContext.Consumer>
                                       {({ state, functions }) => (
                                          <Icon
                                             name='check'
                                             color='grey'
                                             onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                e.nativeEvent.stopImmediatePropagation();
                                                return new Promise(
                                                   resolve => {
                                                      resolve(
                                                         functions.handleSectionOptions(
                                                            index,
                                                            "rename",
                                                            this
                                                               .state
                                                               .sectionName
                                                         )
                                                      );
                                                   }
                                                ).then(() => {
                                                   this.toggleRenameSection(
                                                      index
                                                   );
                                                });
                                             }}
                                          />
                                       )}
                                    </AppContext.Consumer>
                                    <Icon
                                       name='times'
                                       color='grey'
                                       onClick={e => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          e.nativeEvent.stopImmediatePropagation();
                                          this.toggleRenameSection(
                                             index
                                          );
                                       }}
                                    />
                                 </div>
                              </Fragment>
                           ) : (
                              <Fragment>
                                 <div
                                    className='menu-item-left'
                                    style={{
                                       maxWidth: "200px",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <img
                                       alt='default'
                                       src={require("../../../images/sidebar/Background.png")}
                                    />

                                    <span>{item.name}</span>
                                 </div>
                                 <div className='menu-item-right'>
                                    <AppContext.Consumer>
                                       {({ state, functions }) => (
                                          <Dropdown
                                             // trigger
                                             options={sectionOptions}
                                             icon='ellipsis horizontal'
                                             pointing='top right'
                                             defaultValue='none'
                                             onClick = {(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                e.nativeEvent.stopPropagation();
                                                let section_slug = e.target.closest(".miestro_dropdown");
                                                let miestro_dropdown = document.querySelectorAll(".miestro_dropdown");
                                                let childofELement = section_slug.querySelector('.menu.transition');

                                                miestro_dropdown.forEach(element => {
                                                   element.querySelector('.menu.transition').classList.remove("visible");
                                                   element.classList.remove("visible");
                                                   element.classList.remove("active");

                                                });
                                                section_slug.classList.add("visible");
                                                section_slug.classList.add("active");
                                                childofELement.classList.add("visible");
                                             }}

                                             onChange={(e, data) => {
                                                return new Promise(
                                                   resolve => {
                                                      resolve(
                                                         this.sectionDropdown(
                                                            e,
                                                            data,
                                                            index
                                                         )
                                                      );
                                                   }
                                                ).then(result => {
                                                   result &&
                                                                    functions.handleSectionOptions(
                                                                       index,
                                                                       result,
                                                                       this
                                                                          .state
                                                                          .sectionName
                                                                    );
                                                });
                                             }}
                                          />
                                       )}
                                    </AppContext.Consumer>
                                    {!item.props.visibility && (
                                       <Icon name='eye slash outline' />
                                    )}
                                    <img
                                       alt='default'
                                       src={require("../../../images/sidebar/burger.png")}
                                    />
                                 </div>
                              </Fragment>
                           )}
                     </div>
                  )}
               </NaturalDragAnimation>
            )}
         </Draggable>
      );
   }
}

export default Item;
