import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_3",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#f0d9e5",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "30",
                        imgUrl: require("../images/landing_2/logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "PRICING",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#754a87",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ABOUT THE AUTHOR",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#754a87",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "FEATURES",
                        bgColor: "rgba(255, 255, 255, 0)",
                        textColor: "#754a87",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL NOW",
                        bgColor: "#754a87",
                        textColor: "white",
                        size: "small",
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Text with image",
                props: {
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_3/background.png"),
                    bgColor: "#ecd9e4",
                    background_type: "image",
                    spacing: "sm",
                    alignmentAvalaible: true,
                    alignment: "right",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='color: #30242c;font-size:40px;font-weight:bold;'>DO WHAT YOU LOVE</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<span style='color:#67495c;font-size:16px; line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enimLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</span>",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Features",
                props: {
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-weight: bold;'>Insert headline</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_3/Ellipse1.png"),
                        width: "130",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3>Free Videos</h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="color: #666666;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_3/Ellipse3.png"),
                        width: "130",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3>E-Course</h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="color: #666666;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_3/Ellipse2.png"),
                        width: "130",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h3>Community</h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        text:
                            '<p><span style="color: #666666;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Border",
                    name: "Border",
                    props: {
                        height: "3px",
                        width: "100px",
                        borderRadius: "5px",
                        backgroundColor: "#e7d5e1de",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Text with video",
                props: {
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#f9f7f0",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-weight: bold;'>Insert headline</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Border",
                    name: "Border",
                    props: {
                        height: "3px",
                        width: "100px",
                        borderRadius: "6px",
                        backgroundColor: "#ded5b7",
                    },
                },
                {
                    slug: slug(),
                    type: "Video",
                    name: "Video",
                    props: {
                        src: "https://www.youtube.com/embed/zIEtTDXUa3E",
                        visibility: true,
                        width: "600",
                        height: "400",
                        spacing: "xs",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Details",
                props: {
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text: "<h1>Our program modules</h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Border",
                    name: "Border",
                    props: {
                        height: "3px",
                        width: "100px",
                        borderRadius: "6px",
                        backgroundColor: "#b3d9bf",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_3/Rounded1.png"),
                        width: "270",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text:
                            "<p><span style='color:#666666;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_3/Rounded2.png"),
                        width: "270",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text:
                            "<p><span style='color:#666666;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_3/Rounded3.png"),
                        width: "270",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        text:
                            "<p><span style='color:#666666;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad</span></p>",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "JOIN THE PROGRAM",
                        bgColor: "#754a8a",
                        textColor: "#ffffff",
                        size: "medium",
                        linked: true,
                        blanked: false,
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Testimonials",
                props: {
                    order:5,
                    duplicated:5,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#e1ece4",
                    background_type: "color",
                    reverseAvailaible: true,
                    reversed: false,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_3/Layer 15.png"),
                        width: "200",
                        radius: "0",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<h3>About the Host</h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p style='line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:6,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#ffffff",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:26px;font-weight:800'>ENROLL NOW</span></p>",
                        color: "#30242c",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#666666",
                        textColor_selected: "#73478a",
                        bgColor: "#f1f3f3",
                        bgColor_selected: "#eee6f3",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "JOIN THE PROGRAMM",
                        bgColor: "#73478a",
                        textColor: "white",
                        size: "large",
                        href: "",
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Footer",
                props: {
                    order:7,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(116, 77, 135)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        radius: true,
                        floated: "right",
                        text: "© Miestro. All Rights Reserved.",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Powered by Miestro ©",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Privacy Policy",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Terms and Conditions",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Contact us",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
    ],
};

export default state;
