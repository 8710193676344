import React from 'react';

export const AccountImageEditXIcon = () => {
   return (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M11.0206 3.02008C11.5618 2.47897 12.4391 2.47898 12.9802 3.02011C13.5213 3.56122 13.5213 4.43851 12.9802 4.97961L12.4688 5.49093C11.4304 5.39677 10.6035 4.56988 10.5093 3.53143L11.0206 3.02008ZM9.67312 4.36758L5.05744 8.98322C4.53597 9.50468 4.31894 9.72424 4.1477 9.96976C3.99055 10.1951 3.86047 10.4381 3.76016 10.6939C3.65085 10.9726 3.58856 11.2749 3.44393 11.9981L3.30438 12.6958L4.00214 12.5563C4.72529 12.4116 5.02765 12.3494 5.30632 12.2401C5.56207 12.1397 5.80511 12.0097 6.03044 11.8525C6.27597 11.6813 6.49552 11.4642 7.01699 10.9428L11.6327 6.3271C10.7158 6.0104 9.98983 5.28442 9.67312 4.36758ZM13.6873 2.31301C12.7557 1.38136 11.2452 1.38134 10.3135 2.31297L4.35034 8.27611L4.31495 8.31149L4.31495 8.3115C3.83923 8.78715 3.55534 9.071 3.32748 9.39771C3.12543 9.68743 2.95818 9.99992 2.82921 10.3287C2.68376 10.6996 2.60505 11.0932 2.47316 11.7529L2.46335 11.802L2.17671 13.2351C2.14392 13.3991 2.19524 13.5685 2.31345 13.6868C2.43166 13.805 2.60113 13.8563 2.76506 13.8235L4.19826 13.5369L4.24733 13.527L4.24734 13.527C4.907 13.3952 5.30066 13.3164 5.67147 13.171C6.00029 13.042 6.31278 12.8748 6.60249 12.6727C6.92921 12.4449 7.21306 12.161 7.68872 11.6853L7.72409 11.6499L13.6873 5.68672C14.6189 4.7551 14.6189 3.24465 13.6873 2.31301Z' fill='white' />
      </svg>
   );
};

export const AccountCheckXIcon = () => {
   return (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path fillRule='evenodd' clipRule='evenodd' d='M13.8405 3.44714C14.1458 3.72703 14.1664 4.20146 13.8865 4.5068L6.55319 12.5068C6.41496 12.6576 6.22113 12.7454 6.01662 12.7498C5.8121 12.7543 5.61464 12.675 5.47 12.5303L2.13666 9.197C1.84377 8.90411 1.84377 8.42923 2.13666 8.13634C2.42956 7.84345 2.90443 7.84345 3.19732 8.13634L5.97677 10.9158L12.7808 3.49321C13.0607 3.18787 13.5351 3.16724 13.8405 3.44714Z' fill='#24554E' />
      </svg>
   );
};

AccountImageEditXIcon.propTypes = {};
AccountCheckXIcon.propTypes = {};
