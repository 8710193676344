import React, { Component, Fragment } from "react";
import EditorContext from "contexts/EditorContext";
import { Header, Menu, Icon } from "semantic-ui-react";
import { getAllEditableComponents } from "utils";
import "./index.css";

class SettingsMenu extends Component {
   render() {
      let SettingItem;
      return (
         <EditorContext.Consumer>
            {({ state, functions, metas}) => (
               SettingItem = getAllEditableComponents()[state.settingItem],
               <Fragment>
                  <Menu vertical={true} style={{ width: "100%" }}>
                     <Menu.Item
                        name='header'
                        onClick={() => functions.backToCurrentMenu()}
                     >
                        <Header as='h4' color='black'>
                           <Icon name='angle left' disabled />
                           <Header.Content>Go Back</Header.Content>
                        </Header>
                     </Menu.Item>
                     <Menu.Item><SettingItem {...metas} /></Menu.Item>
                  </Menu>
               </Fragment>
            )}
         </EditorContext.Consumer>
      );
   }
}

SettingsMenu.defaultProps = {};

export default SettingsMenu;
