import React from 'react';
import './index.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconNew from 'components/elements/iconsSize';

export const TYPES = {
   regular: 'regular',
   regularMin: 'regularMin',
   regularLarge: 'regularLarge',
   medium: 'medium',
   bold: 'bold',
   bold100: 'bold100',
   regularDefault: 'regularDefault',
   regularDefaultSmall: 'regularDefaultSmall',
   select: 'select',
   select_large: 'select_large',
   mediumLarge: 'mediumLarge',
   medium153: 'medium153',
   medium160: 'medium160',
   mediumLargeGrey: 'mediumLargeGrey',
   mediumSmall: 'mediumSmall',
   regularDefaultSmallX: 'regularDefaultSmallX',
   regular148: 'regular148',
   regular160: 'regular160',
   regularDefaultGrey: 'regularDefaultGrey',
   regularDefaultGrey145: 'regularDefaultGrey145',
   regularDefault145: 'regularDefault145',
   medium150: 'medium150',
   mediumXSmall: 'mediumXSmall',
};

export const SIZES = {
   xlarge: 'xlarge',
   xxlarge: 'xxlarge',
   'size_40': 'size_40',
   large: 'large',
   medium: 'medium',
   small: 'small',
   xsmall: 'xsmall',
   xx_small: 'xx_small',
   size_28: 'size_28',
};


export const TextWithIcon = ({
   className, inner, type, size, style, onClick, tooltip, iconName, isWithoutIcon, iconGap, generalStyles,
   isIconRight,
}) => {
   return (
      <div
         role='presentation'
         onClick={ onClick }
         className='text-with-icon'
         style={ { gap: iconGap || 11, ...generalStyles } }
      >
         {!isWithoutIcon && !isIconRight && (<IconNew name={ iconName || 'LeftArrowL' } />)}
         <span
            style={ style }

            className={
               classNames(
                  className,
                  'textBasic',
                  'dont-break-out',
                  {
                     [`textBasic_type_${ type }`]: type,
                     [`textBasic_size_${ size }`]: size,
                  })
            }
         >
            {inner}
         </span>
         {!isWithoutIcon && isIconRight && (<IconNew name={ iconName || 'LeftArrowL' } />)}
      </div>
   );
};

const Text = ({
   className, inner, type, size, style, onClick, tooltip, miniText,
}) => {
   return (
      <div>
         <span
            style={ style }
            role='presentation'
            onClick={ onClick }
            className={
               classNames(
                  className,
                  'textBasic',
                  'dont-break-out',
                  {
                     [`textBasic_type_${ type }`]: type,
                     [`textBasic_size_${ size }`]: size,
                  })
            }
         >
            {
               Array.isArray(inner) ? (
                  inner.map((child, index) => {
                     if (typeof child === 'string') return child;
                     return React.cloneElement(child, { key: index.toString() });
                  })
               ) : inner
            }
            {!!`${ miniText }` && (
               <span className='mini-text'>{miniText}</span>
            )}
         </span>
      </div>
   );
};


TextWithIcon.propTypes = {
   size: PropTypes.string,
   type: PropTypes.string,
   inner: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
      PropTypes.node,
   ]),
   style: PropTypes.any,
   className: PropTypes.string,
   onClick: PropTypes.func,
   tooltip: PropTypes.string,
   iconName: PropTypes.string,
   isWithoutIcon: PropTypes.bool,
   iconGap: PropTypes.number,
   generalStyles: PropTypes.any,
   isIconRight: PropTypes.bool,
};

TextWithIcon.defaultProps = {
   type: 'medium',
   size: 'large',
   inner: 'Text',
   onClick: () => {},
   isIconRight: false,
};


Text.propTypes = {
   size: PropTypes.string,
   type: PropTypes.string,
   miniText: PropTypes.any,
   inner: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.number,
      PropTypes.node,
   ]),
   style: PropTypes.any,
   className: PropTypes.string,
   onClick: PropTypes.func,
   tooltip: PropTypes.string,
};

Text.defaultProps = {
   type: 'medium',
   size: 'large',
   inner: 'Text',
   onClick: () => {},
};


export default Text;
