import ReactPixel from 'react-facebook-pixel';

const facebookPixel = (fb_pixel_code) => {
    if(fb_pixel_code){
        fb_pixel_code = parseInt(fb_pixel_code);
        // const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        // advancedMatching
   
        ReactPixel.init(fb_pixel_code, options);
        ReactPixel.pageView(); 
    } 
};

export default facebookPixel;
