const highlightSidebar = (componentSlug, toggleSidebar, menuVisible, isSubComponent, maincomponentSlug) => {
    if(!menuVisible) {
        toggleSidebar();
    }
    let CurrentSidebarComponent = document.querySelector("#" + componentSlug);
    let CurrentMainSidebarComponent = document.querySelector("#" + maincomponentSlug);
    if (CurrentSidebarComponent.style.display === "none") {
        if (!isSubComponent) {
            if(document.querySelectorAll(".SectionComponent")) {
                document.querySelectorAll(".SectionComponent").forEach(el => el.style.display = "none");
            }
            if(document.querySelectorAll(".SubSectionComponent")) {
                document.querySelectorAll(".SubSectionComponent").forEach(el => el.style.display = "none");
            }
            document.querySelectorAll(".component_icon_up").forEach(el => el.style.display = "none");
            document.querySelectorAll(".component_icon_down").forEach(el => el.style.display = "block");
        }
        else {
            if(document.querySelectorAll(".SectionComponent")) {
                document.querySelectorAll(".SectionComponent").forEach(el => el.style.display = "none");
            }
            if(document.querySelectorAll(".SubSectionComponent")) {
                document.querySelectorAll(".SubSectionComponent").forEach(el => el.style.display = "none");
            }
            document.querySelectorAll(".component_icon_up").forEach(el => el.style.display = "none");
            document.querySelectorAll(".component_icon_down").forEach(el => el.style.display = "block");
            CurrentMainSidebarComponent.style.display = "block";
            if(CurrentMainSidebarComponent.previousSibling.querySelector('.component_icon_down')) {
                CurrentMainSidebarComponent.previousSibling.querySelector('.component_icon_down').style.display = "none";
            }
            if(CurrentMainSidebarComponent.previousSibling.querySelector('.component_icon_up')) {
                CurrentMainSidebarComponent.previousSibling.querySelector('.component_icon_up').style.display = "block";
            }
        }

        CurrentSidebarComponent.style.display = "block";
        if(CurrentSidebarComponent.previousSibling.querySelector('.component_icon_down')) {
            CurrentSidebarComponent.previousSibling.querySelector('.component_icon_down').style.display = "none";
        }
        if(CurrentSidebarComponent.previousSibling.querySelector('.component_icon_up')) {
            CurrentSidebarComponent.previousSibling.querySelector('.component_icon_up').style.display = "block";
        }

    }
    CurrentSidebarComponent.style.backgroundColor = "#f3f0f0a3";
    CurrentSidebarComponent.scrollIntoView();
    setTimeout(() => {
        CurrentSidebarComponent.scrollIntoView();
    }, 800);

    setTimeout(() => {
        CurrentSidebarComponent.style.backgroundColor = "";
    }, 1000);
};

export default highlightSidebar;
