import React from "react";
import slug from "../../utils/slugGenerator";

export const Landings_groups = [
    {
        name: "Hero Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: 'Hero',
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/heroNew.png"
                            alt="heroNew"
                            width="100%"
                            height={112}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: 'Hero Section',
                        visibility: true,
                        bgVideoSrc: '',
                        bgColor: '#000000',
                        background_type: 'image',
                        bgImgSrc: 'https://miestro-production.s3.us-west-2.amazonaws.com/hero_image_production.png',
                        imageOverlay: true,
                        spacing: 'lg',
                        alignmentAvalaible: true,
                        alignment: 'center',
                        bgColorOverlay: '#000000',
                        paddingBottom: 80,
                        paddingTop: 80,
                        paddingLeft: 176,
                        paddingRight: 176,
                        width: 50,
                        recomendedSize: '1440x664',
                        aspectRatio: '1440 / 664',
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'One Column New',
                        props: {
                            text: 'Component Props'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'left',
                                    text: '<span>WELCOME TO ONLINE OMNIVIBE HUB</span>',
                                    color: '#ffffff',
                                    lineHeight: 10,
                                    fontFamily: 'Cinzel',
                                    fontSize: 72,
                                    mobileFontSize: 36,
                                    paddingBottom: 24,
                                    paddingTop: 10,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 700,
                                    contentWidth: 'min(715px, 100%)',
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Elevate your spirituality, anytime, anywhere.</span>',
                                    color: '#c7c5c5',
                                    lineHeight: 30,
                                    paddingBottom: 48,
                                    fontFamily: 'SourceSansPro-Regular',
                                    fontSize: 20,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 400,
                                    className: 'hero__subtitle__text',
                                    contentWidth: 'min(715px, 100%)',
                                }
                            },
                            {
                                slug: slug(),
                                type: 'ButtonMi',
                                name: 'Call to Action',
                                props: {
                                    text: 'Start Your Free 7-Day Trial Journey',
                                    bgColor: '#24554E',
                                    textColor: '#ffffff',
                                    blanked: false,
                                    size: 'medium',
                                    href: '',
                                    fontFamily: 'Roboto-Medium',
                                    borderColor: '#24554E',
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                    bordered: true,
                                    borderRadius: '10',
                                    linked: true,
                                    alignment: 'left',
                                    textSize: 16,
                                    className: 'hero__section__button',
                                    contentWidth: '100%',
                                    height: '56px'
                                }
                            }
                        ]
                    },
                ],
            }
        ]
    },
    {
        name: "Content Section",
        data: [
            {
                section: {
                    slug: slug(),
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/content_section.png"
                            alt="heroNew"
                            width="100%"
                            height={112}
                        />
                    ),
                    name: 'Content',
                    props: {
                        order: "",
                        duplicated: 'Content Section',
                        visibility: true,
                        bgVideoSrc: '',
                        bgImgSrc: '',
                        bgColor: '#121212',
                        background_type: 'color',
                        spacing: 'md',
                        reverseAvailaible: true,
                        reversed: false,
                        bgColorOverlay: '#121212'
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'One Column New',
                        props: {
                            text: 'Component Props',
                            className: 'content-section-header'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'center',
                                    text: '<span>DIVE INTO ENLIGHTENING WISDOM</span>',
                                    color: '#ffffff',
                                    lineHeight: 10,
                                    fontFamily: 'Cinzel',
                                    fontSize: 48,
                                    mobileFontSize: 28,
                                    maxWidth: 'none',
                                    centerText: true,
                                    paddingBottom: 20,
                                    fontWeight: 700,
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'center',
                                    text: '<span>Explore a library of transformative teachings and guided meditations.</span>',
                                    color: '#b8b8b8',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 20,
                                    maxWidth: 'none',
                                    centerText: true,
                                    paddingBottom: 20,
                                    fontWeight: 400,
                                    className: 'dive__into__subtitle'
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props',
                            width: 300,
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Video',
                                name: 'Video',
                                props: {
                                    src: 'https://www.youtube.com/embed/RVjxFLTCngw',
                                    width: '304',
                                    borderRadius: '12',
                                    height: '180',
                                    visibility: true,
                                    justifyContent: 'flex-start'
                                },
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    type: 'ellipsis',
                                    text: '<span>Journey to Inner Peace: Guided Meditation</span>',
                                    color: '#ffffff',
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 20,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 500
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props',
                            width: 300,
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Video',
                                name: 'Video',
                                props: {
                                    src: 'https://www.youtube.com/embed/RVjxFLTCngw',
                                    width: '304',
                                    borderRadius: '12',
                                    height: '180',
                                    visibility: true,
                                    justifyContent: 'flex-start'
                                },
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    type: 'ellipsis',
                                    text: '<span>Unlocking the Power Within: OmnIVIbe Shares Profound Insights</span>',
                                    color: '#ffffff',
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 20,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 500
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props',
                            width: 300,
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Video',
                                name: 'Video',
                                props: {
                                    src: 'https://www.youtube.com/embed/RVjxFLTCngw',
                                    width: '304',
                                    borderRadius: '12',
                                    height: '180',
                                    visibility: true,
                                    justifyContent: 'flex-start'
                                },
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    type: 'ellipsis',
                                    text: '<span>Navigating Life\'s Challenges: Wisdom for Everyday Living</span>',
                                    color: '#ffffff',
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 20,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 500
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props',
                            width: 300,
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Video',
                                name: 'Video',
                                props: {
                                    src: 'https://www.youtube.com/embed/RVjxFLTCngw',
                                    width: '304',
                                    borderRadius: '12',
                                    height: '180',
                                    visibility: true,
                                    justifyContent: 'flex-start'
                                },
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    type: 'ellipsis',
                                    text: '<span>Awakening the Spirit: Sacred Rituals and Practices with OmnIVIbe</span>',
                                    color: '#ffffff',
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 20,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 500
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "Features Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: 'Features',
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/button_block.png"
                            alt="heroNew"
                            width="100%"
                            height={140}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: 'Features Section',
                        visibility: true,
                        bgVideoSrc: '',
                        bgImgSrc: '',
                        bgColor: '#24554E',
                        background_type: 'color',
                        bgColorOverlay: '#ffffff',
                        spacing: 'none',
                        paddingRight: '0',
                        paddingLeft: '0'
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'One Column New',
                        props: {
                            text: 'Component Props',
                            bgColor: '#24554E',
                            className: 'add_features_section_info temp23_add_features_section_info'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'left',
                                    text: '<span>WHY CHOOSE OMNIVIBE WISDOM HUB?</span>',
                                    color: '#ffffff',
                                    lineHeight: 12,
                                    fontFamily: 'Cinzel',
                                    fontSize: 48,
                                    mobileFontSize: 28,
                                    maxWidth: 'none',
                                    centerText: false,
                                    maxWidth: 385,
                                    width: '100%',
                                    fontWeight: 700,
                                    marginLeft: 85
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Bullet',
                                name: 'Bullet',
                                props: {
                                    align: 'center',
                                    isChecklist: true,
                                    text1: 'Deep Spiritual Insights',
                                    text2: 'Personal Transformation',
                                    text3: 'Community Connection',
                                    description1: 'Gain profound wisdom and insights.',
                                    description2: 'Experience positive shifts in your life.',
                                    description3: 'Join a like-minded global community.',
                                    visibility1: true,
                                    visibility2: true,
                                    visibility3: true,
                                    visibility5: true,
                                    color: '#FFFFFF',
                                    iconColor: '#FFFFFF',
                                    lineHeight: 15,
                                    fontFamily: 'SourceSansPro',
                                    fontSize: 16,
                                    visibility: true,
                                    availableDelete: true,
                                    maxWidth: 400,
                                    iconType: 'circle',
                                    circleWidth: 11,
                                    circleHeight: 11,
                                    fontWeight: 700,
                                    descriptionColor: '#A7BBB8'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'ButtonMi',
                                name: 'Call to Action',
                                props: {
                                    text: 'Start Your Free 7-Day Trial Journey',
                                    bgColor: '#FFFFFF',
                                    textColor: '#24554E',
                                    blanked: false,
                                    size: 'medium',
                                    href: '',
                                    borderColor: '#24554E',
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                    fontFamily: 'Roboto-Medium',
                                    bordered: true,
                                    borderRadius: 10,
                                    linked: true,
                                    alignment: 'left',
                                    marginBottom: 10,
                                    marginTop: 48,
                                    marginLeft: 0,
                                    marginRight: 10,
                                    fontWeight: 700,
                                    textSize: 16,
                                    className: 'feature__button',
                                    height: '56px'
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'One Column New',
                        props: {
                            text: 'Component Props',
                            className: 'add_features_section_image temp23_add_features_section_image'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Image',
                                name: 'Image',
                                props: {
                                    imgUrl: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/featured.png',
                                    visibility: true,
                                    // width: 704,
                                    // height: 825
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "Text with Video",
        data: [
            {
                section: {
                    slug: slug(),
                    name: "Text with Video",
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/section_with_video.png"
                            alt="heroNew"
                            width="100%"
                            height={164}
                        />
                    ),
                    props: {
                        order: 4,
                        duplicated: 'Text with video',
                        visibility: true,
                        bgVideoSrc: '',
                        bgImgSrc: '',
                        bgColor: '#121212',
                        background_type: 'color',
                        spacing: 'lg',
                        bgColorOverlay: '#171C28',
                        paddingTop: 80,
                        paddingBottom: 80
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Two Column',
                        props: {
                            text: 'Component Props'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'center',
                                    text: '<span>Play Anytime, Anywhere</span>',
                                    color: '#ffffff',
                                    lineHeight: 10,
                                    fontFamily: 'Cinzel',
                                    fontSize: 48,
                                    mobileFontSize: 28,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    alignmentAvalaible: true,
                                    centerText: true,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Your spiritual retreat, anytime: accessible wisdom for your journey.</span>',
                                    color: '#b8b8b8',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 20,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 400,
                                    className: 'anytime__subtitle'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Video',
                                name: 'Video',
                                props: {
                                    src: 'https://www.youtube.com/embed/RVjxFLTCngw',
                                    widthWithPercent: 'auto',
                                    height: 612,
                                    spacing: 'xs',
                                    visibility: true,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    className: 'text_and_video_video'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "Text + Video Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: "Text Video Section",
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/text_video_section.png"
                            alt="heroNew"
                            width="100%"
                            height={123}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: "Text Video Section",
                        visibility: true,
                        bgVideoSrc: "",
                        bgImgSrc: "",
                        bgColor: "#ffffff",
                        background_type: "color",
                        spacing: "lg",
                        bgColorOverlay: "#ffffff",
                        paddingTop: 80,
                        paddingBottom: 80
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: "component",
                        name: "One Column New",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Title",
                                props: {
                                    align: "left",
                                    text: "<span>EMBARK ON YOUR SPIRITUAL JOURNEY TODAY</span>",
                                    color: "#000000",
                                    lineHeight: 10,
                                    fontFamily: "Cinzel",
                                    fontSize: 40,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    maxWidth: "none",
                                    centerText: false,
                                    width: "100",
                                    contentWidth: "100%",
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Ready to elevate? Make the first step towards becoming the best version of yourself you can be.</span>",
                                    color: "#000000",
                                    lineHeight: 10,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    maxWidth: "none",
                                    centerText: false,
                                    width: "100",
                                    contentWidth: "100%",
                                }
                            },
                            {
                                slug: slug(),
                                type: "ButtonMi",
                                name: "Call to Action",
                                props: {
                                    text: "Start Free 7-Day Trial",
                                    bgColor: "#24554E",
                                    textColor: "#ffffff",
                                    blanked: false,
                                    size: "medium",
                                    href: "",
                                    borderColor: "#24554E",
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                    bordered: true,
                                    borderRadius: "10",
                                    linked: true,
                                    alignment: "left",
                                    marginBottom: 10,
                                    marginTop: 10,
                                    fontFamily: 'Roboto-Medium',
                                    marginLeft: 0,
                                    marginRight: 0,
                                    contentWidth: "48%",
                                    textSize: "20px",
                                    className: "section_button",
                                }
                            },
                            {
                                slug: slug(),
                                type: "ButtonMi",
                                name: "Call to Action",
                                props: {
                                    text: "Explore Featured Content",
                                    bgColor: "#ffffff",
                                    textColor: "#24554E",
                                    blanked: false,
                                    size: "medium",
                                    href: "",
                                    borderColor: "#24554E",
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                    bordered: true,
                                    fontFamily: 'Roboto-Medium',
                                    borderRadius: "10",
                                    linked: true,
                                    alignment: "left",
                                    marginBottom: 10,
                                    marginTop: 10,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    contentWidth: "48%",
                                    textSize: "20px",
                                    className: "section_button",
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: "component",
                        name: "Two Column",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Video",
                                name: "Video",
                                props: {
                                    src:
                                        "https://www.youtube.com/embed/RVjxFLTCngw",
                                    width: "500",
                                    height: "300",
                                    spacing: "xs",
                                    visibility: true,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "Text + Image Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: "Text Image Section",
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/text_image_section.png"
                            alt="heroNew"
                            width="100%"
                            height={123}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: "Text Image Section",
                        visibility: true,
                        bgVideoSrc: "",
                        bgImgSrc: "",
                        bgColor: "#ffffff",
                        background_type: "color",
                        spacing: "lg",
                        bgColorOverlay: "#ffffff",
                        paddingTop: 80,
                        paddingBottom: 80
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: "component",
                        name: "One Column New",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Title",
                                props: {
                                    align: "left",
                                    text: "<span>EMBARK ON YOUR SPIRITUAL JOURNEY TODAY</span>",
                                    color: "#000000",
                                    lineHeight: 10,
                                    fontFamily: "Cinzel",
                                    fontSize: 40,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    maxWidth: "none",
                                    centerText: false,
                                    width: "100",
                                    contentWidth: "100%",
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Ready to elevate? Make the first step towards becoming the best version of yourself you can be.</span>",
                                    color: "#000000",
                                    lineHeight: 10,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    maxWidth: "none",
                                    centerText: false,
                                    width: "100",
                                    contentWidth: "100%",
                                }
                            },
                            {
                                slug: slug(),
                                type: "ButtonMi",
                                name: "Call to Action",
                                props: {
                                    text: "Start Free 7-Day Trial",
                                    bgColor: "#24554E",
                                    textColor: "#ffffff",
                                    blanked: false,
                                    size: "medium",
                                    href: "",
                                    linked: true,
                                    alignment: "left",
                                    borderColor: "#24554E",
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                    bordered: true,
                                    borderRadius: "10",
                                    marginBottom: 10,
                                    marginTop: 10,
                                    fontFamily: 'Roboto-Medium',
                                    marginLeft: 0,
                                    marginRight: 0,
                                    contentWidth: "48%",
                                    textSize: "20px",
                                    className: "section_button",
                                }
                            },
                            {
                                slug: slug(),
                                type: "ButtonMi",
                                name: "Call to Action",
                                props: {
                                    text: "Explore Featured Content",
                                    bgColor: "#ffffff",
                                    textColor: "#24554E",
                                    blanked: false,
                                    size: "medium",
                                    href: "",
                                    linked: true,
                                    bordered: true,
                                    fontFamily: 'Roboto-Medium',
                                    borderColor: "#24554E",
                                    borderRadius: "10",
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                    alignment: "left",
                                    marginBottom: 10,
                                    marginTop: 10,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    contentWidth: "48%",
                                    textSize: "20px",
                                    className: "section_button",
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: "component",
                        name: "Two Column",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Image",
                                name: "Image",
                                props: {
                                    imgUrl: "https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/screen_storke.png",
                                    width: "415",
                                    height: "230",
                                    borderRadius: "5",
                                    visibility: true
                                }
                            },
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "Text Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: "Text Section",
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/text_section.png"
                            alt="heroNew"
                            width="100%"
                            height={123}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: "Text Section",
                        visibility: true,
                        bgVideoSrc: "",
                        bgImgSrc: "",
                        bgColor: "#ffffff",
                        background_type: "color",
                        spacing: "lg",
                        bgColorOverlay: "#ffffff",
                        paddingTop: 80,
                        paddingBottom: 80
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: "component",
                        name: "One Column New",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Title",
                                props: {
                                    align: "center",
                                    text: "<span>EMBARK ON YOUR SPIRITUAL JOURNEY TODAY</span>",
                                    color: "#000000",
                                    lineHeight: 10,
                                    fontFamily: "Cinzel",
                                    fontSize: 40,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    maxWidth: "none",
                                    centerText: false,
                                    width: "100",
                                    contentWidth: "100%",
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "center",
                                    text:
                                        "<span>Ready to elevate? Make the first step towards becoming the best version of yourself you can be.</span>",
                                    color: "#000000",
                                    lineHeight: 10,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    maxWidth: "none",
                                    centerText: false,
                                    width: "100",
                                    contentWidth: "100%",
                                }
                            },
                            {
                                slug: slug(),
                                type: "ButtonMi",
                                name: "Call to Action",
                                props: {
                                    text: "Start Free 7-Day Trial",
                                    bgColor: "#24554E",
                                    textColor: "#ffffff",
                                    blanked: false,
                                    size: "medium",
                                    href: "",
                                    borderColor: "#24554E",
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                    bordered: true,
                                    borderRadius: "10",
                                    linked: true,
                                    alignment: "right",
                                    fontFamily: 'Roboto-Medium',
                                    marginBottom: 10,
                                    marginTop: 10,
                                    marginLeft: 0,
                                    marginRight: 20,
                                    contentWidth: "49%",
                                    textSize: "22px",
                                }
                            },
                            {
                                slug: slug(),
                                type: "ButtonMi",
                                name: "Call to Action",
                                props: {
                                    text: "Explore Featured Content",
                                    bgColor: "#ffffff",
                                    textColor: "#24554E",
                                    blanked: false,
                                    size: "medium",
                                    href: "",
                                    borderColor: "#24554E",
                                    fontFamily: 'Roboto-Medium',
                                    borderStyle: "solid",
                                    borderWidth: "2px",
                                    bordered: true,
                                    borderRadius: "10",
                                    linked: true,
                                    alignment: "left",
                                    marginBottom: 10,
                                    marginTop: 10,
                                    marginLeft: 20,
                                    marginRight: 0,
                                    contentWidth: "51%",
                                    textSize: "22px",
                                }
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        name: "Testimonials Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: "Testimonials",
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/testimonial.png"
                            alt="heroNew"
                            width="100%"
                            height={145}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: 'Testimonials Section',
                        visibility: true,
                        bgVideoSrc: '',
                        bgImgSrc: '',
                        bgColor: '#E8F2F1',
                        background_type: 'color',
                        spacing: 'not',
                        bgColorOverlay: '#E8F2F1',
                        paddingLeft: 0,
                        paddingRight: 0
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'One Column New',
                        props: {
                            text: 'Component Props',
                            bgImgSrc: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/rectangle.png',
                            isType: 'bgImgEditable',
                            settingName: 'Left Section'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Image',
                                name: 'Image',
                                props: {
                                    imgUrl: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/landing23/testimonials__brekets.png',
                                    visibility: true,
                                    className: 'testimonials-brekets'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'left',
                                    text: '<span>WHAT OUR HAPPY MEMBERS SAY</span>',
                                    color: '#ffffff',
                                    lineHeight: 14,
                                    fontFamily: 'Cinzel',
                                    fontSize: 40,
                                    mobileFontSize: 28,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 700,
                                    borderHover: true
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'left',
                                    text: '<span>Voices of Transformation</span>',
                                    color: '#e7e9e9',
                                    lineHeight: 20,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 24,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 400,
                                    className: 'testimonials__subtitle',
                                    borderHover: true
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'One Column New',
                        props: {
                            text: 'Component Props',
                            bgColor: '#ffffff',
                            settingName: 'Right Section'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'UserInfo',
                                name: 'Section Options',
                                avatarWithText: true,
                                props: {
                                    textProps: {
                                        slug: slug(),
                                        align: 'left',
                                        text: '<span>Marley Dokidis</span>',
                                        color: '#000000',
                                        fontFamily: 'Source sans pro, sans-serif',
                                        fontSize: 24,
                                        mobileFontSize: 22,
                                        maxWidth: 'none',
                                        centerText: false,
                                        width: '100',
                                        fontWeight: 400,
                                        lineheight: 13,
                                        name: 'Title'
                                    },
                                    subtitleProps: {
                                        slug: slug(),
                                        align: 'left',
                                        text: '<span>Business Consultant, New York</span>',
                                        color: '#727978',
                                        fontFamily: 'Source sans pro, sans-serif',
                                        fontSize: 18,
                                        maxWidth: 'none',
                                        centerText: false,
                                        width: '100',
                                        fontWeight: 400,
                                        lineheight: 13,
                                        className: 'image__avatar__subtitle',
                                        name: 'Subtitle'
                                    },
                                    avatarImgProps: {
                                        imgUrl: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/landing23/testimonials__avatar.png',
                                        name: 'Image',
                                        slug: slug(),
                                        visibility: true,
                                        className: 'avatar__image'
                                    }
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Description',
                                props: {
                                    align: 'left',
                                    text: '<p>Joining OmniVibe Wisdom Hub has been a life-changing experience. The teachings resonate deeply, and the community support is invaluable.</p>',
                                    color: '#444C4B',
                                    lineHeight: 20,
                                    fontFamily: 'Source sans pro, sans-serif',
                                    fontSize: 20,
                                    maxWidth: 'none',
                                    centerText: false,
                                    width: '100',
                                    fontWeight: 400,
                                    className: 'avatar__description',
                                    name: 'Description',
                                    borderHover: true
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "FAQ Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: "FAQ",
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/faq_section.png"
                            alt="heroNew"
                            width="100%"
                            height={145}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: "FAQ Section",
                        visibility: true,
                        bgVideoSrc: "",
                        bgColor: "#27282B",
                        background_type: "image",
                        imageOverlay: true,
                        alignmentAvalaible: true,
                        alignment: "center",
                        bgColorOverlay: "#27282B",
                        paddingTop: 60,
                        paddingBottom: 60,
                        paddingRight: 176,
                        paddingLeft: 176,
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'FAQ title',
                        props: {
                            text: 'FAQ title',
                            className: 'faq_secton_heading'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'center',
                                    text: '<span>FREQUENTLY ASKED QUESTIONS</span>',
                                    color: '#ffffff',
                                    visibility: true,
                                    isFaqQuestion: true,
                                    label: 'Show title',
                                    lineHeight: 10,
                                    fontFamily: 'Cinzel',
                                    fontSize: 48,
                                    mobileFontSize: 28,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 700,
                                    paddingBottom: 24
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'center',
                                    text: '<span>Your Questions Answered</span>',
                                    color: '#bebebf',
                                    visibility: true,
                                    isFaqQuestion: true,
                                    label: 'Show title',
                                    lineHeight: 10,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 20,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 400
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Two Column New',
                        props: {
                            text: 'QuestionAnswer',
                            className: 'faq_secton_questions_column',
                            isFaq: true
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'FaqElement',
                                name: 'Faq Element',
                                props: {
                                    marginTop: 0,
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    marginRight: 0,
                                    alignment: 'left',
                                    paddingTop: 24,
                                    paddingLeft: 24,
                                    paddingBottom: 24,
                                    paddingRight: 24,
                                    backColor: '#27282B',
                                    openedBackColor: '#121212',
                                    iconColor: '#ffffff',
                                    borderColor: '#525355',
                                    borderRadius: 20,
                                    title: '<span>How do I access the content?</span>',
                                    titleColor: '#ffffff',
                                    titleFontFamily: 'Roboto-Medium',
                                    titleFontSize: 18,
                                    titleLineHeight: 20,
                                    text: '<span>Our platform is web-based. Once you subscribe, you can log in from any device.</span>',
                                    textColor: '#b8b8b8',
                                    textFontFamily: 'Roboto-Regular',
                                    textFontSize: 14,
                                    textLineHeight: 18
                                }
                            },
                            {
                                slug: slug(),
                                type: 'FaqElement',
                                name: 'Faq Element',
                                props: {
                                    marginTop: 0,
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    marginRight: 0,
                                    alignment: 'left',
                                    paddingTop: 24,
                                    paddingLeft: 24,
                                    paddingBottom: 24,
                                    paddingRight: 24,
                                    backColor: '#27282B',
                                    openedBackColor: '#121212',
                                    iconColor: '#ffffff',
                                    borderColor: '#525355',
                                    borderRadius: 20,
                                    title: '<span>Can I cancel my subscription anytime?</span>',
                                    titleColor: '#ffffff',
                                    titleFontFamily: 'Roboto-Medium',
                                    titleFontSize: 18,
                                    titleLineHeight: 20,
                                    text: '<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel sollicitudin enim. Ut nibh eros, maximus sit amet dui sed, viverra venenatis ipsum. Aliquam mi nulla, luctus in lacus laoreet.</span>',
                                    textColor: '#b8b8b8',
                                    textFontFamily: 'Roboto-Regular',
                                    textFontSize: 14,
                                    textLineHeight: 18
                                }
                            },
                            {
                                slug: slug(),
                                type: 'FaqElement',
                                name: 'Faq Element',
                                props: {
                                    marginTop: 0,
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    marginRight: 0,
                                    alignment: 'left',
                                    paddingTop: 24,
                                    paddingLeft: 24,
                                    paddingBottom: 24,
                                    paddingRight: 24,
                                    backColor: '#27282B',
                                    openedBackColor: '#121212',
                                    iconColor: '#ffffff',
                                    borderColor: '#525355',
                                    borderRadius: 20,
                                    title: '<span>What happens after I subscribe?</span>',
                                    titleColor: '#ffffff',
                                    titleFontFamily: 'Roboto-Medium',
                                    titleFontSize: 18,
                                    titleLineHeight: 20,
                                    text: '<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel sollicitudin enim. Ut nibh eros, maximus sit amet dui sed, viverra venenatis ipsum. Aliquam mi nulla, luctus in lacus laoreet.</span>',
                                    textColor: '#b8b8b8',
                                    textFontFamily: 'Roboto-Regular',
                                    textFontSize: 14,
                                    textLineHeight: 18
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Two Column New',
                        props: {
                            text: 'QuestionAnswer',
                            className: 'faq_secton_questions_column',
                            isFaq: true
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'FaqElement',
                                name: 'Faq Element',
                                props: {
                                    marginTop: 0,
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    marginRight: 0,
                                    alignment: 'left',
                                    paddingTop: 24,
                                    paddingLeft: 24,
                                    paddingBottom: 24,
                                    paddingRight: 24,
                                    backColor: '#27282B',
                                    openedBackColor: '#121212',
                                    iconColor: '#ffffff',
                                    borderColor: '#525355',
                                    borderRadius: 20,
                                    title: '<span>Is there a free trial?</span>',
                                    titleColor: '#ffffff',
                                    titleFontFamily: 'Roboto-Medium',
                                    titleFontSize: 18,
                                    titleLineHeight: 20,
                                    text: '<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel sollicitudin enim. Ut nibh eros, maximus sit amet dui sed, viverra venenatis ipsum. Aliquam mi nulla, luctus in lacus laoreet.</span>',
                                    textColor: '#b8b8b8',
                                    textFontFamily: 'Roboto-Regular',
                                    textFontSize: 14,
                                    textLineHeight: 18
                                }
                            },
                            {
                                slug: slug(),
                                type: 'FaqElement',
                                name: 'Faq Element',
                                props: {
                                    marginTop: 0,
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    marginRight: 0,
                                    alignment: 'left',
                                    paddingTop: 24,
                                    paddingLeft: 24,
                                    paddingBottom: 24,
                                    paddingRight: 24,
                                    backColor: '#27282B',
                                    openedBackColor: '#121212',
                                    iconColor: '#ffffff',
                                    borderColor: '#525355',
                                    borderRadius: 20,
                                    title: '<span>Are there community discussions?</span>',
                                    titleColor: '#ffffff',
                                    titleFontFamily: 'Roboto-Medium',
                                    titleFontSize: 18,
                                    titleLineHeight: 20,
                                    text: '<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vel sollicitudin enim. Ut nibh eros, maximus sit amet dui sed, viverra venenatis ipsum. Aliquam mi nulla, luctus in lacus laoreet.</span>',
                                    textColor: '#b8b8b8',
                                    textFontFamily: 'Roboto-Regular',
                                    textFontSize: 14,
                                    textLineHeight: 18
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "Pricing Section",
        data: [
            {
                section: {
                    slug: slug(),
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/pricing_section.png"
                            alt="heroNew"
                            width="100%"
                            height={146}
                        />
                    ),
                    name: "Pricing",
                    props: {
                        order: "",
                        duplicated: 'Pricing Section',
                        visibility: true,
                        bgVideoSrc: '',
                        bgImgSrc: '',
                        bgColor: '#171C28',
                        background_type: 'color',
                        reverseAvailaible: true,
                        reversed: false,
                        paddingTop: 102,
                        paddingBottom: 102,
                        bgColorOverlay: '#171C28'
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'One Column New',
                        props: {
                            text: 'Component Props',
                            className: 'content-section-header'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'center',
                                    text: '<span>CHOOSE YOUR PATH TO ENLIGHTENMENT</span>',
                                    color: '#ffffff',
                                    lineHeight: 10,
                                    fontFamily: 'Cinzel',
                                    fontSize: 48,
                                    mobileFontSize: 28,
                                    maxWidth: 'none',
                                    centerText: true,
                                    paddingBottom: 20,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'center',
                                    text: '<span>Your journey, your investment: transparent pricing for spiritual awakening</span>',
                                    color: '#b8bcbb',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 20,
                                    maxWidth: 'none',
                                    centerText: true,
                                    paddingBottom: 20,
                                    fontWeight: 400
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props',
                            className: 'pricing_card',
                            bgColor: '#27282b',
                            maxWidth: '342px',
                            width: '100%',
                            padding: '20px 24px'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>$99.00</span>',
                                    color: '#ffffff',
                                    lineHeight: 5,
                                    fontFamily: 'Cinzel',
                                    paddingTop: 50,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: true,
                                    fontSize: 56,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Basic Wisdom</span>',
                                    color: '#ffffff',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 18,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: true,
                                    fontWeight: 600
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Bullet',
                                name: 'Bullet',
                                props: {
                                    align: 'center',
                                    isChecklist: true,
                                    text1: 'Access to featured content',
                                    text2: 'Community access',
                                    text3: 'Monthly insights newsletter',
                                    visibility1: true,
                                    visibility2: true,
                                    visibility3: true,
                                    color: '#ffffff',
                                    iconColor: '#ffffff',
                                    lineHeight: 15,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 16,
                                    visibility: true,
                                    availableDelete: true,
                                    maxWidth: 400,
                                    iconType: 'circle',
                                    fontWeight: 400,
                                    hideDescription: true,
                                    circleWidth: 6,
                                    circleHeight: 6,
                                    paddingBottom: 24,
                                    className: 'pricing_card_description'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Divider',
                                name: 'Divider',
                                props: {
                                    width: 100,
                                    lineThickness: 1,
                                    borderStyle: 'solid',
                                    textBgColor: '#3d3e40'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'ButtonMi',
                                name: 'Call to Action',
                                props: {
                                    text: 'Buy Now',
                                    bgColor: '#24554E',
                                    textColor: '#ffffff',
                                    widthChangeable: true,
                                    width: 'full',
                                    blanked: false,
                                    size: 'medium',
                                    href: '',
                                    borderColor: '#24554E',
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                    bordered: true,
                                    borderRadius: 10,
                                    linked: true,
                                    alignment: 'left',
                                    marginBottom: 24,
                                    marginTop: 24,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    alignment: 'center',
                                    fontFamily: 'Roboto-Medium',
                                    textSize: 16,
                                    height: '56px'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'center',
                                    text: '<span>Preview Before Purchase</span>',
                                    color: '#E7E9E9',
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 16,
                                    maxWidth: 'none',
                                    centerText: true,
                                    paddingBottom: 20,
                                    fontWeight: 700
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props',
                            className: 'pricing_card',
                            bgColor: '#27282b',
                            maxWidth: '342px',
                            width: '100%',
                            padding: '20px 24px'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>$299.00</span>',
                                    color: '#ffffff',
                                    lineHeight: 5,
                                    fontFamily: 'Cinzel',
                                    paddingTop: 50,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: true,
                                    fontSize: 56,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Enlightenment Plus</span>',
                                    color: '#ffffff',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 18,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: true,
                                    fontWeight: 600
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Bullet',
                                name: 'Bullet',
                                props: {
                                    align: 'center',
                                    isChecklist: true,
                                    text1: 'All Basic Wisdom features',
                                    text2: 'Exclusive monthly webinars',
                                    text3: 'Guided meditation series',
                                    visibility1: true,
                                    visibility2: true,
                                    visibility3: true,
                                    color: '#ffffff',
                                    iconColor: '#ffffff',
                                    lineHeight: 15,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 16,
                                    visibility: true,
                                    availableDelete: true,
                                    maxWidth: 400,
                                    iconType: 'circle',
                                    fontWeight: 400,
                                    hideDescription: true,
                                    circleWidth: 6,
                                    circleHeight: 6,
                                    paddingBottom: 24,
                                    className: 'pricing_card_description'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Divider',
                                name: 'Divider',
                                props: {
                                    width: 100,
                                    lineThickness: 1,
                                    borderStyle: 'solid',
                                    textBgColor: '#3d3e40'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'ButtonMi',
                                name: 'Call to Action',
                                props: {
                                    text: 'Buy Now',
                                    widthChangeable: true,
                                    width: 'full',
                                    bgColor: '#24554E',
                                    textColor: '#ffffff',
                                    blanked: false,
                                    size: 'medium',
                                    href: '',
                                    borderColor: '#24554E',
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                    bordered: true,
                                    borderRadius: 10,
                                    linked: true,
                                    alignment: 'left',
                                    marginBottom: 24,
                                    marginTop: 24,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    alignment: 'center',
                                    fontFamily: 'Roboto-Medium',
                                    textSize: 16,
                                    height: '56px'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'center',
                                    text: '<span>Preview Before Purchase</span>',
                                    color: '#E7E9E9',
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 16,
                                    maxWidth: 'none',
                                    centerText: true,
                                    paddingBottom: 20,
                                    fontWeight: 700
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props',
                            className: 'pricing_card',
                            bgColor: '#24554E',
                            maxWidth: '342px',
                            width: '100%',
                            padding: '20px 24px'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>$499.00</span>',
                                    color: '#ffffff',
                                    lineHeight: 5,
                                    fontFamily: 'Cinzel',
                                    fontSize: 56,
                                    paddingTop: 50,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: true,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Infinite Wisdom</span>',
                                    color: '#ffffff',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 18,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: true,
                                    fontWeight: 600
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Bullet',
                                name: 'Bullet',
                                props: {
                                    align: 'center',
                                    isChecklist: true,
                                    text1: 'All Enlightenment Plus features',
                                    text2: 'One-on-one video session with instructor',
                                    text3: 'Priority support',
                                    visibility1: true,
                                    visibility2: true,
                                    visibility3: true,
                                    color: '#ffffff',
                                    iconColor: '#ffffff',
                                    lineHeight: 15,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 16,
                                    visibility: true,
                                    availableDelete: true,
                                    maxWidth: 400,
                                    iconType: 'circle',
                                    fontWeight: 400,
                                    hideDescription: true,
                                    circleWidth: 6,
                                    circleHeight: 6,
                                    paddingBottom: 24,
                                    className: 'pricing_card_description'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Divider',
                                name: 'Divider',
                                props: {
                                    width: 100,
                                    lineThickness: 1,
                                    borderStyle: 'solid',
                                    textBgColor: '#4b736c'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'ButtonMi',
                                name: 'Call to Action',
                                props: {
                                    text: 'Buy Now',
                                    widthChangeable: true,
                                    width: 'full',
                                    bgColor: '#ffffff',
                                    textColor: '#24554E',
                                    blanked: false,
                                    size: 'medium',
                                    href: '',
                                    borderColor: '#ffffff',
                                    borderStyle: 'solid',
                                    borderWidth: '2px',
                                    bordered: true,
                                    borderRadius: 10,
                                    linked: true,
                                    alignment: 'left',
                                    marginTop: 24,
                                    marginBottom: 24,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    alignment: 'center',
                                    textSize: 16,
                                    height: '56px'
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'center',
                                    text: '<span>Preview Before Purchase</span>',
                                    color: '#E7E9E9',
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 16,
                                    maxWidth: 'none',
                                    centerText: true,
                                    paddingBottom: 20,
                                    fontWeight: 700
                                }
                            }
                        ]
                    }                    
                ]
            }
        ]
    },
    {
        name: "Three Cards Section",
        data: [
            {
                section: {
                    slug: slug(),
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/three_card_section.png"
                            alt="heroNew"
                            width="100%"
                            height={103}
                        />
                    ),
                    name: "Three Cards",
                    props: {
                        order: "",
                        duplicated: 'Three Cards Section',
                        visibility: true,
                        bgVideoSrc: '',
                        bgImgSrc: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/three_cards_section_big.png',
                        background_type: 'image',
                        imageOverlay: true,
                        alignmentAvalaible: true,
                        spacing: 'md',
                        reverseAvailaible: true,
                        reversed: false,
                        bgColor: '#000000',
                        bgColorOverlay: '#000000'
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Image',
                                name: 'Image',
                                props: {
                                    align: 'left',
                                    imgUrl: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/landing23/icon_1.png',
                                    width: '33',
                                    visibility: true
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Video Content</span>',
                                    color: '#131F1E',
                                    lineHeight: 18,
                                    fontFamily: 'Cinzel',
                                    fontSize: 32,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Dive into a vast library of teachings.</span>',
                                    color: '#444C4B',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 14,
                                    maxWidth: 'none',
                                    centerText: false,
                                    paddingBottom: 32,
                                    fontWeight: 400
                                }
                            }
                        ]
                    },                    
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Image',
                                name: 'Image',
                                props: {
                                    align: 'left',
                                    imgUrl: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/landing23/icon_2.png',
                                    width: '33',
                                    visibility: true
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Quick Guide</span>',
                                    color: '#131F1E',
                                    lineHeight: 18,
                                    fontFamily: 'Cinzel',
                                    fontSize: 32,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Access concise and powerful insights.</span>',
                                    color: '#444C4B',
                                    lineHeight: 18,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 14,
                                    maxWidth: 'none',
                                    centerText: false,
                                    paddingBottom: 32,
                                    fontWeight: 400
                                }
                            }
                        ]
                    },                                       
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Four Column',
                        props: {
                            text: 'Component Props'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Image',
                                name: 'Image',
                                props: {
                                    align: 'left',
                                    imgUrl: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/landing23/icon_3.png',
                                    width: '33',
                                    visibility: true
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Access Anytime</span>',
                                    color: '#131F1E',
                                    lineHeight: 18,
                                    fontFamily: 'Cinzel',
                                    fontSize: 32,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Connect with wisdom whenever you need it.</span>',
                                    color: '#444C4B',
                                    lineHeight: 12,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 14,
                                    maxWidth: 'none',
                                    centerText: false,
                                    paddingBottom: 41,
                                    fontWeight: 400
                                }
                            }
                        ]
                    }                    
                ]
            }
        ]
    },
    {
        name: "Platform Section",
        data: [
            {
                section: {
                    slug: slug(),
                    name: "Platform",
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/platform_section.png"
                            alt="heroNew"
                            width="100%"
                            height={157}
                        />
                    ),
                    props: {
                        order: "",
                        duplicated: 'Platform Section',
                        visibility: true,
                        bgVideoSrc: '',
                        bgImgSrc: '',
                        bgColor: '#121212',
                        background_type: 'color',
                        spacing: 'lg',
                        bgColorOverlay: '#121212',
                        paddingTop: 60,
                        paddingBottom: 0
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: 'component',
                        name: 'Two Column',
                        props: {
                            text: 'Component Props'
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Title',
                                props: {
                                    align: 'center',
                                    text: '<span>Seamless Experience, Anywhere You Go</span>',
                                    color: '#ffffff',
                                    lineHeight: 10,
                                    fontFamily: 'Cinzel',
                                    fontSize: 48,
                                    mobileFontSize: 28,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    alignmentAvalaible: true,
                                    centerText: true,
                                    fontWeight: 700
                                }
                            },
                            {
                                slug: slug(),
                                type: 'Text',
                                name: 'Text',
                                props: {
                                    align: 'left',
                                    text: '<span>Access your spiritual journey on your phone, tablet, or computer.</span>',
                                    color: '#b8b8b8',
                                    lineHeight: 16,
                                    fontFamily: 'SourceSansPro-SemiBold',
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: 'none',
                                    centerText: false
                                }
                            },
                            {
                                slug: slug(),
                                type: 'ImagePlatform',
                                name: 'Image Platform',
                                props: {
                                    bgColor: '#36796F4D',
                                    imgUrl: 'https://miestro-production.s3.us-west-2.amazonaws.com/landing/landing23/group_1.png',
                                    visibility: true
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: "Gallery Showcase Section",
        data: [
            {
                section: {
                    slug: slug(),
                    icon: (
                        <img
                            src="https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/gallery_showcase_section.png"
                            alt="heroNew"
                            width="100%"
                            height={112}
                        />
                    ),
                    name: "Gallery Showcase Section",
                    props: {
                        order: "",
                        duplicated: "Gallery Showcase Section",
                        visibility: true,
                        bgVideoSrc: "",
                        bgImgSrc: "",
                        bgColor: "#171C28",
                        background_type: "color",
                        spacing: "md",
                        reverseAvailaible: true,
                        reversed: false,
                        bgColorOverlay: "#171C28"
                    }
                },
                components: [
                    {
                        slug: slug(),
                        type: "component",
                        name: "One Column New",
                        props: {
                            text: "Component Props",
                            className: "content-section-header"
                        },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Title",
                                props: {
                                    align: "center",
                                    text: "<span>DIVE INTO ENLIGHTENING WISDOM</span>",
                                    color: "#ffffff",
                                    lineHeight: 10,
                                    fontFamily: "Cinzel",
                                    fontSize: 40,
                                    maxWidth: "none",
                                    centerText: true,
                                    paddingBottom: 20
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "center",
                                    text:
                                        "<span>Explore a library of transformative teachings and guided meditations.</span>",
                                    color: "#ffffff",
                                    lineHeight: 10,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    maxWidth: "none",
                                    centerText: true,
                                    paddingBottom: 20
                                }
                            },
                        ]
                    },
                    {
                        slug: slug(),
                        type: "component",
                        name: "Four Column",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Image",
                                name: "Image",
                                props: {
                                    imgUrl: "https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/cs1.png",
                                    width: "100",
                                    radius: "12",
                                    visibility: true
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Wisdom Of The Ages</span>",
                                    color: "#ffffff",
                                    lineHeight: 12,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Delve into timeless wisdom and teachings with our curated collection, embracing the profound insights of spiritual leaders throughout history.</span>",
                                    color: "#B9BBBE",
                                    lineHeight: 20,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    maxHeight: "none",
                                    centerText: false,
                                    ellipsis: '2.6em',
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Read More</span>",
                                    color: "#ffffff",
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 18,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: "component",
                        name: "Four Column",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Image",
                                name: "Image",
                                props: {
                                    imgUrl: "https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/cs2.png",
                                    width: "100%",
                                    radius: "12",
                                    visibility: true
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Mindful Presence</span>",
                                    color: "#ffffff",
                                    lineHeight: 12,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Immerse yourself in the practice of mindfulness and presence, drawing inspiration from leaders who guide the way to a centered and conscious life.</span>",
                                    color: "#B9BBBE",
                                    lineHeight: 20,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    maxHeight: "none",
                                    centerText: false,
                                    ellipsis: '2.6em',
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Read More</span>",
                                    color: "#ffffff",
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 18,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: "component",
                        name: "Four Column",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Image",
                                name: "Image",
                                props: {
                                    imgUrl: "https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/cs3.png",
                                    width: "100%",
                                    radius: "12",
                                    visibility: true
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Transcendent Teachings</span>",
                                    color: "#ffffff",
                                    lineHeight: 12,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Explore the transcendent teachings of spiritual leaders, offering profound perspectives on life, purpose, and the interconnectedness of all beings.</span>",
                                    color: "#B9BBBE",
                                    lineHeight: 20,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    maxHeight: "none",
                                    centerText: false,
                                    ellipsis: '2.6em',
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Read More</span>",
                                    color: "#ffffff",
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 18,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            }
                        ]
                    },
                    {
                        slug: slug(),
                        type: "component",
                        name: "Four Column",
                        props: { text: "Component Props" },
                        subcomponents: [
                            {
                                slug: slug(),
                                type: "Image",
                                name: "Image",
                                props: {
                                    imgUrl: "https://miestro-production.s3.us-west-2.amazonaws.com/landing/new-sections/cs4.png",
                                    width: "100%",
                                    radius: "12",
                                    visibility: true
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Soulful Reflections</span>",
                                    color: "#ffffff",
                                    lineHeight: 12,
                                    fontFamily: 'Roboto-Medium',
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Embark on a journey of self-discovery and soulful reflection, guided by the wisdom of spiritual leaders who inspire inner peace and enlightenment.</span>",
                                    color: "#B9BBBE",
                                    lineHeight: 20,
                                    fontFamily: "SourceSansPro-SemiBold",
                                    fontSize: 18,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    maxHeight: "none",
                                    centerText: false,
                                    ellipsis: '2.6em',
                                }
                            },
                            {
                                slug: slug(),
                                type: "Text",
                                name: "Text",
                                props: {
                                    align: "left",
                                    text:
                                        "<span>Read More</span>",
                                    color: "#ffffff",
                                    lineHeight: 18,
                                    fontFamily: 'Roboto-Regular',
                                    fontSize: 18,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    maxWidth: "none",
                                    centerText: false
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
];

const Landings_added = [
    //  HERO  SECTIONS DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Hero Section",
            props: {
                order: "",
                duplicated: "Hero Section",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#000000",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#000000",
                paddingBottom: 80,
                paddingTop: 80
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "One Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>DEFAULT TITLE</span>",
                            color: "#ffffff",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 40,
                            paddingBottom: 10,
                            paddingTop: 10,
                            maxWidth: "none",
                            centerText: true
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "center",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#ffffff",
                            lineHeight: 10,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingBottom: 10,
                            paddingTop: 10,
                            maxWidth: "none",
                            centerText: true
                        }
                    },
                    {
                        slug: slug(),
                        type: "ButtonMi",
                        name: "Call to Action",
                        props: {
                            text: "Default Button",
                            bgColor: "#7cb740",
                            textColor: "#ffffff",
                            blanked: false,
                            size: "medium",
                            href: "",
                            borderColor: "#24554E",
                            borderStyle: "solid",
                            borderWidth: "2px",
                            bordered: true,
                            borderRadius: "10",
                            linked: true,
                            alignment: "center",
                            marginBottom: 10,
                            marginTop: 10,
                            marginLeft: 0,
                            marginRight: 0,
                            width: "50%",
                        }
                    }
                ]
            }
        ]
    },

    //  TEXT WITH IMAGE  SECTIONS DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Text with Image",
            props: {
                order: "",
                duplicated: "Text with image",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                reverseAvailaible: true,
                reversed: false,
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_2/comp.png"),
                            width: "100%",
                            radius: "0",
                            visibility: true
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>DEFAULT IMAGE AND TEXT</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-Bold",
                            fontSize: 40,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            }
        ]
    },

    //  TESTIMONIALS  SECTIONS DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Testimonials",
            props: {
                order: "",
                duplicated: "Testimonials",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "sm",
                reverseAvailaible: true,
                reversed: false,
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>DEFAULT TESTIMONIALS</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 5,
                            paddingRight: 5,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 5,
                            paddingRight: 5,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/add_section_default/author.jpg"),
                            width: "150",
                            radius: "0",
                            form: "circle",
                            visibility: true
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>Class Author</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 14,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            }
        ]
    },

    //  TEXT WITH VIDEO  SECTIONS DEFAULT JSON
    {
        section: {
            //slug unique identifier
            slug: slug(),
            //component name (used as show name for sidebar)
            name: "Text with Video",
            //section_props
            props: {
                //props:value
                order: "",
                duplicated: "Text with video",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "lg",
                bgColorOverlay: "#ffffff",
                paddingTop: 80,
                paddingBottom: 80
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>DEFAULT TEXT AND VIDEO</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 48,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqu consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Video",
                        name: "Video",
                        props: {
                            src: "https://www.youtube.com/embed/RVjxFLTCngw",
                            width: "800",
                            height: "500",
                            spacing: "xs",
                            visibility: true,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0
                        }
                    }
                ]
            }
        ]
    },

    //  FEATURES SECTIONS DEFAULT JSON
    {
        section: {
            //slug unique identifier
            slug: slug(),
            //component name (used as show name for sidebar)
            name: "Features",
            //section_props
            props: {
                //props:value
                order: "",
                duplicated: "Features",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "lg",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/add_section_default/default_circle_1.png"),
                            width: "150",
                            radius: "0",
                            visibility: true,
                            feautures: "1"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>DEFAULT 1</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 26,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "center",
                            text:
                                "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/add_section_default/default_circle_2.png"),
                            width: "150",
                            radius: "0",
                            visibility: true,
                            feautures: "2"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>DEFAULT 2</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 26,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "center",
                            text:
                                "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/add_section_default/default_circle_3.png"),
                            width: "150",
                            radius: "0",
                            visibility: true,
                            feautures: "3"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>DEFAULT 3</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 26,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "center",
                            text:
                                "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 20,
                            paddingBottom: 20,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            }
        ]
    },

    //  Text One Column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Text One Column",
            props: {
                order: "",
                duplicated: "Text one column",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "One Column",
                props: { text: "One Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 48,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "center",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            }
        ]
    },

    //  Text Two Column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Text Two Column",
            props: {
                order: "",
                duplicated: "Text two column",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Two Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Two Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            }
        ]
    },

    //  Text Three Column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Text Three Column",
            props: {
                order: "",
                duplicated: "Text three column",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Three Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Three Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Three Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            }
        ]
    },

    //  Call to action DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Call to Action",
            props: {
                order: "",
                duplicated: "Call to action",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "One Column",
                props: { text: "One Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 48,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "center",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "ButtonMi",
                        name: "Call to Action",
                        props: {
                            text: "Default Button",
                            bgColor: "#7cb740",
                            textColor: "#ffffff",
                            widthChangeable: true,
                            href: "",
                            size: "medium",
                            border: "none",
                            blanked: true,
                            linked: true,
                            alignment: "center",
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 0,
                            marginRight: 0,
                            fontFamily: "Inter-Bold",
                            width: "283"
                        }
                    }
                ]
            }
        ]
    },

    // Two feature column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Two Feature Column",
            props: {
                order: "",
                duplicated: "Two feature column",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Two Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "200",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "ButtonMi",
                        name: "Call to Action",
                        props: {
                            text: "Default Button",
                            bgColor: "#7cb740",
                            textColor: "#ffffff",
                            widthChangeable: true,
                            href: "",
                            size: "medium",
                            border: "none",
                            blanked: true,
                            linked: true,
                            alignment: "left"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Two Column",
                props: { text: "Two Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "200",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "ButtonMi",
                        name: "Call to Action",
                        props: {
                            text: "Default Button",
                            bgColor: "#7cb740",
                            textColor: "#ffffff",
                            widthChangeable: true,
                            href: "",
                            size: "medium",
                            border: "none",
                            blanked: true,
                            linked: true,
                            alignment: "left"
                        }
                    }
                ]
            }
        ]
    },

    //  Three feature column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Three Feature Column",
            props: {
                order: "",
                duplicated: "Three feature column",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Three Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "200",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "ButtonMi",
                        name: "Call to Action",
                        props: {
                            text: "Default Button",
                            bgColor: "#7cb740",
                            textColor: "#ffffff",
                            widthChangeable: true,
                            href: "",
                            size: "medium",
                            border: "none",
                            blanked: true,
                            linked: true,
                            alignment: "left"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Three Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "200",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "ButtonMi",
                        name: "Call to Action",
                        props: {
                            text: "Default Button",
                            bgColor: "#7cb740",
                            textColor: "#ffffff",
                            widthChangeable: true,
                            href: "",
                            size: "medium",
                            border: "none",
                            blanked: true,
                            linked: true,
                            alignment: "left"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Three Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "200",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Default title</span>",
                            color: "#34495e",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 30,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                            color: "#34495e",
                            lineHeight: 18,
                            fontFamily: "Inter-Regular",
                            fontSize: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: "none",
                            centerText: false
                        }
                    },
                    {
                        slug: slug(),
                        type: "ButtonMi",
                        name: "Call to Action",
                        props: {
                            text: "Default Button",
                            bgColor: "#7cb740",
                            textColor: "#ffffff",
                            widthChangeable: true,
                            href: "",
                            size: "medium",
                            border: "none",
                            blanked: true,
                            linked: true,
                            alignment: "left"
                        }
                    }
                ]
            }
        ]
    },

    //  Image column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Image Column",
            props: {
                order: "",
                duplicated: "Image column",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Image Column",
                props: { text: "Image Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/image-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true
                        }
                    }
                ]
            }
        ]
    },
    //  Video column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Video Column",
            props: {
                order: "",
                duplicated: "Video column",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Video Column",
                props: { text: "Video Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Video",
                        name: "Video",
                        props: {
                            src: "https://www.youtube.com/embed/RVjxFLTCngw",
                            width: "800",
                            height: "500",
                            spacing: "xs",
                            visibility: true
                        }
                    }
                ]
            }
        ]
    },
    //  Image gallery DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Image Gallery",
            props: {
                order: "",
                duplicated: "Image gallery",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Image Column",
                props: { text: "Image Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (400x250)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Image Column",
                props: { text: "Image Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (400x250)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Image Column",
                props: { text: "Image Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (400x250)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Image Column",
                props: { text: "Image Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (400x250)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Image Column",
                props: { text: "Image Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (400x250)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Image Column",
                props: { text: "Image Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (400x250)"
                        }
                    }
                ]
            }
        ]
    },
    // Logo list DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Logo List",
            props: {
                order: "",
                duplicated: "Logo list",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Logo Column",
                props: { text: "Logo Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Logo Column",
                props: { text: "Logo Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Logo Column",
                props: { text: "Logo Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Logo Column",
                props: { text: "Logo Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Logo Column",
                props: { text: "Logo Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Logo Column",
                props: { text: "Logo Column" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Image",
                        name: "Image",
                        props: {
                            imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                            width: "auto",
                            radius: "0",
                            visibility: true,
                            imageLabel: "Show Image (200x100)"
                        }
                    }
                ]
            }
        ]
    },

    //  FAQ JSON
    {
        section: {
            slug: slug(),
            name: "FAQ",
            props: {
                order: "",
                duplicated: "FAQ",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#edf5ff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#edf5ff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "FAQ title",
                props: { text: "FAQ title" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>FAQ</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqQuestion: true,
                            label: "Show title",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 48,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "QuestionAnswer",
                props: {
                    text: "QuestionAnswer",
                    isFaq: true,
                    isNotDraggable: true
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text: "<span>Where do I host my class?</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqQuestion: true,
                            label: "Show question",
                            lineHeight: 10,
                            fontFamily: "Inter-Medium",
                            fontSize: 20
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqAnswer: true,
                            lineHeight: 18,
                            fontFamily: "Inter-Medium",
                            fontSize: 18
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "QuestionAnswer",
                props: {
                    text: "QuestionAnswer",
                    isFaq: true,
                    isNotDraggable: true
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text:
                                "<span>Are there any transaction fees?</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqQuestion: true,
                            label: "Show question",
                            lineHeight: 10,
                            fontFamily: "Inter-Medium",
                            fontSize: 20
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqAnswer: true,
                            lineHeight: 18,
                            fontFamily: "Inter-Medium",
                            fontSize: 18
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "QuestionAnswer",
                props: {
                    text: "QuestionAnswer",
                    isFaq: true,
                    isNotDraggable: true
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text:
                                "<p><span style='font-size:20px;'>Can I use my own domain?</span></p>",
                            color: "#34495e",
                            visibility: true,
                            isFaqQuestion: true,
                            label: "Show question",
                            lineHeight: 10,
                            fontFamily: "Inter-Medium",
                            fontSize: 20
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqAnswer: true,
                            lineHeight: 18,
                            fontFamily: "Inter-Medium",
                            fontSize: 18
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "QuestionAnswer",
                props: {
                    text: "QuestionAnswer",
                    isFaq: true,
                    isNotDraggable: true
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "left",
                            text:
                                "<span>Do you offer any money back guarantees?</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqQuestion: true,
                            label: "Show question",
                            lineHeight: 10,
                            fontFamily: "Inter-Medium",
                            fontSize: 20
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "left",
                            text:
                                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                            color: "#34495e",
                            visibility: true,
                            isFaqAnswer: true,
                            lineHeight: 18,
                            fontFamily: "Inter-Medium",
                            fontSize: 18
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Empty Column",
                props: { text: "Empty Column" },
                subcomponents: []
            }
        ]
    },
    //  Countdown JSON
    {
        section: {
            slug: slug(),
            name: "Countdown",
            props: {
                order: "",
                duplicated: "Countdown",
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Countdown",
                props: { text: "Countdown" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>Countdown</span>",
                            color: "#34495e",
                            visibility: true,
                            label: "Show title",
                            lineHeight: 10,
                            fontFamily: "Inter-ExtraBold",
                            fontSize: 48,
                            maxWidth: "none",
                            centerText: false
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Time",
                props: { text: "Time", isNotDraggable: true, isType: "Timer" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Days",
                        props: {
                            align: "center",
                            text:
                                "<p><span style='style='font-size:18px; line-height: 1.78;'>Days</span></p>",
                            color: "#34495e",
                            visibility: true,
                            label: "Days",
                            number: 0,
                            isCountdown: true,
                            date: Math.round(Date.now() / 1000)
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Hours",
                        props: {
                            align: "center",
                            text:
                                "<p><span style='style='font-size:18px; line-height: 1.78;'>Hours</span></p>",
                            color: "#34495e",
                            visibility: true,
                            label: "Hours",
                            number: 0,
                            isCountdown: true,
                            date: Math.round(Date.now() / 1000)
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Minutes",
                        props: {
                            align: "center",
                            text:
                                "<p><span style='style='font-size:18px; line-height: 1.78;'>Minutes</span></p>",
                            color: "#34495e",
                            visibility: true,
                            label: "Minutes",
                            number: 0,
                            isCountdown: true,
                            date: Math.round(Date.now() / 1000)
                        }
                    },
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Seconds",
                        props: {
                            align: "center",
                            text:
                                "<p><span style='style='font-size:18px; line-height: 1.78;'>Seconds</span></p>",
                            color: "#34495e",
                            visibility: true,
                            label: "Seconds",
                            number: 0,
                            isCountdown: true,
                            date: Math.round(Date.now() / 1000)
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Empty Column",
                props: { text: "Empty Column" },
                subcomponents: []
            }
        ]
    },

    //  Custom code
    {
        section: {
            slug: slug(),
            name: "Custom Code",
            props: {
                order: "",
                duplicated: "Custom code",
                visibility: true,
                bgVideoSrc: "",
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: false,
                spacing: "lg",
                alignmentAvalaible: false,
                alignment: "center",
                isCustomCode: true,
                bgColorOverlay: "#ffffff"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Custom code",
                props: { text: "Custom code", isNotDraggable: true },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Text",
                        props: {
                            align: "center",
                            text:
                                "<p><span style='style='font-size:18px; line-height: 1.78;'>Custom code goes here...</span></p>",
                            color: "#34495e",
                            isCustomCode: true,
                            isNotDraggable: true
                        }
                    }
                ]
            }
        ]
    },

    // Lessons Area Default JSON

    {
        section: {
            slug: slug(),
            name: "Lessons Area",
            props: {
                order: "",
                duplicated: "course",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#272727",
                background_type: "color",
                spacing: "md",
                videoAvailaible: false,
                bgColorOverlay: "#272727"
            }
        },
        components: [
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Component Props" },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Title",
                        props: {
                            align: "center",
                            text: "<span>Class Curriculum</span>",
                            color: "#ffffff",
                            lineHeight: 10,
                            fontFamily: "Roboto-Black",
                            fontSize: 48,
                            centerText: true,
                            maxWidth: "none"
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: {
                    text: "Component Props",
                    isNotDraggable: true,
                    type: "classes_conteiner"
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: "Text",
                        name: "Curriculum's Colors",
                        props: {
                            text: "<span>Curriculum's Colors</span>",
                            visibility: true,
                            label: "Curriculum's Colors",
                            lessonsBgColor: "#333333",
                            lessonsColor: "#ffffff",
                            isLessonsBgColor: true,
                            lineHeight: 18,
                            fontFamily: "Roboto-Medium",
                            fontSize: 18
                        }
                    }
                ]
            },
            {
                slug: slug(),
                type: "component",
                name: "Three Column",
                props: { text: "Component Props" },
                subcomponents: []
            }
        ]
    }
];

export default Landings_added;

// export {
//     hero,
//     text_and_image,
//     testimonials,
//     text_and_video,
//     features,
// };
