export const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length);

/* eslint-disable no-restricted-syntax */
const iconNamesByMimeType = {
   'jpg': ['image/jpeg', 'image/pjpeg'],
   'tiff': ['image/tiff'],
   'png': ['image/png'],
   'pdf': ['application/pdf'],
   'ppt': ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
   'audio': ['audio/mpeg', 'audio/ogg'],
};

const formatToExtention = {
   'video': ['mp4', 'webm', 'MOV', 'mov', 'AVI', 'avi'],
   'image': ['png', 'jpg', 'jpeg', 'tiff', 'JPG', 'JPEG'],
   'ppt': ['ppt', 'pptx'],
   'pdf': ['pdf'],
   'audio': ['mp3', 'ogg'],
};

const extensionToMimeType = {
   mp4: 'video/mp4',
   mov: 'video/quicktime',
   avi: 'video/x-msvideo',
   webm: 'video/webm',
   jpg: 'image/jpeg',
   tiff: 'image/tiff',
   png: 'image/png',
   pdf: 'application/pdf',
   ppt: 'application/vnd.ms-powerpoint',
   pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
   mp3: 'audio/mpeg',
   ogg: 'audio/ogg',
};

export const getMediaIconByMimeType = (mimeType, lessonFormat = '') => {
   if (lessonFormat === 'video') return 'video';
   for (const [extention, mimeTypes] of Object.entries(iconNamesByMimeType)) {
      if (mimeTypes.includes(mimeType)) {
         return extention;
      }
   }
   return '';
};

export function getFileTypeLimitByFormat(format) {
   if (!formatToExtention[format]) return false;
   return formatToExtention[format].join(' ');
}

export function getAcceptTypesByFormat(format) {
   if (!formatToExtention[format]) return false;
   return formatToExtention[format]
      .map(ext => `.${ ext }`)
      .join(',');
}

export function getInputAllowedExtensionsAndMimeTypes({ allowedExtensions, format }) {
   const extensions = format ? getFileTypeLimitByFormat(format) : allowedExtensions;
   const allowedExtensionList = extensions.split(' ');
   const inputAllowedExtentions = allowedExtensionList.map(ext => `.${ ext }`).join(',');
   const allowedMimeTypes = allowedExtensionList.reduce((res, extension) => {
      if (extensionToMimeType[extension]) {
         res.push(extensionToMimeType[extension]);
      }
      return res;
   }, []);
   return [inputAllowedExtentions, allowedMimeTypes];
}

export function getS3Url(key) {
   const { REACT_APP_AWS_BUCKET_URL } = process.env;
   return `${ REACT_APP_AWS_BUCKET_URL }/${ key }`;
}

export function isVideoFile(mimeType) {
   const videoMimeTypes = formatToExtention.video.map(ext => extensionToMimeType[ext.toLocaleLowerCase()]);
   return videoMimeTypes.includes(mimeType);
}

export function mimeTypeToExtension(mimeType) {
   for (const extension in extensionToMimeType) {
      if (mimeType.toLowerCase() === extensionToMimeType[extension]) {
         return extension;
      }
   }
   return false;
}

export function generateFileName(mimeType) {
   const randomFileName = generateRandomString(9);
   const fileExtension = mimeTypeToExtension(mimeType);
   if (fileExtension) {
      return `${ randomFileName }_${ Date.now() }.${ fileExtension }`;
   }
   return false;
}

export function onDownload(src) {
   const a = document.createElement('a');
   if (src.startsWith('https://ucarecdn.com')) {
      const uuid = src.split('/')[3];
      const downloadSrc = `https://ucarecdn.com/${ uuid }/-/inline/no/`;
      a.href = downloadSrc;
      a.click();
   } else if (src.includes('amazonaws')) {
      a.href = src;
      a.download = true;
      a.click();
   }
}

export function getInitialCropSize(aspect, imageWidth, imageHeight, freeRatio) {
   const result = { width: imageWidth, height: imageHeight };
   if (typeof aspect !== 'number' || freeRatio) {
      return result;
   }
   for (let width = imageWidth; width > 0; width--) {
      const height = width * aspect;
      if (height <= imageHeight) {
         result.width = width;
         result.height = height;
         return result;
      }
   }
   return result;
}

