import React, { Component, Fragment } from "react";
import EditorContext from "contexts/EditorContext";
import { Header, Menu, Icon, Button } from "semantic-ui-react";
import AppContext from "contexts/AppContext";
import "./index.css";
import down from "../../../images/topbar/down.png";
import up from "../../../images/topbar/up.png";

class SectionComponentsMenu extends Component {
   toggleSectionComponent = e => {
      let sectionCurrentComponent = e.target.closest(".item").nextSibling;
      let currentItemDown = e.currentTarget.children[0].children[1];
      let currentItemUp = e.currentTarget.children[0].children[2];
      if (sectionCurrentComponent.style.display === "none") {
         sectionCurrentComponent.style.display = "block";
         currentItemDown.style.display = 'none';
         currentItemUp.style.display = 'block';
      } else {
         sectionCurrentComponent.style.display = "none";
         currentItemDown.style.display = 'block';
         currentItemUp.style.display = 'none';
      }
   };

   render() {
      return (
         <EditorContext.Consumer>
            {({ state,landing, functions, SettingComponent }) => (
               <Fragment>
                  <Menu className='SectionComponents' vertical={true}>
                     <Menu.Item
                        name='header'
                        onClick={() => functions.backToCurrentMenu()}
                     >
                        <Header as='h4' color='black'>
                           <Icon name='angle left' disabled />
                           <Header.Content>Go Back</Header.Content>
                        </Header>
                     </Menu.Item>
                     {state.editableSection && (
                        <Fragment>
                           {!this.props.section.props.isCustomCode &&  <Menu.Item
                              onClick={e =>
                                 this.toggleSectionComponent(e)
                              }
                           >
                              <div className='SectionComponents_name'>
                                 <span className='component_name'>
                                            Background
                                 </span>
                                 <img
                                    alt='Default'
                                    style={{
                                       float: "right",
                                    }}
                                    className='component_icon_down'
                                    src={down}
                                 />
                                 <img
                                    alt='Default'
                                    style={{
                                       float: "right",
                                    }}
                                    className='component_icon_up'
                                    src={up}
                                 />
                              </div>
                           </Menu.Item>}
                           {!this.props.section.props.isCustomCode &&  <div className='SectionComponent' id={state.editableSection.section.slug} style={{display: 'none'}}>
                              {state.editableSection &&
                                        functions.showEditableComponent(
                                           state.editableSection.section,
                                           -1
                                        )}
                           </div>}
                           {state.editableSection.components.map(
                              (component, i) => {
                                 return (
                                    component.type !== "Card" &&
                                            component.type !== "Border" &&
                                            component.props.deleted!== true &&  (
                                       <Fragment key={i}>
                                          <Menu.Item
                                             onClick={e =>
                                                this.toggleSectionComponent(
                                                   e
                                                )
                                             }
                                          >
                                             <div className='SectionComponents_name'>
                                                <span className='component_name'>
                                                   { this.props.section.props.duplicated === 'footer' ? 'Text':  component.name}
                                                </span>
                                                <img
                                                   alt='Default'
                                                   style={{
                                                      float: "right",
                                                   }}
                                                   className='component_icon_down'
                                                   src={down}
                                                  // style={this.props.section.props.isCustomCode? {display: 'none', float: 'right'} :  {float: 'right'}}
                                                />
                                                <img
                                                   alt='Default'
                                                   style={{
                                                      float: "right",
                                                   }}
                                                   className='component_icon_up'
                                                   src={up}
                                                  // style={this.props.section.props.isCustomCode? {display: 'inline-block', float: 'right'} :  {float: 'right'}}
                                                />
                                             </div>
                                          </Menu.Item>
                                          <div className='SectionComponent' id={component.slug}  style={this.props.section.props.isCustomCode? {display: 'block'} :  {display: 'none'}}>
                                             {functions.showEditableComponent(
                                                component,
                                                i,
                                                this.props.section.props.duplicated
                                             )}
                                          </div>
                                       </Fragment>
                                    )
                                 );
                              }
                           )}
                           {this.props.section.props.isWithLinksHeader === true  && this.props.linkComponentCount<4 &&
                                    <AppContext.Consumer>
                                       {({ state, functions }) => (
                                          <Fragment>
                                             <Button className='link_delete medium' onClick={() => functions.handleAddLinkComponent() }>
                                                        Add Link
                                             </Button>
                                          </Fragment>
                                       )}
                                    </AppContext.Consumer>
                           }
                        </Fragment>
                     )}

                     {this.props.section && (

                        this.props.section.props.duplicated !== 'header' &&
                            this.props.section.props.duplicated !== 'footer' &&
                            this.props.section.props.duplicated !== 'pricing' && (
                           <Menu.Item name='remove_section'>
                              <EditorContext.Consumer>
                                 {({ state, landing, functions, SettingComponent }) => (
                                    <input type='hidden' id='back_to_menu' onClick={() => {
                                       functions.backToCurrentMenu()
                                    }} />
                                 )}
                              </EditorContext.Consumer>
                              <AppContext.Consumer>
                                 {({ state, functions }) => (
                                    <Header as='h4' id='section_remove_button' color='black'>
                                       <Button color='red' className='medium' onClick={() => {
                                          functions.handleSectionOptions(
                                             this.props.section.props.order,
                                             'delete',
                                             this.props.section.props.visibility
                                          );
                                          document.getElementById('back_to_menu').click();
                                       }}>
                                                    Delete Section
                                       </Button>
                                    </Header>
                                 )}
                              </AppContext.Consumer>
                           </Menu.Item>
                        )
                     )}

                  </Menu>
               </Fragment>
            )}
         </EditorContext.Consumer>
      );
   }
}

SectionComponentsMenu.defaultProps = {};

export default SectionComponentsMenu;
