import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_11",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "Montserrat-Regular",
        regular_font: "Montserrat-Regular",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
       
    ],
};

export default state;
