import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_7",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,   
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#4285f4",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "35",
                        imgUrl: require("../images/landing_7/logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#26cdc3",
                        textColor: "#ffffff",
                        size: "medium",
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Support",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "medium",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Hero",
                //section_props
                props: {
                    //props:value
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_7/background.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<span style='font-size:50px;fontWeight:1000'>Become the Best</span>",
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</span></p>',
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;line-height: 1.5; font-weight: 500;">laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,</span></p>',
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Enroll in course",
                        bgColor: "#26cdc3",
                        textColor: "#ffffff",
                        size: "medium",
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Try a demo",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "medium",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Text with image",
                //section_props
                props: {
                    //props:value
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(255,255,255)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='font-size:40px;font-weight:1000'>Here’s an intro title</span>",
                        color: "#333333",
                    },
                },

                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;color:#3e3b3b;line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;color:#3e3b3b; line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;color:#3e3b3b; line-height: 1.5; font-weight: 500;"><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/man.png"),
                        width: "400",
                        visibility: true,
                        spacing: "xs",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Features",
                //section_props
                props: {
                    //props:value
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#f5f5f5",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='color:#333333;font-size:40px'>What’s included?</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h3><span style='font-size:20px'>Feature title</span></h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size: 16px;color:#333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.  </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h3>Feature title</h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size: 16px;color:#333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.  </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h3><span style='font-size:20px'>Feature title</span></h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size: 16px;color:#333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.  </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h3><span style='font-size:20px'>Feature title</span></h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size: 16px;color:#333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.  </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h3><span style='font-size:20px'>Feature title</span></h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size: 16px;color:#333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.  </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Card",
                    name: "Card",
                    props: {
                        align: "center",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<h3><span style='font-size:20px'>Feature title</span></h3>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size: 16px;color:#333333;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.  </span></p>',
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Details",
                //section_props
                props: {
                    //props:value
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(255,255,255)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<span style='color: #333333;font-size:40px;font-weight:800'>Course details</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<span style='color:#267ec5;font-size:20px;font-weight:600'>Module#1</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/video_title.png"),
                        width: "30",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>Insert video title</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "right",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>00:43:12</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/video_title.png"),
                        width: "30",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>Insert video title</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "right",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>00:43:12</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/pdf_title.png"),
                        width: "30",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>Insert file title</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "right",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>00:43:12</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text: "<span style='color:#267ec5;font-size:20px;font-weight:600'>Module#2</span>",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/video_title.png"),
                        width: "30",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>Insert video title</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "right",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>00:43:12</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/video_title.png"),
                        width: "30",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>Insert video title</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "right",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>00:43:12</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/pdf_title.png"),
                        width: "30",
                        visibility: true,
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>Insert file title</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "right",
                        text:
                            '<p><span style="font-size:20px;color:#666666"><span>00:43:12</span></p>',
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Testimonials",
                //section_props
                props: {
                    //props:value
                    order:5,
                    duplicated:5,
                    visibility: true,
                    imageOverlay: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_7/bottom_bg.png"),
                    bgColor: "rgb(250,250,250)",
                    background_type: "image",
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_7/author.png"),
                        width: "180",
                        visibility: true,
                        spacing: "md",
                        form:'circle',
                    },
                },

                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<span style='font-size:20px'>Course author</span>",
                        color: "#666666",
                    },
                },
                {
                    slug: slug(),
                    type: "Border",
                    name: "Border",
                    props: {
                        height: "1px",
                        width: "full",
                        borderRadius: "0px",
                        backgroundColor: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:18px;line-height: 1.5; font-weight: 500;'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></p>",
                        color: "#666666",
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:6,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#cbe8ff",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:34px;font-weight:800'>Enrol now!</span></p>",
                        color: "#333333",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#666666",
                        textColor_selected: "#be3f3f",
                        bgColor: "#f1f3f3",
                        bgColor_selected: "#ffe0e0",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#26cdc3",
                        textColor: "#ffffff",
                        size: "medium",
                        href: "",
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Footer",
                props: {
                    order:7,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "© 2017 of CourseBooks, LLC",
                        bgColor: "transparent",
                        textColor: "#757575",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "30",
                        imgUrl: require("../images/landing_7/footer-logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
            ],
        },
    ],
};

export default state;
