import React from 'react';
import Frame from 'react-frame-component';
const getStyles = () => {
    let head = '';
    const sheets = Array.from(document.querySelectorAll('link[rel=stylesheet]'));
    const styles = Array.from(document.querySelectorAll('head style'));

    sheets.forEach(link => {
        head += link.outerHTML;
    });

    styles.forEach(style => {
        head += style.outerHTML;
    });

    return head;
};

export default function Wrapper(WrappedComponent) {
    return class extends React.Component {

        render() {
            return this.props.viewMode === 'phone' || this.props.viewMode === 'tablet'  ? (
                <Frame
                    contentDidMount={this.onLoad}
                    initialContent={`<!DOCTYPE html><html><head>${ getStyles() } </head><body id="iframe-body"><div class="frame-root"></div></body></html>`}
                >
                    <WrappedComponent {...this.props}/>
                </Frame>
            ) : (
                <WrappedComponent {...this.props}/>
            )
        }
    }
}
