import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconNew from 'components/elements/iconsSize';
import Popover from '@material-ui/core/Popover';
import './index.css';
import Text, { TYPES as textType, SIZES as textSize } from 'components/TextNew';
import { v4 as uuidv4 } from 'uuid';

const DropTriggle = ({
   options, type, activeStyles, styles,
}) => {
   const [isOpenTriangle, setIsOpenTriangle] = useState(false);
   const [anchorEl, setAnchorEl] = React.useState(null);
   return (
      <div className='drop__triggle'>
         <div
            className={ `drop__triggle__icon${ isOpenTriangle ? ' drop__triggle__icon__active' : '' }` }
            role='presentation'
            style={ isOpenTriangle ? activeStyles : {} }
            onClick={ (e) => {
               e.stopPropagation()
               e.preventDefault();
               setIsOpenTriangle(true);
               setAnchorEl(e.currentTarget);
            } }
         >
            <IconNew name='ToggleMoreM' />
         </div>
         <Popover
            open={ isOpenTriangle }
            anchorEl={ anchorEl }
            onClose={ () => setIsOpenTriangle(false) }
            className='custom-popover'
            elevation={ 24 }
            style={ styles }
            anchorOrigin={ {
               vertical: 'bottom',
               horizontal: 'right',
            } }
            transformOrigin={ {
               vertical: 'top',
               horizontal: 'right',
            } }
         >
            <div className={ type ? `triggle__popover__${ type }` : 'triggle__popover' }>
               <div className='triggle__popover__flex'>
                  {options.map((option) => {
                     return (
                        <div
                           onClick={ (e) => { 
                              e.stopPropagation()
                              e.preventDefault()
                              option.onClick(e); 
                              setIsOpenTriangle(false);
                           } }
                           role='presentation'
                           key={ uuidv4() }
                           style={ option.style || {} }
                           className={ `triggle__popover__item ${ option.trash && options.length > 1 && 'triggle__popover__item__border' }${ option.trash ? ' triggle__popover__item__trash' : ` triggle__popover__item__${ option.iconName }` }` }
                        >
                           <IconNew name={ option.iconName } />
                           <Text
                              inner={ option.name }
                              type={ textType.regularDefault }
                              size={ textSize.small }
                           />
                        </div>
                     );
                  })}
               </div>
            </div>
         </Popover>
      </div>
   );
};

DropTriggle.propTypes = {
   options: PropTypes.array,
   type: PropTypes.string,
   styles: PropTypes.object,
   activeStyles: PropTypes.object,
};

export default DropTriggle;
