import ReactGA from 'react-ga';

const googleAnalytics = (ga_code) => {
    if(ga_code){
        ReactGA.initialize(ga_code);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
};

export default googleAnalytics;
