import React, { Component, Fragment } from "react";
import EditorContext from "contexts/EditorContext";
import AppContext from "contexts/AppContext";
import { Header, Menu, Icon , Button } from "semantic-ui-react";
import  { Landings_groups } from "./../../../defaults/sections/added_sections_drag_new";
import "./index.css";
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'
import IconNew from "components/elements/iconsSize";
import hand from '../../../images/smart_block_images/hand.png';

class AddSectionMenu extends Component {

   constructor(props){
      super(props);

      this.state = {
         addVisibleSectionName:'',
      }
   }

   showButton = (sectionName) => {
      this.setState({
         addVisibleSectionName:sectionName,
      })
   }

   slug = () => {
      function s4() {
         return new Date().getTime() + Math.floor(Math.random() * 10000);
      }
      return "miestro-" + s4();
   };


   isActiveGroup = (data,activeName) => {
      let isActive = data.filter((e)=>e.section.name === activeName).length
      return !!isActive
   }

   handleAddNewSection = (data,activeName,functions) => {
      let active = data.filter((e)=>e.section.name === activeName)[0]
      let newSection = {...active};
      newSection.section.slug = this.slug();
      if(newSection.components) {
         newSection.components.map(comp => comp.slug = this.slug());
      }
      functions.handleRemovePreviewSection(this.props.currentIndex);
      functions.handleAddSection(newSection, newSection.section.slug, this.props.currentIndex );
   }


   render() {
      const { newApp, currentIndex }  = this.props
      return (
         <Fragment>
            <div className='SectionComponents__addSection'>
               <EditorContext.Consumer>
                  {({ state,functions, SettingComponent , Sections}) => (
                     <div className='SectionComponents__top'>
                        <div className='SectionComponents__top__left'>
                           <div className='SectionComponents__top__icon'
                              onClick={() => functions.backToCurrentMenu()}
                              role='presentation'
                           >
                              <IconNew name='LandingBackL' />
                           </div>
                           <Text
                              inner={`Add Section`}
                              type={types.regular148}
                              size={sizes.medium}
                           />
                        </div>
                     </div>
                  )}
               </EditorContext.Consumer>
               <AppContext.Consumer>
                  {({ state, functions }) => (
                     Landings_groups.map(({data,name}) => {
                        let isActiveGroup = this.isActiveGroup(data,this.state.addVisibleSectionName)
                        return(
                           <div className='add-section-group'>
                              <div className='add-section-group-top'>
                                 <Text
                                    inner={name}
                                    type={types.medium150}
                                    size={sizes.medium}
                                 />
                              </div>
                              <div className='add-section-group-item-new'>
                                 {
                                    data.map((section_default_json, i)=>{
                                       return (
                                          <div className='add-section-items'  key= {"add_section_"  + i}
                                             onMouseOver={()=> {
                                                this.showButton(section_default_json.section.name);
                                                let newSection = {...section_default_json};
                                                newSection.section.slug = this.slug();
                                                if(newSection.components) {
                                                   newSection.components.forEach(comp => {
                                                      comp.slug = this.slug();
                                                      if(comp.subcomponents) {
                                                         comp.subcomponents.forEach(subcomp => {
                                                            subcomp.slug = this.slug();
                                                         })
                                                      }
                                                   })
                                                };
                                                functions.handlePreviewSection(newSection, newSection.section.slug, currentIndex )
                                             }}
                                             onMouseOut={()=> functions.handleRemovePreviewSection(currentIndex)}
                                          >

                                             <div className='add-section-left-side'>
                                                <div className='add-section-left-side-icon'>
                                                   <button className="add-column-section" 
                                                      onClick={()=>{
                                                         if(isActiveGroup){
                                                            this.handleAddNewSection(data,this.state.addVisibleSectionName,functions)
                                                         }
                                                      }}
                                                   >
                                                      Add Section
                                                   </button>
                                                   {section_default_json.section.icon}
                                                </div>
                                                <div className="hoverToAdd">
                                                   <img src={hand} alt="hand" height={20} width={20} />
                                                   <span>Hover To Add</span>
                                                </div>
                                             </div>
                                          </div>
                                       )
                                    })
                                 }
                              </div>
                           </div>
                        )
                     })
                  )}
               </AppContext.Consumer>
            </div>
         </Fragment>
      );
   }
}

AddSectionMenu.defaultProps = {};

export default AddSectionMenu;


