import React, { useRef, useState, useEffect } from 'react';
import './index.css';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import useOutsideClickDetector from 'utils/useOutsideClickDetector';
import Text, { TYPES as txtTypes, SIZES as sizes } from 'components/TextNew';
import { copyToClipBoard } from 'utils/copy';
import Icon from '../elements/Icon';
import IconNew from '../elements/iconsSize';

const Input = function ({
   isError, min, placeholder, value, onChange, onKeyPress, children,
   isCloseHidenOnEmpty, classI, name, isPassword, type, disabled,
   onClearSearchValue, id, label, helpText, data, onSearch, isAnimatedLabel,
   from, to, IToolTipText, minNumber, isHaveCopyButton, withIcon, iconName,
   bottomText, onBlur, inputRef, maxNumber, maxlength, leftText
}) {
   const dataRef = useRef(null);
   const [types, setTypes] = useState(type);
   const intervalRef = useRef(null);
   const [classis, setClassis] = useState('');
   const [isOpenCalendar, setIsOpenCalendar] = useState(false);
   useOutsideClickDetector(dataRef, () => setIsOpenCalendar(false));
   useEffect(() => {
      setTypes(type);
   }, [type]);

   const onChangeNumberInput = (e) => {
      // const toUpdate = Number(e.target.value);
      // if (toUpdate && toUpdate <= maxNumber && toUpdate >= minNumber) {
      //    onChange(e.target.name, Number(e.target.value));
      // }
      if (e.target.value.length !== 0) {
         if (Number(e.target.value) < maxNumber) {
            if (minNumber) {
               if (Number(e.target.value) > minNumber) {
                  onChange(e.target.name, e.target.value.length !== 0 ? Number(e.target.value) : '');
               }
            } else {
               onChange(e.target.name, e.target.value.length !== 0 ? Number(e.target.value) : '');
            }
         }
      } else if (!minNumber) {
         onChange(e.target.name, e.target.value.length !== 0 ? Number(e.target.value) : '');
      }
   };


   const onMouseDownInputNumber = (action) => {
      let innerValue = Number(value);
      const innitial = action === '-' ? Number(innerValue - 1) : Number(innerValue + 1);
      if (minNumber !== undefined) {
         if (innitial > minNumber && innitial < maxNumber) {
            innerValue = innitial;
            onChange(name, innitial);
         }
      } else if (innitial < maxNumber) {
         innerValue = innitial;
         onChange(name, innitial);
      }
      intervalRef.current = setInterval(() => {
         const newValue = action === '-' ? Number(innerValue - 1) : Number(innerValue + 1);
         if (minNumber !== undefined) {
            if (newValue > minNumber && newValue < maxNumber) {
               innerValue = newValue;
               onChange(name, newValue);
            }
         } else if (newValue < maxNumber) {
            innerValue = newValue;
            onChange(name, newValue);
         }
      }, 200);
   };

   const onMouseUpInputNumber = () => {
      clearInterval(intervalRef.current);
   };

   const onChangeTextInput = (e) => {
      const newValue = e.target.value;
      if (newValue.length <= Number(maxlength)) {
         onChange(name, newValue);
      }
   };

   if (types === 'custom') {
      return (
         <div className={ `${ classI }` }>
            {(label || helpText || IToolTipText) && (
               <div className='input_header'>
                  {!isAnimatedLabel ? (
                     <label htmlFor={ id } className={ `input__default__label ${ disabled ? 'inputNew_label_disabled' : '' } ` }>{label}</label>
                  ) : ''}
                  {helpText
                     && <span className='inputNew_helper'>{helpText}</span>}
                  
               </div>
            )}
            <div>
               {children}
            </div>
            {data.length ? (
               <div className='input_footer'>
                  {data.map((e) => {
                     return (
                        <div className='input_footer_message' key={ uuidv4() }>
                           <Icon name={ e.status } />
                           <span className={ `input_footer_text input_footer_text_${ e.status }` }>{e.message}</span>
                        </div>
                     );
                  })}
               </div>
            ) : ''}
         </div>
      );
   }
   if (types === 'search') {
      return (
         <div className={ `inputSearch ${ classI }` }>
            {(label || helpText) && (
               <div className='input_header'>
                  <label htmlFor={ id } className={ `inputNew_label ${ disabled ? 'inputNew_label_disabled' : '' } ${ isError ? 'inputNew_label_error' : '' }` }>{label}</label>
                  {helpText
                     && <span className='inputNew_helper'>{helpText}</span>}
               </div>
            )}
            <div className={ `inputNew ${ classis } ${ disabled ? 'input_new_disabled' : '' }` }>
               <div className='inputNew__search'>
                  <div role='presentation' onClick={ (!disabled && onSearch) ? () => onSearch(value) : () => { } } className={ `inputNew_icon ${ !value ? 'inputNew_icon_inactive' : '' }` }><Icon name='searchNew' /></div>
                  <input
                     id={ id }
                     value={ value }
                     ref={ inputRef }
                     name={ name }
                     type='text'
                     onKeyPress={ onKeyPress }
                     disabled={ disabled }
                     onFocus={ () => setClassis('focused') }
                     onBlur={ onBlur ? (e) => { setClassis(''); onBlur(e.target.name, e.target.value, e); } : () => setClassis('') }
                     placeholder={ placeholder || '' }
                     onChange={ (e) => { onChange(e.target.name, e.target.value, e); } }
                     className=' input_new'
                  />
               </div>
               {/* {value && ( */}
               {isCloseHidenOnEmpty ? (
                  <div>
                     {value && (
                        <div
                           role='presentation'
                           onClick={ !disabled ? () => {
                              onChange(name, '');
                              onClearSearchValue();
                           } : () => { } }
                           className='inputNew_search inputNew_icon'
                        ><Icon name='clearNew' />
                        </div>
                     )}
                  </div>
               ) : (
                  <div
                     role='presentation'
                     onClick={ !disabled ? () => {
                        onChange(name, '');
                        onClearSearchValue();
                     } : () => { } }
                     className='inputNew_search inputNew_icon'
                  ><Icon name='clearNew' color='#797278' />
                  </div>
               )}

               {/* )} */}
            </div>
            {data.length ? (
               <div className='input_footer'>
                  {data.map((e) => {
                     return (
                        <div className='input_footer_message' key={ uuidv4() }>
                           <Icon name={ e.status } />
                           <span className={ `input_footer_text input_footer_text_${ e.status }` }>{e.message}</span>
                        </div>
                     );
                  })}
               </div>
            ) : ''}
         </div>
      );
   }
   if (types === 'textarea') {
      return (
         <div className={ `text_area ${ classI }` }>
            {(helpText || label || IToolTipText) ? (
               <div className='input_header' style={ { marginBottom: '8px' } }>
                  <label htmlFor={ id } className={ `inputNew_label ${ disabled ? 'inputNew_label_disabled' : '' } ${ isError ? 'inputNew_label_error' : '' }` }>{label}</label>
                  {helpText
                     && <span className='inputNew_helper'>{helpText}</span>}
                  
               </div>
            ) : null}
            <textarea
               id={ id }
               value={ value }
               name={ name }
               disabled={ disabled }
               ref={ inputRef }
               onFocus={ () => setClassis('focused') }
               onBlur={ () => setClassis('') }
               placeholder={ placeholder || '' }
               onChange={ (e) => { onChange(e.target.name, e.target.value); } }
               className={ `text_area_new   ${ disabled ? 'text_area_new_disabled' : '' }` }
            />
            {data.length ? (
               <div className='input_footer'>
                  {data.map((e) => {
                     return (
                        <div className='input_footer_message' key={ uuidv4() }>
                           <Icon name={ e.status } />
                           <span className={ `input_footer_text input_footer_text_${ e.status }` }>{e.message}</span>
                        </div>
                     );
                  })}
               </div>
            ) : ''}
            {bottomText && (
               <div className='input_footer'>
                  <Text
                     inner={ bottomText }
                     type={ txtTypes.regular148 }
                     style={ { color: '#727978' } }
                     size={ sizes.xsmall }
                  />
               </div>
            )}
         </div>
      );
   }
   if (type !== 'number') {
      return (
         <div className={ `input ${ classI }` }>
            {(label || helpText || IToolTipText) && (
               <div className='input_header'>
                  {!isAnimatedLabel ? (
                     <label htmlFor={ id } className={ `input__default__label ${ disabled ? 'inputNew_label_disabled' : '' } ` }>{label}</label>
                  ) : ''}
                  {helpText
                     && <span className='inputNew_helper'>{helpText}</span>}
                  
               </div>
            )}
            <div className='inputLeftText'>
               {!!leftText && <div className='inputLeftText__content'>{leftText}</div>}
               <div className={ `inputNew inputNew_${ classis } ${ disabled ? 'input_new_disabled' : '' }` }>
                  {isAnimatedLabel && (
                     <label htmlFor={ id } className={ `inputNew_label ${ disabled ? 'inputNew_label_disabled' : '' } label_${ classis } ${ !value && isAnimatedLabel && 'inputNew_label_animation' } ${ isError ? 'inputNew_label_error' : '' }` }>{label}</label>
                  )}
                  <input
                     id={ id }
                     value={ value }
                     name={ name }
                     type={ types }
                     disabled={ disabled }
                     ref={ inputRef }
                     onKeyPress={ onKeyPress }
                     onFocus={ () => {
                        setClassis('focused');
                     } }
                     onBlur={ onBlur ? (e) => { setClassis(''); onBlur(e.target.name, e.target.value, e); } : () => setClassis('') }
                     placeholder={ placeholder || '' }
                     onChange={ (e) => { onChangeTextInput(e); } }
                     className=' input_new'
                  />
                  {isPassword
                     && <div role='presentation' className='inputNew_icon show-hide' onClick={ () => setTypes(types === 'text' ? 'password' : 'text') }>{types !== 'password' ? <Icon name='Show' /> : <Icon name='UnShown' />}</div>}
                  {isHaveCopyButton && (
                     <div role='presentation' className='inputNew__copy__icon' onClick={ () => copyToClipBoard(value) }>
                        <IconNew name='CopyProgramM' />
                     </div>
                  )}
                  {withIcon && (
                     <div className='inputNew__icon'>
                        <IconNew name={ iconName } />
                     </div>
                  )}
               </div>
            </div>
            {data.length ? (
               <div className='input_footer'>
                  {data.map((e) => {
                     return (
                        <div className='input_footer_message' key={ uuidv4() }>
                           <Icon name={ e.status } />
                           <span className={ `input_footer_text input_footer_text_${ e.status }` }>{e.message}</span>
                        </div>
                     );
                  })}
               </div>
            ) : ''}
            {bottomText && (
               <div className='input_footer'>
                  <Text
                     inner={ bottomText }
                     type={ txtTypes.regular148 }
                     style={ { color: '#727978' } }
                     size={ sizes.xsmall }
                  />
               </div>
            )}
         </div>
      );
   }

   return (
      <div className={ `input_number ${ classI }` }>
         {(label || helpText) && (
            <div className='input_header'>
               <label htmlFor={ id } className={ `inputNew_label ${ disabled ? 'inputNew_label_disabled' : '' } ${ isError ? 'inputNew_label_error' : '' }` }>{label}</label>
               {helpText
                  && <span className='inputNew_helper'>{helpText}</span>}
            </div>
         )}
         <div className={ `inputNew ${ classis } ${ disabled ? 'inputNew_disabled' : '' }` }>
            <input
               value={ value }
               name={ name }
               type='number'
               disabled={ disabled }
               ref={ inputRef }
               onFocus={ () => setClassis('focused') }
               onBlur={ () => setClassis('') }
               placeholder={ placeholder || 'number' }
               onChange={ onChangeNumberInput }
               className='input_new  input_number'
            />
            <div className='input_buttons'>
               <button
                  type='button'
                  className='input_number_button'
                  onMouseDown={ () => onMouseDownInputNumber('+') }
                  onMouseUp={ () => onMouseUpInputNumber() }
               >
                  <Icon name='ArrowUpSmall' />
               </button>
               <button
                  type='button'
                  style={ { transformt: 'rotate(90deg)' } }
                  className='input_number_button'
                  onMouseDown={ () => onMouseDownInputNumber('-') }
                  onMouseUp={ () => onMouseUpInputNumber() }
               >
                  <Icon name='ArrowDownSmall' />
               </button>
            </div>
         </div>
         {/* <div className='input_footer'>
               {data.map((e) => {
                  return (
                     <div className='input_footer_message' key={ uuidv4() }>
                        <Icon name={ e.status } />
                        <span className={ `input_footer_text input_footer_text_${ e.status }` }>{e.message}</span>
                     </div>
                  );
               })}
            </div> */}
      </div>

   );
};

Input.defaultProps = {
   type: 'text',
   isPassword: false,
   placeholder: '',
   value: '',
   name: '',
   classI: '',
   onChange: () => {},
   label: '',
   data: [],
   isError: false,
   onSearch: () => {},
   isAnimatedLabel: false,
   onClearSearchValue: () => {},
   onKeyPress: () => {},
   isCloseHidenOnEmpty: true,
   onBlur: () => {},
   maxNumber: 1000000,
   maxlength: 150,
};

Input.propTypes = {
   type: PropTypes.string,
   isPassword: PropTypes.bool,
   placeholder: PropTypes.string,
   value: PropTypes.any,
   name: PropTypes.string,
   IToolTipText: PropTypes.string,
   classI: PropTypes.string,
   onChange: PropTypes.func,
   disabled: PropTypes.bool,
   id: PropTypes.string,
   label: PropTypes.string,
   helpText: PropTypes.string,
   data: PropTypes.array,
   isError: PropTypes.bool,
   onSearch: PropTypes.func,
   min: PropTypes.number,
   isAnimatedLabel: PropTypes.bool,
   inputRef: PropTypes.object,
   children: PropTypes.any,
   from: PropTypes.any,
   minNumber: PropTypes.number,
   onClearSearchValue: PropTypes.func,
   onKeyPress: PropTypes.func,
   to: PropTypes.any,
   isCloseHidenOnEmpty: PropTypes.bool,
   isHaveCopyButton: PropTypes.bool,
   withIcon: PropTypes.bool,
   iconName: PropTypes.string,
   bottomText: PropTypes.string,
   onBlur: PropTypes.func,
   maxNumber: PropTypes.number,
   maxlength: PropTypes.string,
   leftText: PropTypes.string,
};

export default Input;
