import React, { Component } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import AppContext from "../../../contexts/AppContext";
import Column from "./Column";

class List extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isDragging: false,
      };
   }
   onDragStart = (result) => {
      this.setState({isDragging: true});
   }
   onDragEnd = (result) => {
      this.setState({isDragging: false});
   }
   render() {
      return (
         <AppContext.Consumer>
            {({ state, functions }) => (
               <DragDropContext
                  onDragEnd={(result) => {
                     const { source, destination} = result;

                     functions.handleOnDragEnd({
                        source: { index: source.index },
                        ...(destination && { destination: { index: destination.index + 1 }})
                     });
                     this.onDragEnd(result);
                  }}
                  onDragStart={this.onDragStart}
               >
                  <Column
                     droppableId='droppable'
                     data={this.props.landing}
                     {...this.props}
                     isDragging={this.state.isDragging}
                  />
               </DragDropContext>
            )}
         </AppContext.Consumer>
      );
   }
}

export default List;
