import React from 'react'
import Text, { TYPES as txtTypes, SIZES as txtSizes } from 'components/TextNew';

const FontOptions = ({ options, onSelect }) => {

    return (
        <div className='fontOptions'>
            {
                options.length ? (
                    options.map((option, index) => (
                        <div
                            className='fontOptions__item'
                            onClick={ () => onSelect(option.value) }
                            key={ index }
                            role='presentation'
                        >
                            <Text
                                type={ txtTypes.regular }
                                size={ txtSizes.xsmall }
                                inner={ option.text }
                                style={{
                                    fontFamily: option.value
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <div className='fontOptions__item' style={ { textAlign: 'center' } }>
                        <Text
                            type={ txtTypes.regularDefault }
                            size={ txtSizes.small }
                            style={ { color: 'rgb(114, 121, 120)' } }
                            inner='No more results'
                        />
                    </div>
                )
            }
        </div>
    )
}

export default FontOptions