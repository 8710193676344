import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconNew from 'components/elements/iconsSize';
import Text, { SIZES as txtSizes, TYPES as txtType } from 'components/TextNew';
import './index.css';


const Info = ({ title, isHaveCancel = true, children }) => {
   const [closeInfo, setCloseInfo] = useState(false);
   if (closeInfo) {
      return null;
   }
   return (
      <div className='info'>
         <div className='info-left'>
            <div>
               <IconNew name='infoM' />
            </div>
            <Text
               inner={ title || '' }
               type={ txtType.regularDefault }
               size={ txtSizes.small }
            />
         </div>
         {isHaveCancel && (
            <div onClick={ () => setCloseInfo(true) } role='presentation'>
               <IconNew name='filledCloseM' />
            </div>
         )}
         {children}
      </div>
   );
};

Info.propTypes = {
   title: PropTypes.string,
   isHaveCancel: PropTypes.bool,
   children: PropTypes.any,
};

export default Info;
