const getCurrencySymbol = (symbolName) => {
    switch (symbolName) {
        case 'USD': return '$';
        case 'EUR': return '€';
        case 'GBP': return '£';
        case 'CAD': return '$';
        case 'AUD': return '$';
        case 'CNY': return '¥';
        case 'CHF': return 'CHF';
        case null: return '';
        case undefined: return '';
        default: return symbolName;
    }
};
export default getCurrencySymbol;
