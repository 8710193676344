import React from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../../contexts/AppContext';
import './index.css'
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew';
import IconNew from 'components/elements/iconsSize';
import Button, { THEMES as themes, SIZES as btinSizes } from 'components/BaseButtonNew';

const EditorTop = ({ viewMode, setViewMode, togglePreview }) => {
   return (
      <div className='editor__top'>
         <AppContext.Consumer>
            {({ state, functions }) => (
               <div className='editor__top__wrapper'>
                  <div className='editor__top__wrapper__left'  style={{ marginBottom: '9px'}}>
                     <Text
                        inner='Landing Template'
                        type={types.regular160}
                        size={sizes.large}
                     />
                  </div>
                  <div className='mobile__buttons editor__top__wrapper__actions'>
                     <div className='editor__top__wrapper__actions__undo'>
                        <div
                           className='editor__top__wrapper__actions__button'
                           role='presentation'
                           onClick={ () =>  functions.handleUndoRedo('undo') }
                        >
                           <IconNew
                              name='CheckoutUndoM'
                           />
                        </div>
                        <div
                           role='presentation'
                           onClick={ () => functions.handleUndoRedo('redo') }
                           className='editor__top__wrapper__actions__button'
                        >
                           <IconNew
                              name='CheckoutRedoM'
                           />
                        </div>
                     </div>
                     <div className='editor__top__wrapper__actions__line'></div>
                     <div className='mobile__buttons__wrapper'>
                        <Button
                           iconName='CheckoutActiveEyeM'
                           isIconRight={ true }
                           text='Preview Landing'
                           theme={ themes.secondary }
                           size={ btinSizes.small }
                           style={ { height: '44px', fontSize: '16px' } }
                           onClick ={ togglePreview }
                        />
                        <Button
                           text='Save'
                           theme={ themes.secondary }
                           size={ btinSizes.small }
                           style={ { minHeight: '44px', fontSize: '16px' } }
                           onClick={() =>
                              !state.save_button &&
                              functions.handleUpdateLanding('save')
                           }
                           disabled = {state.save_button}
                        />
                        <Button
                           text='Save & Exit'
                           style={ { minHeight: '44px', fontSize: '16px' } }
                           size={ btinSizes.small }
                           onClick={() =>
                              !state.save_button &&
                              functions.handleUpdateLanding('next')
                           }
                           disabled = {state.save_button}
                        />
                     </div>
                  </div>
                  <div className='editor__top__wrapper__sizes'>
                     <div className={ `editor__top__wrapper__sizes__button${ viewMode === '' ? ' editor__top__wrapper__sizes__active' : '' }` } role='presentation' onClick={ () => setViewMode('') }>
                        <IconNew name='CheckoutDesktopM' />
                     </div>
                     {/* <div className={ `editor__top__wrapper__sizes__button${ viewMode === 'tablet' ? ' editor__top__wrapper__sizes__active' : '' }` } role='presentation' onClick={ () => setViewMode('tablet') }>
                        <IconNew name='CheckoutTabletM' />
                     </div> */}
                     <div className={ `editor__top__wrapper__sizes__button${ viewMode === 'phone' ? ' editor__top__wrapper__sizes__active' : '' }` } role='presentation' onClick={ () => setViewMode('phone') }>
                        <IconNew name='CheckoutPhoneM' />
                     </div>
                  </div>
                  <div className='desktop__buttons editor__top__wrapper__actions'>
                     <div className='editor__top__wrapper__actions__undo'>
                        <div
                           className='editor__top__wrapper__actions__button'
                           role='presentation'
                           onClick={ () =>  functions.handleUndoRedo('undo') }
                        >
                           <IconNew
                              name='CheckoutUndoM'
                           />
                        </div>
                        <div
                           role='presentation'
                           onClick={ () => functions.handleUndoRedo('redo') }
                           className='editor__top__wrapper__actions__button'
                        >
                           <IconNew
                              name='CheckoutRedoM'
                           />
                        </div>
                     </div>
                     <div className='editor__top__wrapper__actions__line'></div>
                     <Button
                        iconName='CheckoutActiveEyeM'
                        isIconRight={ true }
                        text='Preview Landing'
                        theme={ themes.secondary }
                        size={ btinSizes.small }
                        style={ { height: '44px', fontSize: '16px' } }
                        onClick ={ togglePreview }
                     />
                     <Button
                        text='Save'
                        theme={ themes.secondary }
                        size={ btinSizes.small }
                        style={ { minHeight: '44px', fontSize: '16px' } }
                        onClick={() =>
                           !state.save_button &&
                           functions.handleUpdateLanding('save')
                        }
                        disabled = {state.save_button}
                     />
                     <Button
                        text='Save & Exit'
                        style={ { minHeight: '44px', fontSize: '16px' } }
                        size={ btinSizes.small }
                        onClick={() =>
                           !state.save_button &&
                           functions.handleUpdateLanding('next')
                        }
                        disabled = {state.save_button}
                     />
                  </div>
               </div>
            )}
         </AppContext.Consumer>
      </div>
   );
};

EditorTop.propTypes = {
   viewMode: PropTypes.string,
   togglePreview: PropTypes.func,
   setViewMode: PropTypes.func,
};

export default EditorTop;
