import slug from "../../utils/slugGenerator";

export const dragAndDropSections = [
    {
        section: {
            slug: slug(),
            name: 'Banner',
            props: {
                order: "",
                duplicated: 'Banner',
                visibility: true,
                bgVideoSrc: '',
                bgColor: '#000000',
                background_type: 'image',
                bgImgSrc: "https://miestro-production.s3.us-west-2.amazonaws.com/landing/landing22/hero.png",
                imageOverlay: true,
                alignmentAvalaible: true,
                alignment: 'center',
                bgColorOverlay: '#000000',
                paddingTop: 64,
                paddingLeft: 64,
                paddingBottom: 64,
                paddingRight: 64,
            }
        },
        components: [
            {
                slug: slug(),
                type: 'component',
                name: 'One Column New',
                props: {
                    text: 'Component Props',
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: 'Banner',
                        name: 'Banner Element',
                        props: {
                            title: "<span>Banner Title</span>",
                            color: "#ffffff",
                            fontFamily: 'Roboto-Black',
                            height: 55,
                            fontSize: 56,
                            textAlign: 'center',
                            titleShow: true,
                            paddingTop: 0,
                            paddingLeft: 0,
                            paddingBottom: 0,
                            paddingRight: 0,

                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }
                    }
                ]
            },
        ],
    },
    {
        section: {
            slug: slug(),
            name: 'CountdownTimer',
            props: {
                order: "",
                duplicated: 'CountdownTimer',
                visibility: true,
                bgVideoSrc: '',
                bgImgSrc: '',
                bgColor: '#ffffff',
                background_type: 'color',
                imageOverlay: true,
                alignmentAvalaible: true,
                alignment: 'center',
                bgColorOverlay: '#000000',
                paddingTop: 20,
                paddingLeft: 20,
                paddingBottom: 20,
                paddingRight: 20,
            }
        },
        components: [
            {
                slug: slug(),
                type: 'component',
                name: 'Four Column New',
                props: {
                    text: 'Component Props',
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: 'CountDownTimer',
                        name: 'Count Down Element',
                        avatarWithText: true,
                        props: {
                            color: "#34495e",
                            timerDuration: {
                                days: 0,
                                hours: 0,
                                minutes: 0,
                                seconds: 0,
                            },
                            timerStart: Math.round(Date.now() / 1000),
                        }
                    }
                ]
            },
        ],
    },
    {
        section: {
            slug: slug(),
            name: 'CustomCode',
            props: {
                order: "",
                duplicated: 'CustomCode',
                visibility: true,
                bgVideoSrc: '',
                bgImgSrc: '',
                bgColor: '#000000',
                background_type: 'color',
                imageOverlay: true,
                alignmentAvalaible: true,
                alignment: 'center',
                bgColorOverlay: '#000000',
                paddingTop: 10,
                paddingLeft: 10,
                paddingBottom: 10,
                paddingRight: 10,
            }
        },
        components: [
            {
                slug: slug(),
                type: 'component',
                name: 'One Column New',
                props: {
                    text: 'Component Props',
                },
                subcomponents: [
                    {
                        slug: slug(),
                        type: 'CustomCode',
                        name: 'Custom Code',
                        props: {
                            code: "<p style='font-size:18px; line-height: 1.78; color: #c9c9c9; text-align: center'>Custom code goes here...</p>"
                        }
                    }
                ]
            },
        ],
    },
]