import React from 'react';
import './index.css';
import PropTypes from 'prop-types';
import Icon from '../Icon';


export const SIZES = {
   big: 'big',
   medium: 'medium',
   small: 'small',
};

const Switch = ({
   label = false, value, onChange, disabled = false, size = 'big',  iconName, positionText = 'left',
}) => {
   return (
      <div className={ `switch switch__${ SIZES[size] }` }>
         {positionText === 'left' && label && (
            <div className='switch_text'>{label}</div>
         )}
         <label className='toggle'>
            <input className={ `toggle-checkbox ${ disabled ? `toggle_checkbox_${ value }` : '' }` } disabled={ disabled } type='checkbox' checked={ value } onChange={ (e) => {
               onChange(e.target.checked)
            } } />
            <div className={ `toggle-switch${ value ? '  toggle-switch-active ' : '' }${ disabled ? ` toggle_disabled_${ value }` : '' }` } />
         </label>
         {positionText === 'right' && label && (
            <div className='switch_text'>{label}</div>
         )}
      </div>
   );
};

Switch.propTypes = {
   label: PropTypes.string,
   onChange: PropTypes.func,
   value: PropTypes.bool,
   disabled: PropTypes.bool,
   size: PropTypes.string,
   tooltip: PropTypes.string,
   positionText: PropTypes.string,
   iconName: PropTypes.string,
};

export default Switch;
