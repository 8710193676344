import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_20",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#333333",
        regular_text_color: "#333333",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        [
            {
                "section": {
                    "id": 250,
                    "landing_id": 32,
                    "slug": "miestro-1694515132NXgjYCFDSX3WWrcaqpSatPFqGaPjKh13",
                    "name": "Header",
                    "props": {
                        "order": 0,
                        "bgColor": "#F0F2F2",
                        "spacing": "xs",
                        "bgImgSrc": "",
                        "bgVideoSrc": "",
                        "duplicated": "header",
                        "paddingTop": 24,
                        "visibility": true,
                        "paddingLeft": 72,
                        "paddingRight": 72,
                        "isBrandNewImg": true,
                        "paddingBottom": 24,
                        "backgroundLabel": "Background Image (1900x120)",
                        "background_type": "color",
                        "isWithLinksHeader": true
                    },
                    "order": 0,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 707,
                            "section_id": 250,
                            "slug": "miestro-1694515132LEPExYPP5Xfhh7bxkfApuB0MfVMcHhQm",
                            "type": "component",
                            "name": "Three Column",
                            "props": {
                                "text": "Component Props",
                                "isLogo": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132CD4a60rIQ5NnMVdNEH3zOONtMh3uAQRM",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-local.s3-us-west-2.amazonaws.com/681fadabec6c9baf787bf7089869c494/j1417dhdd_1693895285468.png",
                                        "text": "asdasda",
                                        "width": "104",
                                        "maxSize": "104",
                                        "radius": "0",
                                        "visibility": true,
                                        "isLogo": true,
                                        "imageLabel": "Show Image (104x22)",
                                        "isBrandNewImg": true,
                                        "alignmentisOn": false
                                    }
                                }
                            ]
                        },
                        {
                            "id": 708,
                            "section_id": 250,
                            "slug": "miestro-16945151326bfPL3QrazF9YpLRjKp6xdDhUmcoQmFe",
                            "type": "component",
                            "name": "Links",
                            "props": {
                                "text": "Component Props",
                                "isLink": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132o1UByUunNVvHEMuMW7wE6TQB4YDjXON9",
                                    "type": "LinkMi",
                                    "name": "Link",
                                    "props": {
                                        "text": "Home",
                                        "bgColor": "inherit",
                                        "textColor": "#131F1E",
                                        "href": "",
                                        "scroll_to": "",
                                        "size": "medium",
                                        "fontFamily": "Roboto-Bold",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "allCaps": false,
                                        "visibility": true,
                                        "deleted": false,
                                        "target": "_blank",
                                        "availableDelete": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132HcecARhtLfBx4QKqgGDZRJ9QKqo30qgo",
                                    "type": "LinkMi",
                                    "name": "Link",
                                    "props": {
                                        "text": "Services",
                                        "bgColor": "inherit",
                                        "textColor": "#131F1E",
                                        "href": "",
                                        "scroll_to": "",
                                        "size": "medium",
                                        "fontFamily": "Roboto-Bold",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "allCaps": false,
                                        "visibility": true,
                                        "deleted": false,
                                        "target": "_blank",
                                        "availableDelete": true
                                    }
                                },
                                {
                                    "slug": "miestro-16945151329njYQnasUTjvk2v35E5bpuw2CssYUTEx",
                                    "type": "LinkMi",
                                    "name": "Link",
                                    "props": {
                                        "text": "Work",
                                        "bgColor": "inherit",
                                        "textColor": "#131F1E",
                                        "href": "",
                                        "scroll_to": "",
                                        "size": "medium",
                                        "fontFamily": "Roboto-Bold",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "allCaps": false,
                                        "visibility": true,
                                        "deleted": false,
                                        "target": "_blank",
                                        "availableDelete": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132ATxYyoDeXPS3lvMbi1qo4bAIkgSmduJa",
                                    "type": "LinkMi",
                                    "name": "Link",
                                    "props": {
                                        "text": "About",
                                        "bgColor": "inherit",
                                        "textColor": "#131F1E",
                                        "href": "",
                                        "scroll_to": "",
                                        "size": "medium",
                                        "fontFamily": "Roboto-Bold",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "allCaps": false,
                                        "visibility": true,
                                        "deleted": false,
                                        "target": "_blank",
                                        "availableDelete": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132soDke09tIl4rKKyFlejOxzVTprboyfMF",
                                    "type": "LinkMi",
                                    "name": "Link",
                                    "props": {
                                        "text": "About",
                                        "bgColor": "inherit",
                                        "textColor": "#131F1E",
                                        "href": "",
                                        "scroll_to": "",
                                        "size": "medium",
                                        "fontFamily": "Roboto-Bold",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "allCaps": false,
                                        "visibility": true,
                                        "deleted": false,
                                        "target": "_blank",
                                        "availableDelete": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 709,
                            "section_id": 250,
                            "slug": "miestro-1694515132ESBt7PLeT3VPLhnMomKpLlyGINi5senj",
                            "type": "component",
                            "name": "Three Column",
                            "props": {
                                "text": "Component Props",
                                "isLoginButton": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132GUogkCA2lTDUNVoalXrZqsXGaNvPzNYH",
                                    "type": "ButtonMi",
                                    "name": "Call to Action",
                                    "props": {
                                        "text": "Log In",
                                        "bgColor": "linear-gradient(to right, #fe985b 3%, #fe4fa7)",
                                        "textColor": "#ffffff",
                                        "href": "",
                                        "size": "medium-large",
                                        "border": "solid",
                                        "blanked": true,
                                        "alignmentisOn": false,
                                        "allCaps": false,
                                        "fontFamily": "Lato-Bold",
                                        "fontStyle": "bold",
                                        "textSize": 18,
                                        "widthValue": 192,
                                        "isLoginButton": true,
                                        "borderRadius": 4
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 707,
                        "section_id": 250,
                        "slug": "miestro-1694515132LEPExYPP5Xfhh7bxkfApuB0MfVMcHhQm",
                        "type": "component",
                        "name": "Three Column",
                        "props": {
                            "text": "Component Props",
                            "isLogo": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132CD4a60rIQ5NnMVdNEH3zOONtMh3uAQRM",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-local.s3-us-west-2.amazonaws.com/681fadabec6c9baf787bf7089869c494/j1417dhdd_1693895285468.png",
                                    "text": "asdasda",
                                    "width": "104",
                                    "maxSize": "104",
                                    "radius": "0",
                                    "visibility": true,
                                    "isLogo": true,
                                    "imageLabel": "Show Image (104x22)",
                                    "isBrandNewImg": true,
                                    "alignmentisOn": false
                                }
                            }
                        ]
                    },
                    {
                        "id": 708,
                        "section_id": 250,
                        "slug": "miestro-16945151326bfPL3QrazF9YpLRjKp6xdDhUmcoQmFe",
                        "type": "component",
                        "name": "Links",
                        "props": {
                            "text": "Component Props",
                            "isLink": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132o1UByUunNVvHEMuMW7wE6TQB4YDjXON9",
                                "type": "LinkMi",
                                "name": "Link",
                                "props": {
                                    "text": "Home",
                                    "bgColor": "inherit",
                                    "textColor": "#131F1E",
                                    "href": "",
                                    "scroll_to": "",
                                    "size": "medium",
                                    "fontFamily": "Roboto-Bold",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "allCaps": false,
                                    "visibility": true,
                                    "deleted": false,
                                    "target": "_blank",
                                    "availableDelete": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132HcecARhtLfBx4QKqgGDZRJ9QKqo30qgo",
                                "type": "LinkMi",
                                "name": "Link",
                                "props": {
                                    "text": "Services",
                                    "bgColor": "inherit",
                                    "textColor": "#131F1E",
                                    "href": "",
                                    "scroll_to": "",
                                    "size": "medium",
                                    "fontFamily": "Roboto-Bold",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "allCaps": false,
                                    "visibility": true,
                                    "deleted": false,
                                    "target": "_blank",
                                    "availableDelete": true
                                }
                            },
                            {
                                "slug": "miestro-16945151329njYQnasUTjvk2v35E5bpuw2CssYUTEx",
                                "type": "LinkMi",
                                "name": "Link",
                                "props": {
                                    "text": "Work",
                                    "bgColor": "inherit",
                                    "textColor": "#131F1E",
                                    "href": "",
                                    "scroll_to": "",
                                    "size": "medium",
                                    "fontFamily": "Roboto-Bold",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "allCaps": false,
                                    "visibility": true,
                                    "deleted": false,
                                    "target": "_blank",
                                    "availableDelete": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132ATxYyoDeXPS3lvMbi1qo4bAIkgSmduJa",
                                "type": "LinkMi",
                                "name": "Link",
                                "props": {
                                    "text": "About",
                                    "bgColor": "inherit",
                                    "textColor": "#131F1E",
                                    "href": "",
                                    "scroll_to": "",
                                    "size": "medium",
                                    "fontFamily": "Roboto-Bold",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "allCaps": false,
                                    "visibility": true,
                                    "deleted": false,
                                    "target": "_blank",
                                    "availableDelete": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132soDke09tIl4rKKyFlejOxzVTprboyfMF",
                                "type": "LinkMi",
                                "name": "Link",
                                "props": {
                                    "text": "About",
                                    "bgColor": "inherit",
                                    "textColor": "#131F1E",
                                    "href": "",
                                    "scroll_to": "",
                                    "size": "medium",
                                    "fontFamily": "Roboto-Bold",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "allCaps": false,
                                    "visibility": true,
                                    "deleted": false,
                                    "target": "_blank",
                                    "availableDelete": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 709,
                        "section_id": 250,
                        "slug": "miestro-1694515132ESBt7PLeT3VPLhnMomKpLlyGINi5senj",
                        "type": "component",
                        "name": "Three Column",
                        "props": {
                            "text": "Component Props",
                            "isLoginButton": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132GUogkCA2lTDUNVoalXrZqsXGaNvPzNYH",
                                "type": "ButtonMi",
                                "name": "Call to Action",
                                "props": {
                                    "text": "Log In",
                                    "bgColor": "linear-gradient(to right, #fe985b 3%, #fe4fa7)",
                                    "textColor": "#ffffff",
                                    "href": "",
                                    "size": "medium-large",
                                    "border": "solid",
                                    "blanked": true,
                                    "alignmentisOn": false,
                                    "allCaps": false,
                                    "fontFamily": "Lato-Bold",
                                    "fontStyle": "bold",
                                    "textSize": 18,
                                    "widthValue": 192,
                                    "isLoginButton": true,
                                    "borderRadius": 4
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "section": {
                    "id": 251,
                    "landing_id": 32,
                    "slug": "miestro-1694515132AzsHwo6MFc7y948PuqzjSFip3qwiQHXd",
                    "name": "Text with Image",
                    "props": {
                        "order": 1,
                        "bgColor": "#F0F2F2",
                        "spacing": "lg",
                        "bgImgSrc": "",
                        "reversed": false,
                        "bgVideoSrc": "",
                        "duplicated": "ld20_1",
                        "paddingTop": 144,
                        "visibility": true,
                        "isBrandNewImg": true,
                        "paddingBottom": 80,
                        "bgColorOverlay": "#F0F2F2",
                        "backgroundLabel": "Background Image (1900x760)",
                        "background_type": "color",
                        "reverseAvailaible": true
                    },
                    "order": 1,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 710,
                            "section_id": 251,
                            "slug": "miestro-1694515132pGEMkKySXGeFhewuHLxCjyy1q6aQUkoX",
                            "type": "component",
                            "name": "Two Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132imBa3r3KiidrAHwS51fyZGj7P3VOBDpX",
                                    "type": "Text",
                                    "name": "Title",
                                    "props": {
                                        "align": "left",
                                        "text": "<span>Learn how to create digital products</span>",
                                        "color": "#000000",
                                        "fontFamily": "Roboto-Regular",
                                        "fontSize": 44,
                                        "centerText": true,
                                        "paddingTop": 40,
                                        "paddingBottom": 10
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132lTrM9PAKaRR958AnReaIa6EsehViGfhg",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "left",
                                        "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                        "color": "#686868",
                                        "fontFamily": "Roboto-Regular",
                                        "fontSize": 14,
                                        "paddingTop": 10,
                                        "paddingBottom": 16,
                                        "centerText": false
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132JSuuDS4M421vew2nzXDtzRCxFVgbmKKJ",
                                    "type": "ButtonMi",
                                    "name": "Call to Action",
                                    "props": {
                                        "text": "Start Project",
                                        "bgColor": "#000000",
                                        "textColor": "#FFFFFF",
                                        "href": "",
                                        "size": "medium",
                                        "widthChangeable": true,
                                        "border": "none",
                                        "borderRadius": 0,
                                        "fontFamily": "Roboto-Medium",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "widthValue": 142,
                                        "allCaps": false,
                                        "marginTop": 16,
                                        "marginBottom": 0,
                                        "marginRight": 24,
                                        "marginLeft": 0
                                    }
                                },
                                {
                                    "slug": "miestro-16945151321wVrXsdND00v1DpX6sWcQUmkutEOa4rB",
                                    "type": "ButtonMi",
                                    "name": "Call to Action",
                                    "props": {
                                        "text": "Who We Are",
                                        "bgColor": "#000000",
                                        "textColor": "#000000",
                                        "href": "",
                                        "size": "medium",
                                        "widthChangeable": true,
                                        "border": "solid",
                                        "borderRadius": 0,
                                        "fontFamily": "Roboto-Medium",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "widthValue": 140,
                                        "allCaps": false,
                                        "marginTop": 16,
                                        "marginBottom": 0,
                                        "marginLeft": 16,
                                        "marginRight": 0
                                    }
                                }
                            ]
                        },
                        {
                            "id": 711,
                            "section_id": 251,
                            "slug": "miestro-16945151328PL5TAuJSXltEGqlsnrMHe8gho8KnA2J",
                            "type": "component",
                            "name": "Two Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132HBpQtptn0LHLsQg6hICf7oiXPZ2jH2nQ",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing18/hero_img.jpg",
                                        "width": "636",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (580)x420)",
                                        "isBrandNewImg": true
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 710,
                        "section_id": 251,
                        "slug": "miestro-1694515132pGEMkKySXGeFhewuHLxCjyy1q6aQUkoX",
                        "type": "component",
                        "name": "Two Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132imBa3r3KiidrAHwS51fyZGj7P3VOBDpX",
                                "type": "Text",
                                "name": "Title",
                                "props": {
                                    "align": "left",
                                    "text": "<span>Learn how to create digital products</span>",
                                    "color": "#000000",
                                    "fontFamily": "Roboto-Regular",
                                    "fontSize": 44,
                                    "centerText": true,
                                    "paddingTop": 40,
                                    "paddingBottom": 10
                                }
                            },
                            {
                                "slug": "miestro-1694515132lTrM9PAKaRR958AnReaIa6EsehViGfhg",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "left",
                                    "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                    "color": "#686868",
                                    "fontFamily": "Roboto-Regular",
                                    "fontSize": 14,
                                    "paddingTop": 10,
                                    "paddingBottom": 16,
                                    "centerText": false
                                }
                            },
                            {
                                "slug": "miestro-1694515132JSuuDS4M421vew2nzXDtzRCxFVgbmKKJ",
                                "type": "ButtonMi",
                                "name": "Call to Action",
                                "props": {
                                    "text": "Start Project",
                                    "bgColor": "#000000",
                                    "textColor": "#FFFFFF",
                                    "href": "",
                                    "size": "medium",
                                    "widthChangeable": true,
                                    "border": "none",
                                    "borderRadius": 0,
                                    "fontFamily": "Roboto-Medium",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "widthValue": 142,
                                    "allCaps": false,
                                    "marginTop": 16,
                                    "marginBottom": 0,
                                    "marginRight": 24,
                                    "marginLeft": 0
                                }
                            },
                            {
                                "slug": "miestro-16945151321wVrXsdND00v1DpX6sWcQUmkutEOa4rB",
                                "type": "ButtonMi",
                                "name": "Call to Action",
                                "props": {
                                    "text": "Who We Are",
                                    "bgColor": "#000000",
                                    "textColor": "#000000",
                                    "href": "",
                                    "size": "medium",
                                    "widthChangeable": true,
                                    "border": "solid",
                                    "borderRadius": 0,
                                    "fontFamily": "Roboto-Medium",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "widthValue": 140,
                                    "allCaps": false,
                                    "marginTop": 16,
                                    "marginBottom": 0,
                                    "marginLeft": 16,
                                    "marginRight": 0
                                }
                            }
                        ]
                    },
                    {
                        "id": 711,
                        "section_id": 251,
                        "slug": "miestro-16945151328PL5TAuJSXltEGqlsnrMHe8gho8KnA2J",
                        "type": "component",
                        "name": "Two Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132HBpQtptn0LHLsQg6hICf7oiXPZ2jH2nQ",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing18/hero_img.jpg",
                                    "width": "636",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (580)x420)",
                                    "isBrandNewImg": true
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "section": {
                    "id": 252,
                    "landing_id": 32,
                    "slug": "miestro-1694515132pXVtj4Ih6Mi37N9LPZznvCw0uLGdYfN4",
                    "name": "Video",
                    "props": {
                        "order": 2,
                        "bgColor": "#ffffff",
                        "spacing": "lg",
                        "bgImgSrc": "",
                        "reversed": false,
                        "minHeight": 565,
                        "bgVideoSrc": "",
                        "duplicated": "ld20_2",
                        "paddingTop": 80,
                        "visibility": true,
                        "isBrandNewImg": true,
                        "paddingBottom": 80,
                        "bgColorOverlay": "#ffffff",
                        "backgroundLabel": "Background Image (1900x760)",
                        "background_type": "color",
                        "reverseAvailaible": true
                    },
                    "order": 2,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 712,
                            "section_id": 252,
                            "slug": "miestro-1694515132rO4Qwz3zwOxdR5lcgXSXvrrUpxVV1h0t",
                            "type": "component",
                            "name": "Two Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-16945151322hhLgpJNhvrxVpDzWMzXfgqdTdmLhqrX",
                                    "type": "Video",
                                    "name": "Video",
                                    "props": {
                                        "src": "https://www.youtube.com/embed/RVjxFLTCngw",
                                        "borderRadius": 12,
                                        "visibility": true,
                                        "maxWidth": "720",
                                        "centerText": true
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 712,
                        "section_id": 252,
                        "slug": "miestro-1694515132rO4Qwz3zwOxdR5lcgXSXvrrUpxVV1h0t",
                        "type": "component",
                        "name": "Two Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-16945151322hhLgpJNhvrxVpDzWMzXfgqdTdmLhqrX",
                                "type": "Video",
                                "name": "Video",
                                "props": {
                                    "src": "https://www.youtube.com/embed/RVjxFLTCngw",
                                    "borderRadius": 12,
                                    "visibility": true,
                                    "maxWidth": "720",
                                    "centerText": true
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "section": {
                    "id": 253,
                    "landing_id": 32,
                    "slug": "miestro-16945151325FZbctIvz7GnZmaJf7IcrhxUOVQMihby",
                    "name": "Text with Image",
                    "props": {
                        "order": 3,
                        "bgColor": "#FFFFFF",
                        "bgImgSrc": "",
                        "reversed": false,
                        "bgVideoSrc": "",
                        "duplicated": "ld20_3",
                        "paddingTop": 100,
                        "visibility": true,
                        "isBrandNewImg": true,
                        "paddingBottom": 100,
                        "bgColorOverlay": "#ffffff",
                        "backgroundLabel": "Background Image (1900x760)",
                        "background_type": "color",
                        "reverseAvailaible": true
                    },
                    "order": 3,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 713,
                            "section_id": 253,
                            "slug": "miestro-1694515132iCTDAfH8ce0uJ7SDapQvFk4ZtWkEoqLI",
                            "type": "component",
                            "name": "Two Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132t2j07nXm7AjySNCs4WYCdLDyo361dXqo",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/orange_pink.png",
                                        "width": "570",
                                        "radius": "12",
                                        "visibility": true,
                                        "imageLabel": "Show Image (570x290)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true,
                                        "align": "center"
                                    }
                                }
                            ]
                        },
                        {
                            "id": 714,
                            "section_id": 253,
                            "slug": "miestro-1694515132Ip3yhLfU3q9yW7e99oKA0cvucAIsqLR5",
                            "type": "component",
                            "name": "Two Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132f92ZyBrMZ6MdNByJeVdScOMyV8AcbY0A",
                                    "type": "Text",
                                    "name": "Title",
                                    "props": {
                                        "align": "left",
                                        "text": "<span>Our best works</span>",
                                        "color": "#000000",
                                        "fontFamily": "Lato-Regular",
                                        "fontSize": 32,
                                        "maxWidth": "none",
                                        "centerText": false,
                                        "paddingBottom": 6
                                    }
                                },
                                {
                                    "slug": "miestro-16945151323jIvbRdCxPoTyaKHG2jynqtFAvOsTgqH",
                                    "type": "Text",
                                    "name": "Title",
                                    "props": {
                                        "align": "left",
                                        "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "maxWidth": "none",
                                        "centerText": false,
                                        "paddingTop": 6,
                                        "paddingBottom": 16
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132OOCF2LdwmyI6zhlgZSPS8g1luL5RtfkC",
                                    "type": "ButtonMi",
                                    "name": "Call to Action",
                                    "props": {
                                        "text": "View All",
                                        "bgColor": "#000000",
                                        "textColor": "#FFFFFF",
                                        "href": "",
                                        "size": "medium",
                                        "widthChangeable": true,
                                        "border": "none",
                                        "borderRadius": 0,
                                        "fontFamily": "Roboto-Medium",
                                        "fontStyle": "normal",
                                        "textSize": 14,
                                        "widthValue": 114,
                                        "allCaps": false,
                                        "marginTop": 16,
                                        "marginBottom": 0,
                                        "marginRight": 0,
                                        "marginLeft": 0
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 713,
                        "section_id": 253,
                        "slug": "miestro-1694515132iCTDAfH8ce0uJ7SDapQvFk4ZtWkEoqLI",
                        "type": "component",
                        "name": "Two Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132t2j07nXm7AjySNCs4WYCdLDyo361dXqo",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/orange_pink.png",
                                    "width": "570",
                                    "radius": "12",
                                    "visibility": true,
                                    "imageLabel": "Show Image (570x290)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true,
                                    "align": "center"
                                }
                            }
                        ]
                    },
                    {
                        "id": 714,
                        "section_id": 253,
                        "slug": "miestro-1694515132Ip3yhLfU3q9yW7e99oKA0cvucAIsqLR5",
                        "type": "component",
                        "name": "Two Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132f92ZyBrMZ6MdNByJeVdScOMyV8AcbY0A",
                                "type": "Text",
                                "name": "Title",
                                "props": {
                                    "align": "left",
                                    "text": "<span>Our best works</span>",
                                    "color": "#000000",
                                    "fontFamily": "Lato-Regular",
                                    "fontSize": 32,
                                    "maxWidth": "none",
                                    "centerText": false,
                                    "paddingBottom": 6
                                }
                            },
                            {
                                "slug": "miestro-16945151323jIvbRdCxPoTyaKHG2jynqtFAvOsTgqH",
                                "type": "Text",
                                "name": "Title",
                                "props": {
                                    "align": "left",
                                    "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "maxWidth": "none",
                                    "centerText": false,
                                    "paddingTop": 6,
                                    "paddingBottom": 16
                                }
                            },
                            {
                                "slug": "miestro-1694515132OOCF2LdwmyI6zhlgZSPS8g1luL5RtfkC",
                                "type": "ButtonMi",
                                "name": "Call to Action",
                                "props": {
                                    "text": "View All",
                                    "bgColor": "#000000",
                                    "textColor": "#FFFFFF",
                                    "href": "",
                                    "size": "medium",
                                    "widthChangeable": true,
                                    "border": "none",
                                    "borderRadius": 0,
                                    "fontFamily": "Roboto-Medium",
                                    "fontStyle": "normal",
                                    "textSize": 14,
                                    "widthValue": 114,
                                    "allCaps": false,
                                    "marginTop": 16,
                                    "marginBottom": 0,
                                    "marginRight": 0,
                                    "marginLeft": 0
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "section": {
                    "id": 254,
                    "landing_id": 32,
                    "slug": "miestro-1694515132xBcZSSCqVap8hJjC6uepxXt23vshyUWR",
                    "name": "Three Feature Column",
                    "props": {
                        "order": 4,
                        "bgColor": "#F0F2F2",
                        "spacing": "lg",
                        "bgImgSrc": "",
                        "bgVideoSrc": "",
                        "duplicated": "ld20_4",
                        "paddingTop": 100,
                        "visibility": true,
                        "paddingLeft": 0,
                        "paddingRight": 0,
                        "isBrandNewImg": true,
                        "paddingBottom": 100,
                        "bgColorOverlay": "#F0F2F2",
                        "background_type": "color"
                    },
                    "order": 4,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 715,
                            "section_id": 254,
                            "slug": "miestro-1694515132N4PjE5xOIcuqnGeTHYUQi3MEIzQELYqR",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132JUnLsgkWk5FJPplmcYH6GAY31Yvcm35F",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Instructurs</span>",
                                        "color": "#000000",
                                        "fontSize": 32,
                                        "paddingTop": 0,
                                        "paddingBottom": 22,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 716,
                            "section_id": 254,
                            "slug": "miestro-1694515132UF1a2owJ59ZdcWMwM2aNXtDxT89OTESB",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "isType": "Group",
                                "maxWidth": 240,
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132KmlsR3EBXryih3MwAQRBCbc10P011SUy",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/icon.png",
                                        "width": "100",
                                        "height": "100",
                                        "radius": "100% ",
                                        "visibility": true,
                                        "imageLabel": "Show Image (100x100)",
                                        "borderRadius": 100,
                                        "isBrandNewImg": true,
                                        "align": "center"
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132HNhMcdBqtzaVv028jX6tP8FJR4UbbMBD",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Elizabeth Martin</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132bG0q55rE36P3sPCb2eRZOwrN8UzKmSDU",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "paddingTop": 12,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 717,
                            "section_id": 254,
                            "slug": "miestro-1694515132DNOPgf9ucI2AwD2osdpVPzuVe1fuL4HT",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "isType": "Group",
                                "maxWidth": 240,
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132yaYYgvjCBtjGwvT3skmY0cFlZ7D821VH",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/icon.png",
                                        "width": "100",
                                        "height": "100",
                                        "radius": "100% ",
                                        "visibility": true,
                                        "imageLabel": "Show Image (100x100)",
                                        "borderRadius": 100,
                                        "isBrandNewImg": true,
                                        "align": "center"
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132vmuRvGEfD3MjNbIUb08vYtOfVmeHGT8G",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Emery Vetrovs</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132A9lFHt2CaSic7y8vVgibhxgu8jLJ4L6R",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "paddingTop": 12,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 718,
                            "section_id": 254,
                            "slug": "miestro-16945151325K8vOF9RxwBNeP9nvYOhU0oMBb3B2gyt",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "isType": "Group",
                                "maxWidth": 240,
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132FvV91mC6Hfr2Sxbd3MQ4OkHDN3fHyAPM",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/icon.png",
                                        "width": "100",
                                        "height": "100",
                                        "radius": "100% ",
                                        "visibility": true,
                                        "imageLabel": "Show Image (100x100)",
                                        "borderRadius": 100,
                                        "isBrandNewImg": true,
                                        "align": "center"
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132OQ5b8EmgzxJblunh8roBcTpsnpWBnJKs",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Jaxson Botosh</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132Xjr6USrl64I1qyAd1MGP5NWq4tX3PD3I",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "paddingTop": 12,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 715,
                        "section_id": 254,
                        "slug": "miestro-1694515132N4PjE5xOIcuqnGeTHYUQi3MEIzQELYqR",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132JUnLsgkWk5FJPplmcYH6GAY31Yvcm35F",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Instructurs</span>",
                                    "color": "#000000",
                                    "fontSize": 32,
                                    "paddingTop": 0,
                                    "paddingBottom": 22,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 716,
                        "section_id": 254,
                        "slug": "miestro-1694515132UF1a2owJ59ZdcWMwM2aNXtDxT89OTESB",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "isType": "Group",
                            "maxWidth": 240,
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132KmlsR3EBXryih3MwAQRBCbc10P011SUy",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/icon.png",
                                    "width": "100",
                                    "height": "100",
                                    "radius": "100% ",
                                    "visibility": true,
                                    "imageLabel": "Show Image (100x100)",
                                    "borderRadius": 100,
                                    "isBrandNewImg": true,
                                    "align": "center"
                                }
                            },
                            {
                                "slug": "miestro-1694515132HNhMcdBqtzaVv028jX6tP8FJR4UbbMBD",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Elizabeth Martin</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132bG0q55rE36P3sPCb2eRZOwrN8UzKmSDU",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "paddingTop": 12,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 717,
                        "section_id": 254,
                        "slug": "miestro-1694515132DNOPgf9ucI2AwD2osdpVPzuVe1fuL4HT",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "isType": "Group",
                            "maxWidth": 240,
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132yaYYgvjCBtjGwvT3skmY0cFlZ7D821VH",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/icon.png",
                                    "width": "100",
                                    "height": "100",
                                    "radius": "100% ",
                                    "visibility": true,
                                    "imageLabel": "Show Image (100x100)",
                                    "borderRadius": 100,
                                    "isBrandNewImg": true,
                                    "align": "center"
                                }
                            },
                            {
                                "slug": "miestro-1694515132vmuRvGEfD3MjNbIUb08vYtOfVmeHGT8G",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Emery Vetrovs</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132A9lFHt2CaSic7y8vVgibhxgu8jLJ4L6R",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "paddingTop": 12,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 718,
                        "section_id": 254,
                        "slug": "miestro-16945151325K8vOF9RxwBNeP9nvYOhU0oMBb3B2gyt",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "isType": "Group",
                            "maxWidth": 240,
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132FvV91mC6Hfr2Sxbd3MQ4OkHDN3fHyAPM",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/icon.png",
                                    "width": "100",
                                    "height": "100",
                                    "radius": "100% ",
                                    "visibility": true,
                                    "imageLabel": "Show Image (100x100)",
                                    "borderRadius": 100,
                                    "isBrandNewImg": true,
                                    "align": "center"
                                }
                            },
                            {
                                "slug": "miestro-1694515132OQ5b8EmgzxJblunh8roBcTpsnpWBnJKs",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Jaxson Botosh</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132Xjr6USrl64I1qyAd1MGP5NWq4tX3PD3I",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Designspacee creates digital products that stands out and fulfills your business needs.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "paddingTop": 12,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "section": {
                    "id": 255,
                    "landing_id": 32,
                    "slug": "miestro-1694515132R74SfxPgWqLTm75wNEpCFHFWuu1eJLbn",
                    "name": "Text with Images",
                    "props": {
                        "order": 5,
                        "bgColor": "#ffffff",
                        "bgImgSrc": "",
                        "bgVideoSrc": "",
                        "duplicated": "ld20_5",
                        "paddingTop": 100,
                        "visibility": true,
                        "paddingLeft": 72,
                        "paddingRight": 72,
                        "isBrandNewImg": true,
                        "paddingBottom": 100,
                        "bgColorOverlay": "#ffffff",
                        "background_type": "color"
                    },
                    "order": 5,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 719,
                            "section_id": 255,
                            "slug": "miestro-1694515132HNPHnfuSzBzcEtpalgy1WxpJiUG3R4y2",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132ZjTUZnkrIEKv3T0MZvpBPNxFeOmbt8sC",
                                    "type": "Text",
                                    "name": "Title",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Our Categories</span>",
                                        "color": "#000000",
                                        "fontSize": 32,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132HdHM7uj6NkQkVDB7EnVRRpjYgvkDyaGg",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "start",
                                        "text": "<span>Diam amet convallis in nam vitae, curabitur. Duis maecenas quis quam sem ut hendrerit tincidunt. Lorem interdumt.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "centerText": false
                                    }
                                }
                            ]
                        },
                        {
                            "id": 720,
                            "section_id": 255,
                            "slug": "miestro-1694515132i1lMCJanR5GiCdvCjLMqAs4jYfvRn4Nl",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132qgJvFH93nc9rpkX9RTttSKx9hv18hcBP",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Digital marketing</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 0,
                                        "paddingBottom": 44,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132Nocs8Xaznxfo2rNPYsgxLoK5nlzHPKRa",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "212",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (310x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 721,
                            "section_id": 255,
                            "slug": "miestro-1694515132lOJCjKC1pJl6uot1x3LUHwYSt08ZEzYC",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132vGMZeYZQwKf868HkMoRZAl3BGiH8LCJS",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Web designing</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 0,
                                        "paddingBottom": 44,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132fREViPuPLz2EmSgrWNZXUslUWYdF7YQM",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "275",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (310x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 722,
                            "section_id": 255,
                            "slug": "miestro-1694515132XGDvTeV49H8pryRK3Yr4ofGUWgudY66p",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132odhRb673oE1abb12DkXlvQJblEzapOg9",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Influencer</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 0,
                                        "paddingBottom": 44,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132LHZaFaLQilJbqS2ITEDRKE5yLc1GwzlH",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "202",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (310x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 723,
                            "section_id": 255,
                            "slug": "miestro-1694515132OVyALnhwP2A0dPRcMc8MKu2aKaeHqUMi",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132EJFmGHGN8FhC5TTVRw6jlS3bqhir9noI",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Branding</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 0,
                                        "paddingBottom": 44,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132iXU5nZOlqzyEfRdOEG5EZQuw9W2ohSHd",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "211",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (310x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 724,
                            "section_id": 255,
                            "slug": "miestro-1694515132xtbWJxRlF9wMnVOlosk0EFVcDDsx89NB",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-16945151327BfoAP5ErSSgLA9IoUupEdsbYlOQSmRf",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>E-commerce</span>",
                                        "color": "#000000",
                                        "fontSize": 16,
                                        "paddingTop": 0,
                                        "paddingBottom": 44,
                                        "maxWidth": "none",
                                        "width": 100,
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132u9qMMfs0IuvDJuqGB8ZsunQajBWY9jrb",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "184",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (310x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 719,
                        "section_id": 255,
                        "slug": "miestro-1694515132HNPHnfuSzBzcEtpalgy1WxpJiUG3R4y2",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132ZjTUZnkrIEKv3T0MZvpBPNxFeOmbt8sC",
                                "type": "Text",
                                "name": "Title",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Our Categories</span>",
                                    "color": "#000000",
                                    "fontSize": 32,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132HdHM7uj6NkQkVDB7EnVRRpjYgvkDyaGg",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "start",
                                    "text": "<span>Diam amet convallis in nam vitae, curabitur. Duis maecenas quis quam sem ut hendrerit tincidunt. Lorem interdumt.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "centerText": false
                                }
                            }
                        ]
                    },
                    {
                        "id": 720,
                        "section_id": 255,
                        "slug": "miestro-1694515132i1lMCJanR5GiCdvCjLMqAs4jYfvRn4Nl",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132qgJvFH93nc9rpkX9RTttSKx9hv18hcBP",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Digital marketing</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 0,
                                    "paddingBottom": 44,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132Nocs8Xaznxfo2rNPYsgxLoK5nlzHPKRa",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "212",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (310x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 721,
                        "section_id": 255,
                        "slug": "miestro-1694515132lOJCjKC1pJl6uot1x3LUHwYSt08ZEzYC",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132vGMZeYZQwKf868HkMoRZAl3BGiH8LCJS",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Web designing</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 0,
                                    "paddingBottom": 44,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132fREViPuPLz2EmSgrWNZXUslUWYdF7YQM",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "275",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (310x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 722,
                        "section_id": 255,
                        "slug": "miestro-1694515132XGDvTeV49H8pryRK3Yr4ofGUWgudY66p",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132odhRb673oE1abb12DkXlvQJblEzapOg9",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Influencer</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 0,
                                    "paddingBottom": 44,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132LHZaFaLQilJbqS2ITEDRKE5yLc1GwzlH",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "202",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (310x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 723,
                        "section_id": 255,
                        "slug": "miestro-1694515132OVyALnhwP2A0dPRcMc8MKu2aKaeHqUMi",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132EJFmGHGN8FhC5TTVRw6jlS3bqhir9noI",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Branding</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 0,
                                    "paddingBottom": 44,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132iXU5nZOlqzyEfRdOEG5EZQuw9W2ohSHd",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "211",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (310x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 724,
                        "section_id": 255,
                        "slug": "miestro-1694515132xtbWJxRlF9wMnVOlosk0EFVcDDsx89NB",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-16945151327BfoAP5ErSSgLA9IoUupEdsbYlOQSmRf",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>E-commerce</span>",
                                    "color": "#000000",
                                    "fontSize": 16,
                                    "paddingTop": 0,
                                    "paddingBottom": 44,
                                    "maxWidth": "none",
                                    "width": 100,
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132u9qMMfs0IuvDJuqGB8ZsunQajBWY9jrb",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "184",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (310x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "section": {
                    "id": 256,
                    "landing_id": 32,
                    "slug": "miestro-1694515132raVK2HByaT7r5JfuJ7VJ58MvHykjeX7m",
                    "name": "Text with Images",
                    "props": {
                        "order": 5,
                        "bgColor": "#F0F2F2",
                        "bgImgSrc": "",
                        "bgVideoSrc": "",
                        "duplicated": "ld20_6",
                        "paddingTop": 100,
                        "visibility": true,
                        "paddingLeft": 72,
                        "paddingRight": 72,
                        "isBrandNewImg": true,
                        "paddingBottom": 100,
                        "bgColorOverlay": "#F0F2F2",
                        "background_type": "color"
                    },
                    "order": 5,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 725,
                            "section_id": 256,
                            "slug": "miestro-1694515132nCbM8x6v5Z4gaN3GghRo0kQB4bnMyncz",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132XJZ0bdcmw2CBLi03lLVOYRxSvkWr06ai",
                                    "type": "Text",
                                    "name": "Title",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Featured Courses</span>",
                                        "color": "#000000",
                                        "fontSize": 32,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-16945151321GCFj93tHiNu3p6gPKQPOLmmkhIciqlb",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "paddingTop": 16,
                                        "centerText": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 726,
                            "section_id": 256,
                            "slug": "miestro-1694515132zPpxg7HgJD55KR7IZQEsnXEAAcC8qZKa",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132FI7eIwGEv5E3rRZTPRll8oqfLYXShlH6",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "337",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (330x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132vOSBel5DPLIDj9K3Xef15XJvigifeQZb",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Ui Design Masterclass</span>",
                                        "color": "#000000",
                                        "fontSize": 24,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132Gnm0xWr4JoJyPv7NetdrCQWIhoRT8EFr",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>This training shows you how to massively scale your business with online courses.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 727,
                            "section_id": 256,
                            "slug": "miestro-1694515132nHskmvWIRHj4lCizw8P6tx4S6yGzihxF",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132lkcmlOwkHpX7RcqC9pnE2QbxMKYMKeOk",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "337",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (330x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132UeHCQM7Bx3A2QWgRLieTzTJucJFV9uHm",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Course Code Masterclass</span>",
                                        "color": "#000000",
                                        "fontSize": 24,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132KKmkOJeMk4Q4viGu2lqD4dv4wUfx6uQK",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>This training shows you how to massively scale your business with online courses.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 728,
                            "section_id": 256,
                            "slug": "miestro-169451513279ENfqRnQvlg5L56iPrVzau6uGtfho8a",
                            "type": "component",
                            "name": "4 Column",
                            "props": {
                                "text": "Component Props",
                                "color": "#1e88f5",
                                "isType": "Group",
                                "visibility": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132SzYdTtVlTcoEf6xwosinoGSZXDn7BWMI",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                        "width": "337",
                                        "radius": "0",
                                        "visibility": true,
                                        "imageLabel": "Show Image (330x180)",
                                        "borderRadius": 0,
                                        "isBrandNewImg": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132hOgpkwpSDwvxyLEnjc5QR0oK5JvL81HT",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>Miestro Elite</span>",
                                        "color": "#000000",
                                        "fontSize": 24,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132azPVzJndiZJDpBG1vpUkL7CzWB8OdMud",
                                    "type": "Text",
                                    "name": "Text",
                                    "props": {
                                        "align": "center",
                                        "text": "<span>This training shows you how to massively scale your business with online courses.</span>",
                                        "color": "#686868",
                                        "fontSize": 14,
                                        "paddingTop": 16,
                                        "paddingBottom": 0,
                                        "maxWidth": "none",
                                        "centerText": true
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 725,
                        "section_id": 256,
                        "slug": "miestro-1694515132nCbM8x6v5Z4gaN3GghRo0kQB4bnMyncz",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132XJZ0bdcmw2CBLi03lLVOYRxSvkWr06ai",
                                "type": "Text",
                                "name": "Title",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Featured Courses</span>",
                                    "color": "#000000",
                                    "fontSize": 32,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-16945151321GCFj93tHiNu3p6gPKQPOLmmkhIciqlb",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "paddingTop": 16,
                                    "centerText": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 726,
                        "section_id": 256,
                        "slug": "miestro-1694515132zPpxg7HgJD55KR7IZQEsnXEAAcC8qZKa",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132FI7eIwGEv5E3rRZTPRll8oqfLYXShlH6",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "337",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (330x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132vOSBel5DPLIDj9K3Xef15XJvigifeQZb",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Ui Design Masterclass</span>",
                                    "color": "#000000",
                                    "fontSize": 24,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132Gnm0xWr4JoJyPv7NetdrCQWIhoRT8EFr",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>This training shows you how to massively scale your business with online courses.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 727,
                        "section_id": 256,
                        "slug": "miestro-1694515132nHskmvWIRHj4lCizw8P6tx4S6yGzihxF",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132lkcmlOwkHpX7RcqC9pnE2QbxMKYMKeOk",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "337",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (330x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132UeHCQM7Bx3A2QWgRLieTzTJucJFV9uHm",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Course Code Masterclass</span>",
                                    "color": "#000000",
                                    "fontSize": 24,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132KKmkOJeMk4Q4viGu2lqD4dv4wUfx6uQK",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>This training shows you how to massively scale your business with online courses.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 728,
                        "section_id": 256,
                        "slug": "miestro-169451513279ENfqRnQvlg5L56iPrVzau6uGtfho8a",
                        "type": "component",
                        "name": "4 Column",
                        "props": {
                            "text": "Component Props",
                            "color": "#1e88f5",
                            "isType": "Group",
                            "visibility": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132SzYdTtVlTcoEf6xwosinoGSZXDn7BWMI",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing17/slider_1.jpg",
                                    "width": "337",
                                    "radius": "0",
                                    "visibility": true,
                                    "imageLabel": "Show Image (330x180)",
                                    "borderRadius": 0,
                                    "isBrandNewImg": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132hOgpkwpSDwvxyLEnjc5QR0oK5JvL81HT",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>Miestro Elite</span>",
                                    "color": "#000000",
                                    "fontSize": 24,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132azPVzJndiZJDpBG1vpUkL7CzWB8OdMud",
                                "type": "Text",
                                "name": "Text",
                                "props": {
                                    "align": "center",
                                    "text": "<span>This training shows you how to massively scale your business with online courses.</span>",
                                    "color": "#686868",
                                    "fontSize": 14,
                                    "paddingTop": 16,
                                    "paddingBottom": 0,
                                    "maxWidth": "none",
                                    "centerText": true
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "section": {
                    "id": 257,
                    "landing_id": 32,
                    "slug": "miestro-1694515132QAowznDL8DgRVRNjfAims8cb8tAsJE8I",
                    "name": "Footer",
                    "props": {
                        "order": 7,
                        "bgColor": "#000000",
                        "spacing": "sm",
                        "bgImgSrc": "",
                        "bgVideoSrc": "",
                        "duplicated": "footer",
                        "paddingTop": 24,
                        "visibility": true,
                        "isBrandNewImg": true,
                        "paddingBottom": 24,
                        "bgColorOverlay": "#000000",
                        "background_type": "color",
                        "isWithLinksFooter": true
                    },
                    "order": 7,
                    "created_at": "2023-09-12T10:38:52.000000Z",
                    "updated_at": "2023-09-12T10:38:52.000000Z",
                    "components": [
                        {
                            "id": 729,
                            "section_id": 257,
                            "slug": "miestro-1694515132paNOygETPoV1WpIBeGWTlIo3quQkPapx",
                            "type": "component",
                            "name": "Three Column",
                            "props": {
                                "text": "Component Props",
                                "isLogo": true
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132A3LntUZDjDwctgqKIvF4VvFX9VYU4kJz",
                                    "type": "Image",
                                    "name": "Image",
                                    "props": {
                                        "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/logo.png",
                                        "text": "Freehand",
                                        "width": "104",
                                        "maxSize": "104",
                                        "radius": "0",
                                        "visibility": true,
                                        "isLogo": true,
                                        "imageLabel": "Show Image (104x22)",
                                        "isBrandNewImg": true,
                                        "alignmentisOn": false
                                    }
                                }
                            ]
                        },
                        {
                            "id": 730,
                            "section_id": 257,
                            "slug": "miestro-1694515132KeFFS485L62WqJUvTcpp0jlU3WVLMATb",
                            "type": "component",
                            "name": "Copyright",
                            "props": {
                                "text": "footer"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-16945151321WX1ZlTxDrjvT6PI5IMuRVBix4vkv8Dw",
                                    "type": "ButtonMi",
                                    "name": "Copyright",
                                    "props": {
                                        "text": "Copyright © 2023 by Personal Life Coach.",
                                        "bgColor": "transparent",
                                        "textColor": "#FFFFFF",
                                        "size": "extsmall",
                                        "linked": true,
                                        "blanked": false,
                                        "href": "",
                                        "border": "none",
                                        "textSize": 16,
                                        "borderRadius": 0,
                                        "alignmentisOn": false,
                                        "isCopyright": true
                                    }
                                }
                            ]
                        },
                        {
                            "id": 731,
                            "section_id": 257,
                            "slug": "miestro-1694515132LyXcw7pjhWufdMN3da9A4yq5Gf5tRAEc",
                            "type": "component",
                            "name": "Privacy Policy & Terms",
                            "props": {
                                "text": "footer"
                            },
                            "created_at": "2023-09-12T10:38:52.000000Z",
                            "updated_at": "2023-09-12T10:38:52.000000Z",
                            "subcomponents": [
                                {
                                    "slug": "miestro-1694515132TDGUKpmj9kZxvVKhHKKU1Jq2gJpQm6gD",
                                    "type": "ButtonMi",
                                    "name": "Privacy Policy",
                                    "props": {
                                        "text": "Privacy Policy",
                                        "bgColor": "transparent",
                                        "textColor": "#FFFFFF",
                                        "size": "extsmall",
                                        "linked": true,
                                        "blanked": false,
                                        "href": "",
                                        "border": "none",
                                        "textSize": 16,
                                        "borderRadius": 0,
                                        "isPrivacy": true
                                    }
                                },
                                {
                                    "slug": "miestro-1694515132qcFJVRX3N7qhiHCqJmR00qBfO6uPFP2a",
                                    "type": "ButtonMi",
                                    "name": "Terms",
                                    "props": {
                                        "text": "Terms",
                                        "bgColor": "transparent",
                                        "textColor": "#FFFFFF",
                                        "size": "extsmall",
                                        "linked": true,
                                        "blanked": false,
                                        "href": "",
                                        "border": "none",
                                        "textSize": 16,
                                        "borderRadius": 0,
                                        "isTerms": true
                                    }
                                }
                            ]
                        }
                    ]
                },
                "components": [
                    {
                        "id": 729,
                        "section_id": 257,
                        "slug": "miestro-1694515132paNOygETPoV1WpIBeGWTlIo3quQkPapx",
                        "type": "component",
                        "name": "Three Column",
                        "props": {
                            "text": "Component Props",
                            "isLogo": true
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132A3LntUZDjDwctgqKIvF4VvFX9VYU4kJz",
                                "type": "Image",
                                "name": "Image",
                                "props": {
                                    "imgUrl": "https://miestro-production.s3-us-west-2.amazonaws.com/landing/landing19/logo.png",
                                    "text": "Freehand",
                                    "width": "104",
                                    "maxSize": "104",
                                    "radius": "0",
                                    "visibility": true,
                                    "isLogo": true,
                                    "imageLabel": "Show Image (104x22)",
                                    "isBrandNewImg": true,
                                    "alignmentisOn": false
                                }
                            }
                        ]
                    },
                    {
                        "id": 730,
                        "section_id": 257,
                        "slug": "miestro-1694515132KeFFS485L62WqJUvTcpp0jlU3WVLMATb",
                        "type": "component",
                        "name": "Copyright",
                        "props": {
                            "text": "footer"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-16945151321WX1ZlTxDrjvT6PI5IMuRVBix4vkv8Dw",
                                "type": "ButtonMi",
                                "name": "Copyright",
                                "props": {
                                    "text": "Copyright © 2023 by Personal Life Coach.",
                                    "bgColor": "transparent",
                                    "textColor": "#FFFFFF",
                                    "size": "extsmall",
                                    "linked": true,
                                    "blanked": false,
                                    "href": "",
                                    "border": "none",
                                    "textSize": 16,
                                    "borderRadius": 0,
                                    "alignmentisOn": false,
                                    "isCopyright": true
                                }
                            }
                        ]
                    },
                    {
                        "id": 731,
                        "section_id": 257,
                        "slug": "miestro-1694515132LyXcw7pjhWufdMN3da9A4yq5Gf5tRAEc",
                        "type": "component",
                        "name": "Privacy Policy & Terms",
                        "props": {
                            "text": "footer"
                        },
                        "created_at": "2023-09-12T10:38:52.000000Z",
                        "updated_at": "2023-09-12T10:38:52.000000Z",
                        "subcomponents": [
                            {
                                "slug": "miestro-1694515132TDGUKpmj9kZxvVKhHKKU1Jq2gJpQm6gD",
                                "type": "ButtonMi",
                                "name": "Privacy Policy",
                                "props": {
                                    "text": "Privacy Policy",
                                    "bgColor": "transparent",
                                    "textColor": "#FFFFFF",
                                    "size": "extsmall",
                                    "linked": true,
                                    "blanked": false,
                                    "href": "",
                                    "border": "none",
                                    "textSize": 16,
                                    "borderRadius": 0,
                                    "isPrivacy": true
                                }
                            },
                            {
                                "slug": "miestro-1694515132qcFJVRX3N7qhiHCqJmR00qBfO6uPFP2a",
                                "type": "ButtonMi",
                                "name": "Terms",
                                "props": {
                                    "text": "Terms",
                                    "bgColor": "transparent",
                                    "textColor": "#FFFFFF",
                                    "size": "extsmall",
                                    "linked": true,
                                    "blanked": false,
                                    "href": "",
                                    "border": "none",
                                    "textSize": 16,
                                    "borderRadius": 0,
                                    "isTerms": true
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    ],
};

export default state;
