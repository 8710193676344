import React, { Component, Fragment } from "react";
import EditorContext from "contexts/EditorContext";
import { Header, Menu, Icon } from "semantic-ui-react";
import AppContext from "contexts/AppContext";
import ToggleMi from "components/Toggle";
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'
import "./index.css";
import IconNew from "components/elements/iconsSize";
import Button, {THEMES as themes} from 'components/BaseButtonNew'
import DeleteModal from "components/DeleteModal";
import InputRange from "components/InputRange";
import ImageEditable from "components/Image/Editable";
import LinkMiEditable from "components/Link/Editable";
import ColorInput from "components/ColorInput";

class SectionComponentsMenu extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isOpenDeleteModal: false,
         currentComponent: 0,
      };
   }

   toggleSectionComponent = e => {
      let sectionCurrentComponent = e.target.closest(".item").nextSibling;
      let currentItemDown = e.currentTarget.children[0].children[1];
      let currentItem = e.currentTarget.children[0]
      if (sectionCurrentComponent.style.display === "none") {
         sectionCurrentComponent.style.display = "block";
         currentItem.classList.add('SectionComponents_name_active')
         currentItemDown.style.transform = 'rotate(180deg)';
      } else {
         sectionCurrentComponent.style.display = "none";
         currentItem.classList.remove('SectionComponents_name_active')
         currentItemDown.style.transform = 'rotate(0deg)';
      }
   };

   render() {
      const findSettingName = (maincomponent) => {
         if(maincomponent.props.isFooterLink || maincomponent.props.isLink) {
            return 'Links'
         }

         return maincomponent.props.settingName || 'Block Settings'
      }

      return (
         <EditorContext.Consumer>
            {({ state,landing, functions, SettingComponent }) => (
               <Fragment>
                  {this.state.isOpenDeleteModal && (
                     <AppContext.Consumer>
                        {({ s, functions:f }) => (
                           <DeleteModal
                              title='Are you sure you want to delete this section?'
                              deleteText='Delete'
                              maxWidth={414}
                              onCancel={()=>{
                                 this.setState({isOpenDeleteModal:false});
                              }}
                              onDelete={()=>{
                                 functions.backToCurrentMenu()
                                 f.handleSectionOptions(
                                    this.props.section.props.order,
                                    'delete',
                                    this.props.section.props.visibility
                                 );
                              }}
                           />
                        )}
                     </AppContext.Consumer>
                  )}
                  <Menu className='SectionComponents' vertical={true}>
                     <div className='SectionComponents__top'>
                        <div className='SectionComponents__top__left'>
                           <div className='SectionComponents__top__icon'
                              onClick={() => functions.backToCurrentMenu()}
                              role='presentation'
                           >
                              <IconNew name='LandingBackL' />
                           </div>
                           <Text
                              inner={`${ state.editableSection.section.name } Section`}
                              type={types.regular148}
                              size={sizes.medium}
                           />
                        </div>
                        {this.props.section && (
                           this.props.section.props.duplicated !== 'header' &&
                           this.props.section.props.duplicated !== 'footer' &&
                           this.props.section.props.duplicated !== 'pricing' && (
                              <div className='SectionComponents__top__icon'
                                 role='presentation'
                                 onClick={()=>{
                                    this.setState({isOpenDeleteModal: true})
                                 }}
                              >
                                 <IconNew name='LandingRemoveBlackM' />
                              </div>
                           )
                        )}
                     </div>
                     {state.editableSection && (
                        <Fragment>
                           {!this.props.section.props.isCustomCode &&  <Menu.Item
                              onClick={e =>
                                 this.toggleSectionComponent(e)
                              }
                           >
                              <div className='SectionComponents_name'>
                                 <div className='SectionComponents_name_title'>
                                    <Text
                                       inner='Background'
                                       type={types.regularDefault}
                                       size={sizes.medium}
                                    />
                                 </div>
                                 <div className='sectionComponents_name_icon'>
                                    <IconNew name='LandingDropUpM'
                                    />
                                 </div>
                              </div>
                           </Menu.Item>}
                           {!this.props.section.props.isCustomCode &&  <div className='SectionComponent' id={state.editableSection.section.slug} style={{display: 'none'}}>
                              {state.editableSection &&
                                        functions.showEditableComponent(
                                           state.editableSection.section,
                                           -1
                                        )}
                           </div>}
                           {state.editableSection.components.map((maincomponent, j) => {
                              let enableComponentGroup = maincomponent.props.isLink===true || maincomponent.props.isFooterLink===true || (
                                 maincomponent.subcomponents?.length!==0 && !this.props.section?.props?.isCustomCode &&
                                        this.props.section.props.duplicated!=='header'  &&
                                        this.props.section.props.duplicated!=='footer');
                              return <Fragment key={j}>
                                 {enableComponentGroup &&
                                                    <Menu.Item
                                                       onClick={e =>
                                                          this.toggleSectionComponent(
                                                             e
                                                          )
                                                       }
                                                    >
                                                       <div className='SectionComponents_name'>
                                                          <div className='SectionComponents_name_title'>
                                                             <Text
                                                                inner={ findSettingName(maincomponent) }
                                                                type={types.regularDefault}
                                                                size={sizes.medium}
                                                             />
                                                          </div>
                                                          <div className='sectionComponents_name_icon'>
                                                             <IconNew name='LandingDropUpM'
                                                             />
                                                          </div>
                                                       </div>
                                                    </Menu.Item>
                                 }

                                 <div className={enableComponentGroup? 'SectionComponent' : 'Section-Component'} id={maincomponent.slug}  style={enableComponentGroup ? {display: 'none'} : {display: 'block'}}
                                 >
                                    <div className={enableComponentGroup? 'maincomponent': ''}>
                                       {(maincomponent.props.isType==='Group' || maincomponent.props.isType==='Group2' || maincomponent.props.isType==='Group-Pricing') &&
                                                            <div className='item item-flex'>
                                                               <ToggleMi
                                                                  {...maincomponent.props}
                                                                  label = 'Visibility'
                                                                  value = {maincomponent.props.visibility}
                                                                  checked = {maincomponent.props.visibility}
                                                                  props_name = 'visibility'
                                                                  name='visibility'
                                                                  section={this.props.section.props.order}
                                                                  slug={maincomponent.slug}
                                                               />
                                                                {maincomponent.props.hasLink!==undefined &&
                                                                 <ToggleMi
                                                                  {...maincomponent.props}
                                                                  label = 'Add Link'
                                                                  value = {maincomponent.props.hasLink}
                                                                  checked = {maincomponent.props.hasLink}
                                                                  props_name = 'hasLink'
                                                                  name='hasLink'
                                                                  section={this.props.section.props.order}
                                                                  slug={maincomponent.slug}
                                                               />}
                                                               {maincomponent.props.href!==undefined &&
                                                                 <LinkMiEditable
                                                                  {...maincomponent.props}
                                                                  label = 'Custom URL'
                                                                  href = {maincomponent.props.href}
                                                                  props_name = 'href'
                                                                  name='href'
                                                                  section={this.props.section.props.order}
                                                                  slug={maincomponent.slug}
                                                                  scroll_to='another_page'
                                                                  isCustomLink={true}
                                                                  disabled={true}
                                                                  target='_blank'
                                                               />}

                                                               {/* {maincomponent.props.width!==undefined &&
                                                                  <InputRange
                                                                     {...maincomponent.props}
                                                                     label='Width (px)'
                                                                     props_name='width'
                                                                     min={0}
                                                                     max={1001}
                                                                     default='auto'
                                                                     inputValue={maincomponent.props.width=='none'? '' : maincomponent.props.width}
                                                                     section={this.props.section.props.order}
                                                                     slug={maincomponent.slug}
                                                                  />
                                                               } */}
                                                            </div>
                                       }
                                       {
                                          maincomponent.props.isType === 'bgImgEditable' && (
                                             <ImageEditable
                                                {...maincomponent.props}
                                                label="Background Image"
                                                imgUrl={maincomponent.props.bgImgSrc}
                                                section={this.props.section.props.order}
                                                slug={maincomponent.slug}
                                                propsName='bgImgSrc'
                                                props_name=''
                                             />
                                          )
                                       }
                                       {
                                          maincomponent.props.isType === 'bgColorEditable' && (
                                             <ColorInput
                                                label="Background Color"
                                                color={maincomponent.props.bgColor}
                                                section={this.props.section.props.order}
                                                slug={maincomponent.slug}
                                                props_name="bgColor"
                                                isChecked={true}
                                             />
                                          )
                                       }
                                       {!!maincomponent.subcomponents && maincomponent.subcomponents?.length!==0 && maincomponent.subcomponents.map(
                                          (component, i) => {
                                             return (
                                                component.type !== "Card" &&
                                                component.type !== "Border" &&
                                                component.props.deleted !== true && (
                                                   <Fragment key={i}>
                                                      <Menu.Item
                                                         onClick={e =>
                                                            this.toggleSectionComponent(
                                                               e
                                                            )
                                                         }
                                                      >
                                                         <div className='SectionComponents_name'>
                                                            <div className='SectionComponents_name_title'>
                                                               <Text
                                                                  inner=  {component.name}
                                                                  type={types.regularDefault}
                                                                  size={sizes.medium}
                                                               />
                                                            </div>
                                                            <div className='sectionComponents_name_icon'>
                                                               <IconNew name='LandingDropUpM'
                                                               />
                                                            </div>
                                                         </div>
                                                      </Menu.Item>
                                                      <div className='SubSectionComponent' id={component.slug}  style={this.props.section.props.isCustomCode? {display: 'block'} :  {display: 'none'}}>
                                                         {functions.showEditableComponent(
                                                            component,
                                                            i,
                                                            this.props.section.props.duplicated,
                                                            true,
                                                            maincomponent.slug
                                                         )}
                                                      </div>
                                                   </Fragment>
                                                )
                                             );
                                          }
                                       )}
                                       {maincomponent.name === 'Links' && this.props.section.props.isWithLinksHeader === true  && this.props.linkComponentCount<4 &&
                                                    <AppContext.Consumer>
                                                       {({ state, functions }) => (
                                                          <Fragment>
                                                             <Button
                                                                text=' Add Link'
                                                                style={{ width:'100%' }}
                                                                onClick={() => functions.handleAddLinkSubComponent() }
                                                             />
                                                          </Fragment>
                                                       )}
                                                    </AppContext.Consumer>
                                       }
                                       {maincomponent.name === 'Links' && this.props.section.props.isWithLinksFooter === true  && maincomponent.subcomponents?.length<3 &&
                                                    <AppContext.Consumer>
                                                       {({ state, functions }) => (
                                                          <Fragment>
                                                             <Button
                                                                text=' Add Link'
                                                                style={{ width:'100%' }}
                                                                onClick={() => functions.handleAddLinkSubComponent(true) }
                                                             />
                                                          </Fragment>
                                                       )}
                                                    </AppContext.Consumer>
                                       }
                                    </div>
                                 </div>
                              </Fragment>
                           })}
                        </Fragment>
                     )}
                  </Menu>
               </Fragment>
            )}
         </EditorContext.Consumer>
      );
   }
}

SectionComponentsMenu.defaultProps = {};

export default SectionComponentsMenu;
