import React, { Component, Fragment } from "react";
import AppContext from "contexts/AppContext";
import { Header, Form, Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./index.css";
import Select from "components/SelectNew";

class SelectMi extends Component {
   render() {
      return (
         <Fragment>
            <AppContext.Consumer>
               {({ state, functions }) => (
                  <div>
                     <Select
                        label={this.props.heading}
                        placeholder={this.props.placeholder ? this.props.placeholder : ''}
                        value={this.props.options ? this.props.default : null}
                        type='select-medium'
                        options={(this.props.options || []).map((e)=>({label:e.text,value:e.value}))}
                        onChange={(e, data) =>
                           functions.handleUpdateProps(
                              this.props.section,
                              this.props.slug,
                              data,
                              this.props.props_name,
                              null,
                              this.props.propsName
                           )
                        }
                     />
                     {!this.props.removeMargin && 
                     <>{!this.props.removeLine ? <Header as='h3' dividing /> : <div style={{marginTop: '20px'}}></div>}</>
                     }
                  </div>
               )}
            </AppContext.Consumer>
         </Fragment>
      );
   }
}

SelectMi.defaultProps = {
   heading: "",
   props_name: "",
   placeholder: "Select",
   options: [],
};

SelectMi.propTypes = {
   // EXPECTED STRING
   heading: PropTypes.string,
   props_name: PropTypes.string,
   slug: PropTypes.string,
   placeholder: PropTypes.string,
   default: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
   ]),
   // EXPECTED ARRAY
   options: PropTypes.array,
   // EXPECTED NUMBER
   section: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
}


export default SelectMi;
