import React, { Component, Fragment } from "react";
import AppContext from "contexts/AppContext";
import Size from "components/Size";
import { Image,  Icon, Header, Form, Input } from "semantic-ui-react";
import InputRange from "components/InputRange";
import ToggleMi from "components/Toggle";
import { highlightSidebar, isNewApp } from "utils";
import PropTypes from "prop-types";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import UploadImg from 'components/UploadImg';
import Spacing from "components/Spacing";
import Select from "components/Select";
import Button, {THEMES as themes} from 'components/BaseButtonNew'
import default_image from 'images/sidebar/default_image_new.png'

class ImageEditable extends Component {
   componentDidMount() {
      if (this.props.scroll) {
         highlightSidebar(
            this.props.slug,
            this.props.toggleSidebar,
            this.props.menuVisible,
            this.props.isSubComponent,
            this.props.maincomponentSlug
         );
      }
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.scroll) {
         highlightSidebar(
            this.props.slug,
            this.props.toggleSidebar,
            this.props.menuVisible,
            this.props.isSubComponent,
            this.props.maincomponentSlug
         );
      }
   }

   handleButtonClick = () => {
      this.inputElement.click();
   };

   render() {
      let props_name;
      let heading;
      let margin;
      const { propsName } = this.props;
      // let default_image = require("components/../images/sidebar/default_image_new.png");
      let url = window.location.href;
      switch (this.props.label) {
         case "Show Logo":
            props_name = "height";
            heading = "Logo Height";
            break;
         default:
            props_name = "width";
            heading = "Image Width";
      }

      switch (this.props.spacing) {
         case "xs":
            margin = 5;
            break;
         case "sm":
            margin = 15;
            break;
         case "md":
            margin = 25;
            break;
         case "lg":
            margin = 45;
            break;
         case "xl":
            margin = 75;
            break;
         default:
            margin = 10;
      }
      const alignOptions = [
         { text: "Left", value: "left" },
         { text: "Center", value: "center" },
         { text: "Right", value: "right" },
      ];
      return (
         <div className="ImageEditable" >
            {this.props.props_name ? (
               <ToggleMi
                  label={this.props.label}
                  value={this.props.visibility}
                  props_name={this.props.props_name}
                  checked={this.props.visibility}
                  {...this.props}
                  propsName={propsName}
               />
            ) : null}
            {/* <Header deviding="true" /> */}
            {/* <div
               className={
                  this.props.imgUrl
                     ? "sidebar_img"
                     : "sidebar_img default_img"
               }
            >
               { this.props.isLogo ? ( <Image
                  src={
                     this.props.imgUrl && this.props.imgUrl!=='Your Logo'
                        ? this.props.imgUrl : default_image
                  }
                  bordered
                  alt="Default image"
               />) : (
                  <Image
                     src={
                        this.props.imgUrl && this.props.imgUrl !=='https://picsum.photos/200'
                           ? this.props.imgUrl : default_image
                     }
                     bordered
                     alt="Default image"
                  />
               )}
            </div> */}

            <AppContext.Consumer>
               {({ state, functions }) => (
                  <div>
                     {/* <input
                                className='fileUpload'
                                type='file'
                                name={this.props.slug}
                                onChange={(e) =>{
                                    let file = e.target.files[0];
                                    let validFileExtensions = ["jpg", "jpeg", "png"];
                                    let fileType = file.type;
                                    let error = true;
                                    if (fileType) {
                                        if (fileType.length != 0) {
                                            fileType = fileType.split("/");
                                            let fileExtension = fileType[fileType.length - 1].toLowerCase();
                                            validFileExtensions.forEach(extension => {
                                                if (extension === fileExtension) {
                                                    let reader = new FileReader();
                                                    reader.onload = e => {
                                                        functions.handleUpdateProps(
                                                            this.props.section,
                                                            this.props.slug,
                                                            e.target.result,
                                                            'image'
                                                        )
                                                    }
                                                    reader.readAsDataURL(file);
                                                    error = false;
                                                }
                                            });
                                        }
                                    }
                                    if (error) {
                                        toast.error("SUPPORTED ONLY JPG,JPEG AND PNG");
                                    }

                                }}
                                ref={input => (this.inputElement = input)}
                            /> */}
                     <UploadImg
                        {...this.props}
                        img={ this.props.imgUrl }
                        isBrandNewImg={this.props.isBrandNewImg}
                        onChange={ (value) =>  functions.handleUpdateProps(  this.props.section,
                           this.props.slug,
                           value,
                           'image',
                           null,
                           propsName
                        ) }
                     />
                  </div>
               )}
            </AppContext.Consumer>
            {/* <Button
                    primary
                    className='button'
                    animated='vertical'
                    onClick={this.handleButtonClick}
                >
                    <Button.Content hidden>CHOOSE</Button.Content>
                    <Button.Content visible>
                        <Icon name='file image outline' />
                        SELECT IMAGE...
                    </Button.Content>
                </Button> */}
            <Header dividing />
            {this.props.linked && (
               <AppContext.Consumer>
                  {({ state, functions }) => (
                     <Fragment>
                        <Form>
                           <Form.Field>
                              <label>Logo Link</label>
                              <Input
                                 className="link-url"
                                 icon="linkify"
                                 iconPosition="left"
                                 onKeyDown={ (e) => e.key === 'Enter' && e.preventDefault()}
                                 onChange={e =>
                                    functions.handleUpdateProps(
                                       this.props.section,
                                       this.props.slug,
                                       e.target.value,
                                       "href",
                                       this.props.href
                                    )
                                 }
                                 value={this.props.href}
                                 placeholder="https://miestro.com"
                              />
                           </Form.Field>
                           {/* <CheckboxMi
                                        {...this.props}
                                        value={this.props.blanked}
                                        label='Open In New Window'
                                        props_name='blanked'
                                        checked={this.props.blanked}
                                    /> */}
                        </Form>

                        <Header dividing />
                     </Fragment>
                  )}
               </AppContext.Consumer>
            )}

            {this.props.props_name && (
               <Fragment>
                  <Size
                     {...this.props}
                     props_name={props_name}
                     heading={heading}
                     maxSize={ this.props.isLogo ? 300 : 1000}
                     default={
                        this.props.width
                           ? this.props.width
                           : this.props.height
                     }
                  />
                  {/* {this.props.type !== "logo" && (
                            <Select
                                {...this.props}
                                options={this.props.spacing_options}
                                heading='Spacing'
                                props_name='spacing'
                                default={this.props.spacing}
                            />
                        )} */}
               </Fragment>
            )}
            {this.props.props_name && this.props.type !== "logo" && (
            // <InputRange
            //     {...this.props}
            //     label='Spacing (px)'
            //     props_name='spacingValue'
            //     min={5}
            //     max={75}
            //     default={margin}
            //     inputValue={this.props.spacingValue}
            // />
               <Spacing
                  {...this.props}
                  top={ this.props.paddingTop }
                  bottom={ this.props.paddingBottom }
                  left={ this.props.paddingLeft }
                  right={ this.props.paddingRight }
               />
            )}
            {this.props.props_name &&  (
               <InputRange
                  {...this.props}
                  label="Border Radius"
                  props_name="borderRadius"
                  min={0}
                  max={100}
                  default={0}
                  inputValue={this.props.borderRadius}
               />
            )}
            {this.props.props_name && this.props.alignmentisOn !== false && (
               <Select
                  {...this.props}
                  heading="Alignment"
                  props_name="align"
                  options={alignOptions}
                  default={this.props.align? this.props.align : ''}
               />
            )}
            {this.props.availableDelete &&
                <Fragment>
                   <AppContext.Consumer>
                      {({ state, functions }) => (
                         <Button
                            theme={themes.red}
                            text="Delete Image"
                            style={{ margin: '0px auto' }}
                            onClick={() => {
                               functions.handleDeleteComponent(
                                  this.props.section,
                                  this.props.slug,
                                  true,
                                  'deleted'
                               )
                            }}
                         />

                      )}
                   </AppContext.Consumer>
                   <Header as="h3" dividing />
                </Fragment>
            }
         </div>
      );
   }
}

ImageEditable.defaultProps = {
   label: "Show Image",
   width: "",
   height: "",
   section: "",
   slug: "",
   imgUrl: "https://picsum.photos/200",
   linked: false,
   href: "",
   visibility: true,
   props_name: "visibility",
   blanked: false,
   form:"square",
   spacing: "xs",
   spacing_options: [
      { text: "5px", value: "xs" },
      { text: "15px", value: "sm" },
      { text: "25px", value: "md" },
      { text: "45px", value: "lg" },
      { text: "75px", value: "xl" },
   ],
};

ImageEditable.propTypes = {
   // EXPECTED STRING
   label: PropTypes.string,
   width: PropTypes.string,
   height: PropTypes.string,
   slug: PropTypes.string,
   imgUrl: PropTypes.string,
   props_name: PropTypes.string,
   form: PropTypes.string,
   spacing: PropTypes.string,
   // EXPECRED BOOLEAN
   linked: PropTypes.bool,
   visibility: PropTypes.bool,
   blanked: PropTypes.bool,
   // EXPECTED ARRAY
   spacing_options: PropTypes.array,
   // EXPECTED NUMBER OR STRING IF EMPTY
   section:PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
}

export default ImageEditable;
