import React, {Component} from 'react';
import './index.css'

export default class ViewMode extends Component {

   componentWillUnmount(){
      this.props.handleViewMode('')
   }

   render(){
      return (
         <div className='view_icons'>  
            <div className='topbar_item'>
               <div className='desktop'
                  onClick ={()=> {
                     this.props.handleViewMode('');   
                  }}
               >
                  <img
                     src={require("../../../images/topbar/desktop.png")}
                     alt='desktop'
                  />
               </div>
            </div>
            <div className='topbar_item'>
               <div className='tablet'
                  onClick ={()=> {
                     this.props.handleViewMode('tablet');   
                  }}
               >
                  <img
                     src={require("../../../images/topbar/tablet.png")}
                     alt='tablet'
                  />
               </div>
            </div>  
            <div className='topbar_item'>
               <div className='phone'
                  onClick ={()=> {
                     this.props.handleViewMode('phone');   
                  }}
               >
                  <img
                     src={require("../../../images/topbar/mobile.png")}
                     alt='phone'
                  />
               </div>  
            </div> 
         </div>
      )
   }  
}