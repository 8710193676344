const seoDescription = (seo_description = null) => {
    if(seo_description){
        var meta = document.createElement('meta');
        meta.name = "description"
        meta.content = seo_description; 
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
};

export default seoDescription;
