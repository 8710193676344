import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import AppContext from "contexts/AppContext";
import reactCSS from 'reactcss';
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'
import './style.css';
import IconNew from 'components/elements/iconsSize';

class ColorInput extends Component {
   constructor(props) {
      super(props);
      this.state = {
         displayColorPicker: false,
         color: props.color,
         colorInput: '',
      }
   }

   componentDidMount(){
      this.setColor(this.props.color);
   }

   componentDidUpdate(prevProps){
      if(prevProps.color !== this.props.color){
         this.setColor(this.props.color);
      }
   }

   setColor = (color) => {
      let rgba = color;
      let hex;
      if (color === "transparent") {
         hex = "#000000";
         rgba = "rgba(0,0,0,0)";
      } else if (color[0] === '#') {
         hex = color;
         rgba = this.hexToRgbA(color);
      } else {
         hex = this.RGBAToHexA(color);
      }

      this.setState({ color: rgba, colorInput: hex });
   }

   RGBAToHexA = (rgba) => {
      let sep = rgba.indexOf(",") > -1 ? "," : " ";
      rgba = rgba.substring(rgba.indexOf('(') + 1, rgba.indexOf(')')).split(sep);

      // Convert each component to hexadecimal
      let r = (+rgba[0]).toString(16).padStart(2, '0');
      let g = (+rgba[1]).toString(16).padStart(2, '0');
      let b = (+rgba[2]).toString(16).padStart(2, '0');
      
      // Check if alpha channel is present
      let a = rgba.length === 4 ? Math.round(+rgba[3] * 255).toString(16).padStart(2, '0') : '';

      return "#" + r + g + b + a;
   }

   hexToRgbA = (hex) => {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
         c = hex.substring(1).split('');
         if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
         }
         c = `0x${ c.join('') }`;
         return `rgba(${ (c >> 16) & 255 },${ (c >> 8) & 255 },${ c & 255 }, 1)`;
      }
      return 'rgba(255,255,255,1)';
   }

   handleClick = () => {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
   };

   handleClose = () => {
      this.setState({ displayColorPicker: false });
   };

   handleInputChange = (e, callback) => {
      this.setState({color: e.target.value, colorInput: e.target.value }, () => {
         if(callback) {
            callback()
         }
      });
   };

   handleChange = (color) => {
      const rgb = color.rgb;
      const rgba = `rgba(${ rgb.r },${ rgb.g },${ rgb.b },${ rgb.a })`
      let hex = color.hex;
      if (hex === "transparent") {
         hex = "#000000";
      }
      this.setState({ colorInput: hex, color: rgba});
   }

   render() {
      const {
         style,
         placeholder,
         slug,
         label,
         isChecked
      } = this.props;
      const {
         color,
         displayColorPicker,
         colorInput,
      } = this.state;

      const styles = reactCSS({
         default: {
            color: {
               width: '21px',
               height: '20px',
               borderRadius: "1px",
               border: 'solid 1px #cddaf1',
               background: `${ color }`,
            },
            swatch: {
               padding: "5px",
               background: "#fff",
               borderRadius: "1px",
               display: "inline-block",
               cursor: "pointer",
            },
            popover: {
               position: "absolute",
               zIndex: "2",
               top: "35px",
               right: "40px",
            },
            cover: {
               width: "100%",
               height: "7000px",
               position: "fixed",
               top: "0",
               left: "0",
            },
         },
      });

      return (
         <AppContext.Consumer>
            {({ state, functions }) => (
               <div className='colorInput'>
                  <div className='colorInput__wrapper'>
                     <div className='colorInput__wrapper__top'> 
                        {
                           label ? (
                              <Text
                                 inner={label}
                                 type={types.regularDefault}
                                 size={sizes.small}
                              />
                           ) : (
                              <div></div>
                           )
                        }
                        <div 
                           style={{ backgroundColor: colorInput || '#FFFFFF' }} 
                           className='colorInput__wrapper__example'
                           role='presentation'
                           onClick={ isChecked ? (e) => this.handleClick(e) : () => {} }
                        >
                           
                        </div>
                     </div>
                     <div className='colorInput__inputWrapper'>
                        <input
                           type='text'
                           name={`colorInput-${ slug }`}
                           style={ style }
                           placeholder={ placeholder }
                           value={ colorInput || '#FFFFFF' }
                           className='colorInput__input'
                           onKeyDown={ (e) => e.key === 'Enter' && e.preventDefault()}
                           onChange={ (e) => {
                              this.handleInputChange(e, () => {
                                 functions.handleUpdateProps(
                                    this.props.section,
                                    this.props.slug,
                                    this.state.color,
                                    this.props.props_name,
                                    this.props.propsName,
                                    this.props.propsName
                                 )
                              });
                           }}
                           maxLength='7'
                           disabled={!isChecked}
                        />
                        <div
                           style={ styles.swatch }
                           onClick={ isChecked ? (e) => this.handleClick(e) : null }
                           className={ 'colorInput__picker' }
                        >
                           <IconNew name='LandingHashingM' />
                        </div>
                        { displayColorPicker ? (
                           <div style={ styles.popover }>
                              <div style={ styles.cover } onClick={ this.handleClose } />
                              <SketchPicker color={ color } onChange={ (e) => {
                                 this.handleChange(e);
                                 setTimeout(() => {
                                    functions.handleUpdateProps(
                                       this.props.section,
                                       this.props.slug,
                                       this.state.color,
                                       this.props.props_name,
                                       this.props.propsName,
                                       this.props.propsName
                                    )
                                 }, 100);
                              } } />
                           </div>
                        ) : null }
                     </div>
                  </div>
               </div>
            )}
         </AppContext.Consumer>
      );
   }
}

ColorInput.defaultProps = {
   label: "",
   props_name: "bgColor",
   style: {},
   section: "",
   slug: "",
   color: "#cccccc",
};

ColorInput.propTypes = {
   label: PropTypes.string,
   props_name: PropTypes.string,
   slug: PropTypes.string,
   color: PropTypes.string,
   style: PropTypes.object,
}

export default ColorInput;
