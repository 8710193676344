const isNewApp = (url) => {
    if(url.includes("localhost")){
        return false;
    }
    var result = false
    var field = 'token';
    if(url.indexOf('?' + field + '=') != -1)
        result =  true;
    else if(url.indexOf('&' + field + '=') != -1)
        result =  true;
    else if(url.indexOf('token=guest'))
        result = true
    return result
}

export default isNewApp;
