import React from 'react';
import PropTypes from 'prop-types';
import './index.css'

const UploadProgress = ({onCancel, progress, showCancel = true }) => {
  return (
    <div className='uploadProgress__content'>
        <div className='cancel_btn'>
            <span className='upload_text'>{ `Uploading ${ Math.round(progress * 100) }%` }</span>
          {showCancel && (
            <div role='presentation' title='Cancel' onClick={ onCancel }>
                <svg class='close' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g fill='none' fill-rule='evenodd'><path d='M0 0h24v24H0z'></path><path fill='#C2CEDB' class='close_path' fill-rule='nonzero' d='M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm4.903 12.625c.19.19.19.496 0 .685l-1.597 1.593a.484.484 0 0 1-.685 0L12 14.258l-2.625 2.645a.484.484 0 0 1-.685 0l-1.593-1.597a.484.484 0 0 1 0-.685L9.742 12 7.097 9.375a.484.484 0 0 1 0-.685l1.597-1.597c.19-.19.496-.19.685 0L12 9.742l2.625-2.645c.19-.19.496-.19.685 0l1.597 1.597c.19.19.19.496 0 .685L14.258 12l2.645 2.625z'></path></g></svg>
            </div>
          )}
        </div>

      <div className='uploadProgress'>
          <div className='uploadBar' style={{width: `${ progress * 100 }%`}} />
      </div>
    </div>
  );
};

UploadProgress.propTypes = {
  onCancel: PropTypes.func,
  progress: PropTypes.number,
  showCancel: PropTypes.bool,
};

export default UploadProgress;