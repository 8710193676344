import asyncComponent from "components/AsyncComponent";
const Landing_1 = asyncComponent(() =>
   import("landings/Landing_1").then(module => module.default)
);
const Landing_2 = asyncComponent(() =>
   import("landings/Landing_2").then(module => module.default)
);
const Landing_3 = asyncComponent(() =>
   import("landings/Landing_3").then(module => module.default)
);
const Landing_4 = asyncComponent(() =>
   import("landings/Landing_4").then(module => module.default)
);
const Landing_5 = asyncComponent(() =>
   import("landings/Landing_5").then(module => module.default)
);
const Landing_6 = asyncComponent(() =>
   import("landings/Landing_6").then(module => module.default)
);
const Landing_7 = asyncComponent(() =>
   import("landings/Landing_7").then(module => module.default)
);
const Landing_8 = asyncComponent(() =>
   import("landings/Landing_8").then(module => module.default)
);
const Landing_9 = asyncComponent(() =>
   import("landings/Landing_9").then(module => module.default)
);
const Landing_10 = asyncComponent(() =>
   import("landings/Landing_10").then(module => module.default)
);
const Landing_11 = asyncComponent(() =>
   import("landings/Landing_11").then(module => module.default)
);
const Landing_12 = asyncComponent(() =>
   import("landings/Landing_12").then(module => module.default)
);
const Landing_13 = asyncComponent(() =>
   import("landings/Landing_13").then(module => module.default)
);

const Landing_14 = asyncComponent(() =>
   import("landings/Landing_14").then(module => module.default)
);

const Landing_15 = asyncComponent(() =>
   import("landings/Landing_15").then(module => module.default)
);

const Landing_16 = asyncComponent(() =>
   import("landings/Landing_16").then(module => module.default)
);

const Landing_17 = asyncComponent(() =>
   import("landings/Landing_17").then(module => module.default)
);

const Landing_18 = asyncComponent(() =>
   import("landings/Landing_18").then(module => module.default)
);

const Landing_19 = asyncComponent(() =>
   import("landings/Landing_19").then(module => module.default)
);

const Landing_20 = asyncComponent(() =>
   import("landings/Landing_20").then(module => module.default)
);

const Landing_23 = asyncComponent(() =>
   import("landings/Landing_23").then(module => module.default)
);
const Landing_21 = asyncComponent(() =>
   import("landings/Landing_21").then(module => module.default)
);

const Landing_22 = asyncComponent(() =>
   import("landings/Landing_22").then(module => module.default)
);

const getAllLandings = () => {
   return {
      landing_1: Landing_1,
      landing_2: Landing_2,
      landing_3: Landing_3,
      landing_4: Landing_4,
      landing_5: Landing_5,
      landing_6: Landing_6,
      landing_7: Landing_7,
      landing_8: Landing_8,
      landing_9: Landing_9,
      landing_10: Landing_10,
      landing_11: Landing_11,
      landing_12: Landing_12,
      landing_13: Landing_13,
      landing_14: Landing_14,
      landing_15: Landing_15,
      landing_16: Landing_16,
      landing_17: Landing_17,
      landing_18: Landing_18,
      landing_19: Landing_19,
      landing_20: Landing_20,
      landing_21: Landing_21,
      landing_22: Landing_22,
      landing_23: Landing_23,
   };
};

export default getAllLandings;
