import React, { useContext, useEffect, useRef, useState } from 'react';
import UploadProgress from '../UploadProgress';
import { generateFileName, getS3Url } from './helpers';
import { toast } from 'react-toastify';
import init from './evaporate';
import UploadView from './UploadView';
import AppContext from 'contexts/AppContext';


const useS3Upload = (Button, options) => {
   const [progress, setProgress] = useState(0);
   const [uploadInProgress, setUploadInProgress] = useState(false);
   const [modalOpen, setModalOpen] = useState(false);
   const [isUploadedImage, setIsUploadedImage] = useState(false);
   const awsKeyRef = useRef(null);
   const uploadApiRef = useRef(null);
   const {state: { siteUUid } = {}} = useContext(AppContext)
   const uuid = siteUUid
   const {
      onChange = () => {},
      onError = () => {},
      acceptFilesExtentions = '',
      fileLessonFormat = '',
      cropRatio,
      buttonProps = {},
      resetImage,
      imgUrl,
      staticImage
   } = options;

   useEffect(() => {
      init()
         .then(evarporate => uploadApiRef.current = evarporate);
      setIsUploadedImage(imgUrl)
   }, []);

   const handleChangeUploadedImage = () => {
      setIsUploadedImage(false)
   }

   async function cancelUpload(key, isAbort = false) {
      if(!isAbort) {
         setUploadInProgress(false);
      }
      setProgress(0);
      const cancel = await uploadApiRef.current.cancel(`${ process.env.REACT_APP_AWS_BUCKET }/${ key }`);
      awsKeyRef.current = null
      return cancel;
   }

   function clearUploadState() {
      setUploadInProgress(true);
      setProgress(0);
      setModalOpen(false);
   }

   async function onUploadStart() {
      clearUploadState();
      if (awsKeyRef.current) {
         return cancelUpload(awsKeyRef.current, true);
      }
      return {};
   }

   function onFileReady(awsKey, originalName, file) {
      onChange(getS3Url(awsKey), originalName, file);
      setUploadInProgress(false);
      awsKeyRef.current = null;
   }

   function onUploadComplete(awsKey, originalName, file) {
      if (!file.type.startsWith('image')) return onFileReady(awsKey, originalName, file);
      const img = document.createElement('img');
      img.onload = () => {
         onFileReady(awsKey, originalName, file);
      };
      const key = getS3Url(awsKey);
      setIsUploadedImage(key)
      img.src = key;

      return null;
   }



   async function onFileSelect({
      file, fileBlob, fileOptions, onCropStart,
   }, callback) {
      const uploadedFile = file || new File([fileBlob], fileOptions.name, fileOptions);
      const fileName = generateFileName(uploadedFile.type);
      const filePath = `${ uuid }/${ fileName }`;

      if (uploadedFile.size > 2147483648) {
         return toast.error('Size Limit Exceeded');
      }

      if (typeof onCropStart === 'function') {
         return onCropStart();
      }

      try {
         try {
            await onUploadStart();
         } finally {
            awsKeyRef.current = filePath;
            const config = {
               file: uploadedFile,
               name: filePath,
               xAmzHeadersAtInitiate: { 'x-amz-acl': 'public-read', 'Content-Type': uploadedFile.type },
               progress: (progressPercent) => setProgress(progressPercent),
               complete: (_xhr, awsKey) => onUploadComplete(awsKey, uploadedFile.name, uploadedFile),

            };
            uploadApiRef.current.add(config);

            if(callback) {
               callback()
            }
         }
      } catch (reason) {
         onError(reason);
      }
      return true;
   }


   const progressEL = (
      uploadInProgress
      && <UploadProgress showCancel={progress !== 1} className='full-uploadprogress' progress={ progress } onCancel={ () => { cancelUpload(awsKeyRef.current); } } />
   );
   const uploadButton = (
      <UploadView
         open={ modalOpen }
         onClose={ () => setModalOpen(false) }
         onOpen={ () => setModalOpen(true) }
         cropRatio={ cropRatio }
         fileLessonFormat={ fileLessonFormat }
         onFileSelect={ onFileSelect }
         acceptFilesExtentions={ acceptFilesExtentions }
         isUploadedImage={imgUrl}
         handleChangeUploadedImage={handleChangeUploadedImage}
         resetImage={resetImage}
         staticImage={staticImage}
      >
         <Button { ...buttonProps } onClick={ () => setModalOpen(true) } />
      </UploadView>
   );
   return { progressEL, uploadButton, uploadInProgress };
};


export default useS3Upload;
