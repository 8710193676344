import React from 'react';
import './index.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/elements/Icon';
import IconNew from 'components/elements/iconsSize';

export const THEMES = {
   primary: 'primary',
   more: 'more',
   secondary: 'secondary',
   tertiary: 'tertiary',
   tertiaryGreen: 'tertiaryGreen',
   change: 'change',
   filter: 'filter',
   red: 'red',
   error: 'error',
   canva: 'canva',
};

export const SIZES = {
   xsmall: 'xsmall',
   small: 'small',
   medium: 'medium',
   large: 'large',
   large50: 'large50',
   small63: 'small63',
   large120: 'large120',
};

export const IconNames = {
   delete: 'delete',
   edit: 'edit',
   close: 'close',
   add: 'add',
   Sort: 'Sort',
   Plus: 'Plus',
};

const BaseButton = ({
   text, onClick, theme, size, disabled, style, type, id, isRotatedIcon,
   className, iconName, isOpen, isIconRight, isActiveFilterButton, secondIcon,
   isDropButton, isIconLeft, isHidenDiv,
}) => {
   return (
      <button
         type={ type }
         id={ id }
         style={ style }
         onClick={ disabled ? () => {} : (e) => onClick(e) }
         className={
            classNames(
               'btnBasic',
               className,
               {
                  [`btnBasic_theme_${ theme }`]: theme,
                  [`btnBasic_theme_${ theme }_${ isActiveFilterButton || '' }`]: theme,
                  [`btnBasic_size_${ size }`]: size,
                  [`btnBasic_theme_${ theme }_disabled`]: theme && disabled,
               })
         }
      >
         {!isIconRight && !!secondIcon && !isHidenDiv && <IconNew isOpen={ isOpen } name={ secondIcon } /> }
         {!isIconRight && !!secondIcon && !isHidenDiv && <Icon isOpen={ isOpen } name={ secondIcon } /> }
         {isIconRight && !!iconName && isDropButton && !isHidenDiv && (
            <div style={ { transform: isRotatedIcon ? '90deg' : '0deg' } }>
               <IconNew isOpen={ isOpen } name={ iconName } />
            </div>
         )}
         {isIconRight && !!iconName && !isDropButton && !isHidenDiv && (
            <div style={ { transform: isRotatedIcon ? '90deg' : '0deg' } }>
               <IconNew isOpen={ isOpen } name={ iconName } />
            </div>
         )}
         {isIconRight && !!iconName && <Icon isOpen={ isOpen } name={ iconName } /> }
         {text}
         {!!iconName && !isIconRight && isDropButton && (
            <div style={ { transform: `rotate(${ isRotatedIcon ? '180deg' : '0deg' })` } }>
               <IconNew isOpen={ isOpen } name={ iconName } />
            </div>
         )}
         {!!iconName && !!isIconLeft && <IconNew isOpen={ isOpen } name={ iconName } />}
         {!!iconName && !isIconRight && <Icon isOpen={ isOpen } name={ iconName } />}
         {isIconRight && !!secondIcon && <IconNew isOpen={ isOpen } name={ secondIcon } /> }
         {isIconRight && !!secondIcon && <Icon isOpen={ isOpen } name={ secondIcon } /> }
      </button>
   );
};

BaseButton.propTypes = {
   disabled: PropTypes.any,
   size: PropTypes.string,
   isHidenDiv: PropTypes.bool,
   theme: PropTypes.string,
   isOpen: PropTypes.bool,
   text: PropTypes.string,
   onClick: PropTypes.func,
   style: PropTypes.object,
   type: PropTypes.string,
   id: PropTypes.string,
   className: PropTypes.string,
   isIconRight: PropTypes.bool,
   isRotatedIcon: PropTypes.bool,
   isDropButton: PropTypes.bool,
   isActiveFilterButton: PropTypes.bool,
   iconName: PropTypes.string,
   secondIcon: PropTypes.string,
   isIconLeft: PropTypes.bool,
};

BaseButton.defaultProps = {
   theme: 'primary',
   size: 'large',
   text: 'primary',
   isIconRight: false,
   disabled: false,
   onClick: () => {},
   type: 'button',
   id: '',
   className: '',
   isRotatedIcon: null,
   iconName: '',
};

export default BaseButton;
