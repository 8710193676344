import asyncComponent from "components/AsyncComponent";
const SectionEditable = asyncComponent(() =>
    import("components/Section/Editable").then(module => module.default)
);
const TextEditable = asyncComponent(() =>
    import("components/Text/Editable").then(module => module.default)
);
const ImageEditable = asyncComponent(() =>
    import("components/Image/Editable").then(module => module.default)
);
const ButtonMiEditable = asyncComponent(() =>
    import("components/Button/Editable").then(module => module.default)
);
const CardEditable = asyncComponent(() =>
    import("components/Card/Editable").then(module => module.default)
);
const BorderEditable = asyncComponent(() =>
    import("components/Border/Editable").then(module => module.default)
);
const VideoEditable = asyncComponent(() =>
    import("components/Video/Editable").then(module => module.default)
);
const PricingEditable = asyncComponent(() =>
    import("components/Pricing/Editable").then(module => module.default)
);
const LinkMiEditable = asyncComponent(() =>
    import("components/Link/Editable").then(module => module.default)
);
const BulletEditable = asyncComponent(() =>
    import("components/Bullet/Editable").then(module => module.default)
);
const IconEditable = asyncComponent(() =>
    import("components/Icon/Editable").then(module => module.default)
);

const ImagePlatformEditable = asyncComponent(() =>
    import("components/ImagePlatform/Editable").then(module => module.default)
);

//////settings components below
const Favicon = asyncComponent(() =>
    import("components/SettingsComponents/Favicon").then(
        module => module.default
    )
);
const Typography = asyncComponent(() =>
    import("components/SettingsComponents/Typography").then(
        module => module.default
    )
);
const Seo = asyncComponent(() =>
    import("components/SettingsComponents/Seo").then(
        module => module.default
    )
);
const Analytics = asyncComponent(() =>
    import("components/SettingsComponents/Analytics").then(
        module => module.default
    )
);

const DividerEditable = asyncComponent(() =>
    import("components/Divider/Editable").then(module => module.default)
);

const ToggleMiEditable = asyncComponent(() =>
    import("components/ToggleMi/Editable").then(module => module.default)
);

const UserInfoEditable = asyncComponent(() =>
    import("components/UserInfo/Editable").then(module => module.default)
);

const FaqElementEditable = asyncComponent(() =>
    import("components/FaqElement/Editable").then(module => module.default)
);

const CallActionElementEditable = asyncComponent(() =>
    import("components/CallActionElement/Editable").then(module => module.default)
);

const CustomCodeEditable = asyncComponent(() =>
    import("landings/Added_sections_drag/CustomCode/Editable").then(module => module.default)
);

const BannerEditable = asyncComponent(() =>
    import("landings/Added_sections_drag/Banner/Editable").then(module => module.default)
);

const CountDownTimerEditable = asyncComponent(() =>
    import("landings/Added_sections_drag/CountDownTimer/Editable").then(module => module.default)
);

const getAllEditableComponents = () => {
    return {
        SectionEditable: SectionEditable,
        PricingEditable: PricingEditable,
        TextEditable: TextEditable,
        ImageEditable: ImageEditable,
        VideoEditable: VideoEditable,
        ButtonMiEditable: ButtonMiEditable,
        CardEditable: CardEditable,
        BorderEditable: BorderEditable,
        Favicon: Favicon,
        Typography: Typography,
        Seo: Seo,
        Analytics: Analytics,
        LinkMiEditable: LinkMiEditable,
        BulletEditable: BulletEditable,
        IconEditable: IconEditable,
        ImagePlatformEditable,
        DividerEditable,
        ToggleMiEditable,
        UserInfoEditable,
        FaqElementEditable,
        CallActionElementEditable,
        CustomCodeEditable,
        BannerEditable,
        CountDownTimerEditable
    };
};

export default getAllEditableComponents;
