import slug from "../../utils/slugGenerator";



const Landings_added = [

    //  HERO  SECTIONS DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Hero",
            props: {
                order:'',
                duplicated:'Hero',
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#000000",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: '#000000',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "center",
                    text: "<span>DEFAULT TITLE</span>",
                    color: "#ffffff",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 40,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "center",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                    color: "#ffffff",
                    lineHeight: 10,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: "ButtonMi",
                name: "Button Features",
                props: {
                    text: "Default Button",
                    bgColor: '#7cb740',
                    textColor: '#ffffff',
                    blanked: false,
                    size: "medium",
                    href: "",
                    border: "none",
                    linked: true,
                    alignment: 'center',
                },
            },
        ],
    },

    //  TEXT WITH IMAGE  SECTIONS DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Text with image",
            props: {
                order:'',
                duplicated:'Text with image',
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                reverseAvailaible: true,
                reversed: false,
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_2/comp.png"),
                    width: "350",
                    radius: "0",
                    visibility: true,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text:
                    "<span>DEFAULT IMAGE AND TEXT</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-Bold',
                    fontSize: 40,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
        ],
    },

    //  TESTIMONIALS  SECTIONS DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Testimonials",
            props: {
                order:'',
                duplicated:"Testimonials",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "sm",
                reverseAvailaible: true,
                reversed: true,
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/add_section_default/author.jpg"),
                    width: "150",
                    radius: "0",
                    form: "circle",
                    visibility: true,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "center",
                    text:
                    "<span>Class Author</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 14,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text:
                    "<span>DEFAULT TESTIMONIALS</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem PageMaker including versions of Lorem Ipsum.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
        ],
    },

    //  TEXT WITH VIDEO  SECTIONS DEFAULT JSON
    {
        section: {
        //slug unique identifier
            slug: slug(),
            //component name (used as show name for sidebar)
            name: "Text with video",
            //section_props
            props: {
            //props:value
                order:'',
                duplicated:"Text with video",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "lg",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text:
                    "<span>DEFAULT TEXT AND VIDEO</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 48,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqu consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: "Video",
                name: "Video",
                props: {
                    src: "https://www.youtube.com/embed/RVjxFLTCngw",
                    width: "800",
                    height: "500",
                    spacing: "xs",
                    visibility: true,
                },
            },

        ],
    },

    //  FEATURES SECTIONS DEFAULT JSON
    {
        section: {
        //slug unique identifier
            slug: slug(),
            //component name (used as show name for sidebar)
            name: "Features",
            //section_props
            props: {
            //props:value
                order:'',
                duplicated:"Features",
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "lg",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/add_section_default/default_circle_1.png"),
                    width: "150",
                    radius: "0",
                    visibility: true,
                    feautures: "1",
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "center",
                    text:
                    "<span>DEFAULT 1</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 26,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "center",
                    text:
                    "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },

            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/add_section_default/default_circle_2.png"),
                    width: "150",
                    radius: "0",
                    visibility: true,
                    feautures: "2",
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "center",
                    text:
                    "<span>DEFAULT 2</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 26,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "center",
                    text:
                    "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/add_section_default/default_circle_3.png"),
                    width: "150",
                    radius: "0",
                    visibility: true,
                    feautures: "3",
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "center",
                    text:
                    "<span>DEFAULT 3</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 26,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "center",
                    text:
                    "<span>Sed do eiusmod tempor incididunt ut labore et dolore magna  consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna </span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
        ],
    },

    //  Text One Column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Text one column",
            props: {
                order:'',
                duplicated:'Text one column',
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "center",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 48,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "center",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
        ],
    },

    //  Text Two Column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Text two column",
            props: {
                order:'',
                duplicated:'Text two column',
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
        ],
    },

     //  Text Three Column DEFAULT JSON
     {
        section: {
            slug: slug(),
            name: "Text three column",
            props: {
                order:'',
                duplicated:'Text three column',
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
        ],
    },


    //  Call to action DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Call to action",
            props: {
                order:'',
                duplicated:'Call to action',
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "center",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 48,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "center",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: 'ButtonMi',
                name: 'Button Features',
                props: {
                   text: 'Default Button',
                   bgColor: '#7cb740',
                   textColor: '#ffffff',
                   widthChangeable: true,
                   href: '',
                   size: 'medium',
                   border: 'none',
                   blanked: true,
                   linked: true,
                   alignment: 'center',
                },
             },
        ],
    },

    // Two feature column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Two feature column",
            props: {
                order:'',
                duplicated:'Two feature column',
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "200",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: 'ButtonMi',
                name: 'Button Features',
                props: {
                   text: 'Default Button',
                   bgColor: '#7cb740',
                   textColor: '#ffffff',
                   widthChangeable: true,
                   href: '',
                   size: 'medium',
                   border: 'none',
                   blanked: true,
                   linked: true,
                   alignment: 'left',
                },
             },
             {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "200",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: 'ButtonMi',
                name: 'Button Features',
                props: {
                   text: 'Default Button',
                   bgColor: '#7cb740',
                   textColor: '#ffffff',
                   widthChangeable: true,
                   href: '',
                   size: 'medium',
                   border: 'none',
                   blanked: true,
                   linked: true,
                   alignment: 'left',
                },
             },

        ],
    },

        //  Three feature column DEFAULT JSON
        {
        section: {
            slug: slug(),
            name: "Three feature column",
            props: {
                order:'',
                duplicated:'Three feature column',
                visibility: true,
                bgVideoSrc: "",
                // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
                bgColor: "#ffffff",
                background_type: "image",
                imageOverlay: true,
                spacing: "lg",
                alignmentAvalaible: true,
                alignment: "center",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "200",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: 'ButtonMi',
                name: 'Button Features',
                props: {
                   text: 'Default Button',
                   bgColor: '#7cb740',
                   textColor: '#ffffff',
                   widthChangeable: true,
                   href: '',
                   size: 'medium',
                   border: 'none',
                   blanked: true,
                   linked: true,
                   alignment: 'left',
                },
             },
             {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "200",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: 'ButtonMi',
                name: 'Button Features',
                props: {
                   text: 'Default Button',
                   bgColor: '#7cb740',
                   textColor: '#ffffff',
                   widthChangeable: true,
                   href: '',
                   size: 'medium',
                   border: 'none',
                   blanked: true,
                   linked: true,
                   alignment: 'left',
                },
             },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "200",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Title",
                props: {
                    align: "left",
                    text: "<span>Default title</span>",
                    color: "#34495e",
                    lineHeight: 10,
                    fontFamily: 'Inter-ExtraBold',
                    fontSize: 30,
                },
            },
            {
                slug: slug(),
                type: "Text",
                name: "Text",
                props: {
                    align: "left",
                    text:
                    "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>",
                    color: "#34495e",
                    lineHeight: 18,
                    fontFamily: 'Inter-Regular',
                    fontSize: 18,
                },
            },
            {
                slug: slug(),
                type: 'ButtonMi',
                name: 'Button Features',
                props: {
                   text: 'Default Button',
                   bgColor: '#7cb740',
                   textColor: '#ffffff',
                   widthChangeable: true,
                   href: '',
                   size: 'medium',
                   border: 'none',
                   blanked: true,
                   linked: true,
                   alignment: 'left',
                },
            },

       ],
   },


    //  Image column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Image column",
            props: {
                order:'',
                duplicated:'Image column',
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/image-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                },
            },
        ],
    },
    //  Video column DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Video column",
            props: {
                order:'',
                duplicated:'Video column',
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Video",
                name: "Video",
                props: {
                    src: "https://www.youtube.com/embed/RVjxFLTCngw",
                    width: "800",
                    height: "500",
                    spacing: "xs",
                    visibility: true,
                },
            },
        ],
    },
    //  Image gallery DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Image gallery",
            props: {
                order:'',
                duplicated:'Image gallery',
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (400x250)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (400x250)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (400x250)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (400x250)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (400x250)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/gallery-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (400x250)',
                },
            },
        ],
    },
    // Logo list DEFAULT JSON
    {
        section: {
            slug: slug(),
            name: "Logo list",
            props: {
                order:'',
                duplicated:'Logo list',
                visibility: true,
                bgVideoSrc: "",
                bgImgSrc: "",
                bgColor: "#ffffff",
                background_type: "color",
                spacing: "md",
                bgColorOverlay: '#ffffff',
            },
        },
        components: [
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
            {
                slug: slug(),
                type: "Image",
                name: "Image",
                props: {
                    imgUrl: require("../../images/landing_13/logo-placeholder.png"),
                    width: "auto",
                    radius: "0",
                    visibility: true,
                    imageLabel: 'Show Image (200x100)',
                },
            },
        ],
    },

  //  FAQ JSON
  {
    section: {
        slug: slug(),
        name: "FAQ",
        props: {
            order:'',
            duplicated:'FAQ',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#edf5ff",
            background_type: "image",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#edf5ff',
        },
    },
    components: [
        {
            slug: slug(),
            type: "Text",
            name: "Title",
            props: {
                align: "center",
                text: "<span>FAQ</span>",
                color: "#34495e",
                visibility: true,
                isFaqQuestion: true,
                label: 'Show title',
                lineHeight: 10,
                fontFamily: 'Inter-ExtraBold',
                fontSize: 48,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Title",
            props: {
                align: "left",
                text: "<span>Where do I host my class?</span>",
                color: "#34495e",
                visibility: true,
                isFaqQuestion: true,
                label: 'Show question',
                lineHeight: 10,
                fontFamily: 'Inter-Medium',
                fontSize: 20,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Text",
            props: {
                align: "left",
                text:
                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                color: "#34495e",
                visibility: true,
                isFaqAnswer: true,
                lineHeight: 18,
                fontFamily: 'Inter-Medium',
                fontSize: 18,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Title",
            props: {
                align: "left",
                text: "<span>Are there any transaction fees?</span>",
                color: "#34495e",
                visibility: true,
                isFaqQuestion: true,
                label: 'Show question',
                lineHeight: 10,
                fontFamily: 'Inter-Medium',
                fontSize: 20,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Text",
            props: {
                align: "left",
                text:
                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                color: "#34495e",
                visibility: true,
                isFaqAnswer: true,
                lineHeight: 18,
                fontFamily: 'Inter-Medium',
                fontSize: 18,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Title",
            props: {
                align: "left",
                text: "<p><span style='font-size:20px;'>Can I use my own domain?</span></p>",
                color: "#34495e",
                visibility: true,
                isFaqQuestion: true,
                label: 'Show question',
                lineHeight: 10,
                fontFamily: 'Inter-Medium',
                fontSize: 20,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Text",
            props: {
                align: "left",
                text:
                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                color: "#34495e",
                visibility: true,
                isFaqAnswer: true,
                lineHeight: 18,
                fontFamily: 'Inter-Medium',
                fontSize: 18,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Title",
            props: {
                align: "left",
                text: "<span>Do you offer any money back guarantees?</span>",
                color: "#34495e",
                visibility: true,
                isFaqQuestion: true,
                label: 'Show question',
                lineHeight: 10,
                fontFamily: 'Inter-Medium',
                fontSize: 20,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Text",
            props: {
                align: "left",
                text:
                "<span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>",
                color: "#34495e",
                visibility: true,
                isFaqAnswer: true,
                lineHeight: 18,
                fontFamily: 'Inter-Medium',
                fontSize: 18,
            },
        },
     ],
    },
         //  Countdown JSON
  {
    section: {
        slug: slug(),
        name: "CountDown",
        props: {
            order:'',
            duplicated:'Countdown',
            visibility: true,
            bgVideoSrc: "",
            // bgImgSrc: require("../images/add_section_default/default_1.jpg"),
            bgColor: "#ffffff",
            background_type: "image",
            imageOverlay: true,
            spacing: "lg",
            alignmentAvalaible: true,
            alignment: "center",
            bgColorOverlay: '#ffffff',
        },
    },
    components: [
        {
            slug: slug(),
            type: "Text",
            name: "Title",
            props: {
                align: "center",
                text: "<span>Countdown</span>",
                color: "#34495e",
                visibility: true,
                label: 'Show title',
                lineHeight: 10,
                fontFamily: 'Inter-ExtraBold',
                fontSize: 48,
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Days",
            props: {
                align: "center",
                text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Days</span></p>",
                color: "#34495e",
                visibility: true,
                label: 'Days',
                number: 0,
                isCountdown: true,
                date: Math.round(Date.now() / 1000),
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Hours",
            props: {
                align: "center",
                text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Hours</span></p>",
                color: "#34495e",
                visibility: true,
                label: 'Hours',
                number: 0,
                isCountdown: true,
                date: Math.round(Date.now() / 1000),
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Minutes",
            props: {
                align: "center",
                text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Minutes</span></p>",
                color: "#34495e",
                visibility: true,
                label: 'Minutes',
                number: 0,
                isCountdown: true,
                date: Math.round(Date.now() / 1000),
            },
        },
        {
            slug: slug(),
            type: "Text",
            name: "Seconds",
            props: {
                align: "center",
                text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Seconds</span></p>",
                color: "#34495e",
                visibility: true,
                label: 'Seconds',
                number: 0,
                isCountdown: true,
                date: Math.round(Date.now() / 1000),
            },
        },
     ],
  },

    //  Custom code
    {
    section: {
        slug: slug(),
        name: "Custom code",
        props: {
            order:'',
            duplicated:'Custom code',
            visibility: true,
            bgVideoSrc: "",
            bgColor: "#ffffff",
            background_type: "image",
            imageOverlay: false,
            spacing: "lg",
            alignmentAvalaible: false,
            alignment: "center",
            isCustomCode: true,
            bgColorOverlay: '#ffffff',
        },
    },
    components: [
        {
            slug: slug(),
            type: "Text",
            name: "Text",
            props: {
                align: "center",
                text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Custom code goes here...</span></p>",
                color: "#34495e",
                isCustomCode: true,
            },
        },
        ],
    },

    {
        section: {
           slug: slug(),
           name: 'Lessons area',
           props: {
              order: '',
              duplicated: 'course',
              visibility: true,
              bgVideoSrc: '',
              bgImgSrc: '',
              bgColor: '#272727',
              background_type: 'color',
              spacing: 'md',
              videoAvailaible: false,
              bgColorOverlay: '#272727',
           },
        },
        components: [
            {
                slug: slug(),
                type: "Text",
                name: "Curriculum's Colors",
                props: {
                    text: "<p><span style='style='font-size:18px; line-height: 1.78;'>Curriculum's Colors</span></p>",
                    visibility: true,
                    label: "Curriculum's Colors",
                    lessonsBgColor: '#333333',
                    lessonsColor: '#ffffff',
                    isLessonsBgColor: true,
                },
            },
           {
              slug: slug(),
              type: 'Text',
              name: 'Title',
              props: {
                 align: 'center',
                 text: "<span>Class Curriculum</span>",
                 color: '#ffffff',
                 lineHeight: 10,
                 fontFamily: 'Inter-ExtraBold',
                 fontSize: 48,
              },
           },
        ],
     },
]







export default Landings_added;












// export {
//     hero,
//     text_and_image,
//     testimonials,
//     text_and_video,
//     features,
// };
