import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import EditorContext from "../../../contexts/EditorContext";
import Item from "./Item";
import SectionComponentsMenu from "../SectionComponentsMenu";
const grid = 8;

const getListStyle = isDraggingOver => ({
   // padding: grid,
   // width: 350,
});

class Column extends Component {
   static propTypes = {
      droppableId: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
   };

   render() {
      const { droppableId, data, ...props } = this.props;
      const firstSectionOrder = 0;
      const lastSectionOrder = data.length - 1;

      const firstItem = data.find((e) => e.section.props.duplicated === 'header');
      const lastItem = data.find((e) => e.section.props.duplicated === 'footer');
      const filterData = data.filter((e) => e.section.props.duplicated !== 'header' && e.section.props.duplicated !== 'footer');

      return (
         <EditorContext.Consumer>
            {({functions}) => (
               <div className='all-menus'>
                  {
                     firstItem && (
                        <div
                           className='menu-item'
                           style={{
                              cursor: !firstItem.section.props.visibility && "not-allowed",
                           }}
                        >
                           <Item
                              item={firstItem.section}
                              index={firstSectionOrder}
                              isHidenDrag={true}
                              onClick={() => functions.showSection(firstSectionOrder, firstItem.section.slug)}
                              key={"item-" + firstItem.section.props.order}
                              {...props}
                              isDragging={this.props.isDragging}
                           />
                        </div>
                     )
                  }
                  <Droppable
                     droppableId={droppableId}
                  >
                     {(provided, snapshot) => (
                        <div
                           ref={provided.innerRef}
                           className='draggable-menus'
                        >
                           {filterData.map((item, i) => (
                              <Fragment key={i + 1}>
                                 {
                                    !item.section.props.deleted && (
                                       <div
                                          className='menu-item'
                                          style={{ cursor: !item.section.props.visibility && "not-allowed" }}
                                       >
                                          <Item
                                             item={item.section}
                                             index={i + 1}
                                             onClick={()=>functions.showSection(i + 1, item.section.slug)}
                                             key={ "item-" + item.section.props.order }
                                             {...props}
                                             isDragging={this.props.isDragging}
                                          />
                                       </div>
                                    )
                                 }
                              </Fragment>
                           ))}
                           {provided.placeholder}
                        </div>
                     )}
                  </Droppable>
                  {
                     lastItem && (
                        <div
                           className='menu-item'
                           style={{
                              cursor: !lastItem.section.props.visibility && "not-allowed",
                           }}
                        >
                           <Item
                              item={lastItem.section}
                              index={lastSectionOrder}
                              isHidenDrag={true}
                              onClick={() => functions.showSection(lastSectionOrder, lastItem.section.slug)}
                              key={"item-" + lastItem.section.props.order}
                              {...props}
                              isDragging={this.props.isDragging}
                           />
                        </div>
                     )
                  }
               </div>
            )}
         </EditorContext.Consumer>
      );
   }
}

export default Column;
