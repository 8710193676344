import React, { Component, Fragment } from "react";
import AppContext from "contexts/AppContext";
import { Header, Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./index.css";

class Size extends Component {
   maxLengthCheck = (object) => {
      if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
   }
   render() {
      return (
         <AppContext.Consumer>
            {({ state, functions }) => (
               <Fragment>
                  <Form
                     className='size-form'
                  >
                     <Form.Field>
                        <label>{this.props.heading}</label>
                        <div style={{display: 'flex'}}>
                           <input
                              style={this.props.isLogo? {width: '100%', borderRadius: '12px'} : {}}
                              type={this.props.isLogo? 'range' : 'number' }
                              min={this.props.props_name === 'number' ? '0': '5'}
                              max={ this.props.maxSize }
                              value={this.props.default || ''}
                              maxLength={(this.props.props_name === 'number' && !this.props.isCountdown) ? 2 : 3}
                              onInput={this.props.props_name === 'number' && !this.props.isCountdown ? this.maxLengthCheck : () => {}}
                              onKeyDown={(e) => {
                                 if(this.props.isCountdown && (e.key === '-' || e.key === '.' || e.key === '+' || e.key === 'e')) {
                                    e.preventDefault()
                                 }
                              }}
                              onChange={e =>
                              {
                                 let value  = e.target.value
                                 if(this.props.isCountdown && value.length && value[0] === '0') {
                                    value = value.slice(1, value.length)
                                 }
                                 if( !!this.props.maxSize && e.target.value > this.props.maxSize ){

                                 }else{
                                    functions.handleUpdateProps(
                                       this.props.section,
                                       this.props.slug,
                                       value,
                                       this.props.props_name,
                                       this.props.propsName
                                    )
                                    this.props.props_name === 'number' && functions.handleUpdateProps(
                                       this.props.section,
                                       this.props.slug,
                                       Math.round(Date.now() / 1000),
                                       'date',
                                       this.props.propsName
                                    ) 
                                 }

                              }
                              }
                           />
                           {this.props.isLogo && <div style={{marginLeft: '3px'}}>{this.props.default}px</div> }
                        </div>
                     </Form.Field>
                  </Form>
                  <Header dividing />
               </Fragment>
            )}
         </AppContext.Consumer>
      );
   }
}

Size.propTypes = {
   // EXPECTED STRING
   heading: PropTypes.string,
   slug: PropTypes.string,
   props_name: PropTypes.string,
   default: PropTypes.string,

   // EXPECTED NUMBER OR STRING IF EMPTY
   section: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
   ]),
}

Size.defaultProps = {
   default: 0,
}

export default Size;
