import React from 'react';
import InputRange from "components/InputRange";
//import Text, { TYPE as TextType, SIZES as TextSize } from 'components/elements/Text';
import PropTypes from 'prop-types';
import './index.css';
import Text, {TYPES as types,SIZES as sizes} from 'components/TextNew'

const Spacing = (props) => {
   const { top, bottom, left, right } = props
   return (
      <div>
         <Text
            inner='Spacing (px)'
            type={types.regularDefault}
            size={sizes.small}
            style={{ marginBottom: '12px', color: '#131F1E' }}
         />
         <div className='padding-content'>
            <div>
               <div className='padding-top'>
                  <InputRange
                     {...props}
                     label='Top Padding'
                     props_name='paddingTop'
                     min={0}
                     max={60}
                     default={0}
                     inputValue={top}
                  />
               </div>
               <div className='padding-right'>
                  <InputRange
                     {...props}
                     label='Right Padding'
                     props_name='paddingRight'
                     min={0}
                     max={60}
                     default={0}
                     inputValue={right}
                  />
               </div>
               <div className='padding-bottom'>
                  <InputRange
                     {...props}
                     label='Bottom Padding'
                     props_name='paddingBottom'
                     min={0}
                     max={60}
                     default={0}
                     inputValue={bottom}
                  />
               </div>
               <div className='padding-left'>
                  <InputRange
                     {...props}
                     label='Left Padding'
                     props_name='paddingLeft'
                     min={0}
                     max={60}
                     default={0}
                     inputValue={left}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

Spacing.defaultProps = {
   top: '0',
   bottom: '0',
   left: '0',
   right: '0',
};

Spacing.propTypes = {
   slug: PropTypes.string,
   changeProp: PropTypes.func,
   index: PropTypes.number,
   top: PropTypes.string,
   bottom: PropTypes.string,
   left: PropTypes.string,
   right: PropTypes.string,
};

export default Spacing;
