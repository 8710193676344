const getUrl = () => {
   let url = window.location.href
   const metaCode = document.querySelector('meta[name="site_domain"]').content
   if(metaCode){
      return `https://${metaCode}`
   }
   if(url === 'http://localhost:3001/' ){
      url = process.env.REACT_APP_LANDING_URL
   }
   return url
}

export const getApiUrl = () => {
   let url = window.location.href
   const metaCode = document.querySelector('meta[name="site_domain"]').content
   if(metaCode){
      return `https://${metaCode}`
   }
   if(url === 'http://localhost:3001/' ){
      url = process.env.REACT_APP_LANDING_URL
   }
   let splitedUrl = url.split('/')
   return `${ splitedUrl[0] }//${ splitedUrl[2] }`
}

export default getUrl