import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Text, { TYPES as txtTypes, SIZES as txtSizes } from 'components/TextNew';
import { find } from 'lodash';
import { Options } from './Options';
import Icon from '../elements/Icon';
import IconNew from '../elements/iconsSize';

const useOutsideClickDetector = (ref, handleOutsideClick, exceptionPart) => {
   function handleClickOutside(event) {
      if (exceptionPart) {
         if (ref && ref.current && 
            ref.current.id && 
            document.getElementById(ref.current.id) && 
            !document.getElementById(ref.current.id).contains(event.target)) {
            handleOutsideClick();
         }
      } else if (ref.current && !ref.current.contains(event.target)) {
         if (exceptionPart) {
            if (!exceptionPart.current.contains(event.target)) {
               handleOutsideClick();
            }
         } else {
            handleOutsideClick();
         }
      }
   }


   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   });
};
const Select = ({
   type, options, name, className = '', label, value, onChange, isDisabled = false, placeholder, iconName, iconPositionStart = false,
   disableIconAnimation = false, helpText,
}) => {
   const [isOpen, setIsOpen] = useState(false);
   const onSelect = (selectedItem) => {
      onChange(name, selectedItem);
      setIsOpen(false);
   };
   const wrapperRef = useRef(null);
   useOutsideClickDetector(wrapperRef, () => setIsOpen(false));
   const innerSelect = value || value === 0 ? find(options, (e) => e.value === value) : { label: placeholder };
   switch (type) {
      case 'select-large':
         return (
            <div ref={ wrapperRef } role='presentation' className='SelectItem' onClick={ isDisabled ? () => {} : () => setIsOpen(!isOpen) }>
               {label && (
                  <div className='selectItem__label'>
                     <Text
                        inner={ label }
                        type={ txtTypes.regularDefault }
                        size={ txtSizes.small }
                     />
                  </div>
               )}
               <div className={ `SelectItem__button__large ${ isDisabled && ('SelectItem__disabled') } ${ isOpen && 'SelectItem__active' } ${ className }` }>
                  {iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <div>
                              <Icon className='SelectItem__button__icon' name={ iconName } />
                              <IconNew className='SelectItem__button__icon' name={ iconName } />
                           </div>
                        )

                  )}
                  <Text inner={ innerSelect ? innerSelect.label : '' } className={ `${ !value && value !== 0 ? 'SelectItem__button__large__placeholder' : '' }` } size={ txtSizes.small } type={ txtTypes.select_large } />
                  {!iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <div>
                              <Icon className='SelectItem__button__icon' name={ iconName } />
                              <IconNew className='SelectItem__button__icon' name={ iconName } />
                           </div>
                        )
                  )
                  }
               </div>
               {isOpen && (
                  <Options OptionsMap={ options } onSelect={ onSelect } />
               )}
            </div>
         );
      case 'select-transactions':
         return (
            <div ref={ wrapperRef } role='presentation' className='SelectItem' onClick={ isDisabled ? () => {} : () => setIsOpen(!isOpen) }>
               {label && (
                  <div className='selectItem__label'>
                     <Text
                        inner={ label }
                        type={ txtTypes.regularDefault }
                        size={ txtSizes.small }
                     />
                  </div>
               )}
               <div className={ `SelectItem__button__transactions ${ isDisabled ? 'SelectItem__disabled' : '' }${ isOpen ? 'SelectItem__active' : '' }${ className }` }>
                  {iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <Icon className='SelectItem__button__icon' name={ iconName } />
                        )

                  )}
                  <Text inner={ innerSelect ? innerSelect.label : '' } className={ `${ !value && value !== 0 ? 'SelectItem__button__transactions__placeholder' : '' }` } size={ txtSizes.small } type={ txtTypes.select_large } />
                  {!iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <Icon className={ disableIconAnimation ? 'SelectItem__button__icon disableIconAnimation' : 'SelectItem__button__icon' } name={ iconName } />
                        )
                  )
                  }
               </div>
               {isOpen && (
                  <Options OptionsMap={ options } onSelect={ onSelect } />
               )}
            </div>
         );
      case 'select-medium':
         return (
            <div ref={ wrapperRef } role='presentation' className='SelectItem' onClick={ isDisabled ? () => {} : () => setIsOpen(!isOpen) }>
               {label && (
                  <div className='selectItem__label'>
                     <Text
                        inner={ label }
                        type={ txtTypes.regularDefault }
                        size={ txtSizes.small }
                     />
                     {helpText
                  && <span className='inputNew_helper'>{helpText}</span>
                     }
                  </div>
               )}
               <div className={ `SelectItem__button__medium ${ isDisabled ? 'SelectItem__disabled' : '' }${ isOpen ? 'SelectItem__active' : '' }${ className }` }>
                  {iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <Icon className='SelectItem__button__icon' name={ iconName } />
                        )

                  )}
                  <Text inner={ innerSelect ? innerSelect.label : '' } className={ `${ !value && value !== 0 ? 'SelectItem__button__medium__placeholder' : '' }` } size={ txtSizes.small } type={ txtTypes.select_large } />
                  {!iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <Icon className={ disableIconAnimation ? 'SelectItem__button__icon disableIconAnimation' : 'SelectItem__button__icon' } name={ iconName } />
                        )
                  )
                  }
               </div>
               {isOpen && (
                  <Options OptionsMap={ options } onSelect={ onSelect } />
               )}
            </div>
         );
      default:
         return (
            <div ref={ wrapperRef } role='presentation' className='SelectItem' onClick={ isDisabled ? () => {} : () => setIsOpen(!isOpen) }>
               {label && (
                  <div className='selectItem__label'>
                     <Text
                        inner={ label }
                        type={ txtTypes.regularDefault }
                        size={ txtSizes.small }
                     />
                  </div>
               )}
               <div className={ `SelectItem__button ${ isDisabled && ('SelectItem__disabled') } ${ isOpen && 'SelectItem__active' } ${ className }` }>
                  {iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <Icon name={ iconName } />
                        )

                  )}
                  <Text inner={ innerSelect ? innerSelect.label : '' } className={ `${ !value && value !== 0 ? 'SelectItem__button__placeholder' : '' }` } size={ txtSizes.xsmall } type={ txtTypes.select } />
                  {!iconPositionStart && (
                     !iconName
                        ? (
                           <Icon className='SelectItem__button__icon' name='DownNew' />
                        )
                        : (
                           <Icon name={ iconName } />
                        )
                  )
                  }
               </div>
               {isOpen && (
                  <Options OptionsMap={ options } onSelect={ onSelect } />
               )}
            </div>
         );
   }
};

Select.propTypes = {
   options: PropTypes.array,
   value: PropTypes.any,
   onChange: PropTypes.func,
   iconName: PropTypes.string,
   label: PropTypes.string,
   iconPositionStart: PropTypes.bool,
   placeholder: PropTypes.string,
   type: PropTypes.string,
   className: PropTypes.string,
   isDisabled: PropTypes.bool,
   name: PropTypes.any,
   disableIconAnimation: PropTypes.bool,
   helpText: PropTypes.string,
};


export default Select;
