import React, { Component, Fragment } from "react";
import classNames from 'classnames';
import AppContext from "contexts/AppContext";
import EditorContext from "contexts/EditorContext";
import Wrapper from './Wrapper';
import {
   Button,
   Segment,
   Tab,
   Icon,
   Sidebar,
   Checkbox,
} from "semantic-ui-react";
import SectionComponentsMenu from "./SectionComponentsMenu";
import SettingsMenu from "./SettingsMenu";
import AddSectionMenu from "./AddSectionMenu";
import ViewMode from './ViewMode';
import List from "./List";
import EditorTop from "./EditorTop";
//Utils
import { getAllLandings, getAllEditableComponents, isNewApp, removeQueryParam } from "utils";
import addSection from '../../images/sidebar/add.png';
import hero from "../../images/sidebar/Hero.png";
import burger from "../../images/sidebar/burger.png";

class EditorMi extends Component {
   constructor(props) {
      super(props);
      this.state = {
         courseId:null,
         menuVisible: true,
         activeIndex: 0,
         sectionsSubMenu: false,
         editableComponent: null,
         editableSection: null,
         editableSectionOrder: null,
         settingsMenu: false,
         settingItem: "",
         currentSidebarComponent: "",
         addSectionMenu:false,
         previewModeSwitch:false,
         viewMode : "",
         currentIndex: -1,
      };
   }


   componentDidMount() {
      let url = window.location.href;
      let url_array = url.split("/");
      let courseId = url_array[url_array.length-4];
      let previewUrl = new URL(url);
      let previewMode = previewUrl.searchParams.get("preview");
      let searchParams = new URLSearchParams(window.location.search);
      this.courseId = searchParams.get('courseId')
      this.landing_id = this.props.page.split('_')[1];
      this.setState({
         courseId,
      });
      if (this.props.previewMode) {
         this.setState({
            menuVisible: false,
         });
      }
      if(previewMode==='true'){
         this.setState({
            previewModeSwitch: !this.state.previewModeSwitch,
            menuVisible: !this.state.menuVisible,
         })
      }
   }

   static getDerivedStateFromProps(nextProps, prevState){
      const currentSection = nextProps.landing[prevState.editableSectionOrder];
      const editableSection = prevState.editableSection;
      if (currentSection){
         const currentSectionJson = JSON.stringify(currentSection);
         const editableSectionJson = JSON.stringify(editableSection);
         if (currentSectionJson !== editableSectionJson) {
            return {
               editableSection: currentSection,
            };
         }
      }
      return {
         editableSection : prevState.editableSection,
      };
   }

   togglePreview = () => {
      this.props.handleUpdateTempLanding();
      // let url = window.location.href;
      // this.setState({
      //     previewModeSwitch: !this.state.previewModeSwitch,
      //     menuVisible: !this.state.menuVisible,
      // }, ()=>{
      //     if(this.state.previewModeSwitch){
      //         window.history.pushState({preview: true}, null, url);
      //         // window.history.replaceState(null, null, "?preview=true");
      //         this.props.handleUpdateTempLanding();
      //      } else {
      //         let previewUrl = new URL(url);
      //         let previewMode = previewUrl.searchParams.get("preview");
      //         if(previewMode){
      //             // let url_array = url.split("?");
      //             const newUrl = removeQueryParam(url, 'preview');
      //             window.history.replaceState(null, null, newUrl);
      //         }

      //     }
      // });
   }

   toggleSidebar = () => {
      this.setState({
         menuVisible: !this.state.menuVisible,
      });
   };

   toggleMenuSidebar = () => {
      this.setState({
         menuVisible: true,
      });
   };

   backToCurrentMenu = () => {
      this.setState({
         menuVisible: true,
         sectionsSubMenu: false,
         settingsMenu: false,
         addSectionMenu:false,
         currentIndex: -1,
      });
   };

   openAddSectionMenu = (currentIndex) => {
      this.setState({
         sectionsSubMenu: false,
         settingsMenu: false,
         addSectionMenu:true,
         currentIndex: currentIndex,
      });
   };

   showSection = (order , section_slug = null) => {
      this.props.landing[order].section.props.visibility &&
            this.setState({
               sectionsSubMenu: !this.state.sectionsSubMenu,
               editableSection: this.props.landing[order],
               editableSectionOrder: order,
            },() => {
               this.props.handleEditableSectionOrder(order);
               var selected_section = document.querySelector("#right_container #" + section_slug);
               if(selected_section){
                  selected_section.scrollIntoView({ behavior: "smooth"});
               } else {
                  console.log('Ooops something went wrong!')
               }
            });
   };

   editableSection = e => {
      e.preventDefault();
      let section_slug;
      if (e.target.closest(".Section")) {
         section_slug = e.target.closest(".Section").dataset.slug;
         let currentSidebarComponent;
         if (e.target.closest(".mark")) {
            currentSidebarComponent = e.target.closest(".mark").dataset
               .slug;
         }
         this.props.landing.forEach((section, order) => {
            if (section.section.slug === section_slug) {
               this.props.handleEditableSectionOrder(order);
               this.setState({
                  sectionsSubMenu: true,
                  editableSection: this.props.landing[order],
                  editableSectionOrder: order,
                  currentSidebarComponent: currentSidebarComponent,
               },()=>{
                  //temprorary solution
                  setTimeout(() => {
                     this.setState({
                        currentSidebarComponent: "",
                     });
                  }, 1200);
               });
            }
         });
      }
   };

   showEditableComponent = (component, key, duplicated) => {
      let editableComponents = getAllEditableComponents();
      let Component;
      if (key === -1) {
         Component = editableComponents["SectionEditable"];
      } else {
         Component = editableComponents[component.type + "Editable"];
      }
      return (
         <div>
            <Component
               {...component.props}
               section={this.state.editableSectionOrder}
               slug={component.slug}
               key={key}
               scroll={
                  component.slug === this.state.currentSidebarComponent
                     ? true
                     : false
               }
               scroll_to_options = {this.props.scroll_to_options}
               course_id={this.props.course_id}
               user_id={this.props.user_id}
               siteUUid={this.props.siteUUid}
               checkout_courses={this.props.checkout_courses}
               duplicated={ duplicated }
               defaultLanding={this.props.defaultLanding}
               toggleSidebar={() => this.toggleMenuSidebar()}
               menuVisible={this.state.menuVisible}
            />
         </div>
      );
   };

   handleTabChange = (e, { activeIndex }) => {
      this.setState({ activeIndex });
   };
   settingsMenuShow = component => {
      this.setState({
         settingsMenu: true,
         settingItem: component,
      });
   };

   handleViewMode = (type) => {
      this.setState({
         viewMode: type,
      })
   }

   render() {
      const url = window.location.href;
      const Landing = getAllLandings()[this.props.page];
      const settingPanes = ["Favicon", "Typography", "Seo", "Analytics"];
      let styles;
      if (this.state.menuVisible) {
         styles = {
            width: "calc(100vw - 350px)",
            transition: "0.65s",
         };
      } else if (!this.state.menuVisible) {
         styles = {
            width: "100vw",
            transition: "0.65s",
         };
      }
      const newApp = isNewApp(url)
      let stylePageHeader={
         minHeight: '100%',
         overflowY: 'hidden',
      }
      if(!this.props.previewMode && !this.state.previewModeSwitch){
         stylePageHeader={
            maxHeight: this.state.menuVisible
               ?  isNewApp(url) ? "calc(100vh - 70px)" : "calc(100vh - 70px)"
               : this.state.previewModeSwitch ? "calc(100vh - 50px)" : "100vh",
         }
      }
      const panes = [
         {
            menuItem: {
               key : "Sections",
               content: "Sections",
               className: "sidebar_top_section_text",
            },
            render: () => (
               <Tab.Pane style={{ padding: 0, background: 'none', border: 'none' }}>
                  <List
                     animationRotationFade={0.5}
                     rotationMultiplier={5}
                     landing={this.props.landing}
                  />
                  <div className='add-section'
                     onClick={()=>{
                        this.setState({
                           addSectionMenu:true,
                           currentIndex: -1,
                        })
                     }}
                  >
                     <div className='add-new-section'>
                        <button className='add-new-section_button'>
                           <img
                              alt='default'
                              src={addSection}
                           />
                                    Add Section
                        </button>
                     </div>
                  </div>
               </Tab.Pane>
            ),
         },
         {
            menuItem: {
               key: "Settings",
               content: "Settings",
               className: "sidebar_top_section_text",
            },
            render: () => (
               <Tab.Pane style={{ padding: 0, height: "100%" }}>
                  {settingPanes.map((setting, i) => {
                     return (
                        <div
                           className='menu-item menu-item-settings'
                           onClick={e =>
                              this.settingsMenuShow(setting)
                           }
                           key={i}
                        >
                           <div className='menu-items'>
                              <div className='menu-item-left'>
                                 <img
                                    alt='default'
                                    src={hero}
                                 />
                                 <span>Change {setting}</span>
                              </div>
                              <img
                                 alt='default'
                                 src={burger}
                              />
                           </div>
                        </div>
                     );
                  })}
               </Tab.Pane>
            ),
         },
      ];

      return (
         <Fragment>

            <div className='Editor'>
            {(!this.props.previewMode && !this.state.previewModeSwitch) && (
            <EditorTop
                viewMode={this.state.viewMode}
                setViewMode={this.handleViewMode}
                togglePreview={this.togglePreview}
            />)}
               <Sidebar.Pushable
                  style={{
                     overflowY: "hidden",
                     border: "none",
                     margin: "0",
                     minHeight: isNewApp(url) ? "calc(100vh - 70px)" : "calc(100vh - 140px)",
                  }}
                  as={Segment}
                  attached='bottom'
               >
                  {(!this.props.previewMode && !this.state.previewModeSwitch) && (
                     <Sidebar
                        animation='uncover'
                        visible={this.state.menuVisible}
                        vertical='true'
                        inline='true'
                        width='wide'
                        className='sidebarContainer'
                     >
                        <EditorContext.Provider
                           value={{
                              state: this.state,
                              functions: {
                                 backToCurrentMenu: this
                                    .backToCurrentMenu,
                                 showEditableComponent: this
                                    .showEditableComponent,
                                 showSection:this.showSection,
                              },
                              metas:this.props.landing_metas,
                           }}
                        >
                           {this.state.sectionsSubMenu ? (
                              <SectionComponentsMenu section={this.state.editableSection.section} linkComponentCount={this.props.linkComponentCount}/>
                           ) : this.state.settingsMenu ? (
                              <SettingsMenu/>
                           ) : this.state.addSectionMenu ? (
                              <AddSectionMenu newApp={ newApp } currentIndex={this.state.currentIndex}/>
                           ) : (
                              <Tab
                                 panes={panes}
                                 activeIndex={this.state.activeIndex}
                                 onTabChange={this.handleTabChange}
                                 className='Tabs'
                              />
                           )}
                        </EditorContext.Provider>
                     </Sidebar>
                  )}
                  <Sidebar.Pusher style={styles}>
                     {(!this.props.previewMode ) && (
                        <Button
                           icon
                           onClick={(!this.props.previewMode && !this.state.previewModeSwitch) ? () => this.toggleSidebar() : () => this.togglePreview()}
                           className='toggleSidebar'
                        >
                           <Icon
                              name={
                                 this.state.menuVisible
                                    ? "caret left"
                                    : "caret right"
                              }
                              size='large'
                           />
                        </Button>
                     )}


                     <div
                        className={classNames({
                           'right_container_wrapper' : this.state.viewMode !== '',
                           'right_container_style' :  !this.props.previewMode,
                        })}
                     >
                        {/* {
                           !this.props.previewMode && (
                              <div className='bottomBar'>
                                 <div className='mainMenu' >
                                    <AppContext.Consumer>
                                       {({ state, functions }) => (
                                          <div className='centered_mainMenu'>
                                             <div className='topbar_items'>
                                                <Fragment>
                                                   {!this.state.previewModeSwitch && (
                                                      <Fragment>
                                                         <div className='topbar_item undo'
                                                            onClick ={()=> {
                                                               functions.handleUndoRedo('undo');
                                                            }}
                                                         >
                                                            <img
                                                               src={require("../../images/topbar/undo_new_2.png")}
                                                               alt='Undo Changes'
                                                            />
                                                         </div>
                                                         <div className='topbar_item redo'
                                                            onClick ={()=> {
                                                               functions.handleUndoRedo('redo');
                                                            }}
                                                         >
                                                            <img
                                                               src={require("../../images/topbar/redo_new_2.png")}
                                                               alt='Redo Changes'
                                                            />
                                                         </div>

                                                      </Fragment>
                                                   )}
                                                </Fragment>
                                             </div>
                                             <div className='topbar_item redo'
                                                onClick ={ this.togglePreview }
                                             >
                                                <img
                                                   src={require("../../images/topbar/opened.png")}
                                                   alt='Redo Changes'
                                                />
                                             </div>
                                             <ViewMode
                                                currentViewMode={this.state.viewMode}
                                                handleViewMode={this.handleViewMode}
                                             />
                                             <div className='centered_mainMenu_save_button'>
                                                <Button className='save_button' id='save_button'
                                                   onClick={() =>
                                                      !state.save_button &&
                                                                        functions.handleUpdateLanding('save')
                                                   }
                                                   disabled = {state.save_button}
                                                >
                                                                    Save
                                                </Button>
                                             </div>
                                          </div>
                                       )}
                                    </AppContext.Consumer>
                                 </div>
                              </div>
                           )
                        } */}

                        <Segment
                           style={stylePageHeader}
                           className={
                              classNames({
                                 'phoneMode' : this.state.viewMode === 'phone',
                                 'tabletMode': this.state.viewMode === 'tablet',
                              })
                           }
                           id='right_container'
                           basic
                           onClick={this.editableSection}
                        >
                           <EditorContext.Provider
                              value={{
                                 state: this.state,
                                 functions: {
                                    editableSection: this
                                       .editableSection,
                                 },
                              }}
                           >
                              <Landing
                                 customLinks={this.props.customLinks}
                                 landing_metas={this.props.landing_metas}
                                 landing_courses={this.props.landing_courses}
                                 course_id={this.props.course_id}
                                 landingUrl={this.props.landingUrl}
                                 landing_prices={this.props.landing_prices}
                                 authUserRole={this.props.authUserRole}
                                 landing={this.props.landing}
                                 viewMode={this.state.viewMode}
                                 previewModeSwitch={this.state.previewModeSwitch}
                                 privacy= {this.props.privacy}
                                 terms={this.props.terms}
                                 isModeAdmin={ this.props.isModeAdmin }
                                 course={ this.props.course }
                                 openAddSectionMenu={ this.openAddSectionMenu }
                                 linkComponentCount={this.props.linkComponentCount}
                                 user_id={this.props.user_id}
                                 siteUUid={this.props.siteUUid}
                                 checkout_courses={this.props.checkout_courses}
                              />
                           </EditorContext.Provider>
                        </Segment>
                     </div>
                  </Sidebar.Pusher>
               </Sidebar.Pushable>

            </div>

            {/* {(this.state.menuVisible && !isNewApp(url)) &&  (
                    <div className='bottombar'>
                        <AppContext.Consumer>
                            {({ state, functions }) => (
                                <Fragment>
                                    <div className= 'save_container'
                                    >
                                        <Button className='large primary'
                                            onClick={() =>
                                                !state.save_button &&
                                                functions.handleUpdateLanding('save')
                                            }
                                            disabled = {state.save_button}
                                        >
                                    SAVE
                                        </Button>
                                        <Button className='large primary'
                                            onClick={() => !state.save_button && functions.handleUpdateLanding('next')}
                                            disabled = {state.save_button}
                                        >
                                    NEXT
                                        </Button>
                                    </div>
                                    <div className='bottombar-items'>
                                        <div className='bottombar-items-centered'>
                                            <div className='bottombar-item'>
                                                <a href={!state.save_button ? '/admin/courses/'+this.state.courseId+'/edit': '#'}  className='bottombar-item-link'>
                                                    <span className='bottombar-item-page'>1</span>
                                                    <span className='bottombar-item-text'>Info</span>
                                                </a>
                                            </div>
                                            <div className='bottombar-item'>
                                                <a href={!state.save_button ? '/admin/courses/'+this.state.courseId+'/course-materials' : '#'} className='bottombar-item-link' >
                                                    <span className='bottombar-item-page'>2</span>
                                                    <span className='bottombar-item-text'>Course Material</span>
                                                </a>
                                            </div>
                                            <div className='bottombar-item'>
                                                <a href={!state.save_button ? '/admin/courses/'+this.state.courseId+'/course-pricing' : '#'} className='bottombar-item-link' >
                                                    <span className='bottombar-item-page'>3</span>
                                                    <span className='bottombar-item-text'>Pricing</span>
                                                </a>
                                            </div>
                                            <div className='bottombar-item'>
                                                <a href={!state.save_button ?  '/admin/courses/'+this.state.courseId+'/create_step_two': '#'} className='bottombar-item-link' >
                                                    <span className='bottombar-item-page'>4</span>
                                                    <span className='bottombar-item-text'>Landing Page</span>
                                                </a>
                                            </div>
                                            <div className='bottombar-item'>
                                                <a href={!state.save_button ? '/admin/courses/'+this.state.courseId+'/course-checkout': '#'} className='bottombar-item-link' >
                                                    <span className='bottombar-item-page'>5</span>
                                                    <span className='bottombar-item-text'>Checkout</span>
                                                </a>
                                            </div>
                                            <div className='bottombar-item'>
                                                <a href={!state.save_button ? '/admin/courses/'+this.state.courseId+'/live': '#'} className='bottombar-item-link' >
                                                    <span className='bottombar-item-page'>6</span>
                                                    <span className='bottombar-item-text'>Live</span>
                                                </a>
                                            </div>
                                            <div className='bottombar-item'>
                                                <a href={!state.save_button ? '/admin/courses/'+this.state.courseId+'/course-checkout': '#'} className='bottombar-item-link' >
                                                    <img
                                                        src={require("../../images/topbar/arrow-right.png")}
                                                        alt='Next'
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </AppContext.Consumer>
                    </div>
                )} */}
         </Fragment>
      );
   }
}

EditorMi.defaultProps = {
   page: "landing_1",
   landing: [],
   landing_metas: "",
   landing_prices:"",
   authUserRole:"",
   previewMode:"",
   scroll_to_options:[],
};

export default EditorMi;
