import slug from "../utils/slugGenerator";
let state = {
    landing_name: "landing_6",
    landing_metas: {
        landing_favicon: require("../images/topbar/miestro.ico"),
        heading_font: "AvenirNext-Bold",
        regular_font: "AvenirNext-Medium",
        heading_text_color: "#000000",
        regular_text_color: "#000000",
        seo_title: "",
        seo_description: "",
        seo_keywords: "",
        fb_pixel_code: "",
        google_anal_resource_id: "",
        head_section_tracking_codes: "",
        after_opening_body_tag: "",
        before_closing_body_tag: "",
    },
    landing: [
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Header",
                //section_props
                props: {
                    //props:value
                    order:0,
                    duplicated:"header",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(0,0,0)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    //slug unique identifier
                    slug: slug(),
                    //used for component rendering
                    type: "Image",
                    //component name (used for printing editable components dynamic)
                    name: "Logo",
                    //component_props
                    props: {
                        //props:value
                        height: "70",
                        imgUrl: require("../images/landing_6/logo.png"),
                        href: "/",
                        visibility: true,
                        linked: true,
                        blanked: false,
                        label: "Show Logo",
                        spacing: "xs",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL NOW",
                        bgColor: "#e55151",
                        textColor: "#ffffff",
                        size: "small",
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Hero",
                //section_props
                props: {
                    //props:value
                    order:1,
                    duplicated:1,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: require("../images/landing_6/header_bg.png"),
                    bgColor: "rgb(0,0,0)",
                    background_type: "image",
                    alignmentAvalaible: true,
                    spacing: "md",
                    alignment: "center",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        fontSize: "70px",
                        text:
                            '<h2><span style="font-size:80px;font-weight:500">HOW TO PROMOTE</span></h2><h2><span style="font-size:60px;font-weight:500">YOUR MEMBER SITE</span></h2>',
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "center",
                        fontSize: "26px",
                        text:
                            '<p><span style="font-size:20px">Without the raging overwhelm, crippling tech hangups </span></p><p><span style="font-size:20px">and nightmarish what do I do next syndrome</span></p>',
                        color: "#ffffff",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#e55151",
                        textColor: "#ffffff",
                        size: "medium",
                        widthChangeable: true,
                        width: "full",
                        border: "none",
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Details",
                //section_props
                props: {
                    //props:value
                    order:2,
                    duplicated:2,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(250,250,250)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-size:50px;font-weight:500'>Start a course? Awesome!</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h2><span style='font-weight:500'><em>But where the heck do I start!?</em></span></h2>",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt u labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</span></p><p><span style="font-size: 18px;line-height: 1.5; font-weight: 500;"> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </span></p><p><span style="font-size: 18px;line-height: 1.5; font-weight: 500;">Sed ut perspiciatis unde omnis iste natus error sit</span></p><p><span style="font-size: 18px;"> doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</span></p>',
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Features",
                //section_props
                props: {
                    //props:value
                    order:3,
                    duplicated:3,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(244,245,245)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-size:50px;font-weight:500'>Here’s what you will learn</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_6/arrow.png"),
                        width: "20",
                        visibility: true,
                        spacing: "md",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px; line-height: 1.5; font-weight: 500;">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px; line-height: 1.5; font-weight: 500;">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px; line-height: 1.5; font-weight: 500;">doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</span></p>',
                    },
                },
            ],
        },
        {
            section: {
                //slug unique identifier
                slug: slug(),
                //component name (used as show name for sidebar)
                name: "Text with image",
                //section_props
                props: {
                    //props:value
                    order:4,
                    duplicated:4,
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(255,255,255)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h1><span style='font-size:44px;font-weight:500'>And to make sure you're supported </br> every step of the way,</br> you'll also get access to:</span></h1>",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "EXCLUSIVE BONUS",
                        bgColor: "#000000",
                        textColor: "#000000",
                        size: "medium",
                        linked: true,
                        blanked: false,
                        href: "",
                        widthChangeable: true,
                        width: "auto",
                        border: "solid",
                    },
                },
                {
                    slug: slug(),
                    type: "Image",
                    name: "Image",
                    props: {
                        imgUrl: require("../images/landing_6/image_compuer.png"),
                        width: "200",
                        visibility: true,
                        spacing: "xs",
                        form:'circle',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "center",
                        text:
                            "<h2><span style='font-weight:500'>Private mentroship section</span></h2>",
                        color: "#ff0000",
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;line-height: 1.5; font-weight: 500;">John Smith r sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi </span></p>',
                    },
                },
                {
                    slug: slug(),
                    type: "Text",
                    name: "Text",
                    props: {
                        align: "left",
                        text:
                            '<p><span style="font-size:18px;line-height: 1.5; font-weight: 500;">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </span></p>',
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Pricing",
                props: {
                    order:5,
                    duplicated:"pricing",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "#ffffff",
                    background_type: "color",
                    imageOverlay: true,
                    spacing: "md",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "Text",
                    name: "Title",
                    props: {
                        align: "left",
                        text:
                            "<p><span style='font-size:34px;font-weight:800'>Enroll now!</span></p>",
                        color: "#30242c",
                    },
                },
                {
                    slug: slug(),
                    type: "Pricing",
                    name: "Pricing",
                    props: {
                        text: "Montly Fee Title Here",
                        price: "$99/m",
                        textColor: "#666666",
                        textColor_selected: "#be3f3f",
                        bgColor: "#f1f3f3",
                        bgColor_selected: "#ffe0e0",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "ENROLL IN COURSE",
                        bgColor: "#e55151",
                        textColor: "#ffffff",
                        size: "medium",
                        widthChangeable: true,
                        width: "full",
                        href: "",
                        border: "none",
                        blanked: true,
                    },
                },
            ],
        },
        {
            section: {
                slug: slug(),
                name: "Footer",
                props: {
                    order:6,
                    duplicated:"footer",
                    visibility: true,
                    bgVideoSrc: "",
                    bgImgSrc: "",
                    bgColor: "rgb(0,0,0)",
                    background_type: "color",
                    spacing: "sm",
                },
            },
            components: [
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "MIESTRO COMMUNITY",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Powered by Miestro",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Privacy Policy",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Terms & Conditions",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
                {
                    slug: slug(),
                    type: "ButtonMi",
                    name: "Button Features",
                    props: {
                        text: "Contact Us",
                        bgColor: "transparent",
                        textColor: "#ffffff",
                        size: "small",
                        linked: true,
                        blanked: false,
                        href: "",
                        border: "none",
                    },
                },
            ],
        },
    ],
};

export default state;
